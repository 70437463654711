import { ProjectEvents } from 'analytics';
import Button from 'views/components/Button';
import { GqlMutationComponent, Mutations, Queries } from 'gql';
import { LoggerFactory } from 'logger';
import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import Assert from 'utils/Assert';
import { getTheme } from 'utils/SelectTheme';
import StopWatch from 'utils/StopWatch';
import StringUtils from 'utils/StringUtils';
import { toast } from 'utils/Toast';
import withSegments from 'views/components/hocs/withSegments';
import 'views/components/Modal.scss';
import TextInput from 'views/components/TextInput';
import defaultStyles from './defaultStyles';
import CancelButton from 'views/components/CancelButton';
import DropdownStyles from 'views/components/DropdownStyles';
class UpdateProjectModalView extends React.Component {
  static defaultProps = {
    isOpen: false,
    onClose: () => {},
  };

  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    project: PropTypes.shape({}).isRequired,
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        className={'modal-window'}
        onRequestClose={this.props.onClose}
        contentLabel="Update a Project"
        defaultStyles={defaultStyles}>
        <UpdateProjectView onClose={this.props.onClose} project={this.props.project} />
      </Modal>
    );
  }
}

class UpdateProjectView extends GqlMutationComponent {
  static defaultProps = {
    onClose: () => {},
  };

  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { project } = props;
    this._logger = LoggerFactory.getLogger(this);
    this._sw = null;
    this.state = {
      projectId: project.id,
      customerId: project.customer.id,
      customerName: project.customer.name,
      projectName: project.name,
      segment: {
        label: project.customer.segment.name,
        value: project.customer.segment.id.value,
      },
      subSegment: {
        label: project.customer.subSegment.name,
        value: project.customer.subSegment.id.value,
      },
    };
  }

  _onCustomerValueChange = (newValue) => this.setState({ customerName: newValue });

  _onProjectValueChange = (newValue) => this.setState({ projectName: newValue });

  _onSegmentValueChange = (newSegment) => {
    // Need to get the full segment data via the passed-in props.
    const segmentsFullData = this.props.segments || [];
    const selectedSegmentFullData = segmentsFullData.find((s) => newSegment && s.id.equivalent(newSegment.value));
    const subSegmentsForSelectedSegment = selectedSegmentFullData ? selectedSegmentFullData.subSegments : [];

    // If the current subsegment is not a part of the new segment,
    // need to reset the current subsegment.
    const needToResetSubSegment = this.state.subSegment && !subSegmentsForSelectedSegment.some((s) => s.id.equivalent(this.state.subSegment.value));

    if (needToResetSubSegment) {
      this.setState({ segment: newSegment, subSegment: null });
    } else {
      this.setState({ segment: newSegment });
    }
  };

  _onSubSegmentValueChange = (newSubSegment) => this.setState({ subSegment: newSubSegment });

  componentDidMount() {
    //start a stopwatch for timing the API query
    this._sw = StopWatch.startNew();
  }

  onCompleted(data) {
    this._logger.debug(`Project updated successfully in ${this._sw.milliseconds}ms`);

    //track project updated
    ProjectEvents.projectUpdated(data.updateProject.id);

    this.props.onClose();
  }

  onError(error) {
    this._logger.error(`An error occurred executing mutation: ${error.message}`);
    toast('An unexpected error occurred updating project', 'error');
    this.props.onClose();
  }

  mutation() {
    return Mutations.UPDATE_PROJECT;
  }

  _renderProgress() {
    return (
      <div className="modal-submit">
        <div className="loading"></div>
      </div>
    );
  }

  _renderButtons(onUpdateClick) {
    return (
      <div className="modal-submit">
        <CancelButton onClick={this.props.onClose} />
        <Button text={'Update'} onClick={onUpdateClick} color={'red'} isDisabled={!onUpdateClick} data-testid={'update-button'} />
      </div>
    );
  }

  renderMutation(updateProject, loading) {
    const { projectName, customerName, segment, subSegment } = this.state;
    const project = this.props.project;
    const segments = this.props.segments || [];
    const selectedSegment = segments.find((s) => segment && s.id.equivalent(segment.value));
    const subSegments = selectedSegment ? selectedSegment.subSegments : [];

    let onUpdateClick;

    // Client-side validation on whether can call updateProject mutation.
    const canUpdateProject =
      segment &&
      subSegment &&
      !StringUtils.isWhitespace(customerName) &&
      !StringUtils.isWhitespace(projectName) &&
      (this.state.projectName !== project.name ||
        this.state.customerName !== project.customer.name ||
        this.state.segment.value !== project.customer.segment.id.value ||
        this.state.subSegment?.value !== project.customer.subSegment.id.value);

    if (canUpdateProject) {
      onUpdateClick = async (e) => {
        e.stopPropagation();

        this._logger.debug(
          `Calling updateProject mutation.\n
                    projectName: '${this.state.projectName}',
                    customerName: '${this.state.customerName}',
                    subSegment: '${JSON.stringify(this.state.subSegment)}'
                `.replace(/  +/g, '')
        ); // Remove log whitespaces.

        // The earlier client-side validation should have these checks covered.
        // But Assert() just in case, to prevent sending the server a null/undefined
        // subsegment.
        Assert.notNull(this.state.subSegment);
        Assert.isTrue(() => !!this.state.subSegment);

        const updateProjectArgs = {
          variables: {
            param: {
              id: this.state.projectId,
              name: this.state.projectName,
              customer: {
                id: this.state.customerId,
                name: this.state.customerName,
                subSegment: {
                  id: this.state.subSegment.value,
                },
              },
            },
          },
          refetchQueries: [{ query: Queries.GET_PROJECTS }],
        };

        updateProject(updateProjectArgs);
      };
    }

    return (
      <div data-testid={'update-project-modal'}>
        <div className="modal-header">
          <h3>Update a Project</h3>
          <button className="close-modal" onClick={this.props.onClose}>
            <svg height="12" width="12">
              <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
            </svg>
          </button>
        </div>
        <div className="modal-fields modal-fields--full">
          <label>Project Name</label>
          <TextInput value={projectName} placeholder="e.g. New Network" className="textInput" onValueChange={this._onProjectValueChange} />
        </div>
        <div className="modal-fields modal-fields--full">
          <label>Customer Name</label>
          <TextInput value={customerName} placeholder="e.g. Acme Co." className="textInput" onValueChange={this._onCustomerValueChange} />
        </div>

        <div className="modal-fields">
          <label>Customer Segment</label>
          <Select
            placeholder="Select one..."
            options={
              segments.map((i) => ({
                value: i.id.value,
                label: i.name,
              })) || []
            }
            value={segment}
            isLoading={this.props.segmentsLoading}
            onChange={this._onSegmentValueChange}
            theme={getTheme}
            menuPlacement="auto"
            styles={DropdownStyles}
          />
        </div>
        <div className="modal-fields modal-fields--right">
          <label>Customer Sub-segment</label>
          <Select
            placeholder="Then select..."
            options={
              subSegments.map((i) => ({
                value: i.id.value,
                label: i.name,
              })) || []
            }
            value={subSegment}
            isLoading={this.props.segmentsLoading}
            onChange={this._onSubSegmentValueChange}
            theme={getTheme}
            menuPlacement="auto"
            styles={DropdownStyles}
          />
        </div>

        {loading ? this._renderProgress() : this._renderButtons(onUpdateClick)}
      </div>
    );
  }
}

UpdateProjectView = withSegments(UpdateProjectView);

export default UpdateProjectModalView;
