import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';

const useAddMultipleLocations = () => {
  const [doAddMultipleLocations, { data, loading, error }] = useMutation(Mutations.ADD_LOCATIONS);

  const addMultipleLocations = async (projectId, phaseId, fileReference) => {
    try {
      await doAddMultipleLocations({ variables: { projectId, phaseId, fileReference: fileReference.id, notes: '' } });
    } catch {
      return;
    }
  };

  return {
    addMultipleLocations,
    dataAddMultipleLocations: data,
    loadingAddMultipleLocations: loading,
    errorAddMultipleLocations: error,
  };
};

export default useAddMultipleLocations;
