import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { Mutations, Queries } from 'gql';
import { getTheme } from 'utils/SelectTheme';
import { dismissAllToasts } from 'utils/Toast';
import generateOffset from './generateOffset';
import Label from './Label';
import { useMutation, useQuery } from 'react-apollo';

const Labels = ({ metro, phaseId, projectId }) => {
  const { data, loading: queryLoading } = useQuery(Queries.GET_METRO_LABELS, { variables: { projectId, phaseId } });
  const [addMetroLabel, { loading: mutationLoading }] = useMutation(Mutations.ADD_METRO_LABEL);

  const [newLabel, setNewLabel] = useState('');
  const [metroLabels, setMetroLabels] = useState([]);

  useEffect(() => {
    if (!data) return;
    setMetroLabels(data.metroLabels);
  }, [data]);

  const handleNewChange = async (newLabel) => {
    setNewLabel(newLabel);
    const label = {
      value: newLabel.value,
      offset: generateOffset(metro.labels.length),
    };
    await addMetroLabel({
      variables: { projectId, phaseId, metroId: metro.id, label },
      refetchQueries: [{ query: Queries.GET_METRO_LABELS, variables: { projectId, phaseId } }],
    });
    dismissAllToasts();
    setNewLabel('');
  };
  return (
    <>
      <h3>Add Custom labels</h3>
      <div className="input new-label" data-testid={'new-label'}>
        <CreatableSelect
          placeholder="Type to add a custom label..."
          isLoading={queryLoading || mutationLoading}
          isDisabled={queryLoading || mutationLoading}
          onChange={handleNewChange}
          options={metroLabels.map((i) => ({ value: i, label: i }))}
          value={newLabel}
          theme={getTheme}
        />
      </div>
      <div className="labels">
        {metro.labels.map((label) => (
          <Label key={label.id} label={label} projectId={projectId} phaseId={phaseId} metro={metro} />
        ))}
      </div>
    </>
  );
};

export default Labels;
