import React, { useEffect } from 'react';
import User from 'model/User';
import PropTypes from 'prop-types';
import UsersOnline from './UsersOnline';
import UserMenu from './UserMenu';
import HelpAndFeedback from './HelpAndFeedback';
import { useQuery } from 'react-apollo';
import { Queries } from 'gql';
import BellIcon from 'views/components/icons/Bell';

const UserInfo = ({ project, isTemplate, user = null, onLogoutClicked = () => {} }) => {
  const { data } = useQuery(Queries.GET_SLEEKPLAN_SSO_TOKEN);

  useEffect(() => {
    if (data && window.$sleek?.setUser) {
      window.$sleek.setUser({ token: data.sleekplanSsoToken });
    }
  }, [data]);

  const openWidget = async (widget) => {
    window.$sleek.open(widget);

    window.$sleek.on('user_auth_completed', function () {
      window.$sleek.open(widget);
    });
  };

  return (
    <div className="user" data-testid={'user-info'}>
      {project && !isTemplate && <UsersOnline user={user} project={project} />}
      <button className="user_menu" data-badge-changelog onClick={() => openWidget('changelog')}>
        <BellIcon height={16} width={16} />
        What's New
      </button>
      <HelpAndFeedback openWidget={openWidget} />
      <UserMenu user={user} onLogoutClicked={onLogoutClicked} />
    </div>
  );
};

UserInfo.propTypes = {
  user: PropTypes.instanceOf(User).isRequired,
  onLogoutClicked: PropTypes.func.isRequired,
};

export default UserInfo;
