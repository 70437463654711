import React from 'react';
import ResultMessage from './ResultMessage';

const ResultMessagesWrapper = ({ errorMessages, success, rowsInserted, rowsRejected, issues }) => {
  const insertedMessage = `${rowsInserted} Locations imported`;
  const rejectedMessage = `Unable to import ${rowsRejected} Locations`;

  return (
    <React.Fragment>
      {!!errorMessages && errorMessages?.map((errorMessage, index) => <ResultMessage key={index} type={'error'} message={errorMessage} />)}

      {success && !!rowsInserted && <ResultMessage type={'success'} message={insertedMessage} />}

      {!!issues?.length && <ResultMessage type={'error'} message={rejectedMessage} details={issues} />}
    </React.Fragment>
  );
};

export default ResultMessagesWrapper;
