import React, { useState } from 'react';
import { GqlQueryComponent, Queries } from 'gql';
import AccountStrategyPlan from 'model/AccountStrategyPlan';
import { useQuery } from 'react-apollo';

class AccountStrategyPlansWrapper extends GqlQueryComponent {
  query() {
    return Queries.GET_ACCOUNT_STRATEGY_PLANS;
  }

  transform(data) {
    return AccountStrategyPlan.fromJSONArray(data.accountStrategyPlans);
  }
  renderQuery(loading, error, accountStrategyPlans) {
    return this.props.children(accountStrategyPlans, loading);
  }
}

function withAccountStrategyPlans(Component) {
  return (props) => (
    <AccountStrategyPlansWrapper {...props}>
      {(accountStrategyPlans, loading) => <Component accountStrategyPlans={accountStrategyPlans} accountStrategyPlansLoading={loading} {...props} />}
    </AccountStrategyPlansWrapper>
  );
}

export default withAccountStrategyPlans;

const useGetAccountStrategyPlans = () => {
  const [accountStrategyPlans, setAccountStrategyPlans] = useState([]);
  const { loading } = useQuery(Queries.GET_ACCOUNT_STRATEGY_PLANS, {
    onCompleted: (data) => setAccountStrategyPlans(AccountStrategyPlan.fromJSONArray(data.accountStrategyPlans)),
  });

  return { accountStrategyPlans, accountStrategyPlansLoading: loading };
};

export { useGetAccountStrategyPlans };
