import React from 'react';
import cn from 'classnames';
import './Table.scss';

export function Table(props) {
  const { children, className, headers, columns, ...otherProps } = props;
  const myClassName = cn(className, 'wb-table');
  return (
    <table className={myClassName} {...otherProps} cellSpacing="0" cellPadding="0">
      {columns && columns.length > 0 && (
        <colgroup>
          {columns.map((col, index) => {
            const style = {};
            if (typeof col === 'number') {
              style.width = col;
            }
            return <col key={`${index}-${col}`} style={style} />;
          })}
        </colgroup>
      )}
      {headers && headers.length > 0 && (
        <thead>
          <tr>
            {headers.map((header, i) => (
              <th key={`${header}-${i}`}>{header}</th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>{children}</tbody>
    </table>
  );
}

export default Table;
