import React, { useEffect, useState, useMemo } from 'react';
import 'react-notion/src/styles.css';
import SortingControlsTeam from './SortingControlsTeam';
import TeamListItem from './TeamListItem';
import './TeamView.scss';
import { getUserRoleLabel, USER_ROLES } from '../../../model/userRoles';
import { DIRECTION, FILTER, SORT } from './sortingTeam';
import { getCachedSort, setCachedSorted } from '../cachedSortingState';
import InfoIcon from 'views/components/icons/Info';
import RolesModal from '../modals/RolesModal';

const TeamList = ({ members, onUpdate, changeModalState, user }) => {
  const cachedStateKey = 'teamList';
  const userId = user.id.value;
  const { category, direction } = getCachedSort({ cachedStateKey, userId });
  const [membersFormatted, setMembersFormatted] = useState(members);
  const [searchBy, setSearchBy] = useState('');
  const [filterBy, setFilterBy] = useState(FILTER.ALL_TEAM);
  const [sortBy, setSortBy] = useState(category || SORT.USER_EMAIL);
  const [sortDirection, setSortDirection] = useState(direction || DIRECTION.ASCENDING);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (!members) return;
    const filteredBySearch = !searchBy ? members : filterByText(members, searchBy);
    const filteredByFilter = !filterBy
      ? filteredBySearch
      : filteredBySearch.filter((company) => {
          const filterFunction = FILTER_FUNCTIONS[filterBy];
          return !filterFunction || filterFunction(company);
        });
    const sortedBySort = !sortBy ? filteredByFilter : [...filteredByFilter].sort(SORT_FUNCTIONS[sortBy][sortDirection]);
    setMembersFormatted(sortedBySort);
    setCachedSorted({ userId, cachedStateKey, category: sortBy, direction: sortDirection });
    // eslint-disable-next-line
  }, [members, searchBy, sortBy, filterBy, sortDirection]);

  const setSearchText = (value) => {
    setSearchBy(value.trim().toLowerCase());
  };

  const setFilter = (value) => {
    setFilterBy(value);
  };

  const setSort = (value) => {
    setSortBy(value);
  };

  const setDirection = (value) => {
    setSortDirection(value);
  };

  const SORT_FUNCTIONS = useMemo(
    () => ({
      USER_EMAIL: {
        [DIRECTION.ASCENDING]: (a, b) => a.email.localeCompare(b.email),
        [DIRECTION.DESCENDING]: (a, b) => b.email.localeCompare(a.email),
      },
      LAST_LOG_IN: {
        [DIRECTION.ASCENDING]: (a, b) => a.lastLoginDate - b.lastLoginDate,
        [DIRECTION.DESCENDING]: (a, b) => b.lastLoginDate - a.lastLoginDate,
      },
    }),
    []
  );

  const FILTER_FUNCTIONS = useMemo(
    () => ({
      'Company Owner': (user) => user.role === USER_ROLES.companyOwner.value,
      'Team Admin': (user) => user.role === USER_ROLES.teamAdmin.value,
      'Team Member': (user) => user.role === USER_ROLES.teamMember.value,
      'Logged In': (user) => user.lastLoginDate !== 0,
      'Never Logged In': (user) => user.lastLoginDate === 0,
    }),
    []
  );

  const filterByText = (members, searchBy) => {
    return members.filter((member) => member.email.toLowerCase().includes(searchBy) || getUserRoleLabel(member.role).toLowerCase().includes(searchBy));
  };

  const renderNoTeam = () => (
    <div className="no-projects">
      <h3>No Team Members Created Yet</h3>
      <p>Get started on creating your first team member</p>
    </div>
  );

  const renderTeam = (members) => (
    <div>
      <SortingControlsTeam
        setSearchBy={setSearchText}
        setFilterBy={setFilter}
        filterBy={filterBy}
        setSortBy={setSort}
        sortBy={sortBy}
        setSortDirection={setDirection}
        sortDirection={sortDirection}
      />
      <div className={'list-subheader'}>
        <span>Last log in</span>
        <span className={'roles-subheader'}>
          Roles
          <InfoIcon onClick={() => setModalOpen(true)} width={14} height={14} viewBox={`0 0 14 14`} color="#999999" data-testid={'info-icon'} />
        </span>
      </div>
      <ul className="projects-list">
        {members?.map((member, index) => (
          <TeamListItem
            user={user}
            member={member}
            key={index}
            onDelete={() => {
              changeModalState('delete', member);
            }}
            onUpdate={onUpdate}
          />
        ))}
      </ul>
      <RolesModal
        userRole={user.role}
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen('');
        }}
      />
    </div>
  );

  return !!members?.length ? renderTeam(membersFormatted) : renderNoTeam();
};

export default TeamList;
