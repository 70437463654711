import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';

const useRemoveEdgeDeviceToMetro = () => {
  const [doRemoveEdgeDevice, { data, loading, error }] = useMutation(Mutations.REMOVE_NETWORK_EDGE_DEVICE_FROM_METRO);

  const removeEdgeDeviceFromMetro = (projectId, phaseId, metroCode, deviceId = null) =>
    doRemoveEdgeDevice({ variables: { projectId, phaseId, metroCode, deviceId } });

  return {
    removeEdgeDeviceFromMetro,
    dataRemoveEdgeDevice: data,
    loadingRemoveEdgeDevice: loading,
    errorRemoveEdgeDevice: error,
  };
};

export default useRemoveEdgeDeviceToMetro;
