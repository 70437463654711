import Assert from '../utils/Assert';
import ArgumentError from '../error/ArgumentError';

export default class GeoCoordinates {
  constructor(latitude, longitude) {
    Assert.isNumber(latitude);
    Assert.isNumber(longitude);

    this.latitude = latitude;
    this.longitude = longitude;

    this.leaflet = [this.latitude, this.longitude];

    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof GeoCoordinates)) {
      return false;
    }

    return this.latitude === other.latitude && this.longitude === other.longitude;
  }

  toString() {
    return `${this.latitude},${this.longitude}`;
  }

  toLeaflet() {
    return this.leaflet;
  }

  toJSON() {
    return this.toString();
  }

  static fromJSON(json) {
    const { latitude, longitude } = json;
    return new GeoCoordinates(latitude, longitude);
  }

  static parse(s) {
    if (s instanceof GeoCoordinates) {
      return s;
    }

    Assert.isString(s);

    const parts = s.split(',');
    if (parts.length !== 2) {
      throw new ArgumentError("Expected coordinates to be in '{latitude},{longitude}' format");
    }

    return new GeoCoordinates(parseFloat(parts[0].trim()), parseFloat(parts[1].trim()));
  }
}
