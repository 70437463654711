export const FILTER = Object.freeze({
  ALL_COMPANIES: 'ALL_COMPANIES',
  ACTIVE: 'ACTIVE',
  PENDING_DELETION: 'PENDING_DELETION',
});

export const FILTER_OPTIONS = Object.freeze({
  [FILTER.ALL_COMPANIES]: {
    label: 'All Companies',
    value: FILTER.ALL_COMPANIES,
  },
  [FILTER.ACTIVE]: {
    label: 'Active',
    value: FILTER.ACTIVE,
  },
  [FILTER.PENDING_DELETION]: {
    label: 'Pending Deletion',
    value: FILTER.PENDING_DELETION,
  },
});

export const SORT = Object.freeze({
  COMPANY_NAME: 'COMPANY_NAME',
  COMPANY_STATUS: 'COMPANY_STATUS',
});

export const SORT_OPTIONS = Object.freeze({
  [SORT.COMPANY_NAME]: {
    label: 'Company Name',
    value: SORT.COMPANY_NAME,
  },
  [SORT.COMPANY_STATUS]: {
    label: 'Company Status',
    value: SORT.COMPANY_STATUS,
  },
});

export const DIRECTION = Object.freeze({
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
});

export const formatCategoriesForSelect = (categories) => {
  const filterOptions = Object.values(FILTER_OPTIONS);
  return filterOptions.filter((option) => categories?.includes(option.label));
};

export const formatCategories = (categories) => categories.map((category) => category.label);
