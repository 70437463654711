import React, { useEffect } from 'react';
import UnconnectedList from '../UnconnectedList';
import MetroList from '../MetroList';
import MetroListItem from '../MetroListItem';
import ASPSummary from '../ASPSummary';
import RenderList from './RenderList';
import ProductEvents from 'analytics/ProductEvents';
import BulkActions from './BulkActions';
import { usePhaseItems } from 'providers/PhaseItemsProvider';
import useOnDrop from './useOnDrop';
import useOnDelete from './useOnDelete';
import { useRemoveMetro } from 'views/components/hocs/withRemoveMetro';
import { useCurrentProject } from 'providers/ProjectProvider';
import RecommendMetrosButton from './RecommendMetrosButton';
import useOnGroup from './useOnGroup';
import { useQuery } from '@apollo/react-hooks';
import { Queries } from 'gql';

const PhaseItems = ({ phase, selectedItem, isAspInfoOpen, projectId, onSelectionChanged, onClose }) => {
  const { isEditing } = useCurrentProject();
  const { checkedItems, setCheckedItems, isContextMenuOpen, onCloseContextMenu, onContextMenu, anchorPoint, pendingMoves } = usePhaseItems();
  const onDrop = useOnDrop({ selectedItem, onClose });
  const onDelete = useOnDelete({ selectedItem, onClose });
  const onGroup = useOnGroup({ selectedItem, onClose });
  const { removeMetro } = useRemoveMetro();
  const { data: metalPlans } = useQuery(Queries.GET_METAL_PLANS);
  const { data: networkEdgeCategories } = useQuery(Queries.GET_NETWORK_EDGE_CATEGORIES);

  useEffect(() => {
    if (!!checkedItems) {
      setCheckedItems([]);
    }
    // eslint-disable-next-line
  }, [phase]);

  const edgeDevices = networkEdgeCategories?.networkEdgeCategories?.map((category) => category.devices).flatten();
  const metrosWithEdgeAvailable = [...new Set(edgeDevices?.map((device) => device.metroCodes).flatten())];

  const onSelect = (selectedItem) => {
    if (!isEditing) return;
    onSelectionChanged(selectedItem);
  };

  const onRemoveMetro = async (e, projectId, phaseId, metro) => {
    e.syntheticEvent.stopPropagation();
    try {
      const { data } = await removeMetro(projectId, phaseId, metro.id);
      if (selectedItem.id === metro.id) onClose();
      data && ProductEvents.metroRemoved(metro.name);
    } catch (error) {
      return;
    }
  };

  return (
    <div className="phase-nodes" onScroll={onCloseContextMenu}>
      <UnconnectedList onDropped={onDrop}>
        <BulkActions phase={phase} onDrop={onDrop} onDelete={onDelete} onGroup={onGroup} />
        {!!phase.accountStrategyPlans.length && (
          <ASPSummary ASPCount={phase.accountStrategyPlans.length} onClick={() => onSelect('aspSummary')} isActive={isAspInfoOpen} />
        )}
        {isEditing && <RecommendMetrosButton visible={phase.unconnectedItems.length > 0} onSelectionChanged={onSelectionChanged} />}
        <RenderList
          groups={phase.unconnectedGroups}
          locations={phase.unconnectedLocations}
          services={phase.unconnectedServices}
          selectedItem={selectedItem}
          phase={phase}
          onSelect={onSelect}
          onClose={onClose}
        />
      </UnconnectedList>
      <MetroList>
        {phase.metros.map((metro) => {
          const hasMetalProducts = metalPlans?.metalPlans?.some((plan) => plan.metros.some((m) => m.id === metro.id.value));
          const hasEdgeCategories = metrosWithEdgeAvailable?.some((code) => code === metro.code.value);
          const pendingItems = pendingMoves.filter((item) => metro.id.equals(item.metroId)).length;
          return (
            <MetroListItem
              key={`metro-list-item-${metro.id}`}
              id={metro.id}
              item={metro}
              hasMetalProducts={hasMetalProducts}
              hasEdgeCategories={hasEdgeCategories}
              selectedItem={selectedItem}
              checkedItems={checkedItems}
              onSelect={onSelect}
              pendingItems={pendingItems}
              onDropped={onDrop}
              onRemoveMetro={(e) => onRemoveMetro(e, projectId, phase.id, metro)}
              onContextMenu={(e) => onContextMenu(e, 'metro', metro.id)}
              isContextMenuOpen={isContextMenuOpen.metro === metro.id}
              onCloseContextMenu={onCloseContextMenu}
              anchorPoint={anchorPoint}>
              <RenderList
                groups={metro.groups}
                locations={metro.locations}
                services={metro.services}
                metroId={metro.id}
                phase={phase}
                selectedItem={selectedItem}
                onSelect={onSelect}
                onClose={onClose}
              />
            </MetroListItem>
          );
        })}
      </MetroList>
    </div>
  );
};

export default PhaseItems;
