import ProductEvents from 'analytics/ProductEvents';
import React, { useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Checkbox from 'views/components/Checkbox';
import '../AddView.scss';
import './EditMetroView.scss';
import Connections from './Tabs/Connections';
import NotesAndLabels from './Tabs/NotesAndLabels';
import EdgeTabPanel from './Tabs/Platform/Edge/EdgeTabPanel';
import MetalTabPanel from './Tabs/Platform/Metal/MetalTabPanel';
import InfraComponentTabPanel from './Tabs/Platform/InfraComponent/InfraComponentTabPanel';
import ServicesTabPanel from './Tabs/Platform/Services/ServicesTabPanel';
import UseCases from './Tabs/UseCases';
import MarketplaceTabPanel from './Tabs/Platform/Marketplace/MarketplaceTabPanel';
import { dismissAllToasts } from 'utils/Toast';
import Button from 'views/components/Button';
import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';

const EditMetroView = ({
  selectedItem: metro,
  projectId,
  phase,
  phaseId,
  services,
  onSelectionChanged,
  activeMetros,
  directConnections,
  onMetroPanelTabChange,
  selectedTab,
  metros,
}) => {
  const [removeMetro, { loading: removeMetroLoading }] = useMutation(Mutations.REMOVE_METRO);
  const [updateMetro, { loading: updateMetroLoading }] = useMutation(Mutations.UPDATE_METRO);
  const defaultMetro = { metroId: metro?.id || '', metroNotes: metro?.notes || '', metroExisting: metro?.existing || false };
  const [metroState, setMetroState] = useState(defaultMetro);

  useEffect(() => {
    if (!metro) {
      setMetroState(defaultMetro);
    }

    //if this is a different service, clear the state
    if (!metro?.id.equals(metroState.metroId)) {
      setMetroState({ metroId: metro?.id, metroNotes: metro?.notes || '', metroExisting: !!metro?.existing });
    }
    // eslint-disable-next-line
  }, [metro]);

  const renderProgress = () => {
    return (
      <div className="buttons">
        <div className="loading"></div>
      </div>
    );
  };

  const renderButtons = (onDeleteClick) => {
    return (
      <div className="buttons">
        <Button color="red" text="Delete Metro" onClick={onDeleteClick} isStretch={true} />
      </div>
    );
  };

  const onExistingChange = () => {
    setMetroState((prev) => {
      return { ...prev, metroExisting: !prev.metroExisting };
    });
    updateMetro({ variables: { projectId, phaseId, metroId: metro.id, notes: metro.notes, existing: !metroState.metroExisting } });
  };

  const onRemoveClick = async () => {
    onSelectionChanged();
    await removeMetro({
      variables: {
        projectId,
        phaseId,
        metroId: metro.id,
      },
    });
    dismissAllToasts();
    ProductEvents.metroRemoved(metro.name);
  };

  const latencies = metro?.getPaths(activeMetros, metros).tos.filter((to) => !to.metro.id.equals(metro.id));
  return (
    <div className="AddView EditMetroView">
      {metro && (
        <>
          <h2>
            {metro.name}
            <span data-testid={'existing'}>
              <Checkbox id="existing" type="checkbox" checked={metroState.metroExisting} disabled={updateMetroLoading} onCheck={onExistingChange} />
              <label htmlFor="existing" onClick={onExistingChange}>
                Existing deployment
              </label>
            </span>
          </h2>
          <Tabs selectedIndex={selectedTab} onSelect={(index) => onMetroPanelTabChange(index)}>
            <TabList>
              <Tab>Platform Equinix</Tab>
              <Tab>Connections</Tab>
              <Tab>{metro.services.length > 0 ? '2 Use Cases' : '1 Use Case'}</Tab>
              <Tab>Notes & Labels</Tab>
            </TabList>

            <TabPanel>
              <div className="content-view tab-panel">
                <div className="platform-equinix-section">
                  <InfraComponentTabPanel projectId={projectId} phaseId={phaseId} metro={metro} />
                </div>
                <div className="platform-equinix-section">
                  <ServicesTabPanel projectId={projectId} phaseId={phaseId} metro={metro} services={services} />
                </div>
                <div className="platform-equinix-section">
                  <MarketplaceTabPanel metroCode={metro.code} metros={metros} />
                </div>
                <div className="platform-equinix-section">
                  <MetalTabPanel projectId={projectId} phaseId={phaseId} metro={metro} />
                </div>
                <div className="platform-equinix-section">
                  <EdgeTabPanel projectId={projectId} phaseId={phaseId} metro={metro} />
                </div>
              </div>
            </TabPanel>

            <TabPanel>
              <Connections
                metro={metro}
                activeMetros={activeMetros}
                directConnections={directConnections}
                latencies={latencies}
                projectId={projectId}
                phaseId={phaseId}
              />
            </TabPanel>

            <TabPanel>
              <UseCases phase={phase} metro={metro} />
            </TabPanel>

            <TabPanel className="note">
              <NotesAndLabels projectId={projectId} phaseId={phaseId} metro={metro} />
            </TabPanel>
          </Tabs>
          {removeMetroLoading ? renderProgress() : renderButtons(onRemoveClick)}{' '}
        </>
      )}
    </div>
  );
};

export default EditMetroView;
