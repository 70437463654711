import React from 'react';
import Labels from './Labels';
import Notes from './Notes';

const NotesAndLabels = (props) => {
  const { metro, phaseId, projectId } = props;
  return (
    <React.Fragment>
      <Labels projectId={projectId} phaseId={phaseId} metro={metro} />
      <Notes projectId={projectId} phaseId={phaseId} metro={metro} />
    </React.Fragment>
  );
};

export default NotesAndLabels;
