import React from 'react';
import { Pane } from 'react-leaflet';
import PlatformMarker from './PlatformMarker';
import GrayOverlay from './GrayOverlay';
import usePlatformMarker from './hooks/usePlatformMaker';
import { dismissAllToasts } from 'utils/Toast';
import { useCurrentProject } from 'providers/ProjectProvider';

const PlatformEquinixOverlay = ({ platformMetros, projectId, phaseId, onSelect, iconSize, product, platform }) => {
  const { onEnable, onDisable, loading, getIcon } = usePlatformMarker(platform, product);
  const { isEditing } = useCurrentProject();

  const _onEnable = async (metroCode, id) => {
    await onEnable(projectId, phaseId, metroCode, id);
    dismissAllToasts();
  };

  const _onDisable = async (metroCode, id) => {
    await onDisable(projectId, phaseId, metroCode, id);
    dismissAllToasts();
  };

  return (
    <React.Fragment>
      <GrayOverlay />
      <Pane name="platform-markers" style={{ zIndex: 1750 }}>
        {platformMetros?.map((metro) => (
          <PlatformMarker
            key={metro.id}
            phaseId={phaseId}
            metro={metro}
            platform={platform}
            onSelect={onSelect}
            iconSize={iconSize}
            getIcon={getIcon}
            product={product}
            loading={loading}
            onEnable={_onEnable}
            onDisable={_onDisable}
            isEditing={isEditing}
          />
        ))}
      </Pane>
    </React.Fragment>
  );
};

export default PlatformEquinixOverlay;
