export default class StringUtils {
  static isNull(s) {
    return s == null;
  }

  static isBlank(s) {
    return StringUtils.isNull(s) || s === '';
  }

  static isWhitespace(s) {
    return s == null || StringUtils.isBlank(s.trim());
  }
}
