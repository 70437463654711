import React, { Component, Fragment, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Queries, Mutations } from 'gql';
import { ProjectEvents } from 'analytics';
import noop from 'lodash/noop';
import StopWatch from 'utils/StopWatch';
import Project from 'model/Project';
import OutsideAlerter from '../OutsideAlerter';
import './ProjectListItem.scss';

class ProjectListItem extends Component {
  static defaultProps = {
    project: null,
    onSelected: noop,
  };

  static propTypes = {
    project: PropTypes.instanceOf(Project).isRequired,
    onSelected: PropTypes.func.isRequired,
    duplicateProject: PropTypes.shape({
      mutation: PropTypes.func.isRequired,
      loading: PropTypes.bool.isRequired,
      error: PropTypes.shape({}),
    }).isRequired,
    onDeleteProjectClick: PropTypes.func,
    onLeaveProjectClick: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this._sw = null;
  }

  _onOpenClick = async () => this.props.onSelected(this.props.project);

  componentDidMount() {
    //start a stopwatch for timing the API query
    this._sw = StopWatch.startNew();
  }

  render() {
    const { project, duplicateProject, onDeleteProjectClick, onLeaveProjectClick, sharedWithMe, loading, onOpenOptionsMenu, isOptionsMenuOpen } = this.props;
    const customer = project.customer;

    if (duplicateProject.loading) {
      return (
        <Fragment>
          <li className="projects-list_item">
            <div className="project-meta">
              <span className="customer-name">{customer.name}</span>
              <span className="project-name">{project.name}</span>
            </div>
            <div className="project-controls">
              <div className="delete-project"></div>
            </div>
          </li>
          <li className="projects-list_item deleting">
            <div className="project-meta">
              <span className="customer-name muted">Duplicating Project</span>
            </div>
            <div className="project-controls">
              <div className="delete-project"></div>
            </div>
          </li>
        </Fragment>
      );
    }

    if (duplicateProject.error) {
      return (
        <Fragment>
          <li className="projects-list_item delete-failed">
            <div className="project-meta">
              <span className="customer-name">{customer.name}</span>
              <span className="project-name">{project.name}</span>
            </div>
          </li>

          <li className="projects-list_item delete-failed" alt={duplicateProject.error.message} title={duplicateProject.error.message}>
            <div className="project-meta">
              <span className="customer-name">{customer.name}</span>
              <span className="project-name">{project.name}</span>
            </div>
            <div className="project-controls">
              <div className="update-project">!</div>
              <div className="duplicate-project">!</div>
              <div className="delete-project">!</div>
            </div>
          </li>
        </Fragment>
      );
    }

    const duplicateProjectArgs = {
      variables: { projectId: project.id },
      refetchQueries: [{ query: Queries.GET_PROJECTS }],
    };

    const _onDeleteProjectClick = sharedWithMe
      ? null
      : async (e) => {
          e.stopPropagation();
          onDeleteProjectClick(project);
        };
    const onDuplicateClick = async (e) => {
      e.stopPropagation();
      duplicateProject.mutation(duplicateProjectArgs);
    };
    const onUpdateClick = this.props.onUpdateProject
      ? async (e) => {
          e.stopPropagation();
          this.props.onUpdateProject(project);
        }
      : null;
    const onShareClick = this.props.onShareProject
      ? async (e) => {
          e.stopPropagation();
          this.props.onShareProject(project);
        }
      : null;

    const onLeaveClick = async (e) => {
      e.stopPropagation();
      onLeaveProjectClick(project);
    };

    const onOptionsMenuClick = (e) => {
      e.stopPropagation();
      onOpenOptionsMenu(!isOptionsMenuOpen ? project.id : null);
    };

    const onClickOutsideOptions = () => {
      onOpenOptionsMenu(null);
    };

    const sharedUsersCount = project.sharedUsers.length;

    return (
      <li className="projects-list_item" onClick={this._onOpenClick}>
        <div className={cn('project-meta', { 'is-shared-with-me': sharedWithMe })}>
          <span className="customer-name">{customer.name}</span>
          <span className="project-name">{project.name}</span>
        </div>
        <div className="project-shared-users">
          <SharedList sharedWithMe={sharedWithMe} sharedUsers={project.sharedUsers} />
          {sharedWithMe && <span className="owner-name">Shared by {project.owner.email}</span>}
        </div>
        <div className={cn('project-controls', { 'options-menu-open': isOptionsMenuOpen })}>
          <OutsideAlerter onClickOutside={onClickOutsideOptions} style={{ display: 'flex' }}>
            <>
              {onShareClick && (
                <button className={sharedUsersCount ? 'is-shared-project' : 'share-project'} onClick={onShareClick}>
                  {sharedUsersCount ? `Shared (${sharedUsersCount})` : 'Share'}
                </button>
              )}
              {sharedWithMe &&
                (loading ? (
                  <span className="loading" />
                ) : (
                  <button className="leave-project" onClick={onLeaveClick}>
                    Leave
                  </button>
                ))}
              {onUpdateClick && (
                <button className="update-project" onClick={onUpdateClick}>
                  Edit
                </button>
              )}
              <button className="duplicate-project" onClick={onDuplicateClick}>
                Copy
              </button>
              {_onDeleteProjectClick && <button className="delete-project" onClick={_onDeleteProjectClick}></button>}
              <button className="options-menu-btn" onClick={onOptionsMenuClick} title={isOptionsMenuOpen ? 'Hide options' : 'Options'}></button>
            </>
          </OutsideAlerter>
        </div>
      </li>
    );
  }
}

const withDuplicateProject = (WrappedComponent) => {
  return class WithDuplicateProject extends Component {
    constructor(props) {
      super(props);

      this.onCompleted = this.onCompleted.bind(this);
    }

    onCompleted(data) {
      //track project duplicated
      ProjectEvents.projectDuplicated(this.props.project.id.toString(), data.duplicateProject.id.toString());
    }

    render() {
      return (
        <Mutation mutation={Mutations.DUPLICATE_PROJECT} onCompleted={this.onCompleted}>
          {(duplicateProject, { loading, error }) => {
            const duplicateProjectObj = { mutation: duplicateProject, loading, error };
            return <WrappedComponent duplicateProject={duplicateProjectObj} {...this.props} />;
          }}
        </Mutation>
      );
    }
  };
};

function SharedList(props) {
  const { sharedWithMe, sharedUsers } = props;
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const measuredRef = useCallback((node) => {
    if (node !== null) {
      const rect = node.getBoundingClientRect();
      setPosition({ top: rect.top + 27, left: rect.left - 13 });
    }
  }, []);

  if (sharedUsers.length <= 0) {
    return null;
  }

  return (
    <i className={cn('shared', { sharedWithMe })} onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)} ref={measuredRef}>
      {visible &&
        ReactDOM.createPortal(
          <div style={position} className="shared-list">
            {sharedUsers.map((user) => (
              <div key={user.id}>{user.email}</div>
            ))}
          </div>,
          document.body
        )}
    </i>
  );
}

ProjectListItem = withDuplicateProject(ProjectListItem);

export default ProjectListItem;
