import React from 'react';

const OfflineIcon = ({ width = 10, height = 10, color = 'black', background = 'white', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill={background} />
      <g clipPath="url(#clip0_1048:81359)">
        <path
          d="M13.5732 9.04744C13.2427 7.37036 11.7698 6.11133 10.0003 6.11133C9.28088 6.11133 8.61491 6.32036 8.05102 6.68008L8.76074 7.3898C9.13019 7.19536 9.5531 7.08355 10.0003 7.08355C11.4781 7.08355 12.6739 8.27938 12.6739 9.75716V10.0002H13.4031C14.21 10.0002 14.8614 10.6516 14.8614 11.4586C14.8614 12.0079 14.5503 12.4842 14.1031 12.7322L14.808 13.437C15.4253 12.9947 15.8337 12.2752 15.8337 11.4586C15.8337 10.1752 14.8371 9.13494 13.5732 9.04744ZM5.62533 6.72869L6.96213 8.06063C5.41144 8.12869 4.16699 9.4023 4.16699 10.9724C4.16699 12.5815 5.47463 13.8891 7.08366 13.8891H12.7857L13.758 14.8613L14.3753 14.244L6.24269 6.11133L5.62533 6.72869ZM7.92463 9.02799L11.8135 12.9169H7.08366C6.00935 12.9169 5.13921 12.0467 5.13921 10.9724C5.13921 9.89813 6.00935 9.02799 7.08366 9.02799H7.92463Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1048:81359">
          <rect width="11.6667" height="11.6667" fill={color} transform="translate(4.16699 4.16699)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OfflineIcon;
