import React from 'react';
import ObjectUtils from 'utils/ObjectUtils';

export default class SelectorItem extends React.Component {
  static defaultProps = {
    className: '',
    index: -1,
    item: null,
    content: null,
    selected: false,
    onSelected: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.selected,
    };

    this._onClick = this._onClick.bind(this);
  }

  get isSelected() {
    return this.state.selected;
  }

  set isSelected(value) {
    //if our state hasn't changed, nothing to do
    if (this.isSelected === value) {
      return;
    }

    this.setState({ selected: value });

    //if selected == true, fire an event
    if (value) {
      this.props.onSelected(this);
    }
  }

  get index() {
    return this.props.index;
  }

  get item() {
    return this.props.item;
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.isSelected !== nextState.selected || !ObjectUtils.equals(this.item, nextProps.item);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.isSelected = nextProps.selected;
  }

  render() {
    const className = this.props.className ? `${this.props.className} ` : '';
    return (
      <li className={this.isSelected ? `${className}selected` : className} key={this.index} onClick={this._onClick}>
        {this.props.content}
      </li>
    );
  }

  toString() {
    return `SelectorItem { index: ${this.index}, item: "${this.item.toString()}", selected: ${this.isSelected} }`;
  }

  _onClick() {
    this.isSelected = true;
  }
}
