import React from 'react';
import Select from 'react-select';
import { components } from 'react-select/dist/react-select.cjs.prod';
import AddressAutocompleteTextBox from '../AddressAutocompleteTextBox';
import { getTheme } from 'utils/SelectTheme';
import './AddressAutocompleteWithType.scss';
import DropdownStyles from '../DropdownStyles';

const AddressAutocompleteWithType = ({ options, address, onChangeLocationAddress, typeLocation, onChangeTypeLocation, clearSelected = false }) => {
  const { Option } = components;

  const IconOption = (props) => {
    const { icon, label } = props.data;
    return (
      <Option {...props} className={'option-select-type'}>
        {icon}
        <span>{label}</span>
      </Option>
    );
  };

  return (
    <div className="AddressAutocompleteWithType">
      <div className="select-wrapper">
        <Select
          options={options}
          value={typeLocation ?? options[0]}
          theme={getTheme}
          onChange={onChangeTypeLocation}
          classNamePrefix="select"
          components={{
            Option: IconOption,
            SingleValue: (props) => {
              return props.data.icon;
            },
          }}
          isSearchable={false}
          styles={DropdownStyles}
          data-testid={'type-location-select'}
        />
      </div>
      <AddressAutocompleteTextBox
        placeholder="Address or city name..."
        address={address ? address.label : ''}
        onAddressChange={onChangeLocationAddress}
        clearSelected={clearSelected}
        autoFocus={true}
      />
    </div>
  );
};

export default AddressAutocompleteWithType;
