import ProductEvents from 'analytics/ProductEvents';
import { LoggerFactory } from 'logger';
import { useMutation } from 'react-apollo';

const { Mutations } = require('gql');
const logger = LoggerFactory.getLogger('delete location');

const useDeleteLocation = () => {
  const [doDeleteLocation] = useMutation(Mutations.DELETE_PROJECT_ITEMS);

  const deleteLocation = async (projectId, phaseId, location) => {
    logger.debug(`Deleting location: ${location} from phase with id: ${phaseId}`);

    await doDeleteLocation({
      variables: {
        projectId,
        phaseId,
        locationIds: [location.id],
        locationGroupIds: [],
        serviceConnectionIds: [],
      },
    });
    ProductEvents.locationRemoved(location.type.toString());
  };
  return { deleteLocation };
};

export default useDeleteLocation;
