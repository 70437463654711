import BaseModel from './BaseModel';
import InfraComponentId from './InfraComponentId';

export default class InfraComponent extends BaseModel {
  static fields = [
    { key: 'id', type: InfraComponentId, stringify: true },
    { key: 'name', type: 'string', stringify: true },
    { key: 'deployment', type: 'string' },
    { key: 'device', type: 'string' },
    { key: 'isEnabled', type: 'boolean' },
    { key: 'isVisible', type: 'boolean' },
    { key: 'isCustom', type: 'boolean' },
    { key: 'deviceFilter', type: 'string' },
    { key: 'allowedDeployments', type: 'array', of: 'string' },
  ];
}
