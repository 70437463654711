import ReactDOM from 'react-dom';
import { Control } from 'leaflet';
import { MapControl, withLeaflet } from 'react-leaflet';

class LeafletDiv extends Control {
  onAdd() {
    const div = document.createElement('div');
    div.ondblclick = (e) => e.stopPropagation();
    this.div = div;
    const { text, className } = this.options;
    if (text) this.setText(text);
    if (className) this.addClassName(className);
    return div;
  }
  setText(text) {
    if (this.div) this.div.innerText = text;
  }
  addClassName(className) {
    if (this.div) this.div.classList.add(...className.split(' '));
  }
  removeClassName(className) {
    if (this.div) this.div.classList.remove(...className.split(' '));
  }
}

class LatencyDisclaimer extends MapControl {
  createLeafletElement() {
    const d = new LeafletDiv({
      position: this.props.position,
      text: this.props.text,
      className: 'leaflet-control-attribution leaflet-control latency-disclaimer',
    });
    return d;
  }
  updateLeafletElement(fromProps, toProps) {
    if (fromProps.text !== toProps.text) this.leafletElement.setText(toProps.text);
    // if(fromProps.className !== toProps.className) {
    //     this.leafletElement.removeClassName(fromProps.className);
    //     this.leafletElement.addClassName(toProps.className);
    // }
  }
  render() {
    if (this.props.children) {
      return ReactDOM.createPortal(this.props.children, document.querySelector('section.map'));
    } else {
      return null;
    }
  }
}

export default withLeaflet(LatencyDisclaimer);
