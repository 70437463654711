import React from 'react';
import ArrayUtils from 'utils/ArrayUtils';
import Assert from 'utils/Assert';
import ObjectUtils from 'utils/ObjectUtils';
import SelectorItem from '../SelectorItem';
import './Selector.scss';

export default class Selector extends React.Component {
  static defaultProps = {
    className: 'selector',
    itemClassName: '',
    selectedIndex: -1,
    selectedItem: null,
    items: [],
    selectorItemClass: SelectorItem,
    onSelectionChanged: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: this._props.selectedIndex,
      selectedItem: this._props.selectedItem,
    };

    this._onItemSelected = this._onItemSelected.bind(this);

    //set our selected index
    this.state.selectedIndex = ArrayUtils.indexOf(this.items, this.state.selectedItem);
  }

  get items() {
    return this._props.items;
  }

  get selectedItem() {
    return this.state.selectedItem;
  }

  set selectedItem(value) {
    this.selectedIndex = ArrayUtils.indexOf(this.items, value);
  }

  get selectedIndex() {
    return this.state.selectedIndex;
  }

  set selectedIndex(value) {
    Assert.isInteger(value);

    //if this is already the selected index, nothing to do
    if (this.selectedIndex === value) {
      return;
    }

    //get the item for this index
    const item = value === -1 ? null : this.items[value];
    this.setState({ selectedItem: item, selectedIndex: value }, () => this.onSelectionChanged(item, value));
  }

  onSelectionChanged(selectedItem, selectedIndex) {
    this._props.onSelectionChanged(selectedItem, selectedIndex);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!ObjectUtils.equals(this.selectedItem, nextProps.selectedItem)) {
      const selectedIndex = ArrayUtils.indexOf(nextProps.items, nextProps.selectedItem);
      this.setState({ selectedItem: nextProps.selectedItem, selectedIndex: selectedIndex });
    }
  }

  renderPanelTemplate(content) {
    return <ul className={this._props.className}>{content}</ul>;
  }

  renderItem(item) {
    return item.toString();
  }

  render() {
    const content = this._props.items.map((item, index) => this._createSelectorItem(item, index));
    return this.renderPanelTemplate(content);
  }

  toString() {
    return `Selector { selectedIndex: ${this.selectedIndex}, items: ${this.items.length} }`;
  }

  get _props() {
    return Object.assign({}, Selector.defaultProps, this.props);
  }

  _onItemSelected(selectorItem) {
    this.selectedIndex = selectorItem.index;
  }

  _createSelectorItem(item, index) {
    //create a selector item dynamically using the SelectorItem class defined by 'selectorItemClass'
    const selected = index === this.selectedIndex;
    const props = {
      index: index,
      key: index,
      className: this._props.itemClassName,
      item: item,
      selected,
      content: this.renderItem(item, index, selected),
      onSelected: this._onItemSelected,
    };

    return React.createElement(this._props.selectorItemClass, props);
  }
}
