import React from 'react';

const SunIcon = ({ width = '20', height = '20', color = 'black', ...rest }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_4440_2064)">
      <path
        d="M9.99984 13.3337C11.8408 13.3337 13.3332 11.8413 13.3332 10.0003C13.3332 8.15938 11.8408 6.66699 9.99984 6.66699C8.15889 6.66699 6.6665 8.15938 6.6665 10.0003C6.6665 11.8413 8.15889 13.3337 9.99984 13.3337Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10 2.66699V4.00033" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10 16V17.3333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.81348 4.81348L5.76014 5.76014" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.2402 14.2402L15.1869 15.1869" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.6665 10H3.99984" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16 10H17.3333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.81348 15.1869L5.76014 14.2402" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.2402 5.76014L15.1869 4.81348" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_4440_2064">
        <rect width="16" height="16" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

export default SunIcon;
