import React from 'react';
import { Map, Marker, TileLayer } from 'react-leaflet';
import filterMetrosByType from 'utils/filterMetrosByType';
import ActiveMetro from './ActiveMetro';
import Clusterer from './Clusterer';
import DirectConnection from './DirectConnection';
import { getIcon } from './icons';
import LatencyDisclaimer from './LatencyDisclaimer';
import LatencyRingsInfo from './LatencyRingsInfo';
import MarkerGroup from './MarkerGroup';
import MetroConnections from './MetroConnections';
import PhaseLocation from './PhaseLocation';
import ServiceRegionMarker from './ServiceRegionMarker';
import { getLocations, getServiceRegions } from './utils';
import MarketplaceLayer from './MarketplaceLayer';
import { getMapStyle, getMapStyleUrl } from './helpers/storeMapStyle';

export default class ExportMap extends React.Component {
  componentDidMount() {
    this._map.leafletElement.invalidateSize();
  }

  getMapZoom = () => this.map?.leafletElement.getZoom();

  render() {
    const { mini, bounds, center, zoom, phase, metros, connectionType, layers, ringLatency, ringColor, metroType, marketplaceSegment, mapType } = this.props;
    const iconSize = mini ? 0.25 : 1;
    let otherProps = {};
    if (center && zoom) {
      otherProps = { center, zoom };
    } else {
      otherProps = { bounds };
    }
    const locations = getLocations(phase, layers);
    const regions = getServiceRegions(phase, null);
    const activeMetros = filterMetrosByType(phase.metros, metroType);

    return (
      <Map
        maxBounds={bounds}
        dragging={false}
        zoomControl={false}
        zoomSnap={0.1}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        touchZoom={false}
        maxZoom={10}
        attributionControl={false}
        ref={(ref) => (this._map = ref)}
        {...otherProps}>
        {mini && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 500,
            }}></div>
        )}
        {layers.latencyRings && !mini && (
          <LatencyRingsInfo
            color={ringColor}
            latency={ringLatency}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 1200,
            }}
          />
        )}
        <MarketplaceLayer mapType={mapType} marketplaceSegment={marketplaceSegment} metros={metros} isMinimap={mini} zoom={zoom || this.getMapZoom()} />
        <TileLayer
          url={getMapStyleUrl(getMapStyle())}
          attribution={
            '<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>'
          }
          noWrap
        />
        {!mini && <LatencyDisclaimer position="bottomleft" text="Latencies represent round-trip times and are estimated values." />}
        {layers.customerLocations &&
          phase.sortedGroups.map(([color, locations]) => (
            <MarkerGroup key={color} data={locations.map((l) => l.coordinates.toLeaflet())} color={color} iconSize={iconSize} />
          ))}
        {layers.inactiveMetros &&
          metros.map((metro) => <Marker key={metro.id} position={metro.coordinates.toLeaflet()} icon={getIcon('metro', iconSize)} zIndexOffset={-1000} />)}
        {activeMetros.map((metro) => (
          <ActiveMetro key={metro.id} metro={metro} iconSize={iconSize} layers={layers} ringLatency={ringLatency} ringColor={ringColor} />
        ))}
        <Clusterer iconSize={iconSize}>
          {layers.customerLocations && locations.map((location) => <PhaseLocation key={location.id} location={location} iconSize={iconSize} />)}
        </Clusterer>
        {regions.map((spec) => (
          <ServiceRegionMarker
            service={spec.service}
            key={spec.region.id}
            region={spec.region}
            selected={spec.selected}
            enabled={spec.enabled}
            connections={spec.connections}
            metros={metros}
            layers={layers}
            iconSize={iconSize}
          />
        ))}
        {layers.connections && (
          <MetroConnections
            connectionType={connectionType}
            metros={metros}
            activeMetros={activeMetros}
            noText={mini || layers.latencies === false}
            iconSize={iconSize}
            isExport
          />
        )}
        {layers.directConnections &&
          phase.directConnections.map((dm) => {
            const fromMetro = dm.source;
            return dm.connectedTo.map((dc) => {
              return (
                <DirectConnection
                  key={`${fromMetro} => ${dc.metro}`}
                  fromMetro={fromMetro}
                  toMetro={dc.metro}
                  text={dc.label}
                  color={dc.color}
                  iconSize={iconSize}
                  isExport
                />
              );
            });
          })}
      </Map>
    );
  }
}
