import React from 'react';

const PlusIcon = ({ width = 10, height = 10, color, ...rest }) => {
  return (
    <svg width={width} height={height} fill={color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <line x1="5.69421" y1="0.97644" x2="5.69421" y2="10.9845" stroke={color ? color : 'currentColor'} fill={color ? color : 'currentColor'} />
      <line x1="10.7101" y1="6.18579" x2="0.290729" y2="6.18579" stroke={color ? color : 'currentColor'} fill={color ? color : 'currentColor'} />
    </svg>
  );
};

export default PlusIcon;
