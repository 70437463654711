import React from 'react';
import getActivityMonitorService from './ActivityMonitorService';

export { getActivityMonitorService };
export { default as ActivityMonitor } from './ActivityMonitor';

const withActivityMonitor = (WrappedComponent) => {
  return (props) => {
    return <WrappedComponent isUserActive={getActivityMonitorService().isUserActive} {...props} />;
  };
};

export { withActivityMonitor };

const useActivityMonitor = () => {
  return { isUserActive: getActivityMonitorService().isUserActive };
};
export { useActivityMonitor };
