import MagnifyingGlassIcon from 'views/components/icons/MagnifyingGlass';
import TimesCircleSolidIcon from 'views/components/icons/TimesCircleSolid';
import './Searchfield.scss';
import React from 'react';

const Searchfield = ({ onChange, onSearchClear, placeholder, value, filterText }) => {
  return (
    <div className="filter-input-container">
      <div className={'magnifying-glass'}>
        <MagnifyingGlassIcon width={13} height={13} color="#333333" />
      </div>
      <input className="filter-input" type="test" onChange={onChange} placeholder={placeholder} value={value} data-testid={'filter-input'} />
      <div className="clear-search-icon" onClick={onSearchClear}>
        {!!filterText && <TimesCircleSolidIcon width={13} height={13} color="#3E3E3E" />}
      </div>
    </div>
  );
};

export default Searchfield;
