import React from 'react';

const ArrowIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M1.24265 9.48535L5.48529 5.24271L1.24264 1.00007" stroke={color} />
    </svg>
  );
};

export default ArrowIcon;
