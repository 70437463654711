import React from 'react';

const DocumentsIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M17.2031 2.82812L15.1719 0.796875C14.9896 0.614583 14.7812 0.484375 14.5469 0.40625C14.3385 0.302083 14.1042 0.25 13.8438 0.25H7.125C6.60417 0.25 6.16146 0.432292 5.79688 0.796875C5.43229 1.16146 5.25 1.60417 5.25 2.125V4H2.125C1.60417 4 1.16146 4.18229 0.796875 4.54688C0.432292 4.91146 0.25 5.35417 0.25 5.875V18.375C0.25 18.8958 0.432292 19.3385 0.796875 19.7031C1.16146 20.0677 1.60417 20.25 2.125 20.25H10.875C11.3958 20.25 11.8385 20.0677 12.2031 19.7031C12.5677 19.3385 12.75 18.8958 12.75 18.375V16.5H15.875C16.3958 16.5 16.8385 16.3177 17.2031 15.9531C17.5677 15.5885 17.75 15.1458 17.75 14.625V4.15625C17.75 3.89583 17.6979 3.66146 17.5938 3.45312C17.5156 3.21875 17.3854 3.01042 17.2031 2.82812ZM14 1.53906C14.0521 1.53906 14.1042 1.55208 14.1562 1.57812C14.2083 1.60417 14.2474 1.64323 14.2734 1.69531L16.3047 3.72656C16.3568 3.7526 16.3958 3.79167 16.4219 3.84375C16.4479 3.89583 16.4609 3.94792 16.4609 4H14V1.53906ZM11.5 18.375C11.5 18.5573 11.4349 18.7135 11.3047 18.8438C11.2005 18.9479 11.0573 19 10.875 19H2.125C1.94271 19 1.78646 18.9479 1.65625 18.8438C1.55208 18.7135 1.5 18.5573 1.5 18.375V5.875C1.5 5.69271 1.55208 5.54948 1.65625 5.44531C1.78646 5.3151 1.94271 5.25 2.125 5.25H5.25V14.625C5.25 15.1458 5.43229 15.5885 5.79688 15.9531C6.16146 16.3177 6.60417 16.5 7.125 16.5H11.5V18.375ZM16.5 14.625C16.5 14.8073 16.4349 14.9635 16.3047 15.0938C16.2005 15.1979 16.0573 15.25 15.875 15.25H7.125C6.94271 15.25 6.78646 15.1979 6.65625 15.0938C6.55208 14.9635 6.5 14.8073 6.5 14.625V2.125C6.5 1.94271 6.55208 1.79948 6.65625 1.69531C6.78646 1.5651 6.94271 1.5 7.125 1.5H12.75V4.3125C12.75 4.57292 12.8411 4.79427 13.0234 4.97656C13.2057 5.15885 13.4271 5.25 13.6875 5.25H16.5V14.625Z"
      fill={color}
    />
  </svg>
);

export default DocumentsIcon;
