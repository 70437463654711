import BaseModel from './BaseModel';

import SegmentId from './SegmentId';
import SubSegment from './SubSegment';

export default class Segment extends BaseModel {
  static fields = [
    { key: 'id', type: SegmentId, stringify: true },
    { key: 'name', type: 'string', stringify: true },
    { key: 'subSegments', type: 'array', of: SubSegment },
  ];
}
