import React from 'react';
import { Mutations, withMutation } from 'gql';
import './AspDataChangeNotification.scss';

const withAcknowledgeASPUpdate = withMutation({
  name: 'ACKNOWLEDGE_ACCOUNT_STRATEGY_PLAN_UPDATE',
  mutation: Mutations.ACKNOWLEDGE_ACCOUNT_STRATEGY_PLAN_UPDATE,
  mutateProp: 'acknowledgeASPUpdate',
  getMutationArgs(projectId, phaseId, aspId) {
    return {
      variables: { projectId, phaseId, aspId },
    };
  },
});

function AspDataChangeNotification(props) {
  const { projectId, phase, onClose, acknowledgeASPUpdate, loading } = props;

  //TODO: remove this - placeholder to show usage
  const updatedASPs = phase.accountStrategyPlans.filter((asp) => asp.updateAvailable);

  const unacknowlgedASPs = updatedASPs.filter((asp) => !asp.updateAcknowledged);
  if (unacknowlgedASPs.length <= 0) {
    return null;
  }

  async function acknowledge() {
    await Promise.all(unacknowlgedASPs.map((asp) => acknowledgeASPUpdate(projectId, phase.id.value, asp.id)));
    onClose();
  }

  return (
    <div className="asp-data-changed" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <button className="close" onClick={onClose}>
          &times;
        </button>
        <h2>DSP Data Change Notification</h2>
        <p>The DSP customer data used in this phase has been updated since last open.</p>
        {updatedASPs.map((asp) => (
          <p key={asp.id}>
            Customer: <strong>{asp.company}</strong>
            <br />
            Last Updated: <strong>{asp.lastUpdated.toString()}</strong>
          </p>
        ))}
        <p>If you would like this phase to reflect the latest changes to the data, you must remove all DSP-provided customer sites and re-import them.</p>
        <footer>
          {loading ? (
            <div className="loading" />
          ) : (
            <button className="okay" onClick={acknowledge}>
              Okay
            </button>
          )}
        </footer>
      </div>
    </div>
  );
}

export default withAcknowledgeASPUpdate(AspDataChangeNotification);
