import { LoggerFactory } from 'logger';
import { usePhaseItems } from 'providers/PhaseItemsProvider';
import { useCurrentProject } from 'providers/ProjectProvider';
import { useMoveProjectItems } from '../hocs/withMoveProjectItems';

const useOnDrop = ({ selectedItem, onClose }) => {
  const { getDraggedItems, pendingMoves, setPendingMoves, setCheckedItems, clearBulkActionUI } = usePhaseItems();
  const { projectId, selectedPhaseId: phaseId } = useCurrentProject();
  const { moveProjectItems } = useMoveProjectItems();
  const logger = LoggerFactory.getLogger('on drop');

  return async (draggedItem, metroId) => {
    logger.debug(`Dropped ${draggedItem} on metro: ${metroId}`);
    if (selectedItem === draggedItem) {
      onClose();
    }
    const draggedItems = getDraggedItems(draggedItem);
    const newPendingMoves = draggedItems.map((item) => ({ item, metroId }));
    setPendingMoves((prev) => [...prev, ...newPendingMoves]);

    await moveProjectItems(
      projectId,
      phaseId,
      metroId,
      draggedItems.map((i) => i.id)
    );
    const pendingMovesFiltered = pendingMoves.filter((m) => !(m.metroId === metroId && !!draggedItems.find((i) => m.item.equals(i))));
    setPendingMoves(pendingMovesFiltered);
    setCheckedItems([]);
    clearBulkActionUI();
  };
};

export default useOnDrop;
