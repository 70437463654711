import React, { useCallback, useState } from 'react';
import debounce from 'debounce';
import cn from 'classnames';
import Config from 'Config';

import { dismissAllToasts } from 'utils/Toast';
import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';
import TrashIcon from 'views/components/icons/Trash';

const COLORS = ['#F9C49E', '#FFE9BD', '#D5EDC3', '#ADE1E4', '#B3D1F3', '#D4CBE2'];

const Label = ({ projectId, phaseId, metro, label }) => {
  const [deleteMetroLabel, { loading: deleteMetroLabelLoading }] = useMutation(Mutations.DELETE_METRO_LABEL);
  const [updateMetroLabel, { loading: updateMetroLabelLoading }] = useMutation(Mutations.UPDATE_METRO_LABEL);

  const [labelState, setLabelState] = useState(label.value || '');

  const updateLabelDebounced = useCallback(
    debounce(async (value) => {
      await updateMetroLabel({
        variables: {
          projectId,
          phaseId,
          metroId: metro.id,
          labelId: label.id,
          label: {
            value: value,
            color: label.color,
            offset: `${label.offset.x},${label.offset.y}`,
          },
        },
      });
      dismissAllToasts();
    }, Config.TEXT_INPUT_DELAY),
    [metro.id]
  );

  const handleValueChange = (e) => {
    const value = e.target.value;
    setLabelState(value);
    updateLabelDebounced(value);
  };

  const handleColorChange = (color) => async () => {
    await updateMetroLabel({
      variables: {
        projectId,
        phaseId,
        metroId: metro.id,
        labelId: label.id,
        label: {
          value: label.value,
          color: color,
          offset: `${label.offset.x},${label.offset.y}`,
        },
      },
    });
    dismissAllToasts();
  };

  const handleDelete = async () => {
    await deleteMetroLabel({
      variables: {
        projectId,
        phaseId,
        metroId: metro.id,
        labelId: label.id,
      },
    });
    dismissAllToasts();
  };

  return (
    <div className="label" data-testid={'label'}>
      <span className="input">
        <input value={labelState} onChange={handleValueChange} />
        {updateMetroLabelLoading && <span className="loading" />}
      </span>
      {COLORS.map((color) => (
        <span
          data-testid={'label-color'}
          key={color}
          data-color={color}
          className={cn('color', { active: label.color === color })}
          style={{ background: color }}
          onClick={handleColorChange(color)}>
          &nbsp;
        </span>
      ))}
      {deleteMetroLabelLoading ? (
        <span className="delete loading" />
      ) : (
        <div data-testid={'delete-row'} className="delete-row" onClick={handleDelete}>
          <TrashIcon width={13} height={14} color="#333333" />
        </div>
      )}
    </div>
  );
};

export default Label;
