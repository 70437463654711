import { GqlMutationComponent, Mutations } from 'gql';
import { LoggerFactory } from 'logger';
import React from 'react';

class UpdateServiceOffsetWrapper extends GqlMutationComponent {
  constructor(props) {
    super(props);
    this._logger = LoggerFactory.getLogger(this);
  }

  mutation() {
    return Mutations.UPDATE_METRO;
  }

  renderData(doMutation) {
    const updateServiceOffset = async (serviceOffset) => {
      const { projectId, phaseId, metro } = this.props;
      const mutationArgs = {
        variables: { projectId, phaseId, metroId: metro.id, notes: metro.notes, serviceOffset, existing: metro.existing },
      };
      this._logger.debug(`Updating metro: ${metro} to project with id: ${projectId}`);
      doMutation(mutationArgs);
    };
    return this.props.children(updateServiceOffset, false);
  }

  renderLoading() {
    return this.props.children(() => {}, true);
  }
}

export default function withUpdateServiceOffset(Component) {
  return (props) => (
    <UpdateServiceOffsetWrapper projectId={props.projectId} phaseId={props.phaseId} metro={props.metro}>
      {(updateServiceOffset) => <Component updateServiceOffset={updateServiceOffset} {...props} />}
    </UpdateServiceOffsetWrapper>
  );
}
