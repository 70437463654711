import React from 'react';
import { useQuery } from 'react-apollo';
import { GqlQueryComponent, Queries } from 'gql';

class EdgeWrapper extends GqlQueryComponent {
  query() {
    return Queries.GET_NETWORK_EDGE_CATEGORIES;
  }

  queryArgs() {
    return { metroCode: this.props.code || this.props.metro?.code };
  }

  transform(data) {
    return data.networkEdgeCategories;
  }

  fetchPolicy() {
    return 'no-cache';
  }

  renderQuery(loading, error, networkEdgeCategories) {
    return this.props.children(networkEdgeCategories, loading, error);
  }
}

function withEdgeCategories(Component) {
  return (props) => (
    <EdgeWrapper {...props}>
      {(networkEdgeCategories, loading, error) => (
        <Component networkEdgeCategories={networkEdgeCategories} networkEdgeCategoriesLoading={loading} networkEdgeCategoriesError={error} {...props} />
      )}
    </EdgeWrapper>
  );
}

export default withEdgeCategories;

const useEdgeCategories = (metroCode = null) => {
  const { data, loading, error } = useQuery(Queries.GET_NETWORK_EDGE_CATEGORIES, { variables: { metroCode }, fetchPolicy: 'no-cache' });

  return {
    networkEdgeCategories: data?.networkEdgeCategories,
    loadingEdgeCategories: loading,
    errorEdgeCategories: error,
  };
};

export { useEdgeCategories };
