import React from 'react';
import classnames from 'classnames';
import { Mutations } from 'gql';
import { useMutation } from '@apollo/react-hooks';
import EyeballIcon from 'views/components/icons/Eyeball';

const ToggleVisibilityOnServiceButton = ({ projectId, phaseId, service }) => {
  const [toggleVisibility, { loading }] = useMutation(Mutations.UPDATE_SERVICE);

  return (
    <>
      {!loading ? (
        <div
          className={classnames('clickable icon', { invisible: !service.visible })}
          onClick={() =>
            toggleVisibility({
              variables: {
                projectId,
                phaseId,
                serviceId: service.serviceId,
                metroId: service.metroId,
                visible: !service.visible,
              },
            })
          }
          data-testid={'toggle-visibility'}>
          <EyeballIcon width={13} height={8} color="black" />
        </div>
      ) : (
        <div className="loading" />
      )}
    </>
  );
};

export default ToggleVisibilityOnServiceButton;
