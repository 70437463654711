export function getLocations(project, layers = {}) {
  if (layers.activeMetros) {
    return project.unconnectedLocations || [];
  } else {
    return project.locations || [];
  }
}

export function getServiceRegions(project, selected) {
  const services = project.allServices; // these always get rendered at the map level, to avoid duplicate markers
  const regionMap = services.reduce((regions, serviceConnection) => {
    // deduplicate service regions
    // TODO: Recheck
    const metroId = serviceConnection?.id?.metroId;
    serviceConnection.service.regions.forEach((region) => {
      // all regions
      if (!regions[region.id]) {
        regions[region.id] = {
          region,
          service: serviceConnection.service,
          enabled: false,
          selected: false,
          connections: [],
        };
      }
    });
    serviceConnection.regions.forEach((region) => {
      regions[region.id].enabled = true;
      if (serviceConnection.equals(selected)) {
        regions[region.id].selected = true;
      }
      if (metroId) {
        const connection = regions[region.id].region.connectedMetros.find((mc) => metroId.equals(mc.metroId)) || { metroId };
        //                 ^^^^^^^^^^^^^^^^^^^^^^^^^ We need to use the region from the Service, not the ServiceConnection (the latter doesn't have the connectedMetros).
        regions[region.id].connections.push(connection);
      }
    });
    return regions;
  }, {});
  const regions = Object.values(regionMap);
  return regions;
}
