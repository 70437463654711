import React from 'react';
import { Mutations } from 'gql';
import { useMutation } from 'react-apollo';
import { toast, updateToast } from 'utils/Toast';
import './UndoToast.scss';
import debounce from 'debounce';
import cn from 'classnames';

const UndoToast = ({ message, closeToast, projectId, phaseId, toastId }) => {
  const [execUndo, { loading }] = useMutation(Mutations.UNDO);

  const onClick = async () => {
    try {
      updateToast(toastId, { autoClose: false });
      await execUndo({ variables: { projectId, phaseId } });
    } catch (error) {
      toast('There was an error while undoing this action', 'error');
    }
    closeToast();
  };

  const debouncedOnClick = debounce(onClick, 300);

  return (
    <div className={'undo-toast'}>
      {message}
      <button className={cn('undo-btn', { loading })} onClick={!loading ? debouncedOnClick : undefined}>
        {loading ? <div className={'loader'} /> : 'Undo'}
      </button>
    </div>
  );
};

export default UndoToast;
