import React from 'react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import './TextInput.scss';

export default class TextInput extends React.Component {
  static defaultProps = {
    value: null,
    placeholder: null,
    multiline: false,
    disabled: false,
    setRef: noop,
    onKeyUp: noop,
    autoFocus: false,
  };

  static propTypes = {
    value: PropTypes.any,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
    multiline: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired,
    onKeyUp: PropTypes.func,
    setRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    autoFocus: PropTypes.bool,
  };

  _onChange = (e) => {
    const { onValueChange } = this.props;
    onValueChange(e.target.value);
  };

  _renderSingleLine() {
    const { className = '', value = '', type = 'text', placeholder, disabled } = this.props;

    return (
      <input
        className={className.trim()}
        disabled={disabled}
        ref={this.props.setRef}
        onKeyUp={this.props.onKeyUp}
        type={type}
        value={value || ''}
        placeholder={placeholder}
        onChange={this._onChange}
        autoFocus={this.props.autoFocus}
      />
    );
  }

  _renderMultiLine() {
    const { className = '', value = '', placeholder, disabled } = this.props;

    return (
      <textarea
        className={className.trim()}
        ref={this.props.setRef}
        onKeyUp={this.props.onKeyUp}
        value={value || ''}
        disabled={disabled}
        placeholder={placeholder}
        onChange={this._onChange}
      />
    );
  }

  render() {
    return this.props.multiline ? this._renderMultiLine() : this._renderSingleLine();
  }
}
