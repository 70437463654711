const getMaxValue = (densities) => {
  let maxValue = 0;

  for (const key in densities) {
    const density = densities[key];
    if (!density) continue;

    const values = density.map((segment) => segment.count);
    const max = Math.max(...values);
    maxValue = max > maxValue ? max : maxValue;
  }

  return maxValue;
};

export default getMaxValue;
