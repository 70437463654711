import React, { useState } from 'react';
import 'react-notion/src/styles.css';
import cn from 'classnames';
import OutsideAlerter from '../OutsideAlerter';
import './CompaniesView.scss';

const CompanyListItem = ({ company, onOpenClick, openModal }) => {
  const members = company.members;
  const [isOptionsMenuOpen, setIsOptionMenuOpen] = useState(false);

  const _openModal = (e, type) => {
    e.stopPropagation();
    openModal(type, company);
  };

  const onClickOutsideOptions = () => {
    setIsOptionMenuOpen(false);
  };

  const onOptionsMenuClick = (e) => {
    e.stopPropagation();
    setIsOptionMenuOpen((prev) => !prev);
  };

  const deletionDate = company.removalDate + 1000 * 60 * 60 * 24 * 30;
  const remainingDays = Math.ceil((deletionDate - new Date()) / (24 * 60 * 60 * 1000));
  const deletionText = `Pending deletion ${remainingDays === 0 ? 'today' : `in ${remainingDays} day${remainingDays > 1 ? 's' : ''}`}`;

  return (
    <li data-testid="company-list-item" className="projects-list_item" onClick={onOpenClick}>
      <div className={'project-meta'}>
        <span className="customer-name">{company.name}</span>

        {company.removalDate ? (
          <div className="gray-information">{deletionText}</div>
        ) : (
          <span className="project-name">
            {!members.length ? (
              'No members'
            ) : (
              <>
                {members.length} member{members.length > 1 && 's'}
              </>
            )}
          </span>
        )}
      </div>
      <div className={cn('project-controls', { 'options-menu-open': isOptionsMenuOpen })}>
        <OutsideAlerter onClickOutside={onClickOutsideOptions} style={{ display: 'flex' }}>
          <>
            <button
              data-testid="edit-button"
              className="update-project"
              onClick={(e) => {
                _openModal(e, 'edit');
              }}>
              Edit
            </button>
            {company?.removalDate ? (
              <button
                data-testid="restore-button"
                onClick={(e) => {
                  _openModal(e, 'restore');
                }}>
                Restore
              </button>
            ) : (
              <button
                data-testid="delete-button"
                className="delete-project"
                onClick={(e) => {
                  _openModal(e, 'delete');
                }}></button>
            )}
            <button
              data-testid="options-button"
              className="options-menu-btn"
              onClick={onOptionsMenuClick}
              title={isOptionsMenuOpen ? 'Hide options' : 'Options'}></button>
          </>
        </OutsideAlerter>
      </div>
    </li>
  );
};

export default CompanyListItem;
