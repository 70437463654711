import { useMutation } from 'react-apollo';
import { Mutations, withMutation } from 'gql';

export default withMutation({
  name: 'DISABLE_METAL',
  mutation: Mutations.DISABLE_METAL,
  mutateProp: 'disableMetal',
  getMutationArgs(projectId, phaseId, metroCode) {
    return {
      variables: {
        projectId,
        phaseId,
        metroCode,
      },
    };
  },
});

const useDisableMetal = () => {
  const [doDisableMetal, { data, loading, error }] = useMutation(Mutations.DISABLE_METAL);

  const disableMetal = (projectId, phaseId, metroCode) => doDisableMetal({ variables: { projectId, phaseId, metroCode } });

  return {
    disableMetal,
    dataDisableMetal: data,
    loadingDisableMetal: loading,
    errorDisableMetal: error,
  };
};

export { useDisableMetal };
