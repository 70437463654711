import React from 'react';

const ColocationIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M15.0312 4.71875V11.2812C15.0312 11.5312 14.9688 11.7708 14.8438 12C14.7188 12.2083 14.5521 12.375 14.3438 12.5L8.71875 15.7812C8.48958 15.9062 8.25 15.9688 8 15.9688C7.75 15.9688 7.51042 15.9062 7.28125 15.7812L1.65625 12.5C1.44792 12.375 1.28125 12.2083 1.15625 12C1.03125 11.7708 0.96875 11.5312 0.96875 11.2812V4.71875C0.96875 4.46875 1.03125 4.23958 1.15625 4.03125C1.28125 3.80208 1.44792 3.625 1.65625 3.5L7.28125 0.21875C7.51042 0.09375 7.75 0.03125 8 0.03125C8.25 0.03125 8.48958 0.09375 8.71875 0.21875L14.3438 3.5C14.5521 3.625 14.7188 3.80208 14.8438 4.03125C14.9688 4.23958 15.0312 4.46875 15.0312 4.71875ZM12.6875 4.9375L8.21875 2.375C8.15625 2.33333 8.07292 2.3125 7.96875 2.3125C7.88542 2.3125 7.80208 2.33333 7.71875 2.375L3.28125 4.9375C3.19792 4.97917 3.13542 5.04167 3.09375 5.125C3.05208 5.1875 3.03125 5.27083 3.03125 5.375V10.5C3.03125 10.5833 3.05208 10.6667 3.09375 10.75C3.13542 10.8333 3.19792 10.8958 3.28125 10.9375L7.71875 13.5C7.76042 13.5208 7.80208 13.5417 7.84375 13.5625C7.88542 13.5625 7.92708 13.5625 7.96875 13.5625C8.01042 13.5625 8.05208 13.5625 8.09375 13.5625C8.13542 13.5417 8.17708 13.5208 8.21875 13.5L12.6875 10.9375C12.75 10.8958 12.8021 10.8333 12.8438 10.75C12.9062 10.6667 12.9375 10.5833 12.9375 10.5V5.375C12.9375 5.27083 12.9062 5.1875 12.8438 5.125C12.8021 5.04167 12.75 4.97917 12.6875 4.9375ZM7.96875 3.375L11.4688 5.40625L8 7.5L4.4375 5.4375L7.96875 3.375ZM4.03125 6.34375L7.5 8.375V12.2188L4.03125 10.2188V6.34375ZM8.5 12.1875V8.34375L11.9062 6.28125V10.2188L8.5 12.1875Z"
        fill={color}
      />
    </svg>
  );
};

export default ColocationIcon;
