import React from 'react';

const WarningIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M9.86125 3.85996L1.74417 18C1.57682 18.3024 1.48826 18.6453 1.48733 18.9945C1.48639 19.3437 1.5731 19.6871 1.73883 19.9905C1.90456 20.2939 2.14353 20.5467 2.43197 20.7238C2.72042 20.9009 3.04827 20.9961 3.38292 21H19.6171C19.9517 20.9961 20.2796 20.9009 20.568 20.7238C20.8565 20.5467 21.0954 20.2939 21.2612 19.9905C21.4269 19.6871 21.5136 19.3437 21.5127 18.9945C21.5117 18.6453 21.4232 18.3024 21.2558 18L13.1388 3.85996C12.9679 3.56607 12.7274 3.32308 12.4403 3.15444C12.1533 2.98581 11.8294 2.89722 11.5 2.89722C11.1706 2.89722 10.8467 2.98581 10.5597 3.15444C10.2726 3.32308 10.0321 3.56607 9.86125 3.85996V3.85996Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.5 9V13" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.5 17H11.51" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default WarningIcon;
