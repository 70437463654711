import ProjectId from './ProjectId';
import UserId from './UserId';
import Assert from '../utils/Assert';

export default class ProjectPresence {
  constructor(projectId, userId, email, status) {
    Assert.instanceOf(projectId, ProjectId);
    Assert.instanceOf(userId, UserId);
    Assert.isString(email);
    Assert.isString(status);

    this.projectId = projectId;
    this.userId = userId;
    this.email = email;
    this.status = status;
    Object.freeze(this);
  }

  get editing() {
    return this.status.toUpperCase() === 'EDITING';
  }

  get idle() {
    return this.status.toUpperCase() === 'IDLE';
  }

  equals(other) {
    if (!(other instanceof ProjectPresence)) {
      return false;
    }

    return this.projectId.equals(other.projectId) && this.userId.equals(other.userId) && this.status === other.status;
  }

  toString() {
    return `${this.constructor.name}{ project:${this.projectId}, user:'${this.userId}', status:${this.status} }`;
  }

  toJSON() {
    const { projectId, userId, email, status } = this;
    return { projectId, userId, email, status };
  }

  static fromJSON(json) {
    if (!json) {
      return null;
    }

    const { projectId, userId, email, status } = json;

    return new ProjectPresence(new ProjectId(projectId), new UserId(userId), email, status);
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }
    const items = [];
    for (const json of jsonArray) {
      const p = ProjectPresence.fromJSON(json);
      items.push(p);
    }
    return items;
  }
}
