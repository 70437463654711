import Assert from '../utils/Assert';
import GroupId from './GroupId';
import GroupLocationPartialData from './GroupLocationPartialData';
import MetroId from './MetroId';

export default class Group {
  constructor(id, name, color, locationsPartialData, metroId) {
    Assert.instanceOf(id, GroupId);
    Assert.instanceOf(locationsPartialData, Array);

    this.id = id;
    this.name = name;
    this.color = color;
    this.locations = locationsPartialData;
    this.metroId = metroId;
    Object.freeze(this);
  }

  get isConnectedToMetro() {
    return this.metroId != null;
  }

  equals(other) {
    if (!(other instanceof Group)) {
      return false;
    }

    return this.id.equals(other.id);
  }

  compare(other) {
    if (!(other instanceof Group)) {
      throw new Error('Cannot compare to other type!');
    }
    const myLabel = this.name;
    const theirLabel = other.name;
    if (myLabel > theirLabel) {
      return 1;
    } else if (myLabel < theirLabel) {
      return -1;
    } else {
      return this.locations.length - other.locations.length;
    }
  }

  toString() {
    return `${this.constructor.name}{ id:${this.id}, name:'${this.name}'}`;
  }

  toJSON() {
    const { id, name, color, locations, metroId } = this;
    return { id, name, color, locations: locations.map((l) => l.toJSON()), metro: { id: metroId } };
  }

  static fromJSON(json) {
    const { id, name, color, locations, metro } = json;

    return new Group(new GroupId(id), name, color, GroupLocationPartialData.fromJSONArray(locations), metro == null ? null : new MetroId(metro.id));
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = Group.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}
