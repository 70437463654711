import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Queries } from 'gql';
import Project from 'model/Project';
import User from 'model/User';
import { toast } from 'utils/Toast';
import withLeaveProject from '../hocs/withLeaveProject';
import ConfirmationModal from './ConfirmationModal';
import SelectedModalItem from './SelectedModalItem';
import defaultStyles from 'views/ProjectDetailView/modals/defaultStyles';

/**
 * GraphQL LeaveProject Mutation Object
 * @typedef {Object} LeaveProjectMutation
 * @property {function} mutation - Leave Project Mutation function.
 * @property {ApolloError} error - Stores a potential error.
 * @property {boolean} loading - Indicates whether the mutation is in progress.
 */

/**
 * @param {Project} project - Project Object to be unshared
 * @param {boolean} isOpen - Indicates whether the modal is open or not
 * @param {function} onClose - Function to be fired when trying to close the modal
 * @param {LeaveProjectMutation} leaveProject
 */
const LeaveProjectModal = ({ project, user, isOpen, onClose, leaveProject }) => {
  useEffect(() => {
    if (leaveProject.error) {
      toast('An unexpected error occurred leaving project', 'error');
    } else if (leaveProject.data && !leaveProject.error) {
      onClose();
    }
    // eslint-disable-next-line
  }, [leaveProject]);

  const leaveMutation = () => {
    const leaveProjectArgs = {
      variables: { projectId: project.id, userId: user.id },
      refetchQueries: [{ query: Queries.GET_PROJECTS }],
    };

    leaveProject.mutation(leaveProjectArgs);
  };

  return (
    <Modal isOpen={isOpen} className={'modal-window confirmation-modal'} onRequestClose={onClose} defaultStyles={defaultStyles}>
      <ConfirmationModal
        mainText={<SelectedModalItem boldText={project.customer.name} regularText={project.name} />}
        onClose={onClose}
        onConfirm={leaveMutation}
        loading={leaveProject.loading}
        title={'Leave this project'}
        trailingText={'You will lose access to this project until you are re-invited.'}
        buttonText={'Confirm Leave'}
      />
    </Modal>
  );
};

LeaveProjectModal.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
  user: PropTypes.instanceOf(User).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  leaveProject: PropTypes.shape({
    mutation: PropTypes.func.isRequired,
    data: PropTypes.any,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.shape({}),
  }).isRequired,
};

export default withLeaveProject(LeaveProjectModal);
