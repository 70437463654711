import React, { useState } from 'react';
import Modal from 'react-modal';
import TextInput from 'views/components/TextInput';
import defaultStyles from './defaultStyles';
import Select from 'react-select';
import { FILTER_OPTIONS, formatCategoriesForSelect, formatCategories } from '../TemplatesView/sortingTemplates';
import { getTheme } from 'utils/SelectTheme';
import Button from 'views/components/Button';
import DropdownStyles from 'views/components/DropdownStyles';

const TemplateModal = ({ isOpen, onClose, onSave, loading, template }) => {
  const [templateName, setTemplateName] = useState(template?.name);
  const [categories, setCategories] = useState(formatCategoriesForSelect(template?.categories));
  const filterOptions = Object.values(FILTER_OPTIONS);

  const _onClose = () => {
    setTemplateName('');
    setCategories([]);
    onClose();
  };

  const arraysAreTheSame = (categoriesFormatted) => {
    return !(
      categoriesFormatted.every((element) => template?.categories.includes(element)) &&
      template?.categories.every((element) => categoriesFormatted.includes(element))
    );
  };

  const _onSave = async () => {
    const categoriesFormatted = formatCategories(categories);
    if (template?.name !== templateName || arraysAreTheSame(categoriesFormatted) || !template) {
      await onSave(templateName, categoriesFormatted);
    }
    onClose();
  };

  const onCategoryChange = (newValue) => {
    setCategories(newValue);
  };

  const renderProgress = () => {
    return (
      <div className="modal-submit">
        <div className="loading"></div>
      </div>
    );
  };

  const isDisabled = () => !templateName;

  const renderButtons = () => {
    return (
      <div className="modal-submit">
        <Button text="Cancel" color="red" onClick={_onClose} isInverse={true} />
        <Button text="Save" color="red" onClick={_onSave} isDisabled={isDisabled()} />
      </div>
    );
  };
  return (
    <Modal isOpen={isOpen} className="modal-window" onRequestClose={_onClose} contentLabel="Create Template" defaultStyles={defaultStyles}>
      <div data-testid={'template-modal'}>
        <div className="modal-header">
          <h3>{template ? 'Edit Template Details' : 'Create Template'}</h3>
          <button className="close-modal" onClick={_onClose}>
            <svg height="12" width="12">
              <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
            </svg>
          </button>
        </div>
        <div className="modal-fields modal-fields--full">
          <label>Name</label>
          <TextInput
            value={templateName}
            className="textInput"
            onValueChange={(newValue) => {
              setTemplateName(newValue);
            }}
          />
        </div>
        <div className="modal-fields modal-fields--full">
          <label>Categories</label>
          <Select
            isMulti
            placeholder="Select..."
            options={
              filterOptions.map((option) => ({
                value: option.value,
                label: option.label,
              })) || []
            }
            defaultValue={categories}
            values={categories}
            onChange={onCategoryChange}
            theme={getTheme}
            menuPlacement="auto"
            styles={DropdownStyles}
            data-testid={'category-select'}
          />
        </div>
        {loading ? renderProgress() : renderButtons()}
      </div>
    </Modal>
  );
};

export default TemplateModal;
