import ValueObject from './ValueObject';

export default class NetworkEdgeCategoryId extends ValueObject {
  equals(other) {
    if (!(other instanceof NetworkEdgeCategoryId)) {
      return false;
    }

    return super.equals(other);
  }

  static fromJSON(json) {
    return new NetworkEdgeCategoryId(json);
  }
}
