import ProductEvents from 'analytics/ProductEvents';
import Button from 'views/components/Button';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Mutations, Queries } from 'gql';
import { LoggerFactory } from 'logger';
import ProjectPhaseId from 'model/ProjectPhaseId';
import Service from 'model/Service';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import Select from 'react-select';
import { getTheme } from 'utils/SelectTheme';
import { dismissAllToasts, toast } from 'utils/Toast';
import '../AddView.scss';
import ServiceRegionsView from '../ServiceRegionsView';
import './AddServiceView.scss';
import DropdownStyles from 'views/components/DropdownStyles';

const AddServiceView = ({
  projectId,
  phaseId,
  servicesConnections,
  onClose,
  onError,
  selectedRegions,
  onSelectionChanged,
  onEnableRegion,
  onDisableRegion,
}) => {
  const [addService, { loading: loadingAddService }] = useMutation(Mutations.ADD_SERVICE);
  const { data, error } = useQuery(Queries.GET_SERVICES);

  const logger = useMemo(() => LoggerFactory.getLogger('AddServiceView'), []);

  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  //Services that have already been selected, but aren't associated with any metro.
  const disconnectedServices = Service.fromJSONArray(servicesConnections);

  useEffect(() => {
    if (data && data.validServices) {
      const services = Service.fromJSONArray(data.validServices)
        .filter((service) => !disconnectedServices.some((ds) => ds.name === service.name))
        .map((m) => ({ value: m, label: m.name }));

      setOptions(services);
    }
    // eslint-disable-next-line
  }, [data]);

  if (error) {
    logger.error(`An error occurred executing mutation: ${error.message}`);
    toast('An unexpected error occurred adding service', 'error');
    onError(error);
  }

  const _onSelectionChange = (option) => {
    if (option) {
      setSelectedItem(option.value);
      logger.debug(`Service selection changed: ${option.value}`);
      onSelectionChanged(option.value);
    } else {
      // Clear the input text.
      setSelectedItem(null);
      onSelectionChanged(null, true);
    }
  };

  const close = () => {
    setSelectedItem(null);
    onClose();
  };

  const onAddClick = async () => {
    logger.debug(`Addin g service: ${selectedItem} to phase with id: ${phaseId}`);

    const variables = {
      projectId,
      phaseId,
      serviceId: selectedItem.id,
      metroId: null,
      serviceRegionIds: selectedRegions.filter((r) => !!r).map((r) => r.id),
    };

    await addService({ variables });

    ProductEvents.serviceAdded(selectedItem.name);
    close();
    dismissAllToasts();
  };

  const renderProgress = () => {
    return (
      <div className="buttons">
        <div className="loading"></div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="buttons">
        <Button color="red" isInverse={true} onClick={close} text="Discard" />
        <Button color="red" isDisabled={!selectedItem} text="Add" onClick={onAddClick} data-testid={'add-button'} />
      </div>
    );
  };

  const selectedOption = selectedItem ? options.find((o) => selectedItem.equals(o.value)) : null;

  return (
    <div className="AddView AddServiceView" data-testid={'add-service-view'}>
      <h2>Add a Service</h2>

      <div className="where">
        <p>
          Use the search field below to look for services that are business-critical for Acme. This information will be a determining factor in making some
          initial metro recommendations.
        </p>
        <h3>Select a Service to add</h3>

        <Select
          placeholder="Type to search Services..."
          isClearable
          options={options}
          value={selectedOption}
          theme={getTheme}
          onChange={_onSelectionChange}
          styles={DropdownStyles}
          data-testid={'service-select'}
        />
        {selectedItem && selectedItem.regions.length > 0 && <h4>Enable Availability Regions</h4>}
        {selectedItem && selectedItem.regions.length > 0 && (
          <ServiceRegionsView
            projectId={projectId}
            phaseId={phaseId}
            availableRegions={selectedItem.regions}
            selectedRegions={selectedRegions}
            onEnableRegion={onEnableRegion}
            onDisableRegion={onDisableRegion}
          />
        )}
      </div>

      {loadingAddService ? renderProgress() : renderButtons()}
    </div>
  );
};

AddServiceView.propTypes = {
  projectId: PropTypes.string.isRequired,
  phaseId: PropTypes.instanceOf(ProjectPhaseId).isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default AddServiceView;
