import L from 'leaflet';

import { Path, withLeaflet } from 'react-leaflet';

const __onAdd = L.Polyline.prototype.onAdd;
const __onRemove = L.Polyline.prototype.onRemove;
const __updatePath = L.Polyline.prototype._updatePath;
const __bringToFront = L.Polyline.prototype.bringToFront;

class LeafletPolylineWithText extends L.Polyline {
  onAdd(map) {
    __onAdd.call(this, map);
    this._textRedraw();
  }

  onRemove(map) {
    map = map || this._map;
    if (map && this._textNode && map._renderer._container) map._renderer._container.removeChild(this._textNode);
    __onRemove.call(this, map);
  }

  bringToFront() {
    __bringToFront.call(this);
    this._textRedraw();
  }

  _updatePath() {
    __updatePath.call(this);
    this._textRedraw();
  }

  _textRedraw() {
    var text = this._text;
    const options = this._textOptions;
    if (text) {
      this.setText(null).setText(text, options);
    }
  }
  setText(text, options = {}) {
    this._text = text;
    this._textOptions = options;
    const iconSize = options.weight / 3;

    /* If not in Canvas mode or Polyline not added to map yet return */
    /* setText will be called by onAdd, using value stored in this._text */
    if (!L.Browser.canvas || typeof this._map === 'undefined') {
      return this;
    }
    if (!this._parts || !this._parts[0]) {
      // _parts will be empty if it's offscreen, no need to draw it.
      return this;
    }
    const renderer = this._map.getRenderer(this);
    if (renderer instanceof L.Canvas && text) {
      const ctx = renderer._ctx;
      const coords = this._map.latLngToLayerPoint(this.getCenter());
      const segment = this._parts[0]; // take the first segment to compute angle
      const leftPoint = segment[0].x <= segment[1].x ? segment[0] : segment[1];
      const rightPoint = segment[0].x > segment[1].x ? segment[0] : segment[1];
      const angle = Math.atan((rightPoint.y - leftPoint.y) / (rightPoint.x - leftPoint.x));
      ctx.save();
      ctx.font = `700 {12 * iconSize}px Arial, sans-serif`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.fillStyle = options.color || '#000000';
      ctx.translate(coords.x, coords.y);
      ctx.rotate(angle);
      ctx.fillText(text, 0, 0);
      ctx.restore();
    } else if (renderer instanceof L.SVG) {
      const svg = renderer._container;
      // remove the text, as SVG layers are not reset like Canvas ones
      if (this._textNode && this._textNode.parentNode) {
        svg.removeChild(this._textNode);
        /* delete the node, so it will not be removed a 2nd time if the layer is later removed from the map */
        delete this._textNode;
      }
      if (!text) {
        return this;
      }
      // create a text path, to follow the line
      const id = 'pathdef-' + L.Util.stamp(this);
      this._path.setAttribute('id', id);
      const textNode = L.SVG.create('text'),
        textPath = L.SVG.create('textPath');
      textPath.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#' + id);
      textNode.setAttribute('fill', options.color || '#000000');
      textNode.setAttribute('style', `font: 700 ${12 * iconSize}px Arial, sans-serif`);
      textPath.appendChild(document.createTextNode(text));
      textNode.appendChild(textPath);
      this._textNode = textNode;
      svg.appendChild(textNode);
      // center
      var textLength = textNode.getComputedTextLength();
      var pathLength = this._path.getTotalLength();
      textNode.setAttribute('dx', pathLength / 2 - textLength / 2);
      // flip it if needed
      const segment = this._parts[0]; // take the first segment to compute angle
      if (segment[0].x > segment[1].x) {
        var rotatecenterX = textNode.getBBox().x + textNode.getBBox().width / 2;
        var rotatecenterY = textNode.getBBox().y + textNode.getBBox().height / 2;
        textNode.setAttribute('transform', `rotate(180 ${rotatecenterX} ${rotatecenterY})`);
        textNode.setAttribute('dy', -15 * iconSize);
      } else {
        textNode.setAttribute('dy', -5 * iconSize);
      }
    }
    return this;
  }
}

class PolylineWithText extends Path {
  createLeafletElement(props) {
    const { iconSize = 1 } = props;
    const options = this.getPathOptions({ ...props, weight: 3 * iconSize });
    const l = new LeafletPolylineWithText(props.positions, options);
    l.setText(props.text, options);
    return l;
  }
  updateLeafletElement(fromProps, toProps) {
    const { iconSize = 1 } = toProps;
    const options = this.getPathOptions({ ...toProps, weight: 3 * iconSize });
    if (toProps.positions !== fromProps.positions) {
      this.leafletElement.setLatLngs(toProps.positions);
    }
    if (toProps.text !== fromProps.text) {
      this.leafletElement.setText(toProps.text, options);
    }
    if (toProps.iconSize !== fromProps.iconSize) {
      this.leafletElement.setStyle(options);
      this.leafletElement.setText(toProps.text, options);
    }
    this.setStyleIfChanged(fromProps, toProps);
  }
  render() {
    return null;
  }
}

export default withLeaflet(PolylineWithText);
