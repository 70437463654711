import Hashes from 'jshashes';
import { LoggerFactory } from 'logger';

const MD5 = new Hashes.MD5();
const logger = LoggerFactory.getLogger('Sorting State');

// Hash the id instead of using the id directly as a key,
// as a bit of a security measure, so that users aren't able to tell
// what other user ids are active via localStorage.
function getHashedKey(id) {
  return MD5.hex(id.toString());
}

export function getCachedSortingState(id) {
  const key = getHashedKey(id);

  const cachedState = localStorage.getItem(key);

  if (cachedState !== null) {
    return JSON.parse(cachedState);
  }

  return null;
}

export function setCachedSortingState(id, state) {
  const key = getHashedKey(id);

  const serializedState = JSON.stringify(state);

  localStorage.setItem(key, serializedState);
}

export const getCachedSort = ({ userId, cachedStateKey }) => {
  const cachedState = getCachedSortingState(userId);
  let category, direction;

  if (cachedState != null) {
    logger.debug(`Found cached sorting state for id: '${userId}'`);

    if (cachedStateKey in cachedState) {
      logger.debug(`Found cached sorting state for key: '${cachedStateKey}'`);

      const instanceState = cachedState[cachedStateKey];
      category = instanceState.category;
      direction = instanceState.direction;
    } else {
      logger.debug(`Did not find cached sorting state for key: '${cachedStateKey}'`);
    }
  } else {
    logger.debug(`Did not find cached sorting state for id: '${userId}'`);
  }

  return { category, direction };
};

export const setCachedSorted = ({ userId, cachedStateKey, category, direction }) => {
  const cachedState = getCachedSortingState(userId) || {};

  cachedState[cachedStateKey] = {
    category,
    direction,
  };

  logger.debug(`Setting cached sorting state for id: '${userId}' and key: '${cachedStateKey}'`);

  setCachedSortingState(userId, cachedState);
};
