import L from 'leaflet';

const getEdgeIcon = (enabled, sizeFactor, color = 'red') => {
  const enabledIcon = `
  <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="">
      <circle opacity="0.5" cx="30.5" cy="29.5" r="26.5" fill="${color}"/>
      <circle cx="30.5" cy="29.5" r="16.5" fill="${color}"/>
      <path d="M25 30.9091L28.75 35L37 26" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <filter id="filter0_d" x="0" y="0" width="61" height="61" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
      </filter>
    </defs>
  </svg>
  `;

  const disabledIcon = `
    <svg width="43" height="43" viewBox="0 0 43 43" fill="${color}" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <circle cx="21.5" cy="20.5" r="16.5" fill="white"/>
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M33.1225 17.0366V24.3412L28.4973 25.9578V17.0548L26.9575 16.5167V26.4948L22.3317 28.1085V14.903L21.5624 14.6319L20.7908 14.903V28.1085L16.1679 26.4948V16.5167L14.6252 17.0548V25.9578L10 24.3412V17.0366L11.5445 16.4974V23.245L13.0837 23.7867V15.9593L17.7083 14.3455V25.3968L19.2498 25.9361V13.8051L21.5624 13L23.8738 13.8051V25.9361L25.4136 25.3968V14.3455L30.0394 15.9593V23.7867L31.5809 23.245V16.4974L33.1225 17.0366Z" fill="#ED1D24"/>
      <defs>
        <filter id="filter0_d" x="0" y="0" width="43" height="43" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 0 0.733333 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
      </defs>
    </svg>
  `;

  const defaultIconSizes = {
    enabledIcon: 61,
    disabledIcon: 41,
  };

  const size = (enabled ? defaultIconSizes.enabledIcon : defaultIconSizes.disabledIcon) * sizeFactor;
  const icon = L.divIcon({
    html: enabled ? enabledIcon : disabledIcon,
    iconSize: L.point(size, size + 20),
    className: 'ioa-marker',
  });
  return icon;
};

export default getEdgeIcon;
