import React from 'react';
import classNames from 'classnames';
import Expandable from './Expandable';

export function Security(props) {
  const { active, on } = props;
  return (
    <Expandable>
      {(open, toggle) => (
        <div className={classNames('expandable use-case', { active, open, closed: !open })}>
          <div className="expandable-header use-case-header">
            <button className="expand-content" onClick={toggle}>
              <h3>Security</h3>
            </button>
            <div className={classNames('status', { on, active })}>
              {on ? 'Solved' : ''}
              {active ? '' : 'Coming Soon...'}
            </div>
          </div>
          <div className="expandable-content use-case-content">
            <p>
              To secure the digital edge you need to be prepared for multicloud application and data flows servicing people, employees and partners across
              multiple networks. Identity and authentication are just the tip of the iceberg. Learn how to secure for the digital edge with technical blueprints
              and design patterns.
            </p>
            <ul className="design-patterns">
              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Establish Boundary Control</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-boundary-control/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        With the redefined edge, intersecting cloud and B2B traffic with field networks and corporate backbone, a new kind of digital border
                        control is required
                      </p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>DDOS</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  A denial-of-service attack (DoS attack) is a cyber-attack where the perpetrator seeks to make a machine or network resource
                                  unavailable to its intended users by temporarily or indefinitely disrupting services of a host connected to the Internet.
                                  Denial of service is typically accomplished by flooding the targeted machine or resource with superfluous requests in an
                                  attempt to overload systems and prevent some or all legitimate requests from being fulfilled.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Firewall</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  A Network firewall is a network security system that monitors and controls the incoming and outgoing network traffic based on
                                  predetermined security rules. A firewall typically establishes a barrier between a trusted, secure internal network and
                                  another outside network, such as the Internet, that is assumed not to be secure or trusted.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Malware</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Malware, short for malicious software, is any software used to disrupt computer or mobile operations, gather sensitive
                                  information, gain access to private computer systems, or display unwanted advertising. The most well known category of malware
                                  propagation concerns parasitic software fragments that attach themselves to some existing executable content. The fragment may
                                  be machine code that infects some existing application, utility, or system program, or even the code used to boot a computer
                                  system.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>SSL Termination</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Transport Layer Security (TLS) and its predecessor, Secure Sockets Layer (SSL), both frequently referred to as 'SSL', are
                                  cryptographic protocols that provide communications security over a computer network. The connection is private (or secure)
                                  because symmetric cryptography is used to encrypt the data transmitted. The identity of the communicating parties can be
                                  authenticated using public-key cryptography. .A TLS termination proxy (or SSL termination proxy) is a proxy server that is
                                  used by an institution to handle incoming TLS connections, decrypting the TLS and passing on the unencrypted request to the
                                  institution's other servers within the organization's secure zone.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Create an Inspection Zone</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-inspection-zone"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        What was traditionally done with a perimeter DMZ now needs to be distributed to scale with each edge node. Intersection points provide a
                        unique control point for all traffic
                      </p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Data Leakage Protection</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Prevent unauthorized access or removal of data by monitoring all requests and enfocing access policies based upon role and
                                  data sensitivity.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Deep Packet Inspection</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Deep packet inspection (DPI) is a form of computer network packet filtering that examines the data part (and possibly also the
                                  header) of a packet as it passes an inspection point, searching for protocol non-compliance, viruses, spam, intrusions, or
                                  defined criteria to decide whether the packet may pass or if it needs to be routed to a different destination, or, for the
                                  purpose of collecting statistical information that functions at the Application layer of the OSI. There are multiple headers
                                  for IP packets; network equipment only needs to use the first of these (the IP header) for normal operation.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Apply Policy Enforcement</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-policy-administration-enforcement/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        After segmenting the traffic, we now need more fine-grained prescription on what is allowed within the flows. Services and mobile apps
                        will be changing rapidly, requiring stronger governance
                      </p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Intrusion Detection</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  An intrusion detection system (IDS) is a device or software application that monitors a network or computer systems for
                                  malicious activity or policy violations. Any detected activity or violation is typically reported either to an administrator
                                  or collected centrally using a security information and event management (SIEM) system. A SIEM system combines outputs from
                                  multiple sources, and uses alarm filtering techniques and various algorithms to distinguish malicious activity from false
                                  alarms.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Co-locate Identity & Key Management</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-locate-identity-and-key-management"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        The node design provides extremely low latency but only if the reasons for traffic to leave are reduced. Co-locate high dependency
                        services in the node and scale as more nodes are deployed
                      </p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Identity</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Identity management (IdM) is the task of controlling information about users on computers. Such information includes
                                  information that authenticates the identity of a user, and information that describes information and actions they are
                                  authorized to access and/or perform. It also includes the management of descriptive information about the user and how and by
                                  whom that information can be accessed and modified. Managed entities typically include users, hardware and network resources
                                  and even applications. ID management can also include user role based policies to provide a golden record of access rights.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Key Management</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Key management is the management of cryptographic keys in a cryptosystem. Key management concerns keys at the user level,
                                  either between users or systems This includes dealing with the generation, exchange, storage, use, and replacement of keys. It
                                  includes cryptographic protocol design, key servers, user procedures, and other relevant protocols.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Integrate Security Analytics & Logging</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-security-analytics-logging"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        Link all of these controls together with security (and network) analytics to not only make sure all the doors and windows are locked but
                        ensure that everyone is in the crowd is behaving.
                      </p>
                      <h5>Data Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Predictive Analytics</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Predictive analytics encompasses a variety of statistical techniques from predictive modeling, machine learning, and data
                                  mining that analyze current and historical facts to making predictions about future or otherwise unknown events.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>
            </ul>
          </div>
        </div>
      )}
    </Expandable>
  );
}
