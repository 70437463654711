import { Mutations, withMutation } from 'gql';
import { useMutation } from 'react-apollo';

export const withToggleVisibilityService = withMutation({
  name: 'TOGGLE_SERVICE_VISIBILITY',
  mutation: Mutations.UPDATE_SERVICE,
  mutateProp: 'toggleVisibility',
  getMutationArgs(projectId, phaseId, serviceConnection) {
    return {
      variables: {
        projectId,
        phaseId,
        serviceId: serviceConnection.serviceId,
        metroId: serviceConnection.metroId,
        visible: !serviceConnection.visible,
      },
    };
  },
});

export const useToggleVisibilityService = () => {
  const [doToggleVisibilityService] = useMutation(Mutations.UPDATE_SERVICE);

  const toggleVisibility = async (projectId, phaseId, serviceConnection) => {
    await doToggleVisibilityService({
      variables: {
        projectId,
        phaseId,
        serviceId: serviceConnection.serviceId,
        metroId: serviceConnection.metroId,
        visible: !serviceConnection.visible,
      },
    });
  };
  return { toggleVisibility };
};
