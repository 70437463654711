import React from 'react';
import Modal from 'react-modal';
import defaultStyles from './defaultStyles';
import Button from 'views/components/Button';
import './CompanyModals.scss';
import { USER_ROLES } from '../../../model/userRoles';

const RolesModal = ({ isOpen, onClose, userRole }) => {
  return (
    <Modal isOpen={isOpen} className="modal-window" onRequestClose={onClose} contentLabel="Roles Description" defaultStyles={defaultStyles}>
      <div className="modal-header">
        <h3>Roles</h3>
        <button className="close-modal" onClick={onClose}>
          <svg height="12" width="12">
            <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
          </svg>
        </button>
      </div>

      <div className="modal-fields modal-fields--full">
        {
          <>
            <b>
              <label data-testid={'member-label'}>{USER_ROLES.teamMember.label}</label>
            </b>
            <p>Team Members are able to create and share projects and templates</p>
          </>
        }
        {userRole <= USER_ROLES.teamAdmin.value && (
          <>
            <b>
              <label data-testid={'admin-label'}>{USER_ROLES.teamAdmin.label}</label>
            </b>
            <p>Can add and remove users, and change user roles</p>
          </>
        )}
        {userRole <= USER_ROLES.companyOwner.value && (
          <>
            <b>
              <label data-testid={'owner-label'}>{USER_ROLES.companyOwner.label}</label>
            </b>
            <p>Can edit Company settings</p>
          </>
        )}
      </div>

      <div className="modal-submit">
        <Button text="Close" color="red" onClick={onClose} />
      </div>
    </Modal>
  );
};

export default RolesModal;
