import debounce from 'debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select';
import DropdownStyles from 'views/components/DropdownStyles';
import { getTheme } from 'utils/SelectTheme';
import Searchfield from '../Searchfield';
import { CATEGORY_OPTIONS, DIRECTION, FILTER_OPTIONS } from './sorting';
import { LoggerFactory } from 'logger';
import ArrowIcon from 'views/components/icons/Arrow2';

class SortingControls extends Component {
  static propTypes = {
    filterText: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    direction: PropTypes.string.isRequired,
    filterOption: PropTypes.string.isRequired,
    onFilterTextChange: PropTypes.func.isRequired,
    onCategoryChange: PropTypes.func.isRequired,
    onDirectionChange: PropTypes.func.isRequired,
    onFilterOptionChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this._logger = LoggerFactory.getLogger(this);

    this.state = {
      filterText: this.props.filterText,
    };
  }

  _getSortArrowOptionClass = () => {
    const isAscending = this.props.direction === DIRECTION.ASCENDING;
    return `rotate-arrow ${!isAscending ? '  ' : 'descending'}`;
  };

  _onFilterInputChange = (e) => {
    // IMPORTANT:
    // Need to persist the event in order to use it later:
    // https://stackoverflow.com/questions/49081149/debounce-on-react-got-e-target-value-undefined
    e.persist();

    this.setState({
      filterText: e.target.value,
    });

    this._onFilterInputChangeDebounced(e);
  };

  _onFilterInputChangeDebounced = debounce((e) => {
    this.props.onFilterTextChange(e.target.value);
  }, 350);

  onSearchClear = () => {
    this.setState({
      filterText: '',
    });

    this.props.onFilterTextChange('');
  };

  _onDirectionChange = () => {
    const updatedDirection = this.props.direction === DIRECTION.ASCENDING ? DIRECTION.DESCENDING : DIRECTION.ASCENDING;
    this.props.onDirectionChange(updatedDirection);
  };

  render() {
    return (
      <div className="projects-sort-options">
        <Searchfield
          onChange={this._onFilterInputChange}
          placeholder={'Search Projects...'}
          value={this.state.filterText}
          onSearchClear={this.onSearchClear}
          filterText={this.state.filterText}
        />
        <div className="spacer"></div>
        <div className="sort-container">
          <div className="sort-label">Sort by:</div>
          <div className="select-wrapper">
            <Select
              classNamePrefix="select"
              onChange={(e) => this.props.onCategoryChange(e.value)}
              options={Object.values(CATEGORY_OPTIONS)}
              isSearchable={false}
              theme={getTheme}
              menuPosition={'fixed'}
              value={CATEGORY_OPTIONS[this.props.category]}
              styles={DropdownStyles}
              data-testid={'sort-select'}
            />
          </div>
          <div className={this._getSortArrowOptionClass()} onClick={this._onDirectionChange} role="button">
            <ArrowIcon data-testid={'sort-arrow'} color="#333333" />
          </div>
        </div>

        <div className="sort-container">
          <div className="sort-label">Filter:</div>
          <div className="select-wrapper">
            <Select
              classNamePrefix="select"
              onChange={(e) => this.props.onFilterOptionChange(e.value)}
              options={Object.values(FILTER_OPTIONS)}
              isSearchable={false}
              theme={getTheme}
              menuPosition={'fixed'}
              value={FILTER_OPTIONS[this.props.filterOption]}
              styles={DropdownStyles}
              data-testid={'filter-select'}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SortingControls;
