import React from 'react';

const DownloadIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M14.2875 7.775C13.8908 5.61875 12.1233 4 10 4C8.31417 4 6.85 5.025 6.12083 6.525C4.365 6.725 3 8.31875 3 10.25C3 12.3188 4.56917 14 6.5 14H14.0833C15.6933 14 17 12.6 17 10.875C17 9.225 15.8042 7.8875 14.2875 7.775ZM12.9167 9.625L10 12.75L7.08333 9.625H8.83333V7.125H11.1667V9.625H12.9167Z"
        fill={color}
      />
    </svg>
  );
};

export default DownloadIcon;
