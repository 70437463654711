import Version from './model/Version';

export default {
  VERSION: Version.parse(process.env.REACT_APP_VERSION || '0.0.0'),
  AUTH_URL: process.env.REACT_APP_AUTH_URL || 'http://localhost:8081/',
  API_URL: process.env.REACT_APP_API_URL || 'http://localhost:8080/',
  REPORTS_URL: process.env.REACT_APP_REPORTS_URL || 'http://localhost:8083/',
  GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || 'GTM-XXXXXX',
  TEXT_INPUT_DELAY: process.env.REACT_APP_TEXT_INPUT_DELAY || 3000, //3s
  ACTIVE_UPDATE_INTERVAL: process.env.REACT_APP_ACTIVE_UPDATE_INTERVAL || 10000, // 10s
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL || '',
  GETTING_STARTED_LINK: process.env.REACT_APP_GETTING_STARTED_LINK || '',
  MARKETPLACE_LINK: process.env.REACT_APP_MARKETPLACE_LINK || '',
  STONLY_WID: process.env.REACT_APP_STONLY_WID || '',
  MAINTENANCE_RUNNING_TEXT: process.env.REACT_APP_MAINTENANCE_RUNNING_TEXT || '',
  MAINTENANCE_TEXT: process.env.REACT_APP_MAINTENANCE_TEXT || '',
  SLEEKPLAN_PRODUCT_ID: process.env.REACT_APP_SLEEKPLAN_PRODUCT_ID,
};
