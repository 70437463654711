import { useState } from 'react';

const useTimer = () => {
  const [time, setTime] = useState(null);

  const initTimer = () => setTime(new Date());

  const getTimer = () => {
    if (!time) return time;

    let timer = new Date() - time;

    return timer;
  };

  const resetTimer = () => setTime(null);

  return {
    initTimer,
    getTimer,
    resetTimer,
  };
};

export default useTimer;
