const category = 'Project Events';

module.exports = [
  {
    fn: 'projectCreated',
    category,
    name: 'Project Created',
    trigger: 'ProjectCreated',
    params: ['projectId'],
  },
  {
    fn: 'projectUpdated',
    category,
    name: 'Project Updated',
    trigger: 'ProjectUpdated',
    params: ['projectId'],
  },
  {
    fn: 'projectDuplicated',
    category,
    name: 'Project Duplicated',
    trigger: 'ProjectDuplicated',
    params: ['projectId', 'newProjectId'],
  },
  {
    fn: 'projectShared',
    category,
    name: 'Project Shared',
    trigger: 'ProjectShared',
    params: ['projectId', 'sharedUserId'],
  },
  {
    fn: 'projectDeleted',
    category,
    name: 'Project Deleted',
    trigger: 'ProjectDeleted',
    params: ['projectId'],
  },
  {
    fn: 'phaseAdded',
    category,
    name: 'Phase Added',
    trigger: 'PhaseAdded',
    params: ['projectId'],
  },
  {
    fn: 'phaseCopied',
    category,
    name: 'Phase Copied',
    trigger: 'PhaseCopied',
    params: ['projectId'],
  },
  {
    fn: 'templateUsed',
    category,
    name: 'Template Used',
    trigger: 'TemplateUsed',
    params: ['templateId'],
  },
];
