export default class ValueObject {
  constructor(value) {
    if (value == null) {
      return;
    }
    this.value = `${value}`.trim();
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof ValueObject)) {
      return false;
    } else if (!(other instanceof this.constructor)) {
      return false;
    }

    return this.value === other.value;
  }

  equivalent(other) {
    return this.toString() === other.toString();
  }

  toString() {
    return this.value;
  }

  toJSON() {
    return this.toString();
  }

  static fromJSON(json) {
    return new this(json);
  }
}
