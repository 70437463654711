import L from 'leaflet';

const getPacificConnections = (fromCoords, toCoords, isExport) => {
  let bounds = L.bounds(L.point(90, -180), L.point(-90, 180));
  if (isExport) {
    // The exported map for APAC goes a bit over 180°E on the right, use the actual number
    bounds = L.bounds(L.point(90, -180), L.point(-90, 222.890625));
  }
  const ff = L.point(fromCoords.toLeaflet());
  const tf = L.point(toCoords.latitude / 1.5, toCoords.longitude - 360);
  const lineFrom = L.LineUtil.clipSegment(ff, tf, bounds);

  const ft = L.point(fromCoords.latitude / 1.5, fromCoords.longitude + 360);
  const tt = L.point(toCoords.toLeaflet());
  const lineTo = L.LineUtil.clipSegment(ft, tt, bounds);
  return { lineFrom, lineTo };
};

export default getPacificConnections;
