const filterMetrosByType = (metros, metroType) => {
  switch (metroType) {
    case 'existing':
      return metros.filter((m) => m.existing);
    case 'recommended':
      return metros.filter((m) => !m.existing);
    default:
      return metros;
  }
};

export default filterMetrosByType;
