import React from 'react';

const SavedIcon = ({ width = 10, height = 10, isFill = false, lineColor = 'black', ...rest }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12 14.5L6.55553 10.6111L1.11108 14.5V2.05556C1.11108 1.643 1.27497 1.24733 1.5667 0.955612C1.85842 0.663888 2.25408 0.5 2.66664 0.5H10.4444C10.857 0.5 11.2526 0.663888 11.5444 0.955612C11.8361 1.24733 12 1.643 12 2.05556V14.5Z"
      stroke={!isFill ? lineColor : undefined}
      fill={isFill ? '#FFBC16' : undefined}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SavedIcon;
