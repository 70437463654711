import React from 'react';
import Modal from 'react-modal';
import { GqlQueryComponent, Queries } from 'gql';
import DataSourceMetadata from 'model/DataSourceMetadata';
import Config from 'Config';
import '../Modal.scss';
import './DatasourcesModalView.scss';
import defaultStyles from 'views/ProjectListView/modals/defaultStyles';
import EdgeIcon from '../icons/Edge';
import MetalIcon from '../icons/Metal';
import MetroIcon from '../icons/Metro';
import CloudIcon from '../icons/Cloud';
import StarIcon from '../icons/Star';
import GlobeIcon from '../icons/Globe';

const ICONS = {
  '373606893035192321': <MetroIcon width={17} height={19} viewBox="0 0 17 19" />,
  '378717741131235329': <GlobeIcon width={17} height={19} />,
  '378721020485435393': <CloudIcon width={17} height={19} Color="black" viewBox="0 0 21 14" />,
  '378721020485500929': <CloudIcon width={17} height={19} Color="black" viewBox="0 0 21 14" />,
  '378721020485304321': <StarIcon width={17} height={19} rounded viewBox="0 0 17 17" />,
  '472861534398185473': <EdgeIcon width={17} height={19} />,
  '465292323211706369': <MetalIcon width={17} height={19} />,
};

export default class DatasourcesModalView extends GqlQueryComponent {
  query() {
    return Queries.GET_DATA_SOURCES;
  }

  transform(data) {
    return DataSourceMetadata.fromJSONArray(data.dataSources);
  }

  _renderProgress() {
    return (
      <Modal isOpen className="modal-window about" defaultStyles={defaultStyles}>
        <div className="loading"></div>
      </Modal>
    );
  }

  _renderItems(dataSources) {
    return dataSources.map((dataSource) => this._renderItem(dataSource));
  }

  _renderIcon(dataSource) {
    return ICONS[dataSource.id.value] || <CloudIcon />;
  }

  _renderItem(dataSource) {
    return (
      <tr key={dataSource.id}>
        <td className="information-column">
          {this._renderIcon(dataSource)} {dataSource.name}
        </td>
        <td>{dataSource.source}</td>
        <td>{dataSource.lastUpdated.toString('YYYY MMM D')}</td>
      </tr>
    );
  }

  renderQuery(loading, error, dataSources) {
    if (loading) {
      return this._renderProgress();
    }

    const { onClose } = this.props;
    return (
      <Modal isOpen className="modal-window about" defaultStyles={defaultStyles}>
        <div className="modal-image-header map" data-testid={'about-modal'}>
          <button className="close-modal" onClick={onClose}>
            <svg height="12" width="12">
              <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
            </svg>
          </button>
        </div>
        <div className="modal-header" style={{ position: 'relative' }}>
          <div>
            <h3>
              IOA WorkBench<span className="register">®</span>
            </h3>
            <p>Version {Config.VERSION.toString()}</p>
            <p>Service Window: Saturdays, 1am - 3am PST</p>
          </div>
        </div>
        <div className="modal-body">
          <table className="datasources">
            <thead>
              <tr>
                <th>Information</th>
                <th>Data Source</th>
                <th>Last Update</th>
              </tr>
            </thead>
            <tbody>{this._renderItems(dataSources)}</tbody>
          </table>
        </div>
      </Modal>
    );
  }
}
