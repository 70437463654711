import React from 'react';
import ToggleButton from 'views/components/ToggleButton';
import './TableStyles.scss';

const TextAndToggleTable = ({ items, onEnable, onDisable, isLoading }) => {
  return (
    <div className={'table'}>
      {items.map((item) => {
        return (
          <div className={'column'} data-testid={'item'} key={item.id}>
            <p>{item.name}</p>
            <ToggleButton small checked={item.checked} onChecked={() => onEnable(item)} onUnchecked={() => onDisable(item)} enabled={!isLoading} />
          </div>
        );
      })}
    </div>
  );
};

export default TextAndToggleTable;
