import React from 'react';
import { ControlledMenu } from '@szhsin/react-menu';

const ContextMenu = ({ children, isContextMenuOpen, onCloseContextMenu, anchorPoint }) => {
  return (
    <ControlledMenu
      state={isContextMenuOpen ? 'open' : 'closed'}
      onClose={onCloseContextMenu}
      anchorPoint={anchorPoint}
      portal
      viewScroll={'close'}
      direction={'right'}
      className={'default-menu sidebar-context-menu'}>
      {children}
    </ControlledMenu>
  );
};

export default ContextMenu;
