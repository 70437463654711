import { Mutations, withMutation } from 'gql';

export default withMutation({
  name: 'UPDATE_DEPLOYMENT_OFFSET',
  mutation: Mutations.UPDATE_DEPLOYMENT_OFFSET,
  mutateProp: 'updateDeploymentOffset',
  loadingProp: 'updateOffsetLoading',
  getMutationArgs(phaseId, metroId, deployment, offset) {
    return {
      variables: {
        phaseId,
        metroId,
        deployment,
        offset,
      },
    };
  },
});
