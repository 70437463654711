import React from 'react';
import ToggleButton from 'views/components/ToggleButton';

const TabPanelProductsSide = ({ productsText, productEnabled, onChecked, onUnchecked, loading, isCategory, productAvailable, toggleButton = true }) => {
  return (
    <>
      <span className="productsText">{productsText}</span>

      {productAvailable
        ? toggleButton && <ToggleButton checked={productEnabled} onChecked={onChecked} onUnchecked={onUnchecked} enabled={!loading} small={isCategory} />
        : !loading && <span data-testid={'not-available'}>not available at this location</span>}
    </>
  );
};

export default TabPanelProductsSide;
