import BaseModel from './BaseModel';
import DirectConnection from './DirectConnection';
import AccountStrategyPlanForPhase from './AccountStrategyPlanForPhase';
import ServiceConnection from './ServiceConnection';
import ProjectMetro from './ProjectMetro';
import Location from './Location';
import Group from './Group';
import ProjectPhaseId from './ProjectPhaseId';

export default class ProjectPhase extends BaseModel {
  static fields = [
    { key: 'id', type: ProjectPhaseId, stringify: true },
    { key: 'name', type: 'string', stringify: true },
    { key: 'metros', type: 'array', of: ProjectMetro },
    { key: 'services', type: 'array', of: ServiceConnection },
    { key: 'locations', type: 'array', of: Location },
    { key: 'groups', type: 'array', of: Group },
    { key: 'accountStrategyPlans', type: 'array', of: AccountStrategyPlanForPhase },

    /* Not yet implemented */
    { key: 'directConnections', type: 'array', of: DirectConnection },
  ];

  get isEmpty() {
    return (
      (!this.metros || this.metros.length === 0) &&
      (!this.groups || this.groups.length === 0) &&
      (!this.locations || this.locations.length === 0) &&
      (!this.services || this.services.length === 0)
    );
  }

  get exportable() {
    return !this.isEmpty && this.metros.length > 0;
  }

  get items() {
    const connectedLocations = this.metros.flatMap((m) => m.locations);
    const connectedGroups = this.metros.flatMap((m) => m.groups);
    const connectedServices = this.metros.flatMap((m) => m.services);
    return [...this.unconnectedItems, ...connectedLocations, ...connectedGroups, ...connectedServices];
  }

  get unconnectedItems() {
    return [...this.unconnectedGroups, ...this.unconnectedLocations, ...this.unconnectedServices];
  }

  get unconnectedGroups() {
    return [...this.groups];
  }

  get unconnectedLocations() {
    return [...this.locations];
  }

  get unconnectedServices() {
    return [...this.services];
  }

  get allServices() {
    const connectedServices = this.metros.flatMap((m) => m.services);
    return [...connectedServices, ...this.services];
  }

  get allLocations() {
    const connectedLocations = this.metros.flatMap((m) => m.locations);
    return [...connectedLocations, ...this.locations];
  }

  get allGroups() {
    const connectedGroups = this.metros.flatMap((m) => m.groups);
    return [...connectedGroups, ...this.groups];
  }

  get sortedGroups() {
    return ProjectPhase.sortGroups(this.allGroups);
  }

  static sortGroups(groups) {
    const sortedGroups = groups.reduce((sorted, group) => {
      if (sorted.has(group.color)) {
        return sorted.set(group.color, sorted.get(group.color).concat(group.locations));
      } else {
        return sorted.set(group.color, group.locations);
      }
    }, new Map());
    return [...sortedGroups];
  }
}
