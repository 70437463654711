const scrollPadding = 20;
const scrollUnits = 200;
const behavior = 'smooth';

const getMaxScroll = (element) => element.scrollWidth - element.clientWidth;

const isNearLeft = (element) => element.scrollLeft <= scrollPadding;

const isNearRight = (element) => element.scrollLeft >= getMaxScroll(element) - scrollPadding;

const scrollToLeft = (element) => {
  const shouldScrollToStart = element.scrollLeft - scrollUnits <= scrollPadding;
  element.scroll({ left: shouldScrollToStart ? 0 : element.scrollLeft - scrollUnits, behavior });
};

const scrollToRight = (element) => {
  const shouldScrollToEnd = getMaxScroll(element) - (element.scrollLeft + scrollUnits) <= scrollPadding;
  element.scroll({ left: shouldScrollToEnd ? getMaxScroll(element) : element.scrollLeft + scrollUnits, behavior });
};

export { getMaxScroll, isNearLeft, isNearRight, scrollToLeft, scrollToRight };
