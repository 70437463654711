import BaseModel from './BaseModel';

import SubSegmentId from './SubSegmentId';

export default class SubSegment extends BaseModel {
  static fields = [
    { key: 'id', type: SubSegmentId, stringify: true },
    { key: 'name', type: 'string', stringify: true },
  ];
}
