import ValueObject from './ValueObject';

export default class NetworkEdgeDeviceId extends ValueObject {
  equals(other) {
    if (!(other instanceof NetworkEdgeDeviceId)) {
      return false;
    }

    return super.equals(other);
  }

  static fromJSON(json) {
    return new NetworkEdgeDeviceId(json);
  }
}
