import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { Mutations, Queries } from 'gql';
import { toast } from 'utils/Toast';
import { LoggerFactory } from 'logger';
import { thumbnail as defaultThumbnail } from '../modals/defaultTemplateThumbnail';
import TemplateCardInformation from './TemplateCardInformation';
import TemplateImageButtons from './TemplateImageButtons';
import TemplateModals from './TemplateModals';
import { TemplateEvents } from 'analytics';
import './TemplateCard.scss';
import Button from 'views/components/Button';

const TemplateCard = ({ template, history, user }) => {
  const logger = LoggerFactory.getLogger('template');
  const [updateTemplate, { loading: updateLoading }] = useMutation(Mutations.UPDATE_TEMPLATE);
  const [userSaveTemplate] = useMutation(Mutations.USER_SAVE_TEMPLATE);
  const [userUnsaveTemplate, { loading: unsaveLoading }] = useMutation(Mutations.USER_UNSAVE_TEMPLATE);
  const [deleteTemplate, { loading: deleteLoading }] = useMutation(Mutations.DELETE_TEMPLATE);
  const {
    thumbnail,
    timesSaved,
    name,
    owner,
    timesUsed,
    lastUpdated,
    categories,
    description,
    project,
    id: templateId,
    published,
    isSaved,
    updateAvailable,
    deleted,
  } = template;
  const isOwner = user.id.value === owner.id;
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isUnsaveModalOpen, setIsUnsaveModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const formattedDefaultThumbnail = `data:image/png;base64,${defaultThumbnail}`;
  const formattedThumbnail = thumbnail || formattedDefaultThumbnail;

  const openCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const openUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const openPublishModal = () => {
    setIsPublishModalOpen(true);
  };

  const closePublishModal = () => {
    setIsPublishModalOpen(false);
  };

  const openUnsaveModal = () => {
    setIsUnsaveModalOpen(true);
  };

  const closeUnsaveModal = () => {
    setIsUnsaveModalOpen(false);
  };

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onTogglePublicTemplate = async () => {
    const updatedTemplate = { id: templateId, published: !published };
    try {
      await updateTemplate({ variables: { param: updatedTemplate }, refetchQueries: [{ query: Queries.GET_TEMPLATES, variables: { scope: 'PUBLISHED' } }] });
      if (!published) {
        TemplateEvents.templatePublished(templateId);
      } else {
        TemplateEvents.templateUnpublished(templateId);
      }
      closePublishModal();
    } catch (err) {
      logger.error(`An unexpected error occurred toggling template's visibility: ${err}`);
      return toast("An unexpected error occurred toggling template's visibility", 'error');
    }
  };

  const ChangeSavedStatus = async () => {
    const mutationArgs = {
      variables: { templateId },
      refetchQueries: [{ query: Queries.GET_TEMPLATES, variables: { scope: 'SAVED' } }],
    };
    try {
      if (isSaved) {
        await userUnsaveTemplate(mutationArgs);
        closeUnsaveModal();
      } else {
        await userSaveTemplate(mutationArgs);
        TemplateEvents.templateSaved(templateId);
      }
    } catch (error) {
      return toast('An unexpected error occurred saving template.', 'error');
    }
  };

  const onClickSaveButton = () => {
    isSaved && (deleted || !published) ? openUnsaveModal() : ChangeSavedStatus();
  };

  const onDelete = async () => {
    try {
      await deleteTemplate({ variables: { templateId: templateId }, refetchQueries: [{ query: Queries.GET_TEMPLATES, variables: { scope: 'MINE' } }] });
      closeDeleteModal();
    } catch (err) {
      logger.error(`An unexpected error occurred deleting Template: ${err}`);
      return toast('An unexpected error occurred deleting Template', 'error');
    }
  };

  const onThumbnailError = (e) => {
    e.target.src = formattedDefaultThumbnail;
  };

  return (
    <div className="template-card" data-testid={'template-card'}>
      <div className="image-container">
        <img src={formattedThumbnail} alt="thumbnail" onError={onThumbnailError} />
        <TemplateImageButtons
          isOwner={isOwner}
          published={published}
          openPublishModal={openPublishModal}
          timesSaved={timesSaved}
          updateAvailable={updateAvailable}
          isSaved={isSaved}
          openUpdateModal={openUpdateModal}
          onClickSaveButton={onClickSaveButton}
        />
      </div>
      <TemplateCardInformation
        name={name}
        timesUsed={timesUsed}
        templateId={templateId}
        project={project}
        isOwner={isOwner}
        owner={owner}
        lastUpdated={lastUpdated}
        categories={categories}
        description={description}
        openDeleteModal={openDeleteModal}
      />
      <div className="buttons-container" data-testid={'buttons-container'}>
        <Button color="red" isInverse={true} text={'Use this template'} onClick={openCreateModal} />
        <Button
          color="gray"
          isInverse={true}
          text={'Preview'}
          onClick={() =>
            history.push({
              pathname: `/templates/${templateId}`,
            })
          }
        />
      </div>
      <TemplateModals
        isCreateModalOpen={isCreateModalOpen}
        isUpdateModalOpen={isUpdateModalOpen}
        isPublishModalOpen={isPublishModalOpen}
        isUnsaveModalOpen={isUnsaveModalOpen}
        isDeleteModalOpen={isDeleteModalOpen}
        closeCreateModal={closeCreateModal}
        closeUpdateModal={closeUpdateModal}
        closeDeleteModal={closeDeleteModal}
        updateLoading={updateLoading}
        unsaveLoading={unsaveLoading}
        deleteLoading={deleteLoading}
        onTogglePublicTemplate={onTogglePublicTemplate}
        onDelete={onDelete}
        history={history}
        ChangeSavedStatus={ChangeSavedStatus}
        closePublishModal={closePublishModal}
        closeUnsaveModal={closeUnsaveModal}
        template={template}
        companyId={user.companyId}
      />
    </div>
  );
};

export default TemplateCard;
