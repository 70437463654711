import React from 'react';
import Checkbox from 'views/components/Checkbox';
import Flyout from '@streammedev/flyout';
import classnames from 'classnames';
import { usePhaseItems } from 'providers/PhaseItemsProvider';
import iconTrash from 'static/img/icon-trash-white.svg';

const BulkActions = ({ phase, onDrop, onDelete, onGroup }) => {
  const { checkedItems, setCheckedItems, moveActionsFlyoutRef } = usePhaseItems();

  return (
    <div data-testid={'bulk-action'} className={classnames('bulk-actions', { visible: !!checkedItems.length })}>
      <Checkbox checked={checkedItems.length} onCheck={() => setCheckedItems([])} />
      {checkedItems.length} items
      <button className="group button" data-testid={'bulk-group'} onClick={onGroup}>
        Group Sites
      </button>
      <Flyout closeOnWindowBlur closeOnExternalClick className="move-menu" ref={moveActionsFlyoutRef}>
        <Flyout.FlyoutToggle className="toggle button">Move</Flyout.FlyoutToggle>
        <button onClick={() => onDrop(checkedItems[0], null)}>To Unconnected</button>
        {phase.metros.map((metro) => (
          <button key={metro.id} onClick={() => onDrop(checkedItems[0], metro.id)}>
            To {metro.name}
          </button>
        ))}
      </Flyout>
      <button className="delete button" data-testid={'bulk-delete'} onClick={onDelete}>
        <img src={iconTrash} alt="Delete" />
      </button>
    </div>
  );
};

export default BulkActions;
