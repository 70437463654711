import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import noop from 'lodash/noop';
import Project from 'model/Project';
import ProjectListItem from './ProjectListItem';
import { CATEGORY, DIRECTION, SORTING_MODE, FILTER, buildSorting } from './sorting';
import SortingControls from './SortingControls';
import { getCachedSort, setCachedSorted } from '../cachedSortingState';

class ProjectsList extends Component {
  static defaultProps = {
    projects: [],
    onSelectionChanged: noop,
  };

  static propTypes = {
    projects: PropTypes.arrayOf(PropTypes.instanceOf(Project)).isRequired,
    sharedProjects: PropTypes.arrayOf(PropTypes.instanceOf(Project)),
    onSelectionChanged: PropTypes.func.isRequired,
    user: PropTypes.shape({ id: PropTypes.any.isRequired }).isRequired,
    onDeleteProjectClick: PropTypes.func,
    onLeaveProjectClick: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      category: CATEGORY.LAST_UPDATED,
      direction: DIRECTION.ASCENDING,
      filterText: '',
      filterOption: FILTER.ALL_PROJECTS,
    };

    this.cachedStateKey = 'myProjects';
    this.userId = this.props.user.id.value;

    const { category, direction } = getCachedSort({ cachedStateKey: this.cachedStateKey, userId: this.userId });
    category && (this.state.category = category);
    direction && (this.state.direction = direction);
  }

  componentDidUpdate() {
    setCachedSorted({ userId: this.userId, cachedStateKey: this.cachedStateKey, category: this.state.category, direction: this.state.direction });
  }

  _onDirectionChange = (direction) => {
    this.setState({
      direction,
    });
  };

  _onCategoryChange = (category) => {
    this.setState({
      category,
    });
  };

  _onFilterTextChange = (filterText) => {
    this.setState({
      filterText,
    });
  };

  _onFilterOptionChange = (filterOption) => {
    this.setState({
      filterOption,
    });
  };

  _onOpenOptionsMenu = (projectId) => {
    this.setState({ hasMenuOpen: projectId });
  };

  renderProjects() {
    let sortedProjects;

    switch (this.state.filterOption) {
      case FILTER.ALL_PROJECTS:
        sortedProjects = [...this.props.projects, ...this.props.sharedProjects];
        break;

      case FILTER.I_SHARED:
        sortedProjects = this.props.projects.filter((project) => project.sharedUsers.length);
        break;

      case FILTER.SHARED_WITH_ME:
        sortedProjects = [...this.props.sharedProjects];
        break;

      default:
        sortedProjects = [...this.props.projects, ...this.props.sharedProjects];
        break;
    }

    if (this.state.filterText) {
      const fuseOptions = {
        // The lower the threshold, the stricter the fuzzy search is.
        // 0.0 requires a perfect string match, so want just slightly above 0.0,
        // so that partial matches still occur (e.g. 'argo' should match 'Wells Fargo').
        threshold: 0.1,

        keys: ['name', 'customer.name'],
      };

      const fuse = new Fuse(sortedProjects, fuseOptions);

      sortedProjects = fuse.search(this.state.filterText).map((result) => result.item);
    }

    switch (buildSorting(this.state.category, this.state.direction)) {
      case SORTING_MODE.PROJECT_NAME_DESCENDING:
        sortedProjects.sort((a, b) => b.name.localeCompare(a.name));
        break;

      case SORTING_MODE.PROJECT_NAME_ASCENDING:
        sortedProjects.sort((a, b) => a.name.localeCompare(b.name));
        break;

      case SORTING_MODE.CUSTOMER_NAME_DESCENDING:
        sortedProjects.sort((a, b) => b.customer.name.localeCompare(a.customer.name));
        break;

      case SORTING_MODE.CUSTOMER_NAME_ASCENDING:
        sortedProjects.sort((a, b) => a.customer.name.localeCompare(b.customer.name));
        break;

      case SORTING_MODE.LAST_UPDATED_DESCENDING:
        sortedProjects.sort((a, b) => a.lastUpdated.value - b.lastUpdated.value);
        break;

      case SORTING_MODE.LAST_UPDATED_ASCENDING:
        sortedProjects.sort((a, b) => b.lastUpdated.value - a.lastUpdated.value);
        break;

      default:
        sortedProjects.sort((a, b) => b.lastUpdated.value - a.lastUpdated.value);
        break;
    }

    return (
      <div>
        <SortingControls
          filterText={this.state.filterText}
          category={this.state.category}
          direction={this.state.direction}
          filterOption={this.state.filterOption}
          onFilterTextChange={this._onFilterTextChange}
          onCategoryChange={this._onCategoryChange}
          onDirectionChange={this._onDirectionChange}
          onFilterOptionChange={this._onFilterOptionChange}
        />
        {this.props.isScrolling && <div className="shadow-bottom"></div>}
        <ul className="projects-list">{sortedProjects.map((project) => this.renderProject(project))}</ul>
      </div>
    );
  }

  renderProject(project) {
    const { onUpdateProject, onShareProject, onDeleteProjectClick, onLeaveProjectClick, sharedProjects } = this.props;
    const sharedWithMe = sharedProjects.find((p) => p.id.equals(project.id));
    const isOptionsMenuOpen = project.id.equals(this.state.hasMenuOpen);
    return (
      <ProjectListItem
        key={project.id}
        sharedWithMe={sharedWithMe}
        project={project}
        user={this.props.user}
        onOpenOptionsMenu={this._onOpenOptionsMenu}
        isOptionsMenuOpen={isOptionsMenuOpen}
        onUpdateProject={!sharedWithMe ? onUpdateProject : undefined}
        onShareProject={!sharedWithMe ? onShareProject : undefined}
        onDeleteProjectClick={!sharedWithMe ? onDeleteProjectClick : undefined}
        onLeaveProjectClick={onLeaveProjectClick}
        onSelected={this.props.onSelectionChanged}
      />
    );
  }

  renderNoProjects() {
    return (
      <div className="no-projects">
        <h3>No Projects Created Yet</h3>
        <p>Get started on creating your first IOA design</p>
      </div>
    );
  }

  render() {
    return !this.props.projects.length && !this.props.sharedProjects.length ? this.renderNoProjects() : this.renderProjects();
  }
}

export default ProjectsList;
