import React from 'react';

const Metro2Icon = ({ width = 10, height = 10, color, ...rest }) => {
  return (
    <svg className="metro-icon" width={width} height={height} fill={color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M20 20H0V16.1902L1.32597 15.9053V8.57048L3.43278 7.37204L4.78821 7.98976V8.86639L10.3904 6.48431L18.2689 9.89837V10.9008L10.3536 7.76782L4.89871 10.087V11.3076L10.3904 9.34724L18.2689 12.1325V13.416L10.372 10.701L4.86188 12.5024V13.7489L10.3794 12.3063L18.2689 14.4258V15.8203L19.9816 16.2345L20 20Z"
        fill={color ? color : 'currentColor'}
      />
      <path
        d="M4.82515 7.60133L10.3684 4.93444L18.269 8.62962V7.60503L10.35 3.57325L4.82515 6.43618C4.82515 6.43618 4.82515 7.65681 4.82515 7.60133Z"
        fill={color ? color : 'currentColor'}
      />
      <path d="M4.82515 5.20801L10.3758 2.04918L18.269 6.41015V4.64948L10.3537 0L4.82515 3.40296V5.20801Z" fill={color ? color : 'currentColor'} />
    </svg>
  );
};

export default Metro2Icon;
