import React, { useState, useEffect } from 'react';
import FeatureEvents from 'analytics/FeatureEvents';
import { LoggerFactory } from 'logger';
import { dismissAllToasts, toast } from 'utils/Toast';
import './AddMultipleLocationsView.scss';
import useAddMultipleLocations from './useAddMultipleLocations';
import ResultMessagesWrapper from './ResultMessagesWrapper';
import DescriptionSection from './DescriptionSection';
import uploadFile from './uploadFile';
import ExcelDropzone from './ExcelDropzone';
import useTimer from 'hooks/useTimer';
import Button from 'views/components/Button';

const AddMultipleLocationsView = ({ projectId, phaseId, onClose }) => {
  const _logger = LoggerFactory.getLogger('AddMultipleLocationsView');

  const { addMultipleLocations, dataAddMultipleLocations, errorAddMultipleLocations } = useAddMultipleLocations();
  const { initTimer, getTimer, resetTimer } = useTimer();

  const [file, setFile] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [success, setSuccess] = useState(false);
  const [rowsInserted, setRowsInserted] = useState(null);
  const [rowsRejected, setRowsRejected] = useState(null);
  const [issues, setIssues] = useState([]);

  const clearState = () => {
    setFile(null);
    setLoadingFile(false);
    setErrorMessages([]);
    setSuccess(false);
    setRowsInserted(null);
    setRowsRejected(null);
    setIssues([]);
    resetTimer();
  };

  const doAddMultipleLocations = async (fileReference) => {
    _logger.debug(`Adding multiple locations with filename: ${fileReference}`);
    FeatureEvents.excelUploaded(phaseId);
    initTimer();
    await addMultipleLocations(projectId, phaseId, fileReference);
  };

  useEffect(() => {
    // onCompleted
    if (!dataAddMultipleLocations) return;
    // TODO: addPhaseLocations? addLocations?
    const { issues, locationsInserted, locationsRejected } = dataAddMultipleLocations.addProjectLocations.importation;
    _logger.debug('Add multiple locations mutation completed successfully');

    clearState();
    setSuccess(true);
    setRowsInserted(locationsInserted);
    setRowsRejected(locationsRejected);
    setIssues(issues);
    // eslint-disable-next-line
  }, [dataAddMultipleLocations]);

  useEffect(() => {
    // onError
    if (!errorAddMultipleLocations) return;

    if (errorAddMultipleLocations.networkError) {
      const timeoutMilliseconds = 55000;
      const didTimeout = getTimer() > timeoutMilliseconds;

      if (didTimeout) {
        clearState();
        setErrorMessages([
          'This file is taking a long time to process and will continue processing in the background. Please try refreshing momentarily.\n\nIf the import fails, please try splitting into multiple excel files.',
        ]);

        return;
      }
    }

    clearState();
    toast('Make sure you are using the latest version of the Excel Template v1.2', 'error');
    // eslint-disable-next-line
  }, [errorAddMultipleLocations]);

  const _onClose = () => {
    clearState();
    onClose();
  };

  const onDropAccepted = async (files) => {
    const file = files[0];

    clearState();
    setFile(file);

    setLoadingFile(true);
    try {
      const fileReference = await uploadFile(file);
      await doAddMultipleLocations(fileReference);
      dismissAllToasts();
    } catch {
      clearState();
      toast('An unexpected error occurred adding multiple locations', 'error');
    }
  };

  const onDropRejected = (files) => {
    clearState();
    const errorsMessages = {
      'file-invalid-type': 'File type must be Excel (.xlsx file).',
      'file-too-large': 'File is larger than 1 mb.',
    };
    const defaultErrorMessage = 'An unexpected error occurred.';

    const errorMessages = files[0].errors.map(({ code }) => errorsMessages[code] || defaultErrorMessage);
    setErrorMessages(errorMessages);
  };

  return (
    <div className="AddView AddMultipleLocationsView">
      <h2>Upload Excel File</h2>

      <DescriptionSection />

      <div className="file-zone" data-testid={'file-zone'}>
        <ResultMessagesWrapper errorMessages={errorMessages} success={success} rowsInserted={rowsInserted} rowsRejected={rowsRejected} issues={issues} />

        <ExcelDropzone onDropAccepted={onDropAccepted} onDropRejected={onDropRejected} fileName={file?.name} loading={loadingFile} />
      </div>

      <div className="buttons">
        <Button color="red" isDisabled={loadingFile} text={'Close'} onClick={_onClose} />
      </div>
    </div>
  );
};

export default AddMultipleLocationsView;
