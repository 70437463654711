import React from 'react';
import StarIcon from '../icons/Star';
import ArrowIcon from '../icons/Arrow';

/**
 *
 * @param {number} ASPCount Amount of imported ASPs
 * @param {bool} isActive Whether the summary is currently active or not
 * @param {func} onClick Function to be executed when the component is clicked
 */
const ASPSummary = ({ ASPCount, isActive, onClick }) => {
  return (
    <div className={`phase-asps ${isActive ? 'active' : ''}`} onClick={onClick}>
      <div className={'start'}>
        <StarIcon width={18} height={18} color={isActive ? 'black' : 'white'} />
        <div className={'asp-count'}>
          <p>
            {ASPCount} Imported DSP{ASPCount > 1 && 's'}
          </p>
        </div>
      </div>
      <ArrowIcon className={'arrow-icon'} width={7} height={10} color="#999999" />
    </div>
  );
};

export default ASPSummary;
