export const FILTER = Object.freeze({
  ALL_TEAM: 'ALL_TEAM',
  COMPANY_OWNER: 'COMPANY_OWNER',
  TEAM_ADMIN: 'TEAM_ADMIN',
  TEAM_MEMBER: 'TEAM_MEMBER',
  LOGGED_IN: 'LOGGED_IN',
  NEVER_LOGGED_IN: 'NEVER_LOGGED_IN',
});

export const FILTER_OPTIONS = Object.freeze({
  [FILTER.ALL_TEAM]: {
    label: 'All Roles & Activity',
    value: FILTER.ALL_TEAM,
  },
  [FILTER.COMPANY_OWNER]: {
    label: 'Company Owner',
    value: FILTER.COMPANY_OWNER,
  },
  [FILTER.TEAM_ADMIN]: {
    label: 'Team Admin',
    value: FILTER.TEAM_ADMIN,
  },
  [FILTER.TEAM_MEMBER]: {
    label: 'Team Member',
    value: FILTER.TEAM_MEMBER,
  },
  [FILTER.LOGGED_IN]: {
    label: 'Logged In',
    value: FILTER.LOGGED_IN,
  },
  [FILTER.NEVER_LOGGED_IN]: {
    label: 'Never Logged In',
    value: FILTER.NEVER_LOGGED_IN,
  },
});

export const SORT = Object.freeze({
  USER_EMAIL: 'USER_EMAIL',
  LAST_LOG_IN: 'LAST_LOG_IN',
});

export const SORT_OPTIONS = Object.freeze({
  [SORT.USER_EMAIL]: {
    label: 'User Email',
    value: SORT.USER_EMAIL,
  },
  [SORT.LAST_LOG_IN]: {
    label: 'Last Log In',
    value: SORT.LAST_LOG_IN,
  },
});

export const DIRECTION = Object.freeze({
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
});

export const formatCategoriesForSelect = (categories) => {
  const filterOptions = Object.values(FILTER_OPTIONS);
  return filterOptions.filter((option) => categories?.includes(option.label));
};

export const formatCategories = (categories) => categories.map((category) => category.label);
