import React from 'react';

const UsersIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M12.6364 14.5453V13.0907C12.6364 12.3192 12.3299 11.5793 11.7843 11.0337C11.2388 10.4881 10.4988 10.1816 9.72727 10.1816H3.90909C3.13755 10.1816 2.39761 10.4881 1.85205 11.0337C1.30649 11.5793 1 12.3192 1 13.0907V14.5453"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.81729 7.27326C8.42394 7.27326 9.72639 5.97082 9.72639 4.36417C9.72639 2.75752 8.42394 1.45508 6.81729 1.45508C5.21065 1.45508 3.9082 2.75752 3.9082 4.36417C3.9082 5.97082 5.21065 7.27326 6.81729 7.27326Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0002 14.5464V13.0919C16.9997 12.4473 16.7852 11.8212 16.3903 11.3118C15.9954 10.8023 15.4425 10.4385 14.8184 10.2773"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9082 1.55078C12.534 1.711 13.0886 2.07493 13.4847 2.58519C13.8807 3.09545 14.0957 3.72302 14.0957 4.36896C14.0957 5.0149 13.8807 5.64248 13.4847 6.15274C13.0886 6.663 12.534 7.02693 11.9082 7.18714"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UsersIcon;
