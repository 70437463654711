import React from 'react';
import cn from 'classnames';
import COLORS from './colors';

const ColorPicker = ({ open, value, formId, onColorClick, togglePicker }) => (
  <>
    <div className="color-picker">
      <div className="current color" style={{ backgroundColor: value }} onClick={() => togglePicker(formId)} />
      {open && (
        <div className="dropdown">
          {COLORS.map((color) => (
            <div
              key={color}
              className={cn('color', {
                selected: color === value,
              })}
              style={{ backgroundColor: color }}
              onClick={() => onColorClick(color)}
            />
          ))}
        </div>
      )}
    </div>
    {open && <div className="color-picker-backdrop" onClick={() => togglePicker(null)} />}
  </>
);

export default ColorPicker;
