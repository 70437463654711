import { LoggerFactory } from 'logger';
import Location from 'model/Location';
import ServiceConnection from 'model/ServiceConnection';
import { usePhaseItems } from 'providers/PhaseItemsProvider';
import { useCurrentProject } from 'providers/ProjectProvider';
import { useAddLocationGroupLocations } from '../hocs/withAddLocationGroupLocations';

const useOnDropOnGroup = (onClose) => {
  const { getDraggedItems, pendingMoves, setPendingMoves, checkedItems, setCheckedItems, clearBulkActionUI } = usePhaseItems();
  const { projectId, selectedPhaseId: phaseId } = useCurrentProject();
  const { addLocationGroupLocations } = useAddLocationGroupLocations();
  const logger = LoggerFactory.getLogger('on drop on group');

  return async (draggedItem, group) => {
    if (draggedItem instanceof ServiceConnection) return;
    logger.debug(`Dropped ${draggedItem} on group: ${group}`);
    onClose();
    const draggedItems = getDraggedItems(draggedItem).filter((t) => t instanceof Location);

    if (draggedItems.length <= 0) {
      return; // don't do anything if we don't have locations to add to the group
    }

    const newPendingMoves = draggedItems.map((t) => ({ item: t, metroId: '__GROUP__' }));

    // Add the dropped group to the list of pending moves, so that the group shows the loading animation.
    // ONLY add the group, if it is not in the list of pending moves already.
    const isGroupAlreadyInPendingMoves = pendingMoves.some((pendingMove) => pendingMove.item.equals(group));
    if (!isGroupAlreadyInPendingMoves) {
      newPendingMoves.push({
        item: group,
        metroId: group.metroId,
      });
    }

    // uncheck the locations that were grouped
    const newCheckedItems = checkedItems.filter((i) => !draggedItems.find((l) => i.equals(l)));
    setPendingMoves((prev) => [...prev, ...newPendingMoves]);
    setCheckedItems(newCheckedItems);

    await addLocationGroupLocations(
      projectId,
      phaseId,
      group.id,
      draggedItems.map((i) => i.id)
    );

    const pendingMovesFiltered = pendingMoves.filter((pendingMove) => {
      // Check the list of just moved draggedItems.
      let isFinishedPendingMove = draggedItems.some((i) => pendingMove.item.equals(i));

      // Check for whether one of the pending moves was the Group that was
      // just dropped on.
      if (group.equals(pendingMove.item)) {
        isFinishedPendingMove = true;
      }

      return !isFinishedPendingMove;
    });
    setPendingMoves(pendingMovesFiltered);
    setCheckedItems([]);
    clearBulkActionUI();
  };
};

export default useOnDropOnGroup;
