import LOGGER from './logger';
import { getGtagContext } from './GtagContext';

export default class ApiEvents {
  static projectsListed(time) {
    LOGGER.debug('Tracking projects listed');

    const gtag = getGtagContext();
    gtag.event('ProjectsListed', { time });
  }

  static projectLoaded(time) {
    LOGGER.debug('Tracking project loaded');

    const gtag = getGtagContext();
    gtag.event('ProjectLoaded', { time });
  }
}
