import React from 'react';
import CustomerSitesTemplate from 'static/CustomerSites_v1.2.xlsx';
import DownloadIcon from 'views/components/icons/Download';

const DescriptionSection = () => {
  const downloadIcon = <DownloadIcon width={20} height={20} color="white" />;

  return (
    <div className="type">
      <p>Use the template below to upload multiple customer locations.</p>

      <a href={CustomerSitesTemplate} target="_blank" download="CustomerSites_v1.2.xlsx" rel="noopener noreferrer" className={'download-template-link'}>
        <div className={'download-template'}>
          <p>Download the Excel Template v1.2</p> {downloadIcon}
        </div>
      </a>
    </div>
  );
};

export default DescriptionSection;
