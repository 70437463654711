import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that triggers a function if you click outside of it
 */
export default class OutsideAlerter extends Component {
  onBlur(event) {
    // currentTarget refers to this component.
    // relatedTarget refers to the element where the user clicked (or focused) which triggered this event.
    // So in effect, this condition checks if the user clicked outside the component.
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.props.onClickOutside();
    }
  }

  render() {
    // tabIndex="0" will make the browser know that your element is focusable
    // https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null
    return (
      <div tabIndex="0" style={this.props.style} onBlur={this.onBlur.bind(this)}>
        {this.props.children}
      </div>
    );
  }
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  style: PropTypes.object,
};
