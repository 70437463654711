import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink, concat } from 'apollo-link';
import { onError } from 'apollo-link-error';
import Config from 'Config';
import getAuthenticationService from 'service/AuthenticationService';
import { LoggerFactory } from 'logger';
import { Actions } from 'stores/UserStore';
import resolvers from './resolvers';

const LOGGER = LoggerFactory.getLogger('GraphQL');

const API_URL = Config.API_URL + 'graphql';

const CACHE = new InMemoryCache();

const HTTP_LINK = new HttpLink({ uri: API_URL });

const AUTH_MIDDLEWARE = new ApolloLink((operation, forward) => {
  const authenticationService = getAuthenticationService();
  const token = authenticationService.getToken();

  //if the token exists, add the authorization header
  if (token) {
    operation.setContext({
      headers: {
        Authorization: 'Bearer ' + token.value,
      },
    });
  }

  return forward(operation);
});

const ERROR_HANDLER = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => LOGGER.error(`Message: ${message}, Location: ${locations}, Path: ${path}`));
  }

  if (networkError) {
    if (networkError.statusCode === 403) {
      Actions.expired();
    }
    LOGGER.error(`Network error: ${networkError.message}`);
  }
});

const CLIENT = new ApolloClient({
  link: concat(AUTH_MIDDLEWARE, ApolloLink.from([ERROR_HANDLER, HTTP_LINK])),
  cache: CACHE,
  resolvers,
});

/*CLIENT
.query({
    query: gql`
        {
            version {
                major, minor, offset
            },
            user {
                id
            }
        }
    `
})
.then(result => console.log("VERSION RESULT: " + result.data.version, result.data.version));
*/

export default CLIENT;
