import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'react-modal';
import CreateProjectView from './CreateProjectView';
import defaultStyles from './defaultStyles';

class CreateProjectModalView extends React.Component {
  static defaultProps = {
    isOpen: false,
    fromTemplate: false,
    template: null,

    onClose: () => {},
  };

  static propTypes = {
    isOpen: PropTypes.bool,
    fromTemplate: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    template: PropTypes.shape({
      templateId: PropTypes.string,
      name: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);
    this.state = {
      customerName: null,
      projectName: null,
      segmentId: null,
    };
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        className="modal-window"
        onRequestClose={this.props.onClose}
        contentLabel="Create a Project"
        defaultStyles={defaultStyles}>
        <CreateProjectView
          companyId={this.props.companyId}
          history={this.props.history}
          onClose={this.props.onClose}
          fromTemplate={this.props.fromTemplate}
          template={this.props.template}
        />
      </Modal>
    );
  }
}

export default CreateProjectModalView;
