import L from 'leaflet';
import 'leaflet-maskcanvas';
import { MapLayer, withLeaflet } from 'react-leaflet';

const MARKER_RADIUS = 4.5;

class MarkerGroup extends MapLayer {
  createLeafletElement(props) {
    var layer = L.TileLayer.maskCanvas({
      radius: MARKER_RADIUS * props.iconSize, // radius in pixels or in meters (see useAbsoluteRadius)
      useAbsoluteRadius: false, // true: r in meters, false: r in pixels
      color: props.color, // the color of the layer
      opacity: 1, // opacity of the not covered area
      noMask: true, // true results in normal (filled) circled, instead masked circles
      lineColor: '#2F80ED00', // color of the circle outline if noMask is true
      noWrap: true,
    });
    layer.setData(props.data);
    return layer;
  }
  updateLeafletElement(fromProps, toProps) {
    if (fromProps.data.length !== toProps.data.length) {
      this.leafletElement.setData(toProps.data);
    }
    if (fromProps.color !== toProps.color) {
      this.leafletElement.options.color = toProps.color;
      this.leafletElement.redraw();
    }
    if (fromProps.iconSize !== toProps.iconSize) {
      this.leafletElement.options.radius = MARKER_RADIUS * toProps.iconSize;
      this.leafletElement.setData(toProps.data);
      // turns out the radius is stored with each data point individually,
      // so we need to re-set the data for the new radius to be taken into account.
    }
  }
}

export default withLeaflet(MarkerGroup);
