import Assert from '../utils/Assert';
import ServiceRegionId from './ServiceRegionId';
import GeoCoordinates from './GeoCoordinates';
import Metro from './Metro';
import MetroId from './MetroId';

class MetroConnection {
  constructor(metroId, latency) {
    Assert.instanceOf(metroId, MetroId);
    Assert.isNumber(latency);

    this.metroId = metroId;
    this.latency = latency;
    Object.freeze(this);
  }

  equals(other) {
    if (other instanceof MetroConnection) {
      return this.metroId.equals(other.metroId) && this.latency === other.latency;
    } else if (other instanceof MetroId) {
      return this.metroId.equals(other);
    } else if (other instanceof Metro) {
      return this.metroId.equals(other.id);
    } else {
      return false;
    }
  }

  static fromJSON(json) {
    const { latency, metro } = json;

    return new MetroConnection(new MetroId(metro.id), latency);
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = MetroConnection.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}

class City {
  constructor(name, region) {
    Assert.isString(name);
    Assert.isString(region);

    this.name = name;
    this.region = region;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof City)) {
      return false;
    }

    return this.name === other.name && this.region === other.region;
  }

  toString() {
    return `${this.constructor.name}{ name:'${this.name}', region:'${this.region}' }`;
  }

  toJSON() {
    const { name, region } = this;
    return { name, region };
  }

  static fromJSON(json) {
    const { name, region } = json;

    return new City(name, region);
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = City.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}

export default class ServiceRegion {
  constructor(id, name, code, city, coordinates, connectedMetros, deprecated) {
    Assert.instanceOf(id, ServiceRegionId);
    Assert.isString(name);
    Assert.isString(code);
    // Assert.isString(city);
    Assert.instanceOf(city, City);
    Assert.instanceOf(coordinates, GeoCoordinates);
    deprecated == null || Assert.isBoolean(deprecated);

    this.id = id;
    this.name = name;
    this.code = code;
    this.city = city;
    this.coordinates = coordinates;
    this.connectedMetros = connectedMetros;
    this.deprecated = deprecated ?? false;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof ServiceRegion)) {
      return false;
    }

    return this.id.equals(other.id);
  }

  toString() {
    return `${this.constructor.name}{ id:${this.id}, name:'${this.name}', deprecated:'${this.deprecated}' }`;
  }

  toJSON() {
    const { id, name, code, city, coordinates, deprecated } = this;
    return { id, name, code, city: city.toJSON(), coordinates: coordinates.toJSON(), deprecated };
  }

  static fromJSON(json) {
    const { id, name, code, city, coordinates, connectedMetros, deprecated } = json;

    return new ServiceRegion(
      new ServiceRegionId(id),
      name,
      code,
      City.fromJSON(city),
      GeoCoordinates.fromJSON(coordinates),
      MetroConnection.fromJSONArray(connectedMetros),
      deprecated
    );
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = ServiceRegion.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}
