export default class MetroMarketplace {
  constructor({ buyers }) {
    this.buyers = buyers;

    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof MetroMarketplace)) {
      return false;
    }

    return super.equals(other);
  }

  static fromJSON(json) {
    return new MetroMarketplace(json);
  }
}
