import getEdgeIcon from '../getEdgeIcon';
import getMetalIcon from '../getMetalIcon';
import useEdgeCategoryMarker from './useEdgeCategoryMarker';
import useEdgeDeviceMarker from './useEdgeDeviceMarker';
import useMetalMarker from './useMetalMarker';
import useMetalProductMarker from './useMetalProductMarker';

const usePlatformMarker = (platform, product) => {
  let platformHook, getIcon;

  if (platform === 'edge') {
    platformHook = product?.category ? useEdgeDeviceMarker : useEdgeCategoryMarker;
    getIcon = getEdgeIcon;
  }

  if (platform === 'metal') {
    platformHook = product?.value ? useMetalProductMarker : useMetalMarker;
    getIcon = getMetalIcon;
  }

  return platformHook && { ...platformHook(), getIcon };
};

export default usePlatformMarker;
