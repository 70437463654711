import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { getTheme } from '../../../../../utils/SelectTheme';
import useAddEdgeDeviceToMetro from '../../../hooks/useAddEdgeDeviceToMetro';
import { useDisableEdgeCategory } from '../../../hocs/withDisableEdge';
import withEdgeCategories from '../../../hocs/withEdgeCategories';
import { useEnableEdgeCategory } from '../../../hocs/withEnableEdge';
import useRemoveEdgeDeviceToMetro from '../../../hooks/useRemoveEdgeDeviceFromMetro';
import '../AddView.scss';
import PlatformMetroList from '../PlatformMetroList';
import { dismissAllToasts } from 'utils/Toast';
import DropdownStyles from 'views/components/DropdownStyles';
import Button from 'views/components/Button';

const defaultCategoryOptions = [
  {
    value: '',
    label: 'Any Network Edge Device',
    deprecated: false,
  },
];

const AddEdgeView = ({ onClose, networkEdgeCategories, metros, activeMetros, loading, projectId, phaseId, onPlatformMetrosChange }) => {
  const { enableEdgeCategory, loadingEnableEdge } = useEnableEdgeCategory();
  const { disableEdgeCategory, loadingDisableEdge } = useDisableEdgeCategory();
  const { addEdgeDeviceToMetro, loadingAddEdgeDevice } = useAddEdgeDeviceToMetro();
  const { removeEdgeDeviceFromMetro, loadingRemoveEdgeDevice } = useRemoveEdgeDeviceToMetro();
  const [edgeCategories, setEdgeCategories] = useState();
  const [categoryOptions, setCategoryOptions] = useState(defaultCategoryOptions);
  const [deviceOptions, setDeviceOptions] = useState();
  const [category, setCategory] = useState(defaultCategoryOptions[0]);
  const [device, setDevice] = useState();
  const [metroList, setMetroList] = useState();

  const isLoading = loadingEnableEdge || loadingDisableEdge || loadingAddEdgeDevice || loadingRemoveEdgeDevice || loading;

  const uniqueMetros = useMemo(() => {
    if (edgeCategories) {
      const edgeMetroCodes = [...edgeCategories.map((cat) => cat.metroCodes)].flatten();

      const list = metros
        .filter((metro) => edgeMetroCodes.some((metroCode) => metroCode === metro.code))
        .map((metro) => {
          const activeMetro = activeMetros.find((active) => active.id.value === metro.id.value);

          return {
            ...metro,
            networkEdgeEnabled: activeMetro?.networkEdgeEnabled || false,
            networkEdgeCategories: activeMetro?.networkEdgeCategories || [],
          };
        });
      return list;
    }
  }, [activeMetros, metros, edgeCategories]);

  const filteredDeviceOptions = useMemo(() => {
    const defaultOption = {
      value: '',
      label: `Any ${category.label}s`,
    };

    const categoryDeviceOptions = deviceOptions?.length ? deviceOptions?.filter((opt) => opt.category === category?.value) : [];

    return [defaultOption, ...categoryDeviceOptions];
  }, [deviceOptions, category]);

  useEffect(() => {
    if (networkEdgeCategories) {
      const categoryOptions = networkEdgeCategories.map(({ id, name, deprecated }) => ({
        value: id,
        label: name,
        deprecated,
      }));

      const deviceOptions = networkEdgeCategories
        .map((category) =>
          category.devices.map(({ id, name, deprecated }) => ({
            value: id,
            label: name,
            category: category.id,
            deprecated,
          }))
        )
        .flatten();

      setEdgeCategories(networkEdgeCategories);
      setCategoryOptions([...defaultCategoryOptions, ...categoryOptions]);
      setDeviceOptions(deviceOptions);
    }
  }, [networkEdgeCategories]);

  useEffect(() => {
    setDevice(filteredDeviceOptions[0]);
    // eslint-disable-next-line
  }, [category]);

  useEffect(() => {
    updateList();
    // eslint-disable-next-line
  }, [uniqueMetros, edgeCategories, category, device]);

  const getUniqueList = (metroCodes) => {
    return uniqueMetros.filter((metro) => metroCodes?.some((metroCode) => metroCode === metro.code));
  };

  useEffect(() => {
    const product = device?.value ? device : category;
    onPlatformMetrosChange(metroList, product);
    // eslint-disable-next-line
  }, [metroList]);

  const filterDeprecated = (list) => {
    return list.filter((metro) => metro.networkEdgeCategories.some((cat) => cat.id.value === category.value));
  };

  const getDeviceMetroCodesFromCategory = (category, deviceId) => {
    const device = category?.devices.find((dev) => dev.id === deviceId);
    return device ? device.metroCodes : [];
  };

  const getUniqueNonDeprecatedMetros = (metroCodes) => {
    const list = getUniqueList(metroCodes);
    const updatedList = category?.deprecated ? filterDeprecated(list) : list;

    return updatedList;
  };

  const getCurrentCategory = () => edgeCategories?.find((cat) => cat.id === category.value);

  /*   const hasDevices = useMemo(() => {
    const currentCategory = getCurrentCategory();
    return !!currentCategory?.devices?.length;
  }, [category]); */

  const updateList = () => {
    if (category?.value) {
      const edgeCategory = getCurrentCategory();

      if (device.value) {
        const deviceMetroCodes = getDeviceMetroCodesFromCategory(edgeCategory, device.value);

        // TODO: Re check deprecated
        const list = getUniqueNonDeprecatedMetros(deviceMetroCodes);

        setMetroList(list);
      } else {
        const list = getUniqueNonDeprecatedMetros(edgeCategory.metroCodes);

        setMetroList(list);
      }
    } else {
      setMetroList(uniqueMetros);
    }
  };

  const onChecked = async (metro) => {
    if (device?.value) {
      await addEdgeDeviceToMetro(projectId, phaseId, metro.code, device.value);
    } else {
      const categoryId = category?.value ? category.value : null;
      await enableEdgeCategory(projectId, phaseId, metro.code, categoryId);
    }
    dismissAllToasts();
  };

  const onUnchecked = async (metro) => {
    if (device?.value) {
      await removeEdgeDeviceFromMetro(projectId, phaseId, metro.code, device.value);
    } else {
      const categoryId = category?.value ? category.value : null;
      await disableEdgeCategory(projectId, phaseId, metro.code, categoryId);
    }
    dismissAllToasts();
  };

  const onCategoryChange = (category) => {
    setCategory(category);
  };

  /*   const onDeviceChange = (device) => {
    setDevice(device);
  }; */

  const isSelected = (metro) => {
    if (category.value) {
      if (device.value) {
        const currentCategory = getCurrentCategory();
        const metroCategory = metro.networkEdgeCategories.find((cat) => cat.id.value === currentCategory.id);
        return metroCategory?.devices.some((dev) => dev.id.value === device.value);
      } else {
        return metro.networkEdgeCategories.some((cat) => cat.id.value === category.value);
      }
    } else {
      return metro.networkEdgeEnabled;
    }
  };

  return (
    <div className={'AddView'} data-testid={'add-network-edge-view'}>
      <h2>Add Network Edge</h2>
      <div className={'where'}>
        <div className={'platform-section'}>
          <label>Selected Device</label>
          <Select
            placeholder={'Any Network Edge Device'}
            className={'edge-select'}
            options={categoryOptions}
            value={category}
            onChange={onCategoryChange}
            menuPlacement={'auto'}
            theme={getTheme}
            styles={DropdownStyles}
            isSearchable={false}
            data-testid={'device-select'}
          />
          {/*                     {!!category?.value && hasDevices && (
                        <Select
                            placeholder={'Any Product'}
                            className={'edge-select'}
                            options={filteredDeviceOptions}
                            value={device}
                            onChange={onDeviceChange}
                            menuPlacement={'auto'}
                            theme={getTheme}
                            components={{ IndicatorSeparator: () => null }}
                            isSearchable={false}
                        />
                    )} */}
        </div>
        <div className={'platform-section'}>
          <label>Available At</label>
          <PlatformMetroList isSelected={isSelected} metroList={metroList} onUnchecked={onUnchecked} onChecked={onChecked} loading={isLoading} />
        </div>
      </div>
      <div className="buttons">
        <Button color={'red'} text={'Close'} onClick={onClose} />
      </div>
    </div>
  );
};

export default withEdgeCategories(AddEdgeView);
