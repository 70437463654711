import DunsNumber from './DunsNumber';
import Timestamp from './Timestamp';
import Assert from '../utils/Assert';
import SubSegmentId from './SubSegmentId';
import SegmentId from './SegmentId';

export default class AccountStrategyPlan {
  constructor(id, company, segmentId, subSegmentId, lastUpdated) {
    Assert.instanceOf(id, DunsNumber);
    Assert.isString(company);
    Assert.instanceOf(segmentId, SegmentId);
    Assert.instanceOf(subSegmentId, SubSegmentId);
    Assert.instanceOf(lastUpdated, Timestamp);

    this.id = id;
    this.company = company;
    this.segmentId = segmentId;
    this.subSegmentId = subSegmentId;
    this.lastUpdated = lastUpdated;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof AccountStrategyPlan)) {
      return false;
    }
    return this.id.equals(other.id);
  }

  toString() {
    return `${this.constructor.name}{ id:${this.id}, company:'${this.company}', lastUpdated:'${this.lastUpdated}' }`;
  }

  toJSON() {
    const { id, company, segmentId, subSegmentId, lastUpdated } = this;
    return { id, company, segmentId, subSegmentId, lastUpdated };
  }

  static fromJSON(json) {
    if (!json) {
      return null;
    }

    const { id, company, segmentId, subSegmentId, lastUpdated } = json;

    return new AccountStrategyPlan(
      new DunsNumber(id),
      company,
      SegmentId.fromJSON(segmentId),
      SubSegmentId.fromJSON(subSegmentId),
      Timestamp.fromJSON(lastUpdated)
    );
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      items.push(AccountStrategyPlan.fromJSON(json));
    }

    return items;
  }
}
