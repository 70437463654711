import './VersionStamp.scss';
import React from 'react';
import PropTypes from 'prop-types';
import Version from 'model/Version';

class VersionStamp extends React.Component {
  static defaultProps = {
    version: null,
  };

  static propTypes = {
    version: PropTypes.instanceOf(Version).isRequired,
  };

  render() {
    return <div className="VersionStamp">v{this.props.version.toString()}</div>;
  }
}

export default VersionStamp;
