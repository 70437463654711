import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import defaultStyles from './defaultStyles';
import TextInput from 'views/components/TextInput';
import ToggleButton from 'views/components/ToggleButton';
import Button from 'views/components/Button';
import TrashIcon from 'views/components/icons/Trash';
import './CompanyModals.scss';
import { toast } from 'utils/Toast';
import DeleteCompanyModal from './DeleteCompanyModal';
import { USER_ROLES } from '../../../model/userRoles';
import Config from 'Config';
import { LoggerFactory } from 'logger';

const CompanyModal = ({ isOpen, onClose, onSave, loading, company, usedDomains, userRole, onDelete }) => {
  const logger = LoggerFactory.getLogger('Company');
  const [whitelistDomains, setWhitelistDomains] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [addedDomains, setAddedDomains] = useState([]);
  const [toggleChecked, setToggleChecked] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const _onClose = () => {
    onClose();
    if (!company) {
      setCompanyName('');
      setWhitelistDomains('');
      setAddedDomains([]);
      setToggleChecked(false);
    }
  };

  useEffect(() => {
    if (!company) return;
    const formatedDomains = company.domains.map((domain) => {
      return { domain: domain.domain, isEnabled: domain.isEnabled };
    });
    setCompanyName(company.name);
    setAddedDomains(formatedDomains);
    setToggleChecked(company.isDSPAllowed);
  }, [company]);

  const _onSave = async () => {
    await onSave(companyName, toggleChecked, addedDomains);
    _onClose();
  };

  const changeEnable = (domain, value) => {
    setAddedDomains((prev) => prev.map((pdom) => (pdom.domain === domain.domain ? { ...pdom, isEnabled: value } : pdom)));
  };
  const changeDomain = (domain, value) => {
    setAddedDomains((prev) => prev.map((pdom) => (pdom.domain === domain.domain ? { ...pdom, domain: value } : pdom)));
  };

  const deleteDomain = (index) => {
    const updatedDomains = addedDomains.filter((_, i) => i !== index);
    setAddedDomains(updatedDomains);
  };

  const addDomain = () => {
    if (!whitelistDomains.includes('.') || whitelistDomains.includes('@') || whitelistDomains.includes(',') || whitelistDomains.includes(' ')) {
      toast('That domain is invalid', 'error');
      return;
    }
    const existInThis = addedDomains.find((domain) => domain.domain === whitelistDomains);
    const existInOther = usedDomains?.find((domain) => domain.domain === whitelistDomains);
    if (existInThis) toast('That domain already exist', 'error');
    else {
      if (existInOther) toast('That domain is already in use for another company', 'error');
      else {
        setAddedDomains((prev) => [...prev, { domain: whitelistDomains, isEnabled: false }]);
        setWhitelistDomains('');
      }
    }
  };

  const renderProgress = () => {
    return (
      <div className="modal-submit">
        <div className="loading"></div>
      </div>
    );
  };

  const isDisabled = () => !companyName;
  const _onDelete = (companyId, checked) => {
    onDelete(companyId, checked);
    setDeleteModalOpen(false);
    logger.error(`Company deleted successfully`);
    toast('Company deleted successfully', 'info');
  };
  const renderButtons = () => {
    return (
      <div className="modal-submit">
        <Button text="Cancel" color="red" onClick={_onClose} isInverse={true} />
        <Button data-testId={'company-save-button'} text="Save" color="red" onClick={_onSave} isDisabled={isDisabled()} />
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} className="modal-window" onRequestClose={_onClose} contentLabel="Create a Company" defaultStyles={defaultStyles}>
      <div>
        <div className="modal-header">
          <h3>{company ? 'Update' : 'Create'} Company</h3>
          <button className="close-modal" onClick={_onClose}>
            <svg height="12" width="12">
              <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
            </svg>
          </button>
        </div>
        <div className="modal-fields modal-fields--full">
          <label>Company Name</label>
          <TextInput
            value={companyName}
            placeholder="e.g. AnalytixLink Co."
            className="textInput"
            onValueChange={(newValue) => {
              setCompanyName(newValue);
            }}
          />
        </div>

        {userRole === USER_ROLES.workbenchAdmin.value && (
          <div className="modal-fields modal-fields--full" data-testId={'dsp-toggle'}>
            <ToggleButton onChecked={() => setToggleChecked(true)} onUnchecked={() => setToggleChecked(false)} checked={toggleChecked} />
            <span className="toggle-label">Allow access to DSP data</span>
          </div>
        )}

        <div className="horizontal-line" />
        <div className="modal-title-and-description">
          <p>
            <b>Whitelisted Domains</b>
            {userRole === USER_ROLES.workbenchAdmin.value
              ? `Added email domains allow users to sign up to this company without being invited. Added domains will be available to Company Admins to enable.
                Enabling the domain will allow users to sign up automatically.`
              : !!addedDomains?.length &&
                `Allow users to join automatically when their email contains the following domain${addedDomains?.length > 1 ? 's' : ''}:`}
          </p>
        </div>
        {userRole === USER_ROLES.workbenchAdmin.value && (
          <>
            <div className="collaborators-modal">
              <div className="modal-fields modal-fields--full embedded-button-input">
                <TextInput
                  value={whitelistDomains}
                  placeholder="e.g. exampledomain.com"
                  className="textInput"
                  onValueChange={(newValue) => {
                    setWhitelistDomains(newValue);
                  }}
                />
                <div className="embedded add-collaborator company">
                  <Button data-testId={'add-domain-button'} text={'Add domain'} onClick={addDomain} color={'red'} isDisabled={!whitelistDomains} />
                </div>
              </div>
            </div>
          </>
        )}

        {!!addedDomains?.length && (
          <>
            <div className={'domains-list-title'}>
              <p>Domains</p>
            </div>
            <div className={'domains-list-title domains-list-title--right'}>
              <p>Enable Access</p>
            </div>
            {addedDomains.map((domain, index) => (
              <div data-testId={'domain-section'} key={index} className={'domain-input'}>
                {userRole === USER_ROLES.workbenchAdmin.value ? (
                  <>
                    <div data-testId={'delete-domain-button'} className="delete" onClick={() => deleteDomain(index)}>
                      <TrashIcon width={13} height={14} color="#333333" />
                    </div>
                    <TextInput
                      value={domain.domain}
                      placeholder=""
                      className="textInput"
                      onValueChange={(newValue) => {
                        changeDomain(domain, newValue);
                      }}
                    />
                  </>
                ) : (
                  <span>{domain.domain}</span>
                )}

                <ToggleButton
                  small
                  onChecked={() => {
                    changeEnable(domain, true);
                  }}
                  onUnchecked={() => {
                    changeEnable(domain, false);
                  }}
                  checked={domain.isEnabled}
                />
              </div>
            ))}
          </>
        )}
        {userRole === USER_ROLES.companyOwner.value && (
          <p>
            Need to add a domain? Write to <a href={`mailto:${Config.SUPPORT_EMAIL}`}>{Config.SUPPORT_EMAIL}</a>
          </p>
        )}
        {!!onDelete && !company?.removalDate && (
          <>
            <div className="horizontal-line" />
            <div className="modal-title-and-description">
              <p>
                <b>Deactivate Company</b>
                This will immediately lock the account and prevent all users from logging in. The account will be placed in a temporary hold for 30 days, during
                which time the full account can be recovered.
              </p>
            </div>
            <Button
              data-testId={'delete-company-button'}
              color={'gray'}
              text="Deactivate Company"
              onClick={() => {
                setDeleteModalOpen(true);
              }}
            />
          </>
        )}
        {loading ? renderProgress() : renderButtons()}
      </div>
      <DeleteCompanyModal
        loading={loading}
        onDelete={_onDelete}
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        company={company}
      />
    </Modal>
  );
};

export default CompanyModal;
