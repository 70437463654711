import React from 'react';

const OfficeIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.51 3.11662C10.0517 1.64663 9.0425 0 6.99917 0C4.95583 0 3.94667 1.64663 4.48861 3.11662C4.46667 3.11458 4.44361 3.10933 4.42333 3.10933C4.10472 3.10933 4.09333 3.50955 4.1675 3.94095C4.23639 4.34642 4.51 4.82096 4.82917 4.82096C4.84 4.82096 4.84889 4.81572 4.85889 4.81455C4.99778 5.24304 5.17306 5.64297 5.385 5.9843C5.385 5.9843 5.57722 6.93369 5.39056 7.37588C5.20389 7.81778 2.54056 8.58556 2.18861 8.75346C1.83611 8.92165 1.3775 9.48743 1.31583 9.92525L1 12.1578C1 12.8142 3.68639 14 7.00028 14C10.3142 14 13 12.8142 13 12.1578C13 12.1525 12.9964 12.1464 12.9958 12.1409L12.6825 9.92525C12.6208 9.48743 12.1622 8.92165 11.8097 8.75346C11.4578 8.58556 8.79444 7.81778 8.60778 7.37588C8.42111 6.93369 8.61305 5.9843 8.61305 5.9843C8.82528 5.64297 9.00056 5.24304 9.13972 4.81455C9.14972 4.81572 9.15861 4.82096 9.16889 4.82096C9.48833 4.82096 9.76194 4.34642 9.83111 3.94095C9.90528 3.50955 9.89361 3.10933 9.57472 3.10933C9.555 3.10933 9.53194 3.11458 9.51 3.11662V3.11662Z"
        fill={color}
      />
    </svg>
  );
};

export default OfficeIcon;
