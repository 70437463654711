import React from 'react';

const SelectedModalItem = ({ boldText, regularText }) => {
  return (
    <p>
      <b>{boldText}</b>&nbsp;&nbsp;|&nbsp;&nbsp;
      {regularText}
    </p>
  );
};

export default SelectedModalItem;
