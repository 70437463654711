import React, { useState } from 'react';
import { getTheme } from 'utils/SelectTheme';
import Select from 'react-select';
import { debounce } from 'debounce';
import { DIRECTION, FILTER_OPTIONS, SORT_OPTIONS } from './sortingTeam';
import Searchfield from '../Searchfield';
import DropdownStyles from 'views/components/DropdownStyles';
import ArrowIcon from 'views/components/icons/Arrow2';

const SortingControlsTeam = ({ setSearchBy, sortBy, setSortBy, sortDirection, setSortDirection, filterBy, setFilterBy }) => {
  const [filterText, setFilterText] = useState('');

  const getSortArrowOptionClass = () => {
    const isAscending = sortDirection === DIRECTION.ASCENDING;
    return `rotate-arrow ${!isAscending ? '  ' : 'descending'}`;
  };

  const onDirectionChange = () => {
    const updatedDirection = sortDirection === DIRECTION.ASCENDING ? DIRECTION.DESCENDING : DIRECTION.ASCENDING;
    setSortDirection(updatedDirection);
  };

  const onFilterChange = (value) => {
    const newValue = value ? FILTER_OPTIONS[value].label : '';
    setFilterBy(newValue);
  };

  const onSearchChange = (e) => {
    e.persist();
    setFilterText(e.target.value);

    debounce(() => {
      setSearchBy(e.target.value);
    }, 350)();
  };

  const onClearInput = () => {
    setSearchBy('');
    setFilterText('');
  };

  return (
    <div className="projects-sort-options">
      <Searchfield
        onChange={(e) => onSearchChange(e)}
        placeholder={'Search Team Members...'}
        value={filterText}
        onSearchClear={onClearInput}
        filterText={filterText}
      />
      <div className="spacer"></div>
      <div className="sort-container">
        <div className="sort-label">Sort by:</div>
        <div className="select-wrapper">
          <Select
            classNamePrefix="select"
            onChange={(e) => setSortBy(e.value)}
            options={Object.values(SORT_OPTIONS)}
            isSearchable={false}
            theme={getTheme}
            menuPosition={'fixed'}
            value={SORT_OPTIONS[sortBy]}
            styles={DropdownStyles}
            data-testid={'sort-select'}
          />
        </div>
        <div className={getSortArrowOptionClass()} onClick={onDirectionChange} role="button">
          <ArrowIcon data-testid={'sort-arrow'} color="#333333" />
        </div>
      </div>

      <div className="sort-container">
        <div className="sort-label">Filter:</div>
        <div className="select-wrapper">
          <Select
            classNamePrefix="select"
            onChange={(e) => onFilterChange(e.value)}
            options={Object.values(FILTER_OPTIONS)}
            isSearchable={false}
            theme={getTheme}
            menuPosition={'fixed'}
            value={FILTER_OPTIONS[filterBy]}
            styles={DropdownStyles}
            data-testid={'filter-select'}
          />
        </div>
      </div>
    </div>
  );
};

export default SortingControlsTeam;
