import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import '../AddView.scss';
import { getTheme } from '../../../../../utils/SelectTheme';
import withMetals from '../../../hocs/withMetals';
import useEnableMetal from '../../../hooks/useEnableMetal';
import { useDisableMetal } from '../../../hocs/withDisableMetal';
import useAddMetalToMetro from 'views/components/hooks/useAddMetalToMetro';
import useRemoveMetalFromMetro from '../../../hooks/useRemoveMetalFromMetro';
import PlatformMetroList from '../PlatformMetroList';
import { dismissAllToasts } from 'utils/Toast';
import Button from 'views/components/Button';
import DropdownStyles from 'views/components/DropdownStyles';

const defaultOptions = [
  {
    value: '',
    label: 'Any Equinix Metal Server Config',
    deprecated: false,
  },
];

const AddMetalView = ({ onClose, metros, activeMetros, phaseId, projectId, metalPlans, onPlatformMetrosChange }) => {
  const { enableMetal, loadingEnableMetal } = useEnableMetal();
  const { disableMetal, loadingDisableMetal } = useDisableMetal();
  const { addMetalToMetro, loadingAddMetalToMetro } = useAddMetalToMetro();
  const { removeMetalFromMetro, loadingRemoveMetalFromMetro } = useRemoveMetalFromMetro();
  const [options, setOptions] = useState(defaultOptions);
  const [product, setProduct] = useState(defaultOptions[0]);
  const [metals, setMetals] = useState();
  const [metroList, setMetroList] = useState();

  const isLoading = loadingEnableMetal || loadingDisableMetal || loadingAddMetalToMetro || loadingRemoveMetalFromMetro;

  // List of unique metros, to avoid duplicate entries
  const uniqueMetros = useMemo(() => {
    if (metals) {
      const metalMetros = [...metals.map((metal) => metal.metros)].flatten();

      const list = metros
        .filter((metro) => metalMetros.some((metalMetro) => metalMetro.code === metro.code))
        .map((metro) => {
          const activeMetro = activeMetros.find((active) => active.id.value === metro.id.value);

          return {
            ...metro,
            metalEnabled: activeMetro?.metalEnabled || false,
            metalPlans: activeMetro?.metalPlans || [],
          };
        });
      return list;
    }
  }, [activeMetros, metros, metals]);

  useEffect(() => {
    if (metalPlans) {
      const options = metalPlans.map((plan) => ({
        value: plan.id,
        label: plan.name,
        deprecated: plan.deprecated,
      }));

      setMetals(metalPlans);
      setOptions([...defaultOptions, ...options]);
    }
  }, [metalPlans]);

  const onProductChange = (product) => {
    setProduct(product);
  };

  const onCheckMetal = async (metro) => {
    if (product?.value) {
      await addMetalToMetro(projectId, phaseId, metro.code, product.value);
    } else {
      await enableMetal(projectId, phaseId, metro.code);
    }
    dismissAllToasts();
  };

  const onUncheckMetal = async (metro) => {
    if (product?.value) {
      await removeMetalFromMetro(projectId, phaseId, metro.code, product.value);
    } else {
      await disableMetal(projectId, phaseId, metro.code);
    }
    dismissAllToasts();
  };

  // If we selected a product, we trigger the corresponding filtering
  const updateList = () => {
    if (product.value) {
      const metalMetros = metals?.find((m) => m.id === product.value)?.metros;

      const list = uniqueMetros.filter((metro) => metalMetros?.some((f) => f.code === metro.code));

      const currentList = product.deprecated ? list.filter((m) => m.metalPlans.some((plan) => plan.id.value === product.value)) : list;

      setMetroList(currentList);
    } else {
      setMetroList(uniqueMetros);
    }
  };

  const isSelected = (metro) => {
    if (product.value) {
      return metro.metalPlans.some((metal) => metal.id.value === product.value);
    } else {
      return metro.metalEnabled;
    }
  };

  // Update the list of metros if metros, metals or selected product change
  useEffect(() => {
    updateList();
    // eslint-disable-next-line
  }, [uniqueMetros, metals, product]);

  useEffect(() => {
    onPlatformMetrosChange(metroList, product);
    // eslint-disable-next-line
  }, [metroList]);

  return (
    <div className={'AddView'}>
      <h2>
        Add Equinix Metal<span className="register">®</span>
      </h2>

      <div className={'where'}>
        <div className={'platform-section'}>
          <label>Selected Server Config</label>
          <Select
            data-testid={'config-select'}
            placeholder={'Any Equinix Metal Server Config'}
            options={options}
            value={product}
            onChange={onProductChange}
            menuPlacement={'auto'}
            theme={getTheme}
            isSearchable={false}
            styles={DropdownStyles}
          />
        </div>
        <div className={'platform-section'}>
          <label>Available At</label>
          <PlatformMetroList isSelected={isSelected} metroList={metroList} onUnchecked={onUncheckMetal} onChecked={onCheckMetal} loading={isLoading} />
        </div>
      </div>
      <div className="buttons">
        <Button color="red" text={'Close'} onClick={onClose} />
      </div>
    </div>
  );
};

export default withMetals(AddMetalView);
