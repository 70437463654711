import { Mutations, withMutation } from 'gql';

export default withMutation({
  name: 'ADD_METRO',
  mutation: Mutations.ADD_METRO,
  mutateProp: 'addMetro',
  getMutationArgs(projectId, phaseId, metro) {
    return {
      variables: {
        projectId,
        phaseId,
        metroId: metro.id,
      },
    };
  },
});
