const getMaxAndMinSegmentCount = ({ marketplaceSegment, metros }) => {
  const marketplace = metros.map((metro) => {
    let result = [...metro.marketplace.buyers];
    metro.marketplace.buyers.forEach((segment) => {
      if (segment.subSegments?.length) result = [...result, ...segment.subSegments];
    });

    return result;
  });
  const currentSegmentDensities = marketplace.map((market) => market?.find((market) => market?.name === marketplaceSegment.name)).filter((e) => !!e);
  const densityCountArray = currentSegmentDensities.map((density) => density.count);

  return { maxSegmentCount: Math.max(...densityCountArray), minSegmentCount: Math.min(...densityCountArray) };
};

export default getMaxAndMinSegmentCount;
