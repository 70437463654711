import React from 'react';
import ProductEvents from 'analytics/ProductEvents';
import { useMutation } from '@apollo/react-hooks';
import { Mutations } from 'gql';
import { dismissAllToasts } from 'utils/Toast';
import TextAndToggleTable from '../TextAndToggleTable';

const MetrosTable = ({ metros, activeMetros, phaseId, projectId }) => {
  const [execRemoveMetro, { loading: removeLoading }] = useMutation(Mutations.REMOVE_METRO);
  const [execAddMetro, { loading: addLoading }] = useMutation(Mutations.ADD_METRO);

  const onRemoveMetro = async (metro) => {
    await execRemoveMetro({ variables: { projectId, phaseId, metroId: metro.id } });
    dismissAllToasts();
    ProductEvents.metroRemoved(metro.name);
  };

  const onAddMetro = async (metro) => {
    await execAddMetro({ variables: { projectId, phaseId, metroId: metro.id } });
    dismissAllToasts();
    ProductEvents.metroAdded(metro.name);
  };

  const metrosFormatted = metros.map((metro) => {
    const active = !!activeMetros.find((activeMetro) => activeMetro.id.equals(metro.id));
    return { ...metro, checked: active };
  });

  return <TextAndToggleTable items={metrosFormatted} onEnable={onAddMetro} onDisable={onRemoveMetro} isLoading={removeLoading || addLoading} />;
};

export default MetrosTable;
