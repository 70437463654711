import React from 'react';

const RenderProgress = () => {
  return (
    <div className="modal-submit">
      <div className="loading"></div>
    </div>
  );
};

export default RenderProgress;
