import React from 'react';
import { Query } from 'react-apollo';
import ArgumentError from '../error/ArgumentError';
import { LoggerFactory } from '../logger';

/**
 * Component that makes a query to GraphQL for necessary data to drive the component's view state.
 */
export default class GqlQueryComponent extends React.Component {
  constructor(props) {
    super(props);
    this._logger = LoggerFactory.getLogger(this);
  }

  _renderQuery = (queryResult) => {
    const { loading, error, data } = queryResult;
    this.queryResult = queryResult;

    const transformed = data ? this.transform(data) : null;

    //this._logger.debug(`GraphQL query succeeded with data: ${transformed}`);

    return this.renderQuery(loading, error, transformed);
  };

  _onCompleted = async (data) => this.onCompleted(data);

  _onError = async (error) => this.onError(error);

  /**
   * Callback occurring when a mutation completes successfully.
   * @param data The returned data.
   * @virtual
   */
  onCompleted() {
    //nothing to do by default
  }

  /**
   * Callback occurring when a mutation fails to complete.
   * @param error The error message.
   * @virtual
   */
  onError() {
    //nothing to do by default
  }

  /**
   * Method for whether the GraphQL Query can be executed.
   * Useful for when a component should not execute the query on every Render().
   *
   * @returns {boolean}
   * @virtual
   */
  canExecuteQuery() {
    return true;
  }

  /**
   * Render method for when the query should not be executed.
   *
   * @returns {null}
   * @virtual
   */
  renderQueryNotExecuted() {
    return null;
  }

  /**
   * Render the GraphQL loading, error, and complete states.
   * This method delegates to GqlQueryComponent#renderLoading(),
   * GqlQueryComponent#renderError(error), and GqlQueryComponent#renderData(data)
   * respectively unless this method is overridden.
   *
   * @param loading Whether or not this query is loading, {@code true} if data is loading, {@code false} if it is not.
   * @param error The error that occurred in the process of executing the query, will not be set if no error occurred.
   * @param data On query success, this will be the queried data.
   * @returns {null}
   * @virtual
   */
  renderQuery(loading, error, data) {
    if (loading) {
      this._logger.debug('GraphQL query loading');
      return this.renderLoading();
    }

    if (error) {
      this._logger.error('GraphQL query failed');
      return this.renderError(error);
    }

    return this.renderData(data);
  }

  /**
   * Render the loading state for this view while the GraphQL query completes.
   * Defaults to render {@code null}
   *
   * @returns {null}
   * @virtual
   */
  renderLoading() {
    return null;
  }

  /**
   * Render the error state for this view if the GraphQL query fails.
   * Defaults to render {@code null}
   *
   * @returns {null}
   * @virtual
   */
  renderError() {
    return null;
  }

  /**
   * Render the data for this view if the GraphQL query completes successfully.
   *
   * @param data The GraphQL query result transformed via {@link GqlQueryComponent#transform()}.
   * @returns {null}
   * @virtual
   */
  renderData() {
    return null;
  }

  /**
   * The query GraphQL will execute.
   * This method must be overridden or an error will occur.
   *
   * @abstract
   */
  query() {
    throw new ArgumentError(`GqlQueryComponent.query() must be overridden in '${this.constructor.name}' component and return a valid GraphQL query`);
  }

  /**
   * Specifies the interval in ms at which you want your component to poll for data.
   * Defaults to 0 (no polling).
   *
   * @returns {number}
   * @virtual
   */
  pollInterval() {
    return 0;
  }

  /**
   * The fetch policy to be used for the GraphQL query.
   * Determines how the GraphQL query interacts with the cache.
   *
   * @returns {string}
   * @virtual
   */
  fetchPolicy() {
    return 'cache-and-network';
  }

  /**
   * The arguments to pass along with the GraphQL query.
   * This method is only required if the query requires arguments.
   *
   * @returns {*}
   * @virtual
   */
  queryArgs() {
    return null;
  }

  /**
   * Transform the GraphQL query result before the component calls {@link GqlQueryComponent#renderData()}.
   * This method is optional to override, and defaults to returning the raw GraphQL query result as-is.
   *
   * @param data The raw GraphQL query result to transform.
   * @returns {*}
   * @virtual
   */
  transform(data) {
    return data;
  }

  render() {
    if (!this.canExecuteQuery()) {
      return this.renderQueryNotExecuted();
    }

    return (
      <Query
        // Disable cache for automated tests, so that tests are easier to write.
        // Otherwise, there is a complete rabbit hole of 'heuristic fragment matching' errors.
        fetchPolicy={process.env.NODE_ENV === 'test' ? 'no-cache' : this.fetchPolicy()}
        query={this.query()}
        variables={this.queryArgs()}
        onError={this._onError}
        onCompleted={this._onCompleted}
        pollInterval={this.pollInterval()}
        returnPartialData>
        {this._renderQuery}
      </Query>
    );
  }
}
