import React, { useState } from 'react';
import { Mutations, withMutation } from 'gql';
import ToggleButton from 'views/components/ToggleButton';
import ProductEvents from 'analytics/ProductEvents';
import { dismissAllToasts } from 'utils/Toast';
import 'views/components/InfoPanel/children/TableStyles.scss';
import ConfirmationModal from 'views/ProjectListView/modals/ConfirmationModal';
import Modal from 'react-modal';
import defaultStyles from 'views/ProjectDetailView/modals/defaultStyles';

export const withEnableServiceRegion = withMutation({
  name: 'ENABLE_SERVICE_REGION',
  mutation: Mutations.ENABLE_SERVICE_REGION,
  mutateProp: 'enableServiceRegion',
  getMutationArgs(projectId, phaseId, serviceConnection, region) {
    const serviceConnectionId = serviceConnection.id;
    return {
      variables: {
        projectId,
        phaseId,
        serviceConnectionId,
        serviceRegionIds: [region.id],
      },
    };
  },
});
export const withDisableServiceRegion = withMutation({
  name: 'DISABLE_SERVICE_REGION',
  mutation: Mutations.DISABLE_SERVICE_REGION,
  mutateProp: 'disableServiceRegion',
  getMutationArgs(projectId, phaseId, serviceConnection, region) {
    const serviceConnectionId = serviceConnection.id;
    return {
      variables: {
        projectId,
        phaseId,
        serviceConnectionId,
        serviceRegionIds: [region.id],
      },
    };
  },
});

function Toggle(props) {
  const {
    serviceConnection,
    region,
    checked,
    projectId,
    phaseId,
    // Provided by HOCs
    enableServiceRegion,
    disableServiceRegion,
    loading,
  } = props;

  const [openModal, setOpenModal] = useState(false);
  const onChecked = async () => {
    await enableServiceRegion(projectId, phaseId, serviceConnection, region);
    dismissAllToasts();

    //in the add service view, this is not set, so skip tracking here
    if (serviceConnection) {
      ProductEvents.serviceRegionAdded(`${serviceConnection.name} ${region.code}`);
    }
  };

  const onUnchecked = async () => {
    await disableServiceRegion(projectId, phaseId, serviceConnection, region);
    dismissAllToasts();

    //in the add service view, this is not set, so skip tracking here
    if (serviceConnection) {
      ProductEvents.serviceRegionRemoved(`${serviceConnection.name} ${region.code}`);
    }
  };

  const enabled = checked || (!loading && !region.deprecated);

  const checkWarning = () => {
    region.deprecated ? setOpenModal(true) : onUnchecked();
  };

  return (
    <>
      <ToggleButton checked={checked} enabled={enabled} onChecked={onChecked} onUnchecked={checkWarning} small />
      <Modal isOpen={openModal} className={'modal-window confirmation-modal'} onRequestClose={() => setOpenModal(false)} defaultStyles={defaultStyles}>
        <ConfirmationModal
          onClose={() => setOpenModal(false)}
          onConfirm={onUnchecked}
          loading={loading}
          title={'Disable Region'}
          trailingText={
            'This region is no longer available. After removing it you will be unable to add it back to your project. Are you sure you want to remove it from your project?'
          }
          buttonText={'Remove Permanently'}
        />
      </Modal>
    </>
  );
}

export const RegionToggle = withEnableServiceRegion(withDisableServiceRegion(Toggle));

export default function ServiceRegionsView(props) {
  const { projectId, phaseId, serviceConnection, selectedRegions, availableRegions, onEnableRegion, onDisableRegion } = props;

  // TODO: Please refactor this
  const ToggleComponent = onEnableRegion && onDisableRegion ? Toggle : RegionToggle;
  const calculateLatency = (region) => {
    const latency = region.connectedMetros.find((connection) => connection.metroId.equals(serviceConnection.metroId)).latency;
    return `${latency}ms`;
  };

  return (
    <div className="table" data-testid={'regions-container'}>
      {[...availableRegions]
        .filter((region) => {
          const checked = !!selectedRegions.find((r) => r && r.equals(region));
          return checked || !region.deprecated;
        })
        .sort((ar1, ar2) => {
          //if this service is connected to a metro, sort by latency between metro -> service region
          if (serviceConnection && serviceConnection.metroId) {
            const c1 = ar1.connectedMetros.find((connection) => connection.metroId.equals(serviceConnection.metroId));
            const c2 = ar2.connectedMetros.find((connection) => connection.metroId.equals(serviceConnection.metroId));
            return c1.latency > c2.latency ? 1 : -1;
          }

          //if the service is not connected to any metro, sort by region, then alphabetically
          if (ar1.city.region === ar2.city.region) {
            return ar1.code > ar2.code ? 1 : -1;
          }

          return ar1.city.region > ar2.city.region ? 1 : -1;
        })
        .map((region) => {
          const formattedLatency = serviceConnection ? (
            serviceConnection.metroId ? (
              <span className="latency">
                <b>•</b>
                <em>{calculateLatency(region)}</em>
              </span>
            ) : null
          ) : null;

          const checked = !!selectedRegions.find((r) => r && r.equals(region));
          return (
            <div key={region.id} className="column" data-testid={'region'}>
              <p title={formattedLatency ? calculateLatency(region) : null}>
                {region.deprecated && '(deprecated)'} {region.code} ({region.city.name}){formattedLatency}
              </p>
              <ToggleComponent
                projectId={projectId}
                phaseId={phaseId}
                enableServiceRegion={() => onEnableRegion(region)}
                disableServiceRegion={() => onDisableRegion(region)}
                serviceConnection={serviceConnection}
                region={region}
                checked={checked}
              />
            </div>
          );
        })}
    </div>
  );
}
