import './InfoPanel.scss';
import React from 'react';
import infoPanelChildren from './children';

const InfoPanel = ({ onClose, view, ...rest }) => {
  const Component = infoPanelChildren[view];
  return (
    <div className="info-panel">
      <button className="close-panel" onClick={onClose}>
        <svg height="12" width="12">
          <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
        </svg>
      </button>

      {Component ? <Component {...rest} onClose={onClose} /> : null}
    </div>
  );
};

export default InfoPanel;
