const getAllSegmentsRadius = ({ metros, metro }) => {
  const count = metro.marketplace.buyers.reduce((sum, { count }) => sum + count, 0);

  const allCounts = metros.map((metro) => metro.marketplace.buyers.reduce((sum, { count }) => sum + count, 0));

  const minSegmentCount = Math.min(...allCounts);
  const maxSegmentCount = Math.max(...allCounts);

  const minRadius = 75000;
  const maxRadius = minRadius * 10;

  const normalizedCountValue = ((count - minSegmentCount) / (maxSegmentCount - minSegmentCount)) * maxRadius;

  return normalizedCountValue < minRadius ? minRadius : normalizedCountValue;
};

export default getAllSegmentsRadius;
