import React from 'react';
import cn from 'classnames';

const DeploymentContainer = ({ name, individual = false, icon, sectionInfraComponent, iconSize }) => {
  const sortedArray = sectionInfraComponent?.sort();
  return (
    <div className={cn({ 'deployment-container': individual })}>
      <div className={cn('deployment-title', { smallTitle: iconSize < 0.7 }, { 'individual-title': individual })}>
        {icon} {name} <span className="amount-number">{iconSize < 0.7 && sectionInfraComponent.length}</span>
      </div>
      {iconSize >= 0.7 &&
        sortedArray?.map(
          (infraComponent) =>
            infraComponent.isVisible && (
              <div className="infraComponent-information" key={infraComponent.name}>
                <p className="infraComponent-name">{infraComponent.name}</p>
                <p className="infraComponent-device">{infraComponent.device}</p>
              </div>
            )
        )}
    </div>
  );
};

export default DeploymentContainer;
