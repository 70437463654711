import React from 'react';

const StarIcon = ({ width = 10, height = 10, color, rounded = false, ...rest }) => {
  return rounded ? (
    <svg width={width} height={height} fill={color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.20703 1.36719L5.42969 4.97656L1.4375 5.55078C1.07292 5.60547 0.835938 5.80599 0.726562 6.15234C0.617188 6.4987 0.690104 6.79948 0.945312 7.05469L3.84375 9.87109L3.16016 13.8633C3.10547 14.2096 3.22396 14.4922 3.51562 14.7109C3.80729 14.9297 4.10807 14.9479 4.41797 14.7656L8 12.9062L11.582 14.7656C11.8919 14.9297 12.1927 14.9023 12.4844 14.6836C12.776 14.4831 12.8945 14.2096 12.8398 13.8633L12.1562 9.87109L15.0547 7.05469C15.3099 6.79948 15.3828 6.4987 15.2734 6.15234C15.1641 5.80599 14.9271 5.60547 14.5625 5.55078L10.5703 4.97656L8.79297 1.36719C8.62891 1.03906 8.36458 0.875 8 0.875C7.63542 0.875 7.37109 1.03906 7.20703 1.36719Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  ) : (
    <svg width={width} height={height} fill={color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M7.53882 1.1011C7.71017 0.691981 8.28983 0.691981 8.46118 1.1011L10.2738 5.4288C10.3459 5.60091 10.5078 5.71857 10.6938 5.73394L15.3698 6.1205C15.8118 6.15704 15.991 6.70832 15.6548 6.99772L12.0991 10.0589C11.9576 10.1807 11.8958 10.371 11.9386 10.5527L13.016 15.1193C13.1178 15.551 12.6489 15.8917 12.2698 15.6614L8.25957 13.2257C8.10008 13.1288 7.89992 13.1288 7.74043 13.2257L3.73025 15.6614C3.35114 15.8917 2.88219 15.551 2.98404 15.1193L4.06137 10.5527C4.10422 10.371 4.04236 10.1807 3.90095 10.0589L0.345187 6.99772C0.00904274 6.70832 0.188166 6.15704 0.630213 6.1205L5.30623 5.73394C5.49219 5.71857 5.65413 5.60091 5.72621 5.4288L7.53882 1.1011Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};

export default StarIcon;
