import React from 'react';
import defaultStyles from 'views/ProjectDetailView/modals/defaultStyles';
import Modal from 'react-modal';
import TemplateUpdateAvailableModal from '../modals/TemplateUpdateAvailableModal';
import CreateProjectModal from '../modals/CreateProjectModal';
import ConfirmationModal from '../modals/ConfirmationModal';

const TemplateModals = ({
  isCreateModalOpen,
  isUpdateModalOpen,
  isPublishModalOpen,
  isUnsaveModalOpen,
  isDeleteModalOpen,
  closeCreateModal,
  closeUpdateModal,
  closeDeleteModal,
  updateLoading,
  unsaveLoading,
  deleteLoading,
  onTogglePublicTemplate,
  onDelete,
  ChangeSavedStatus,
  closePublishModal,
  closeUnsaveModal,
  template,
  history,
  companyId,
}) => {
  const { published, id: templateId, project, name } = template;
  return (
    <>
      <CreateProjectModal
        companyId={companyId}
        history={history}
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        fromTemplate={true}
        template={template}
      />

      <Modal isOpen={isPublishModalOpen} className={'modal-window confirmation-modal'} onRequestClose={closePublishModal} defaultStyles={defaultStyles}>
        <ConfirmationModal
          onClose={closePublishModal}
          onConfirm={onTogglePublicTemplate}
          loading={updateLoading}
          title={published ? 'Make this template private' : 'Make this template public'}
          trailingText={`This will make this template ${published ? 'unavailable' : 'available'} to all Equinix users on Workbench.`}
          buttonText={published ? 'Make Private' : 'Make Public'}
        />
      </Modal>

      <TemplateUpdateAvailableModal isUpdateModalOpen={isUpdateModalOpen} closeUpdateModal={closeUpdateModal} templateId={templateId} project={project} />

      <Modal isOpen={isUnsaveModalOpen} className={'modal-window confirmation-modal'} onRequestClose={closeUnsaveModal} defaultStyles={defaultStyles}>
        <ConfirmationModal
          onClose={closeUnsaveModal}
          onConfirm={ChangeSavedStatus}
          loading={unsaveLoading}
          title={'Unsave this template'}
          trailingText={`Are you sure you would like to unsave this template? It was deleted and you will not be able to undo this action.`}
          buttonText={'Confirm'}
        />
      </Modal>

      <Modal isOpen={isDeleteModalOpen} className={'modal-window confirmation-modal'} onRequestClose={closeDeleteModal} defaultStyles={defaultStyles}>
        <ConfirmationModal
          onClose={closeDeleteModal}
          onConfirm={onDelete}
          loading={deleteLoading}
          title={'Delete this template'}
          mainText={name}
          trailingText={`Are you sure you would like to permanently delete this template? You will not be able to undo this action.`}
          buttonText={'Confirm Delete'}
        />
      </Modal>
    </>
  );
};

export default TemplateModals;
