import ValueObject from './ValueObject';

export default class ServiceId extends ValueObject {
  equals(other) {
    if (!(other instanceof ServiceId)) {
      return false;
    }

    return super.equals(other);
  }

  static fromJSON(json) {
    return new ServiceId(json);
  }
}
