import React, { useEffect } from 'react';
import classNames from 'classnames';
import TriangleIcon from 'views/components/icons/Triangle';
import Expandable from './UseCases/Expandable';
import { toast } from 'utils/Toast';

const TabPanel = ({ children, sideSection = null, icon, title, productAvailable, loading, error, initOpen = false, isCategory = false, noProducts }) => {
  const categoryClass = isCategory ? 'product' : 'platform-equinix-header';
  const platformClass = !isCategory ? 'platform-equinix' : '';
  useEffect(() => {
    error && toast("Couldn't fetch products", 'error');
  }, [error]);

  return (
    <Expandable initOpen={initOpen}>
      {(open, toggle) => {
        const toggleFunction = productAvailable === false || noProducts ? () => {} : toggle;
        const disabledClass = !loading && productAvailable ? '' : 'disabled';
        const noIconTitle = icon ? '' : 'no-icon-title';

        return (
          <div className={classNames(platformClass, { open, closed: !open })}>
            <div className={`expandable-header ${categoryClass} ${disabledClass}`} data-testid={'expandable-header'}>
              {icon && (
                <div className="icon-div" onClick={toggleFunction}>
                  {icon}
                </div>
              )}
              <button className={`expand-content`} onClick={toggleFunction}>
                {isCategory ? <span>{title}</span> : <h3 className={noIconTitle}>{title}</h3>}
              </button>

              {productAvailable && !noProducts && (
                <div onClick={toggleFunction} className="open-icon" data-testid={'open-icon'}>
                  <TriangleIcon width={6} height={5} color="black" />
                </div>
              )}

              <div className="spacer"></div>

              {sideSection}
            </div>
            {children}
          </div>
        );
      }}
    </Expandable>
  );
};

export default TabPanel;
