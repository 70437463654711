import React, { useState, useCallback, useEffect, useMemo } from 'react';
import ArrowIcon from '../icons/Arrow2';
import SavedIcon from '../icons/Saved';
import cn from 'classnames';
import ArrowUpCircleIcon from '../icons/ArrowUpCircle';
import LockIcon from '../icons/Lock';
import EyeIcon from '../icons/Eye';
import ToggleButton from '../ToggleButton';
import { useCurrentProject } from 'providers/ProjectProvider';
import EditIcon from '../icons/Edit';
import TextInput from '../TextInput';
import TemplateModal from 'views/ProjectListView/modals/TemplateModal';
import CreateProjectModal from 'views/ProjectListView/modals/CreateProjectModal';
import { useHistory } from 'react-router';
import debounce from 'debounce';
import Config from 'Config';
import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';
import { dismissAllToasts, toast } from 'utils/Toast';
import { LoggerFactory } from 'logger';
import updateSnapshot from 'service/updateSnapshot';
import TemplateUpdateAvailableModal from 'views/ProjectListView/modals/TemplateUpdateAvailableModal';
import defaultStyles from 'views/ProjectDetailView/modals/defaultStyles';
import Modal from 'react-modal';
import ConfirmationModal from '../../ProjectListView/modals/ConfirmationModal';
import { TemplateEvents } from 'analytics';
import Button from 'views/components/Button';
import { usePhaseItems } from 'providers/PhaseItemsProvider';

const TemplateSection = ({ template, onClose, mapConfig, companyId }) => {
  const logger = useMemo(() => LoggerFactory.getLogger('template'), []);
  const history = useHistory();
  const { project, selectedPhaseId, toggleEditing, isEditing, changePhase, isOwner, lastTemplateVersion } = useCurrentProject();
  const { setCheckedItems } = usePhaseItems();
  const [updateTemplate, { data: updateData, loading }] = useMutation(Mutations.UPDATE_TEMPLATE);
  const [userSaveTemplate] = useMutation(Mutations.USER_SAVE_TEMPLATE);
  const [userUnsaveTemplate] = useMutation(Mutations.USER_UNSAVE_TEMPLATE);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUnsaveModalOpen, setIsUnsaveModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [description, setDescription] = useState();
  const phasesIds = project.phases.map((phase) => phase.id.value);
  const phaseIndex = phasesIds.indexOf(selectedPhaseId);

  useEffect(() => {
    const currentPhaseDescription = template.descriptions.find((desc) => desc.phaseId === selectedPhaseId);
    setDescription(currentPhaseDescription?.description);
    // eslint-disable-next-line
  }, [selectedPhaseId]);

  useEffect(() => {
    if (!updateData) return;
    logger.debug('Template updated successfully');
    // eslint-disable-next-line
  }, [updateData]);

  const updateTemplateDescription = useCallback(
    debounce(async (description) => {
      const newTemplate = { id: template.id, phaseId: selectedPhaseId, description };
      logger.debug(`Updating template with id: '${template.id}'`);
      try {
        await updateTemplate({ variables: { param: newTemplate } });
      } catch (err) {
        logger.debug(`An error occurred executing mutation: ${err}`);
        return toast('An unexpected error occurred editing template', 'error');
      }
    }, Config.TEXT_INPUT_DELAY),
    [selectedPhaseId]
  );

  const handlesDescriptionChange = (newValue) => {
    setDescription(newValue);
    updateTemplateDescription(newValue);
  };

  const onCreateTemplate = () => {
    setModalIsOpen(true);
  };

  const onCloseModal = () => {
    setModalIsOpen(false);
  };

  const onToggleEditing = () => {
    setCheckedItems([]);
    dismissAllToasts();
    onClose();
    toggleEditing();
    const lastPhaseId = project.phases[project.phases.length - 1].id;
    updateSnapshot(template.id, project.id, lastPhaseId, mapConfig);
  };

  const onUpgradeTemplateSuccess = () => {
    history.go(0);
  };

  const openCreateModal = () => {
    setIsCreateModalOpen(true);
  };
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const openUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const closeUpdateModal = () => {
    setIsUpdateModalOpen(false);
  };

  const openUnsaveModal = () => {
    setIsUnsaveModalOpen(true);
  };

  const closeUnsaveModal = () => {
    setIsUnsaveModalOpen(false);
  };

  const openPublishModal = () => {
    setIsPublishModalOpen(true);
  };

  const closePublishModal = () => {
    setIsPublishModalOpen(false);
  };

  const nextPhase = () => {
    changePhase(phasesIds[phaseIndex + 1]);
  };

  const previousPhase = () => {
    changePhase(phasesIds[phaseIndex - 1]);
  };

  const ChangeSavedStatus = async () => {
    const mutationArgs = {
      variables: { templateId: template.id },
    };
    try {
      if (template.isSaved) {
        await userUnsaveTemplate(mutationArgs);
        if (template.deleted || !template.published) {
          history.push({
            pathname: `/projects`,
          });
        }
      } else {
        await userSaveTemplate(mutationArgs);
        TemplateEvents.templateSaved(template.id);
      }
    } catch (error) {
      return toast('An unexpected error occurred saving template.', 'error');
    }
  };

  const onClickSaveButton = () => {
    template.isSaved && (template.deleted || !template.published) ? openUnsaveModal() : ChangeSavedStatus();
  };

  const onTogglePublicTemplate = async () => {
    const updatedTemplate = { id: template.id, published: !template.published };
    logger.debug(`Updating template with id: '${template.id}'`);
    try {
      await updateTemplate({ variables: { param: updatedTemplate } });
      if (!template.published) {
        TemplateEvents.templatePublished(template.id);
      } else {
        TemplateEvents.templateUnpublished(template.id);
      }
      closePublishModal();
    } catch (err) {
      logger.error(`An unexpected error occurred toggling template's visibility: ${err}`);
      return toast("An unexpected error occurred toggling template's visibility", 'error');
    }
  };

  const onUpdateTemplate = async (templateName, categoriesFormatted) => {
    const newTemplate = { id: template.id, name: templateName, categories: categoriesFormatted };
    logger.debug(`Updating template with id: '${template.id}'`);
    try {
      await updateTemplate({ variables: { param: newTemplate } });
      logger.debug(`Successfully updated template with id: '${template.id}'`);
    } catch (err) {
      logger.error(`An unexpected error occurred updating template with id: '${template.id}'`);
      return toast('An unexpected error occurred updating template', 'error');
    }
  };

  return (
    <div className="template-container">
      <div className="header">
        <div className="title">
          <div className="name">
            <h2>{template.name}</h2>
            {isEditing && (
              <div data-testid={'edit-template'} onClick={onCreateTemplate}>
                <EditIcon width={18} height={18} color="white" />
              </div>
            )}
          </div>
          {!isOwner && !isEditing && (
            <h2 className="email">
              by {template.owner.email} • {new Date(template.lastUpdated).toLocaleDateString()}
            </h2>
          )}
        </div>
        {isEditing ? (
          <Button smallerText={true} text={'Done'} onClick={onToggleEditing} color="lightGray" />
        ) : isOwner ? (
          <div className="visibility-section" data-testid={'visibility-section'}>
            <LockIcon width={18} height={18} color="white" />
            <ToggleButton small checked={template.published} onToggled={openPublishModal} />
            <EyeIcon width={18} height={18} color="white" />
          </div>
        ) : (
          <div className={cn('saved', { fill: template.isSaved })} data-testid={'times-saved'} onClick={onClickSaveButton}>
            {template.timesSaved}
            <SavedIcon width={13} height={15} isFill={template.isSaved} lineColor="white" />
          </div>
        )}
      </div>
      {isEditing ? (
        <TextInput
          value={description}
          className="multiline-black"
          multiline={true}
          onValueChange={handlesDescriptionChange}
          placeholder="Add a description..."
        />
      ) : (
        <>
          <div className="buttons-section">
            <Button smallerText={true} text={'Use this Template'} onClick={openCreateModal} color="red" />
            {isOwner ? (
              <Button smallerText={true} color="lightGray" text={'Edit Template'} onClick={toggleEditing} />
            ) : (
              template.updateAvailable && (
                <Button smallerText={true} text={'Update Available'} onClick={openUpdateModal} color="lightGray" Icon={ArrowUpCircleIcon} />
              )
            )}
          </div>
          <div className="description">{description}</div>
          {project.phases.length !== 1 && (
            <div className="phases-navigate">
              {phaseIndex > 0 && (
                <div className="clickable" onClick={previousPhase}>
                  <ArrowIcon className={'rotate180-icon'} color="white" width={11} height={9} /> Previous Phase
                </div>
              )}
              {phaseIndex < project.phases.length - 1 && (
                <div className="clickable next" onClick={nextPhase}>
                  Next Phase <ArrowIcon color="white" width={11} height={9} />
                </div>
              )}
            </div>
          )}
        </>
      )}
      {modalIsOpen && <TemplateModal isOpen={modalIsOpen} onClose={onCloseModal} onSave={onUpdateTemplate} template={template} loading={loading} />}
      {isCreateModalOpen && (
        <CreateProjectModal
          companyId={companyId}
          history={history}
          isOpen={isCreateModalOpen}
          onClose={closeCreateModal}
          fromTemplate={true}
          template={template}
        />
      )}
      {isUpdateModalOpen && (
        <TemplateUpdateAvailableModal
          isUpdateModalOpen={isUpdateModalOpen}
          closeUpdateModal={closeUpdateModal}
          templateId={template.id}
          project={project}
          onSuccess={onUpgradeTemplateSuccess}
          lastTemplateVersion={lastTemplateVersion}
        />
      )}

      {isUnsaveModalOpen && (
        <Modal isOpen={isUnsaveModalOpen} className={'modal-window confirmation-modal'} onRequestClose={closeUnsaveModal} defaultStyles={defaultStyles}>
          <ConfirmationModal
            onClose={closeUnsaveModal}
            onConfirm={ChangeSavedStatus}
            title={'Unsave this template'}
            trailingText={`Are you sure you would like to unsave this template? It was deleted and you will not be able to undo this action.`}
            buttonText={'Confirm'}
          />
        </Modal>
      )}

      {isPublishModalOpen && (
        <Modal isOpen={isPublishModalOpen} className={'modal-window confirmation-modal'} onRequestClose={closePublishModal} defaultStyles={defaultStyles}>
          <ConfirmationModal
            onClose={closePublishModal}
            onConfirm={onTogglePublicTemplate}
            loading={loading}
            title={template.published ? 'Make this template private' : 'Make this template public'}
            trailingText={`This will make this template ${template.published ? 'unavailable' : 'available'} to all Equinix users on Workbench.`}
            buttonText={template.published ? 'Make Private' : 'Make Public'}
          />
        </Modal>
      )}
    </div>
  );
};

export default TemplateSection;
