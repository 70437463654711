import Assert from '../utils/Assert';
import ArgumentError from '../error/ArgumentError';
import Address from './Address';
import LocationId from './LocationId';
import MetroId from './MetroId';
import GeoCoordinates from './GeoCoordinates';

const LOCATION_TYPE_VALUES = new Map();
export class LocationType {
  static OFFICE = new LocationType('OFFICE');
  static DATA_CENTER = new LocationType('DATACENTER');

  constructor(value) {
    this.name = Symbol(value);
    this.value = value;
    LOCATION_TYPE_VALUES.set(value, this);
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof LocationType)) {
      return false;
    }

    return this.name === other.name;
  }

  compare(other) {
    if (this.equals(other)) {
      return 0;
    } else if (this.equals(LocationType.OFFICE)) {
      return -1;
    } else {
      return 1;
    }
  }

  toString() {
    return this.value;
  }

  static get values() {
    return LOCATION_TYPE_VALUES.values();
  }

  static of(value) {
    if (!value) {
      return null;
    }

    Assert.isString(value);

    const v = LOCATION_TYPE_VALUES.get(value.toUpperCase());
    if (!v) {
      throw new ArgumentError(`Enum not found for ${value}`);
    }

    return v;
  }
}

export default class Location {
  constructor(id, name, type, employees, notes, address, coordinates, metroId) {
    Assert.instanceOf(id, LocationId);
    Assert.instanceOf(type, LocationType);
    Assert.instanceOf(address, Address);
    Assert.instanceOf(coordinates, GeoCoordinates);
    //Assert.instanceOf(metroId, MetroId);

    this.id = id;
    this.name = name;
    this.type = type;
    this.employees = employees;
    this.notes = notes;
    this.address = address;
    this.coordinates = coordinates;
    this.metroId = metroId;
    Object.freeze(this);
  }

  get isConnectedToMetro() {
    return this.metroId != null;
  }

  equals(other) {
    if (!(other instanceof Location)) {
      return false;
    }

    return this.id.equals(other.id);
  }

  toString() {
    const n = this.name || (this.address == null ? null : this.address.label);
    return `${this.constructor.name}{ id:${this.id}, name:'${n}', type:${this.type} }`;
  }

  toJSON() {
    const { id, name, type, employees, notes, address, coordinates, metroId } = this;
    return { id, name, type: type.toString(), employees, notes, address: address.toJSON(), coordinates: coordinates.toJSON(), metro: { id: metroId } };
  }

  compare(other) {
    if (!(other instanceof Location)) {
      throw new Error('Cannot compare to other type!');
    }
    const typeComparison = this.type.compare(other.type);
    if (typeComparison !== 0) {
      return typeComparison;
    }
    const myLabel = this.name || this.address.label;
    const theirLabel = other.name || other.address.label;
    if (myLabel > theirLabel) {
      return 1;
    } else if (myLabel < theirLabel) {
      return -1;
    } else {
      return 0;
    }
  }

  static fromJSON(json) {
    const { id, name, type, employees, notes, address, coordinates, metro } = json;

    return new Location(
      new LocationId(id),
      name,
      LocationType.of(type),
      employees,
      notes,
      Address.fromJSON(address),
      GeoCoordinates.fromJSON(coordinates),
      metro == null ? null : new MetroId(metro.id)
    );
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = Location.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}
