import React from 'react';
import DirectConnectionForm from './DirectConnectionForm';
import 'views/components/InfoPanel/children/TableStyles.scss';

const DirectConnectionsTable = ({ otherMetros, connectedTo, projectId, phaseId, metro, directConnections, handleCurrentPicker, currentPicker }) => {
  return (
    <div className="table" data-testid={'direct-latencies'}>
      {otherMetros.map((m) => {
        const connection = connectedTo.find((dc) => m.id.equals(dc.metro.id));
        const key = m.id.value;
        return (
          <DirectConnectionForm
            key={key}
            projectId={projectId}
            phaseId={phaseId}
            from={metro}
            to={m}
            directConnections={directConnections}
            connection={connection}
            handleCurrentPicker={handleCurrentPicker}
            isPickerOpen={key === currentPicker}
            formId={key}
          />
        );
      })}
    </div>
  );
};

export default DirectConnectionsTable;
