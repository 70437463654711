import ValueObject from './ValueObject';

export default class ProjectId extends ValueObject {
  equals(other) {
    if (!(other instanceof ProjectId)) {
      return false;
    }

    return super.equals(other);
  }

  static fromJSON(json) {
    return new ProjectId(json);
  }
}
