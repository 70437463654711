import BaseModel from './BaseModel';

import MetroId from './MetroId';
import MetroMarketplace from './MetroMarketplace';
import GeoCoordinates from './GeoCoordinates';
import Group from './Group';
import Location from './Location';
import ServiceConnection from './ServiceConnection';
import Label from './Label';
import Point from './Point';
import getPath from 'utils/getPath';
import MetalPlan from './MetalPlan';
import NetworkEdgeCategory from './NetworkEdgeCategory';

class MetroConnection extends BaseModel {
  // Fields defined at the bottom to avoid circular dependency
}

export default class Metro extends BaseModel {
  static fields = [
    { key: 'id', type: MetroId, stringify: true },
    { key: 'name', type: 'string', stringify: true },
    { key: 'region', type: 'string', stringify: true },
    { key: 'status', type: 'string', stringify: true },
    { key: 'notes', type: 'string', optional: true },
    { key: 'coordinates', type: GeoCoordinates },
    { key: 'code', type: 'string' },
    { key: 'connectedMetros', type: 'array', of: MetroConnection },
    { key: 'groups', type: 'array', of: Group },
    { key: 'locations', type: 'array', of: Location },
    { key: 'services', type: 'array', of: ServiceConnection },
    { key: 'labels', type: 'array', of: Label },
    { key: 'serviceOffset', type: Point },
    { key: 'marketplace', type: MetroMarketplace },
    { key: 'metalPlans', type: 'array', of: MetalPlan },
    { key: 'networkEdgeEnabled', type: 'boolean' },
    { key: 'networkEdgeCategories', type: 'array', of: NetworkEdgeCategory },
  ];

  // Dijkstra's algorithm, adapted a little.
  // One important distinction is that we compute the path to multiple destination nodes at once.
  // The algorithm already computes most of it anyway, no sense in repeating it multiple times
  getPaths(to, metros) {
    // if(this.paths && this.tos) {
    //     return {
    //         paths: this.paths,
    //         tos: this.tos,
    //     };
    // }
    // Set the initial distance of each to Infinity, except our starting metro
    const unvisited = metros.map((m) => ({ metro: m, distance: this.id.equals(m.id) ? 0 : Infinity }));
    const visited = [];

    // Pick our starting metro as the current one
    unvisited.sort((a, b) => a.distance - b.distance);
    let current = unvisited.shift();

    // TODO: find an efficient way to stop the computation once all destination nodes have been visited,
    // because right now it will continue until ALL nodes have been visited.
    // Most obvious ways will loop through the array, which will actually worsen performance.
    while (unvisited.length) {
      // Compute the cost of each connected metro
      for (let neighbour of current.metro.connectedMetros) {
        // Use the ones in the original list
        const unvisitedNeighbour = unvisited.find((e) => e.metro.id.equals(neighbour.metro.id));
        if (unvisitedNeighbour) {
          const newDistance = current.distance + neighbour.latency;
          if (newDistance < unvisitedNeighbour.distance) {
            // Use the current distance if it's shorter
            unvisitedNeighbour.distance = current.distance + neighbour.latency;
            unvisitedNeighbour.prev = current;
            unvisitedNeighbour.latency = neighbour.latency;
          }
        }
      }
      // mark as visited
      visited.push(current);
      // Use the node with the shortest distance as the next one
      unvisited.sort((a, b) => a.distance - b.distance);
      current = unvisited.shift();
    }
    visited.push(current);

    const tos = to
      .map((t) => visited.find((v) => v.metro.id.equals(t.id)))
      // exclude nodes with no connection
      .filter((t) => t.distance !== Infinity);
    const paths = tos.map((t) => getPath(t));
    return { paths, tos };
  }
}

MetroConnection.fields = [
  { key: 'latency', type: 'number' },
  { key: 'metro', type: Metro },
];

export const MetroStatus = Object.freeze({
  OPEN: 'OPEN',
  UNDER_ACQUISITION: 'UNDER_ACQUISITION',
});
