import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useCurrentProject } from 'providers/ProjectProvider';
import UndoToast from 'views/components/UndoToast';
import { toast } from 'utils/Toast';
import { useRecommendMetros } from '../hocs/withRecommendMetros';
import Button from 'views/components/Button';

const RecommendMetrosButton = ({ visible, onSelectionChanged }) => {
  const { recommendMetros, recommendMetrosLoading } = useRecommendMetros();
  const { projectId, selectedPhaseId: phaseId, changeProcessLoading, processLoading } = useCurrentProject();

  useEffect(() => {
    changeProcessLoading(recommendMetrosLoading);
  }, [recommendMetrosLoading, changeProcessLoading]);

  const onRecommendMetros = async () => {
    onSelectionChanged(null);
    try {
      await recommendMetros(projectId, phaseId);
      toast(({ closeToast, toastProps: { toastId } }) => (
        <UndoToast closeToast={closeToast} message={'Connected to Metros'} projectId={projectId} phaseId={phaseId} toastId={toastId} />
      ));
    } catch (error) {
      toast('An unexpected error occurred recommending metros', 'error');
    }
  };

  return (
    <div className={classNames('phase-recommend', { visible })} data-testid={'phase-recommend'}>
      <Button color="red" isStretch={true} text={'Autofill Metros'} onClick={onRecommendMetros} isDisabled={processLoading} />
      {processLoading && <span className="loading" />}
    </div>
  );
};

export default RecommendMetrosButton;
