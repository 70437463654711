import getPacificConnections from './getPacificConnections';

const buildPacificConnections = (fromRegion, toRegion, fromCoords, toCoords, isExport) => {
  let pacificConnections;

  if (fromRegion === 'AMER' && toRegion === 'APAC') {
    pacificConnections = getPacificConnections(fromCoords, toCoords, isExport);
  }
  if (fromRegion === 'APAC' && toRegion === 'AMER') {
    pacificConnections = getPacificConnections(toCoords, fromCoords, isExport);
  }

  return pacificConnections;
};

export default buildPacificConnections;
