import React from 'react';

const TabPanelContent = ({ children }) => {
  return (
    <div className="expandable-content platform-equinix-content">
      <div className="content-view">
        <div className={`content-section equinix-platform-panel`}>{children}</div>
      </div>
    </div>
  );
};

export default TabPanelContent;
