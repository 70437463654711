const category = 'Template Events';

module.exports = [
  {
    fn: 'templateUpdated',
    category,
    name: 'Template Updated',
    trigger: 'TemplateUpdated',
    params: ['templateId'],
  },
  {
    fn: 'templatePublished',
    category,
    name: 'Template Published',
    trigger: 'TemplatePublished',
    params: ['templateId'],
  },
  {
    fn: 'templateUnpublished',
    category,
    name: 'Template Unpublished',
    trigger: 'TemplateUnpublished',
    params: ['templateId'],
  },
  {
    fn: 'templateSaved',
    category,
    name: 'Template Saved',
    trigger: 'TemplateSaved',
    params: ['templateId'],
  },
  {
    fn: 'templateCreated',
    category,
    name: 'Template Created',
    trigger: 'TemplateCreated',
    params: ['templateId'],
  },
];
