import React from 'react';
import BookIcon from 'views/components/icons/Book';
import { useListView } from 'providers/ListViewProvider';
import { Link } from 'react-router-dom';
import { GETTING_STARTED_VIEW_SELECTION } from 'views/ProjectListView/DefaultListViewSelection';

const GettingStarted = () => {
  const { setListView } = useListView();

  const onSelect = () => {
    setListView(GETTING_STARTED_VIEW_SELECTION);
  };
  return (
    <Link
      to={{ pathname: '/projects' }}
      data-testid={'getting-started'}
      onClick={onSelect}
      className="user_linked-icon-link user_linked-icon-link--gettingStarted">
      <BookIcon />
      <span>Getting Started</span>
    </Link>
  );
};

export default GettingStarted;
