import { useMarketplaceSegments } from 'providers/MarketplaceProvider';
import React from 'react';

const MarketplaceChildItem = ({ item: { name, count }, calculateWidth }) => {
  const { marketplaceSegments } = useMarketplaceSegments();

  let average;
  marketplaceSegments.some((segment) => {
    const subsegment = segment.subSegments.find((subSegment) => subSegment.name === name);
    if (subsegment) {
      average = Math.round(subsegment.average);
      return true;
    }
    return false;
  });

  const countWidth = calculateWidth(count);
  const countStyle = { width: countWidth };
  const averageWidth = calculateWidth(average);
  const averageStyle = { width: averageWidth };

  return (
    <div className="marketplace-item child">
      <span className="title">{name}</span>

      <div className="bar-wrapper">
        <span className="value">{count}</span>
        <div className="bar" style={countStyle}></div>
      </div>

      <div className="bar-wrapper avg">
        <span className="value">avg {average}</span>
        <div className="bar" style={averageStyle}></div>
      </div>
    </div>
  );
};

export default MarketplaceChildItem;
