import React from 'react';
import classNames from 'classnames';
import Expandable from './Expandable';

export function Data(props) {
  const { active, on } = props;
  return (
    <Expandable>
      {(open, toggle) => (
        <div className={classNames('expandable use-case', { active, open, closed: !open })}>
          <div className="expandable-header use-case-header">
            <button className="expand-content" onClick={toggle}>
              <h3>Data</h3>
            </button>
            <div className={classNames('status', { on, active })}>
              {on ? 'Solved' : ''}
              {active ? '' : 'Coming Soon...'}
            </div>
          </div>
          <div className="expandable-content use-case-content">
            <p>
              To architect for the digital edge you need to localize data requirements, balance protection with accessibility, and govern data movement and
              placement either locally or across geographic regions. Learn more about data and the digital edge with technical blueprints and design patterns.
            </p>
            <ul className="design-patterns">
              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Establish Distributed Data Repository</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-distributed-data-repository"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        Address data performance and data sovereignty by placing data caches/copies in proximity, or keep the data local. Also solve for
                        multicloud access by placing data at network intersection points
                      </p>
                      <h5>Data Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Data Lake</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  The premise of a data lake is to have a single store of all data in an enterprise ranging from raw data (which implies exact
                                  copy of source system data) to transformed data which is used for various tasks including reporting, visualization, analytics
                                  and machine learning. A data lake is a method of storing data within a system or repository, in its natural/ original format
                                  that facilitates the collocation of data in various schemata and structural forms, usually object blobs or files. but those
                                  file types can be wide ranging from binary ( audio, video, images), structured record based, and unstructured documents.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>File System Data</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  A filesystem is used to control how myriad data types (implying formats) are stored, retrieved and manipulated. Without a file
                                  system, information placed in a storage medium would be one large body of data with no way to tell where one piece of
                                  information stops and the next begins. By separating the data into 'files' and giving each 'file' a name, and a type, the
                                  information is easily isolated, identified and associated with a particular service that can interpret and edit that file. The
                                  filesystem can also dictate how the file might be optimally stored.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Object Store</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  A persistent object store is a type of computer storage system that records and retrieves complete objects, or provides the
                                  illusion of doing so. Simple examples store the serialized object in binary format (zeroes and ones). This is in sharp
                                  contrast with file based storage or relational DB storage of records. More complex examples include object databases or
                                  object-relational mapping systems, which combine a database system with support for easily storing objects.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Solve Data Cache Placement</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-data-cache-edge-placement"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        Solve for data availability by placing a global namespace over the edge nodes and extend that into the various clouds as appropriate
                      </p>
                      <h5>Data Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Cached Data</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  An application based data cache is a fast and relatively small memory store, that is generally not visible to the application
                                  software, which accelerates read and write access, obviating the need to frequently access a spinning disk. Caches have
                                  synchronization mechanisms to make sure changes are propagated between distributed stores, some of them are transactional in
                                  nature, some are eventually persistent, such as for event based content updates of web pages. Caches are essential to
                                  enhancing performance of applications, although with the increasing use of solid state drives, the assumptions about how this
                                  is used will change. The increasing use of distributed data across the network into digital edge nodes will increase the use
                                  of caches in all forms.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Localized Data</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  The technique where data normally held in a centralized data center is distributed to edge nodes ( such as Equinix IBXs) or
                                  even remote / satellite offices in order to reduce response time, latency and improve user experience. The tactic of
                                  localizing data is augmented with various distributed caching schemes that will enable synchronization of data changes that
                                  occur across the network.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Place Edge Analytics & Streaming Flows</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-edge-analytics-streaming-flows"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>Leverage edge nodes for data collection and data aggregation, with local event processing to optimize streaming and time to insight</p>
                      <h5>Data Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Device Management</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  The scope of device management in this workbench context pertains to Mobile devices. Mobile application management (MAM)
                                  describes software and services responsible for provisioning and controlling access to internally developed and commercially
                                  available mobile apps used in business settings on both company-provided and employee owned smartphones and tablet computers.
                                  Issues of access, security and trusted application management are covered here. In the case of commercial users of a deployed
                                  mobile app, this pertains to the methods needed to manage updates and user profiles.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Event Processing</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Event processing is a method of tracking and analyzing streams of information or data about predefined events, and deriving
                                  conclusions from them in near real time. Complex event processing, or CEP, is event processing that combines data from
                                  multiple sources to infer events or patterns that suggest more complicated circumstances. The goal of complex event processing
                                  is to identify meaningful events (such as opportunities or threats) and respond to them as quickly as possible.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Data Exchanges & Data Integration</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-data-exchanges-data-integration"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        Combine data from disparate sources into meaningful information and deliver it as trusted data (which can be monetized and exchanged)
                      </p>
                      <h5>Data Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Data Ingestion</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Data Ingestion (Integration) involves combining data residing in different sources with different formats to provide consumers
                                  with a unified view of all of these elements. This process becomes complex in many scenarios, in a range of usage domains both
                                  commercial (when multiple companies decide to merge their databases) and scientific (combining research results from different
                                  repositories). Data integration is playing an increasing role in real time analytics, even though it was spawned from data
                                  warehousing and business intelligence domains.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Streaming Data</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Streaming Data refers to the influx of data elements from a variety of sources, usually small data elements containing event
                                  info that must be analyzed in near real time and acted upon. Ingesting this data is particularly challenging due to multiple
                                  formats and the nature of unbridled input that can't be held in check. This data is used by real time analytics in distributed
                                  locations to supports of insight and engagement.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Manage Meta Data and Data Pipelines</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-data-pipelines-provenance"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        Lastly, you will need data orchestration and data provenance to facilitate and track data flows and consumption from disparate sources
                        across the data fabric
                      </p>
                      <h5>Data Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Batch Content</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Batch content is delivered in bulk to a destination as opposed to being streamed. Therefore it is less jitter and latency
                                  sensitive, but still might have performance issues as this tactic is used for substantially sized files that are much larger
                                  than even a digital movie. Protocols are in place to ensure proper reassembly at the destination, and there is usually QoS
                                  policies regarding the transmission of these files.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Data Orchestration Service</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Organize the ingestion, transformation and movement of data across many soruces and programs so that scheduled work is
                                  consistent and complete. Runs on scripts that mandate sources, destinations, times, minimum viable conditiins for running and
                                  deadlines for completion.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Data Provenence</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>Keep track of and publish APIs for automatic updates of metadata management and allow programmatic metadata queries.</p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Streaming Content</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Streaming media is multimedia that is constantly received by and presented to an end-user while being delivered by a provider.
                                  The distinguishing feature of streaming media is that the audio/ video content is not downloaded as a whole first, but
                                  transmitted linearly on a constant basis. The result is that this service can suffer from network congestion and lags which
                                  the user experiences as jittery delivery ( screen pixelating, audio skipping and not in sync with the video). This represents
                                  significant challenges to content providers and the networks that carry these services.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>
            </ul>
          </div>
        </div>
      )}
    </Expandable>
  );
}
