import BaseModel from './BaseModel';

import DirectConnectionId from './DirectConnectionId';
import Metro from './Metro';
import DirectConnectionAndLatency from './DirectConnectionAndLatency';

export default class DirectConnection extends BaseModel {
  static fields = [
    { key: 'id', type: DirectConnectionId, stringify: true },
    { key: 'source', type: Metro, stringify: true },
    { key: 'connectedTo', type: 'array', of: DirectConnectionAndLatency },
  ];
}
