import React from 'react';
import { GqlQueryComponent, Queries } from 'gql';

class MetalsWrapper extends GqlQueryComponent {
  query() {
    return Queries.GET_METAL_PLANS;
  }

  queryArgs() {
    return { metroCode: this.props.code || this.props.metro?.code };
  }

  transform(data) {
    return data.metalPlans;
  }

  renderQuery(loading, error, metalPlans) {
    return this.props.children(metalPlans, loading, error);
  }
}

function withMetals(Component) {
  return (props) => (
    <MetalsWrapper {...props}>
      {(metalPlans, loading, error) => <Component metalPlans={metalPlans} metalPlansLoading={loading} metalPlansError={error} {...props} />}
    </MetalsWrapper>
  );
}

export default withMetals;
