import React from 'react';

const TagIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M1.54424 4.04053V4.04053L6.38336 4.04053L12.4699 10.1271C12.6652 10.3224 12.6652 10.6389 12.4699 10.8342L8.33785 14.9663C8.14258 15.1616 7.826 15.1616 7.63074 14.9663L1.54422 8.87965L1.54424 4.04053Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <circle cx="4.26465" cy="6.74548" r="0.75" fill={color} />
  </svg>
);

export default TagIcon;
//viewBox={`0 0 ${width} ${height}`}
