import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import ArrowIcon from 'views/components/icons/Arrow';

const Pagination = ({ items, setCurrentItems, itemsPerPage }) => {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [forcePage, setForcePage] = useState(undefined);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (!items) return;

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
    // eslint-disable-next-line
  }, [itemOffset, items]);

  useEffect(() => {
    if (!items) return;
    setForcePage(undefined);
    items.length && setTimeout(() => setForcePage(0), 0); // We have to reset the state for the library to re-render
    handlePageClick({ selected: 0 });
    // eslint-disable-next-line
  }, [items]);

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={<ArrowIcon color="#333333" width={8} height={13} />}
      onPageChange={handlePageClick}
      pageRangeDisplayed={2}
      pageCount={pageCount}
      previousLabel={<ArrowIcon className={'rotate180-icon'} color="#333333" width={8} height={13} />}
      containerClassName="pagination"
      activeLinkClassName="active"
      pageLinkClassName="page-link"
      previousLinkClassName="page-link"
      nextLinkClassName="page-link"
      disabledLinkClassName="disabled-item"
      renderOnZeroPageCount={null}
      forcePage={forcePage}
    />
  );
};

export default Pagination;
