import Button from 'views/components/Button';
import { LocationType } from 'model/Location';
import React from 'react';
import Selector from '../Selector';
import SelectorItem from '../SelectorItem';
import DatacenterIcon from '../icons/Datacenter';
import OfficeIcon from '../icons/Office';
class LocationTypeListSelectorItem extends SelectorItem {
  render() {
    const item = this.props.item;
    const className = this.props.className ? `${this.props.className} ${item.toString().toLowerCase()}` : item.toString().toLowerCase();
    const icon = className === 'office' ? OfficeIcon : DatacenterIcon;
    const iconColor = this.isSelected ? 'white' : '#ED1C24';
    return (
      <li key={this.index}>
        <Button
          Icon={icon}
          iconColor={iconColor}
          iconSize={14}
          text={this.props.content}
          color={this.isSelected ? 'red' : 'lightGray'}
          onClick={this._onClick}
          data-testid={`${className}-button`}
        />
      </li>
    );
  }
}

export default class LocationTypeList extends Selector {
  static defaultProps = {
    className: 'LocationTypeList',
    selectorItemClass: LocationTypeListSelectorItem,
  };

  renderItem(item) {
    return LocationType.DATA_CENTER === item ? 'Data Center' : 'Office';
  }
}
