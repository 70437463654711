const PAGE_TITLE_BASE = 'IOA WorkBench® | Equinix';

export default class Page {
  static title(value) {
    if (!value) {
      return PAGE_TITLE_BASE;
    }

    return value == null ? PAGE_TITLE_BASE : `${value} | ${PAGE_TITLE_BASE}`;
  }
}
