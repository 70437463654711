import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getTheme } from 'utils/SelectTheme';
import { dismissAllToasts, toast } from 'utils/Toast';
import ProductEvents from 'analytics/ProductEvents';
import { Mutations, Queries } from 'gql';
import DropdownStyles from 'views/components/DropdownStyles';
import ServicesTable from './ServicesTable';
import { useMutation, useQuery } from 'react-apollo';

const Services = ({ metro, projectId, phaseId }) => {
  const { data, loading: addServiceLoading } = useQuery(Queries.GET_SERVICES);
  const [addService] = useMutation(Mutations.ADD_SERVICE);
  const [selectedOption, setSelectedOption] = useState();
  const [showServicesArray, setShowServicesArray] = useState({});
  const [services, setServices] = useState([]);

  useEffect(() => {
    if (!data) return;
    setServices(data.validServices);
  }, [data]);

  const onAddService = async (e) => {
    //don't attempt to add a service that already exists on this metro
    const found = metro.services.find((serviceConnection) => serviceConnection.serviceId.equals(e.value.id));
    if (found != null) {
      toast(`Can't add service, service '${e.value.name}' already exists`, 'error');
      return;
    }

    setSelectedOption(e);
    await addService({
      variables: { projectId, phaseId, serviceId: e.value.id, metroId: metro.id, serviceRegionIds: [] },
    });
    dismissAllToasts();
    ProductEvents.serviceAdded(e.value.name);

    setSelectedOption(null);
  };

  const changeShowServicesState = (id, newState) => {
    showServicesArray[id] = newState;
    setShowServicesArray({ ...showServicesArray });
  };

  const filteredServices = services
    .filter((service) => !metro.services.find((serviceMetro) => serviceMetro.serviceId.value === service.id))
    .map((m) => {
      return { value: m, label: m.name };
    });

  return (
    <>
      <Select
        placeholder="Type to search Services..."
        options={filteredServices}
        onChange={onAddService}
        value={selectedOption}
        theme={getTheme}
        isLoading={addServiceLoading}
        styles={DropdownStyles}
      />
      <p className="latency-disclaimer">
        Values between Metros and CSP datacenters are indicative only of relative distance. Actual testing at an Equinix SVC or equivalent is strongly suggested
        for any other purpose.
      </p>
      <ServicesTable
        metro={metro}
        showServicesArray={showServicesArray}
        changeShowServicesState={changeShowServicesState}
        projectId={projectId}
        phaseId={phaseId}
      />
    </>
  );
};

export default Services;
