import { usePhaseItems } from 'providers/PhaseItemsProvider';
import { useRef, useEffect } from 'react';
import dragPreview from './dragPreview';

const useUpdateItemPreview = ({ item, connectDragPreview, isChecked, isSelected, selectedItem, scrollTargetRef }) => {
  const { checkedItems } = usePhaseItems();

  // Set the default scroll behaviour. Overwrite as needed, for special cases.
  const scrollBehaviour = { behavior: 'smooth', block: 'center' };

  const refPrev = useRef({ prevCheckedItems: [], prevSelectedItem: null });

  const scrollToTarget = () => {
    // Important to check for an unset scrollTargetRef.current, as it is possible that
    // the current ref has not been set yet.
    // Or it is possible that the derived class did not set the scrollTargetRef.
    if (!scrollTargetRef.current) return;

    // Scroll into view if selected.
    if (isSelected) {
      scrollTargetRef.current.scrollIntoView(scrollBehaviour);
    }
  };

  const updatePreview = () => {
    if (connectDragPreview instanceof Function) {
      if (isChecked && checkedItems.length > 1) {
        const svg = dragPreview(checkedItems.length);
        const img = new Image();
        img.src = `data:image/svg+xml;utf8,${svg}`;
        connectDragPreview(img);
      } else {
        connectDragPreview(null);
      }
    }
  };

  useEffect(() => {
    updatePreview();

    if (checkedItems.length !== refPrev.current.prevCheckedItems.length || item.serviceId) {
      //                                                      ^^^^^^ Be a bit more aggressive for services
      updatePreview();

      // Check that this item is now selected, but was not selected previously.
      const isNewlySelected =
        isSelected &&
        // If the previous selected item was null, we know that
        // this item is newly selected.
        (!refPrev.current.selectedItem ||
          // If the previous selected item is not the current
          // selected item, this item is newly selected.
          !refPrev.current.selectedItem.id.equals(selectedItem.id));

      if (isNewlySelected) {
        // When newly selected, need to scroll to the item.
        scrollToTarget();
      }

      refPrev.current.prevCheckedItems = checkedItems;
      refPrev.current.prevSelectedItem = selectedItem;
    }
    // eslint-disable-next-line
  }, [checkedItems, selectedItem, item]);
};

export default useUpdateItemPreview;
