import React from 'react';
import { withLeaflet } from 'react-leaflet';
import DirectConnectionCurve from './DirectConnectionCurve';
import buildPacificConnections from './helpers/buildPacificConnections';
import makeGeoCoordinatesFromPoints from './helpers/makeGeoCoordinatesFromPoints';
import GeoCoordinates from '../../../model/GeoCoordinates';

function DirectConnection({ text, color, iconSize, isExport, leaflet, fromMetro, toMetro }) {
  const fromRegion = fromMetro.id.value.split('_')[0]; // example id: AMER_SEATTLE
  const toRegion = toMetro.id.value.split('_')[0];

  const fromCoords = fromMetro.coordinates;
  const toCoords = toMetro.coordinates;

  const pacificConnections = buildPacificConnections(fromRegion, toRegion, fromCoords, toCoords, isExport);

  if (pacificConnections) {
    const { lineFrom, lineTo } = pacificConnections;

    const [geoFrom1, geoTo1] = makeGeoCoordinatesFromPoints(lineFrom);
    const [geoFrom2, geoTo2] = makeGeoCoordinatesFromPoints(lineTo);

    return (
      <React.Fragment>
        <DirectConnectionCurve from={geoFrom1} to={geoTo1} color={color} text={text} iconSize={iconSize} isPacific leaflet={leaflet} />
        <DirectConnectionCurve from={geoFrom2} to={geoTo2} color={color} text={text} iconSize={iconSize} isPacific leaflet={leaflet} />
      </React.Fragment>
    );
  } else {
    const geoFrom = new GeoCoordinates(fromCoords.latitude, fromCoords.longitude);
    const geoTo = new GeoCoordinates(toCoords.latitude, toCoords.longitude);
    return <DirectConnectionCurve from={geoFrom} to={geoTo} color={color} text={text} iconSize={iconSize} leaflet={leaflet} />;
  }
}

export default withLeaflet(DirectConnection);
