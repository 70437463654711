import CustomerId from './CustomerId';
import Segment from './Segment';
import Assert from '../utils/Assert';
import SubSegment from './SubSegment';

export default class Customer {
  constructor(id, name, segment, subSegment) {
    Assert.instanceOf(id, CustomerId);
    Assert.isString(name);

    if (segment) {
      Assert.instanceOf(segment, Segment);
    }

    if (subSegment) {
      Assert.instanceOf(subSegment, SubSegment);
    }

    this.id = id;
    this.name = name;
    this.segment = segment;
    this.subSegment = subSegment;

    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof Customer)) {
      return false;
    }

    return this.id.equals(other.id);
  }

  toString() {
    return `${this.constructor.name}{ id:${this.id}, name:'${this.name}' }`;
  }

  toJSON() {
    const { id, name, segment, subSegment } = this;
    return { id, name, segment, subSegment };
  }

  static fromJSON(json) {
    const { id, name, segment, subSegment } = json;

    return new Customer(new CustomerId(id), name, Segment.fromJSON(segment), SubSegment.fromJSON(subSegment));
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      items.push(Customer.fromJSON(json));
    }

    return items;
  }
}
