import React from 'react';

const EditIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M7.70071 2.68671H2.14991C1.72929 2.68671 1.3259 2.85426 1.02848 3.15251C0.731055 3.45076 0.563965 3.85528 0.563965 4.27707V15.4096C0.563965 15.8314 0.731055 16.2359 1.02848 16.5341C1.3259 16.8324 1.72929 17 2.14991 17H13.2515C13.6721 17 14.0755 16.8324 14.3729 16.5341C14.6704 16.2359 14.8374 15.8314 14.8374 15.4096V9.84333"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6475 1.49406C13.963 1.17772 14.3908 1 14.837 1C15.2831 1 15.7109 1.17772 16.0264 1.49406C16.3419 1.8104 16.5191 2.23946 16.5191 2.68683C16.5191 3.13421 16.3419 3.56326 16.0264 3.8796L8.49318 11.4338L5.32129 12.229L6.11426 9.04828L13.6475 1.49406Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
