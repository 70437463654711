import React from 'react';
import MarketplaceCategoryItem from './MarketplaceCategoryItem';

const MarketplaceCategory = ({ title, items, calculateWidth }) => {
  return (
    <div className="marketplace-category">
      <h4>{title}</h4>

      <div className="marketplace-items-wrapper">
        {items
          .sort((a, b) => (a.count < b.count ? 1 : -1))
          .map((item) => (
            <MarketplaceCategoryItem key={item.id} item={item} calculateWidth={calculateWidth} />
          ))}
      </div>
    </div>
  );
};

export default MarketplaceCategory;
