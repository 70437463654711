import React from 'react';

const CloudIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M20.3177 9.22744C20.1444 7.14801 18.8881 6.02166 17.1552 5.50181C17.1119 2.46931 14.7292 0 11.8267 0C9.9639 0 8.1444 1.16968 7.36462 2.55596C6.93141 2.29603 6.41155 2.12274 5.84837 2.12274C4.20217 2.12274 2.68592 3.55235 2.68592 5.2852C2.68592 5.63177 2.72924 6.02166 2.72924 6.02166C1.12635 6.4982 0.0433213 7.58123 0 9.48736C0 10.0939 0.0866427 11.1336 1.03971 12H19.2347C20.1444 11.3069 20.361 10.0072 20.3177 9.22744Z"
      fill={color}
    />
  </svg>
);
export default CloudIcon;
