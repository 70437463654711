import Assert from '../utils/Assert';

export default class FileReference {
  constructor(id, name, size, checksum) {
    Assert.isString(id);
    Assert.isString(name);
    Assert.isPositiveInteger(size);
    Assert.isString(checksum);

    this.id = id;
    this.name = name;
    this.size = size;
    this.checksum = checksum;

    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof FileReference)) {
      return false;
    }

    return this.id === other.id;
  }

  toString() {
    return `${this.constructor.name}{ id:'${this.id}', name:'${this.name}', size:${this.size}b, checksum:${this.checksum} }`;
  }

  toJSON() {
    const { id, name, size, checksum } = this;
    return { id, name, size, checksum };
  }

  static fromJSON(json) {
    const { id, name, size, checksum } = json;

    return new FileReference(id, name, size, checksum);
  }
}
