import useAddMetalToMetro from '../../../hooks/useAddMetalToMetro';
import useRemoveMetalFromMetro from '../../../hooks/useRemoveMetalFromMetro';

const useMetalProductMarker = () => {
  const { addMetalToMetro, loadingAddMetalToMetro } = useAddMetalToMetro();
  const { removeMetalFromMetro, loadingRemoveMetalFromMetro } = useRemoveMetalFromMetro();

  const onAddMetalToMetro = async (projectId, phaseId, metroCode, planId) => {
    await addMetalToMetro(projectId, phaseId, metroCode, planId);
  };
  const onRemoveMetalFromMetro = async (projectId, phaseId, metroCode, planId) => {
    await removeMetalFromMetro(projectId, phaseId, metroCode, planId);
  };

  return {
    onEnable: onAddMetalToMetro,
    onDisable: onRemoveMetalFromMetro,
    loading: loadingAddMetalToMetro || loadingRemoveMetalFromMetro,
  };
};

export default useMetalProductMarker;
