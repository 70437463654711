import React from 'react';
import EquinixPlatformIcon from 'views/components/icons/EquinixPlatform';
import InfraComponentTable from './InfraComponentTable';
import TabPanel from '../../TabPanel';
import TabPanelContent from '../../TabPanelContent';

const InfraComponentTabPanel = ({ projectId, phaseId, metro }) => {
  const title = 'Infrastructure Components';
  const icon = <EquinixPlatformIcon width={16} height={16} color="black" />;

  return (
    <TabPanel icon={icon} title={title} productAvailable={true}>
      <TabPanelContent>
        <InfraComponentTable projectId={projectId} phaseId={phaseId.value} metro={metro} />
      </TabPanelContent>
    </TabPanel>
  );
};

export default InfraComponentTabPanel;
