import { usePhaseItems } from 'providers/PhaseItemsProvider';

const useOnCheckedListItem = (phase) => {
  const { checkedItems, setCheckedItems, lastChecked, setLastChecked } = usePhaseItems();

  return async (checkedItem, shiftKey) => {
    if (shiftKey && checkedItems.length) {
      // shift-click
      const allItems = [
        ...phase.unconnectedGroups,
        ...phase.unconnectedLocations,
        ...phase.unconnectedServices,
        ...phase.metros.map((m) => [...m.groups, ...m.locations, ...m.services]).reduce((m, a) => [...m, ...a], []),
      ];
      const start = allItems.findIndex((i) => i.id.equals(lastChecked.id));
      const end = allItems.findIndex((i) => i.id.equals(checkedItem.id));
      let affectedItems = null;
      if (end > start) {
        affectedItems = allItems.slice(start + 1, end + 1);
      } else {
        affectedItems = allItems.slice(end, start);
      }
      if (checkedItems.find((i) => i.id.equals(checkedItem.id))) {
        // If we clicked on an already-selected items, de-select affected items
        const newCheckedItems = checkedItems.filter((i) => !affectedItems.find((c) => i.id.equals(c.id)));
        setCheckedItems(newCheckedItems);
      } else {
        // Make sure we don't re-select already selected items
        const newCheckedItems = affectedItems.filter((i) => !checkedItems.find((c) => i.id.equals(c.id)));
        setCheckedItems([...checkedItems, ...newCheckedItems]);
      }
    } else {
      // simple click
      if (checkedItems.find((i) => i.id.equals(checkedItem.id))) {
        // It was already checked, remove it
        setCheckedItems(checkedItems.filter((i) => !i.id.equals(checkedItem.id)));
      } else {
        setCheckedItems([...checkedItems, checkedItem]);
        setLastChecked(checkedItem);
      }
    }
  };
};

export default useOnCheckedListItem;
