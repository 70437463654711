import React, { useRef } from 'react';
import { DragSource } from 'react-dnd';

import { source, dragCollect } from './dndSpecs';

import Location from '../../../model/Location';
import { ControlledMenu, MenuItem } from '@szhsin/react-menu';
import Checkbox from '../Checkbox';
import { usePhaseItems } from 'providers/PhaseItemsProvider';
import useItemParams from './useItemParams';
import useUpdateItemPreview from './useUpdateItemPreview';
import { useCurrentProject } from 'providers/ProjectProvider';

const LocationItemF = ({
  item,
  pendingAdd,
  connectDragSource,
  onContextMenu,
  isContextMenuOpen,
  onDeleteLocation,
  onSelect,
  onCheck,
  selectedItem,
  connectDragPreview,
}) => {
  const itemTypeClassName = () => {
    const isLocation = item instanceof Location;
    if (isLocation) {
      return item.type.toString().toLowerCase();
    } else {
      return null;
    }
  };

  const baseClassName = itemTypeClassName();
  const scrollTargetRef = useRef();
  const { isEditing } = useCurrentProject();
  const { onCloseContextMenu, anchorPoint } = usePhaseItems();
  const { isSelected, isChecked, classNames } = useItemParams({ item, selectedItem, baseClassName, pendingAdd, isEditing });
  useUpdateItemPreview({ item, connectDragPreview, isChecked, isSelected, scrollTargetRef, selectedItem });

  const _onSelect = () => {
    onSelect(item);
  };

  const _onCheck = (e) => {
    e.stopPropagation();
    onCheck(item, e.shiftKey);
  };

  return connectDragSource(
    <li ref={scrollTargetRef} className={classNames} onClick={_onSelect} onContextMenu={onContextMenu}>
      <ControlledMenu
        state={isContextMenuOpen ? 'open' : 'closed'}
        onClose={onCloseContextMenu}
        anchorPoint={anchorPoint}
        portal
        direction={'right'}
        className={'default-menu sidebar-context-menu'}>
        <MenuItem onClick={onDeleteLocation}>Delete Site</MenuItem>
      </ControlledMenu>
      {isEditing && <Checkbox checked={isChecked} onCheck={_onCheck} />}
      <span className="item-text">{item.name || item.address.label}</span>
      {pendingAdd ? <span className="loading" /> : null}
    </li>
  );
};

export const LocationItemFDrag = DragSource('projectItem', source('location'), dragCollect)(LocationItemF);
