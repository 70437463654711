import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useCurrentProject } from './ProjectProvider';

const PhaseItemsContext = createContext(null);

const PhaseItemsProvider = ({ children }) => {
  const { isEditing } = useCurrentProject();

  const [isContextMenuOpen, setIsContextMenuOpen] = useState({
    metro: false,
    service: false,
    location: false,
  });

  const [anchorPoint, setAnchorPoint] = useState({});

  const [lastChecked, setLastChecked] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [pendingMoves, setPendingMoves] = useState([]);
  const [pendingRemoval, setPendingRemoval] = useState([]);

  // The Flyout Component is a self-contained library Component, so we need
  // the ref to manually call the exposed .close() function when needed.
  const moveActionsFlyoutRef = useRef();
  const clearBulkActionUI = moveActionsFlyoutRef?.current?.close;

  // HELPER
  const defaultProps = (item) => ({
    key: item.id,
    checkedItems,
    item,
  });

  // HELPER
  const pendingProps = (type, metroId) => {
    return pendingMoves
      .filter((move) => (metroId ? metroId.equals(move.metroId) : !move.metroId) && move.item instanceof type)
      .map((move) => ({
        ...defaultProps(move.item),
        pendingAdd: true,
      }));
  };

  // HELPER
  useEffect(() => setPendingRemoval(pendingMoves.map((move) => move.item)), [pendingMoves]);

  // HELPER
  const getDraggedItems = (draggedItem) => {
    if (checkedItems.some((i) => i.id.equals(draggedItem.id))) {
      return checkedItems;
    } else {
      return [draggedItem];
    }
  };

  const onCloseContextMenu = () => {
    setIsContextMenuOpen({
      metro: false,
      service: false,
      location: false,
    });
  };

  const onContextMenu = (e, type, id) => {
    e.preventDefault();
    if (!isEditing) return;
    setAnchorPoint({ x: e.pageX, y: e.pageY });
    setIsContextMenuOpen((prev) => ({ ...prev.isContextMenuOpen, [type]: id }));
  };

  return (
    <PhaseItemsContext.Provider
      value={{
        checkedItems,
        pendingRemoval,
        defaultProps,
        pendingProps,
        setCheckedItems,
        isContextMenuOpen,
        setIsContextMenuOpen,
        clearBulkActionUI,
        onCloseContextMenu,
        onContextMenu,
        getDraggedItems,
        anchorPoint,
        pendingMoves,
        setPendingMoves,
        lastChecked,
        setLastChecked,
        moveActionsFlyoutRef,
      }}>
      {children}
    </PhaseItemsContext.Provider>
  );
};

export const usePhaseItems = () => {
  const phaseItemsContext = useContext(PhaseItemsContext);

  if (!phaseItemsContext) {
    throw new Error('PhaseItemsContext not found');
  }

  return phaseItemsContext;
};

export const withPhaseItemsProvider = (Component) => {
  return (props) => (
    <PhaseItemsProvider {...props}>
      <Component {...props} />
    </PhaseItemsProvider>
  );
};

export default PhaseItemsProvider;
