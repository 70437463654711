import React from 'react';

const EditingIcon = ({ width = 10, height = 10, color = 'black', background = 'white', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0 10C0 4.47715 4.47715 0 10 0V0C15.5228 0 20 4.47715 20 10V10C20 15.5228 15.5228 20 10 20V20C4.47715 20 0 15.5228 0 10V10Z" fill={background} />
      <path
        d="M5 12.9161V14.9994H7.08333L13.2278 8.85499L11.1444 6.77166L5 12.9161ZM14.8389 7.24388C15.0556 7.02721 15.0556 6.67721 14.8389 6.46055L13.5389 5.16055C13.3222 4.94388 12.9722 4.94388 12.7556 5.16055L11.7389 6.17721L13.8222 8.26055L14.8389 7.24388V7.24388Z"
        fill={color}
      />
    </svg>
  );
};

export default EditingIcon;
