import Config from 'Config';
import React from 'react';
import './MaintenanceView.scss';

const MaintenanceView = () => {
  return (
    <div className="maintenance">
      <div className="banner">
        <span className="title">We'll be back shortly</span>
        <p className="text">{Config.MAINTENANCE_RUNNING_TEXT}</p>
      </div>
    </div>
  );
};

export default MaintenanceView;
