import Config from 'Config';
import React from 'react';
import OpenLink from 'views/components/icons/OpenLink';

const TabPanelMarketplaceSide = () => {
  return (
    <div className="marketplace-link">
      <a href={Config.MARKETPLACE_LINK} target="_blank" rel="noopener noreferrer">
        <span>Open in Marketplace</span>
        <OpenLink width={12} height={11} color="#333333" />
      </a>
    </div>
  );
};

export default TabPanelMarketplaceSide;
