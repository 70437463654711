import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';

const useEnableMetal = () => {
  const [doEnableMetal, { data, loading, error }] = useMutation(Mutations.ENABLE_METAL);

  const enableMetal = (projectId, phaseId, metroCode) => doEnableMetal({ variables: { projectId, phaseId, metroCode } });

  return {
    enableMetal,
    dataEnableMetal: data,
    loadingEnableMetal: loading,
    errorEnableMetal: error,
  };
};

export default useEnableMetal;
