import { usePhaseItems } from 'providers/PhaseItemsProvider';
import classnames from 'classnames';

const useItemParams = ({ item, selectedItem, baseClassName, pendingAdd, isEditing }) => {
  const { checkedItems } = usePhaseItems();

  const isSelected = selectedItem && item.id.equals(selectedItem.id);
  const isChecked = !!checkedItems.find((i) => i.equals(item));
  const classNames = classnames(
    baseClassName,
    /*className,*/ {
      selected: isSelected,
      checked: isChecked,
      adding: pendingAdd,
      // removing: pendingRemoval,
      local: item.local,
      hidden: !item.visible,
      editing: isEditing,
      item,
    }
  );

  return {
    isSelected,
    isChecked,
    classNames,
  };
};

export default useItemParams;
