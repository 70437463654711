function getImg(num) {
  return encodeURIComponent(
    `
<svg width="369" height="56" viewBox="0 0 369 56" fill="none" xmlns="http://www.w3.org/2000/svg">
  <style>
    .text {
      font-family: Roboto, Helvetica, sans-serif;
      font-size: 14px;
      font-weight: 100;
      fill: white
    }

  </style>
  <g opacity="0.9" filter="url(#filter0_d)">
    <rect x="4.5" y="4.5" width="352" height="39" fill="#121212" stroke="black"/>
    <rect width="353" height="3" transform="matrix(1 0 0 -1 8 48)" fill="#121212"/>
    <rect width="3" height="41" transform="matrix(1 0 0 -1 358 48)" fill="#121212"/>
    <rect width="350" height="1" transform="matrix(1 0 0 -1 8 45)" fill="#3D3D3D"/>
    <rect width="1" height="38" transform="matrix(1 0 0 -1 357 45)" fill="#3D3D3D"/>
    <rect width="350" height="1" transform="matrix(1 0 0 -1 12 49)" fill="#3D3D3D"/>
    <rect width="353" height="3" transform="matrix(1 0 0 -1 12 52)" fill="#121212"/>
    <rect x="28.5" y="20.5" width="11" height="11" rx="1.5" fill="#58B8E6" stroke="#58B8E6"/>
    <path d="M31.5 26L33.5 28L37 24.5" stroke="white"/>
    <text x="55" y="31" class="text">${num} Selected Item${num > 1 ? 's' : ''}</text>
    <rect x="361" y="10" width="1" height="39" fill="#3D3D3D"/>
    <rect width="3" height="39" transform="matrix(1 0 0 -1 362 49)" fill="#121212"/>
  </g>
  <defs>
  <filter id="filter0_d" x="0" y="0" width="369" height="56" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="2"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.345098 0 0 0 0 0.721569 0 0 0 0 0.901961 0 0 0 1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
  </filter>
  </defs>
</svg>
    `.replace(/\n/g, '')
  );
}

export default getImg;
