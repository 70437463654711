import BaseModel from './BaseModel';

import Metro from './Metro';

export default class DirectConnectionAndLatency extends BaseModel {
  static fields = [
    { key: 'metro', type: Metro, stringify: true },
    { key: 'label', type: 'string', stringify: true },
    { key: 'color', type: 'string' },
  ];
}
