import ValueObject from './ValueObject';
import Assert from '../utils/Assert';
import ServiceId from './ServiceId';
import MetroId from './MetroId';
import ProjectPhaseId from './ProjectPhaseId';

const ID_SEPARATOR = '+';
const METRO_ID_SEPARATOR = ':';

const createServiceConnectionId = (phaseId, serviceId, metroId) => {
  let id = '';

  if (phaseId != null) {
    Assert.instanceOf(phaseId, ProjectPhaseId);
    id = `${phaseId}${ID_SEPARATOR}`;
  }

  Assert.instanceOf(serviceId, ServiceId);
  id = `${id}${serviceId}`;

  if (metroId != null) {
    Assert.instanceOf(metroId, MetroId);
    id = `${id}${METRO_ID_SEPARATOR}${metroId}`;
  }

  return id;
};

export default class ServiceConnectionId extends ValueObject {
  constructor(phaseId, serviceId, metroId) {
    super(null);
    this.phaseId = phaseId || null;
    this.serviceId = serviceId || null;
    this.metroId = metroId || null;
    this.value = createServiceConnectionId(phaseId, serviceId, metroId);
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof ServiceConnectionId)) {
      return false;
    }

    return super.equals(other);
  }

  static fromJSON(json) {
    const phaseId = ServiceConnectionId._parsePhaseId(json);
    const serviceId = ServiceConnectionId._parseServiceId(json);
    const metroId = ServiceConnectionId._parseMetroId(json);

    return new ServiceConnectionId(phaseId, serviceId, metroId);
  }

  static _parsePhaseId(identifier) {
    const index = identifier.indexOf(ID_SEPARATOR);
    if (index < 0) {
      return null;
    }

    const s = identifier.substring(0, index);

    return new ProjectPhaseId(s.trim());
  }

  static _parseServiceId(identifier) {
    const index = identifier.indexOf(ID_SEPARATOR);
    if (index < 0) {
      return new ServiceId(identifier.trim());
    }

    const s = identifier.substring(index + 1);

    const index2 = s.indexOf(METRO_ID_SEPARATOR);
    let ss;
    if (index2 < 0) {
      ss = s;
    } else {
      ss = s.substring(0, index2);
    }

    return new ServiceId(ss.trim());
  }

  static _parseMetroId(identifier) {
    const index = identifier.indexOf(METRO_ID_SEPARATOR);
    if (index < 0) {
      return null;
    }

    const s = identifier.substring(index + 1);

    return new MetroId(s.trim());
  }
}
