// let phases = [
//   {
//     id: '1',
//     name: 'Base setup',
//     order: 1,
//     services: [],
//     directConnections: [],
//     groups: [],
//     locations: [],
//     accountStrategyPlans: [],
//     allServices: [],
//     metros: [],
//     __typename: 'Phase',
//   },
//   {
//     id: '2',
//     name: 'Network refinements',
//     order: 2,
//     services: [],
//     sharedUsers: [],
//     directConnections: [],
//     groups: [],
//     locations: [],
//     accountStrategyPlans: [],
//     allServices: [],
//     metros: [
//       {
//         id: 'AMER_DENVER',
//         name: 'Denver',
//         region: 'AMER',
//         code: 'DE',
//         status: 'OPEN',
//         notes: null,
//         serviceOffset: {
//           x: 0,
//           y: 0,
//           __typename: 'Point',
//         },
//         existing: false,
//         coordinates: {
//           latitude: 39.587392,
//           longitude: -104.876307,
//           __typename: 'GeoCoordinates',
//         },
//         locations: [],
//         groups: [],
//         services: [],
//         labels: [],
//         metalEnabled: false,
//         metalPlans: [],
//         networkEdgeEnabled: false,
//         networkEdgeCategories: [],
//         __typename: 'ProjectMetro',
//       },
//     ],
//     __typename: 'Phase',
//   },
//   {
//     id: '3',
//     name: 'Deployment',
//     order: 3,
//     services: [],
//     sharedUsers: [],
//     directConnections: [],
//     groups: [],
//     locations: [],
//     accountStrategyPlans: [],
//     allServices: [],
//     metros: [],
//     __typename: 'Phase',
//   },
// ];

// let mockProject = {
//   id: 123,
//   name: 'Project Name',
//   customer: {
//     name: 'Customer Name',
//   },
//   owner: {
//     email: 'joaquin.zapata@nan-labs.com',
//     id: '493880204138119169',
//     __typename: 'User',
//   },
//   sharedUsers: [
//     {
//       email: 'example@example.com',
//       id: '123',
//       __typename: 'User',
//     },
//     {
//       email: 'email@example.com',
//       id: '321',
//       __typename: 'User',
//     },
//     {
//       email: 'name@company.com',
//       id: '456',
//       __typename: 'User',
//     },
//   ],
//   phases,
//   selectedPhaseId: '1',
//   __typename: 'Project',
// };

let functionalities = [];
const createCustomFunctionalityResolver = (params) => {
  const newFunctionality = { ...params, id: functionalities.length };
  functionalities.push(newFunctionality);
  console.log({ create: functionalities });
  return newFunctionality;
};

const updateCustomFunctionalityResolver = (params) => {
  functionalities = functionalities.map((functionality) => {
    if (functionality.id === params.functionality) {
      return { ...functionality, params };
    }
    return functionality;
  });
  console.log({ update: functionalities });
};

const deleteFunctionalityResolver = (params) => {
  functionalities = functionalities.filter((functionality) => functionality.id !== params.functionality);
  console.log({ delete: functionalities });
};

const enableFunctionalityResolver = (params) => {
  functionalities = functionalities.map((functionality) => {
    if (functionality.id === params.functionalityId) {
      return { ...functionality, enable: true };
    }
    return functionality;
  });
  console.log({ enable: functionalities });
};

const disableFunctionalityResolver = (params) => {
  functionalities = functionalities.map((functionality) => {
    if (functionality.id === params.functionalityId) {
      return { ...functionality, enable: false };
    }
    return functionality;
  });
  console.log({ disable: functionalities });
};

const resolvers = {
  // Query: {
  //   companies: () => getCompaniesResolver(),
  //   company: (_, { companyId }) => getCompanyResolver(companyId),
  // },
  Mutation: {
    createCustomFunctionality: (_, params) => createCustomFunctionalityResolver(params),
    updateCustomFunctionality: (_, params) => updateCustomFunctionalityResolver(params),
    deleteFunctionality: (_, params) => deleteFunctionalityResolver(params),
    enableFunctionality: (_, params) => enableFunctionalityResolver(params),
    disableFunctionality: (_, params) => disableFunctionalityResolver(params),
  },
};

export default resolvers;
