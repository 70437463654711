import React from 'react';
import Ring from './Ring';
import './LatencyRingsInfo.scss';

const LatencyRingsInfo = ({ latency, color, ...rest }) => {
  return (
    <div className="info-wrapper" {...rest}>
      <Ring size={32} color={color} />
      <p>
        Latency Ring Approximate Radius: <b>{latency}ms</b>
      </p>
    </div>
  );
};

export default LatencyRingsInfo;
