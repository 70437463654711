import BaseModel from './BaseModel';

import ProjectId from './ProjectId';
import Customer from './Customer';
import User from './User';
import Timestamp from './Timestamp';
import ProjectPhase from './ProjectPhase';
import Version from './Version';

export default class Project extends BaseModel {
  static fields = [
    { key: 'id', type: ProjectId, stringify: true },
    { key: 'name', type: 'string', stringify: true },
    { key: 'customer', type: Customer, stringify: true },
    { key: 'owner', type: User },
    { key: 'sharedUsers', type: 'array', of: User },
    { key: 'phases', type: 'array', of: ProjectPhase },
    { key: 'createdOn', type: Timestamp },
    { key: 'lastUpdated', type: Timestamp },
    { key: 'version', type: Version, stringify: true },
  ];

  get isEmpty() {
    return !this.phases?.length;
  }

  get isShared() {
    return this.sharedUsers && this.sharedUsers.length > 0;
  }
}
