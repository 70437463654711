import Button from 'views/components/Button';
import React from 'react';

const ExportBriefingButton = ({ phase, onExportBriefing }) => (
  <div className="phase-export" data-testid={'phase-export'}>
    <Button color={'lightGray'} isStretch={true} text={'Export Briefing Materials'} isDisabled={!phase.exportable} onClick={onExportBriefing} />
  </div>
);

export default ExportBriefingButton;
