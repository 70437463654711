const getHeatmapPoints = (metros, marketplaceSegment) => {
  if (marketplaceSegment.name === 'All Participants') {
    return metros.map((metro) => {
      const allCount = metro.marketplace.buyers.reduce((sum, { count }) => sum + count, 0);

      return [metro.coordinates.latitude, metro.coordinates.longitude, allCount];
    });
  }

  const result = [];
  metros.forEach((metro) =>
    metro.marketplace?.buyers.forEach((density) => {
      if (density.name === marketplaceSegment.name) result.push([metro.coordinates.latitude, metro.coordinates.longitude, density.count]);
      density.subSegments.forEach((subSegment) => {
        if (subSegment.name === marketplaceSegment.name) result.push([metro.coordinates.latitude, metro.coordinates.longitude, subSegment.count]);
      });
    })
  );
  return result;
};

export default getHeatmapPoints;
