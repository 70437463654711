import { useEffect } from 'react';

const useKeyUp = (key, func) => {
  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.key === key) {
        func();
      }
    };
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
    //eslint-disable-next-line
  }, []);
};

export default useKeyUp;
