import Config from 'Config';
import getAuthenticationService from 'service/AuthenticationService';

const updateSnapshot = async (templateId, projectId, phaseId, mapConfig) => {
  const { connectionType, metroType, marketplaceSegment, mapType, ringLatency, ringColor, layers } = mapConfig;
  const query = {
    bounds: '65,-160,-50,160',
    mini: true,
    size: '285,140',
    connectionType,
    metroType,
    marketplaceSegment: encodeURIComponent(JSON.stringify(marketplaceSegment)),
    mapType,
    ...(layers.latencyRings && {
      rl: ringLatency,
      rc: ringColor,
    }),
    options: [
      layers.activeMetros ? 'a' : '',
      layers.inactiveMetros ? 'i' : '',
      layers.customerLocations ? 'o' : '',
      layers.services ? 's' : '',
      layers.activeRegions ? 'R' : '',
      layers.inactiveRegions ? 'r' : '',
      layers.latencies ? 'l' : '',
      layers.serviceLatencies ? 'L' : '',
      layers.connections ? 'c' : '',
      layers.directConnections ? 'd' : '',
      layers.labels ? 'C' : '',
      layers.latencyRings ? 'f' : '',
      layers.customerLocationLines ? 'y' : '',
    ].join(''),
  };
  const queryStr = Object.keys(query)
    .map((k) => `${k}=${query[k]}`)
    .join('&');
  const url = `${Config.REPORTS_URL}updateSnapshot/${templateId}/${projectId}/${phaseId}?${queryStr}`;

  const authenticationService = getAuthenticationService();
  const token = authenticationService.getToken();

  await fetch(`${Config.REPORTS_URL}authorize`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Authorization: `${token.value}`,
    },
  });

  await fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `${token.value}`,
    },
  });
};

export default updateSnapshot;
