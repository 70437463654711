import React, { useState } from 'react';
import './PhasesHeader.scss';
//import MultiplePhasesConfirmationBox from './MultiplePhasesConfirmationBox';
import AddPhaseButton from './AddPhaseButton';
//import AllowMultipleSection from './AllowMultipleSection';
import DraggablePhases from './DraggablePhases';
import { useCurrentProject } from 'providers/ProjectProvider';
import TemplateModal from 'views/ProjectListView/modals/TemplateModal';
import { Mutations } from 'gql';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'utils/Toast';
import { useHistory } from 'react-router';
import { LoggerFactory } from 'logger';
import { TemplateEvents } from 'analytics';

const PhasesHeader = ({ phases = [], onChangePhase, onReorderPhases, onOpenPhasesModals }) => {
  const history = useHistory();
  const logger = LoggerFactory.getLogger('Template Modal');
  const [createTemplate, { loading }] = useMutation(Mutations.CREATE_TEMPLATE_FROM_PROJECT);
  const { isEditing, isTemplate, projectId, processLoading } = useCurrentProject();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  //  const [allowMultiple, setAllowMultiple] = useState(false);

  //  const toggleAllowMultiple = () => setAllowMultiple((prev) => !prev);

  const OpenCreateTemplateModal = () => {
    setIsCreateModalOpen(true);
  };

  const CloseCreateTemplateModal = () => {
    setIsCreateModalOpen(false);
  };

  const onCreateTemplate = async (templateName, categoriesFormatted) => {
    logger.debug(`Creating new template with name: '${templateName}'`);
    try {
      const { data } = await createTemplate({
        variables: { projectId: projectId, name: templateName, categories: categoriesFormatted },
      });
      TemplateEvents.templateCreated(data.createTemplateFromProject.id);
      history.push({
        pathname: `/templates/${data.createTemplateFromProject.id}`,
        state: { template: data.createTemplateFromProject, edit: true },
      });
    } catch (err) {
      logger.error(`An unexpected error occurred creating template: '${templateName}'`);
      return toast('An unexpected error occurred creating template', 'error');
    }
  };

  return (
    <div className={'phases-header'} data-testid={'phases-header'}>
      <div className={'phases-tabs'}>
        <DraggablePhases
          phases={phases}
          onChangePhase={onChangePhase}
          onReorderPhases={onReorderPhases}
          onOpenPhasesModals={onOpenPhasesModals}
          isEditing={isEditing}
          processLoading={processLoading}
        />
        <div className={'add-phase'}>
          {isEditing && (
            <AddPhaseButton phases={phases} onOpenPhasesModals={onOpenPhasesModals} onChangePhase={onChangePhase} recommendMetrosLoading={processLoading} />
          )}
        </div>
      </div>
      {isTemplate ? (
        isEditing && <div className={'editing-text'}>You are editing this template</div>
      ) : (
        <div className={'editing-text clickable'} onClick={OpenCreateTemplateModal} data-testid={'save-as-template'}>
          Save as a Template
        </div>
      )}

      <TemplateModal isOpen={isCreateModalOpen} onClose={CloseCreateTemplateModal} onSave={onCreateTemplate} loading={loading} />

      {/*<AllowMultipleSection checked={allowMultiple} toggle={toggleAllowMultiple} />
        //TODO: Add this for multiple actions feature
      {allowMultiple && <MultiplePhasesConfirmationBox turnOff={toggleAllowMultiple} />}*/}
    </div>
  );
};

export default PhasesHeader;
