import React from 'react';

const RefreshIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="9" cy="9" r="8.5" stroke={color} />
      <path
        d="M13.084 6.66732L10.7507 9.00065H12.5007C12.5007 10.9315 10.9315 12.5007 9.00065 12.5007C8.41148 12.5007 7.85148 12.3548 7.36732 12.0923L6.51565 12.944C7.23315 13.399 8.08482 13.6673 9.00065 13.6673C11.579 13.6673 13.6673 11.579 13.6673 9.00065H15.4173L13.084 6.66732ZM5.50065 9.00065C5.50065 7.06982 7.06982 5.50065 9.00065 5.50065C9.58982 5.50065 10.1498 5.64648 10.634 5.90898L11.4857 5.05732C10.7682 4.60232 9.91648 4.33398 9.00065 4.33398C6.42232 4.33398 4.33398 6.42232 4.33398 9.00065H2.58398L4.91732 11.334L7.25065 9.00065H5.50065Z"
        fill={color}
      />
    </svg>
  );
};

export default RefreshIcon;
