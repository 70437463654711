import React from 'react';
import './ASPInfo.scss';

const ASPInfo = ({ phase }) => {
  const { accountStrategyPlans: ASPs } = phase;
  return (
    <div className={'asp-info-wrapper'}>
      <h1 className={'title'}>Imported DSPs</h1>
      <div className={'companies'}>
        {ASPs.map((asp, index, array) => (
          <div className={`company-wrapper ${index === array.length - 1 ? 'last' : ''}`} key={`company-${asp.company}-${index}`}>
            <h2 className={'company-name'}>{asp.company}</h2>
            <div className={'info-section'}>
              <dt>Segment</dt>
              <dd>{asp.segment?.name}</dd>
            </div>
            <div className={'info-section separator'}>
              <dt>Sub-Segment</dt>
              <dd>{asp.subSegment?.name}</dd>
            </div>
            <div className={'info-section separator'}>
              <dt>Worldwide Employees</dt>
              <dd>{asp.employees?.toLocaleString()}</dd>
            </div>
            <div className={'it-spend'}>
              <h3>IT Spend</h3>
              {asp.itSpends?.map(({ region, percentage }) => (
                <div className={'info-section'} key={`it-spends-${region}-${asp.company}`}>
                  <dt>{region}</dt>
                  <dd>{percentage}</dd>
                </div>
              ))}
            </div>
            {/*                         <h3>Digital Adoption Profiles</h3>
                        <div className={'tag-wrapper'}>
                            {!!asp.adoptionProfiles?.length ? (
                                asp.adoptionProfiles.map((profile) => (
                                    <span className={'tag'}>{profile}</span>
                                ))
                            ) : (
                                <p>No identified digital adoption profiles.</p>
                            )}
                        </div>
                        <h3>Digital Adoption Attributes</h3>
                        <div className={'tag-wrapper'}>
                            {!!asp.adoptionAttributes?.length ? (
                                asp.adoptionAttributes.map((attr) => (
                                    <span className={'tag'}>{attr}</span>
                                ))
                            ) : (
                                <p>
                                    No identified digital adoption attributes.
                                </p>
                            )}
                        </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ASPInfo;
