import useAddEdgeDeviceToMetro from 'views/components/hooks/useAddEdgeDeviceToMetro';
import useRemoveEdgeDeviceToMetro from 'views/components/hooks/useRemoveEdgeDeviceFromMetro';

const useEdgeDeviceMarker = () => {
  const { addEdgeDeviceToMetro, loadingAddEdgeDevice } = useAddEdgeDeviceToMetro();
  const { removeEdgeDeviceFromMetro, loadingRemoveEdgeDevice } = useRemoveEdgeDeviceToMetro();

  const onEnableEdgeDevice = async (projectId, phaseId, metroCode, id) => {
    await addEdgeDeviceToMetro(projectId, phaseId, metroCode, id);
  };
  const onDisableEdgeDevice = async (projectId, phaseId, metroCode, id) => {
    await removeEdgeDeviceFromMetro(projectId, phaseId, metroCode, id);
  };

  return {
    onEnable: onEnableEdgeDevice,
    onDisable: onDisableEdgeDevice,
    loading: loadingAddEdgeDevice || loadingRemoveEdgeDevice,
  };
};

export default useEdgeDeviceMarker;
