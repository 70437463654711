import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import ConfirmationModal from 'views/ProjectListView/modals/ConfirmationModal';
import { Mutations } from 'gql';
import { useMutation } from '@apollo/react-hooks';
import { dismissAllToasts, toast } from 'utils/Toast';
import defaultStyles from './defaultStyles';

const RenamePhaseModal = ({ isOpen, onClose, phaseModalData: { projectId, phaseId, phaseName } }) => {
  const [execUpdateProjectPhase, { data, loading, error }] = useMutation(Mutations.UPDATE_PROJECT_PHASE);
  const [inputValue, setInputValue] = useState('');

  const onValueChange = (value) => {
    setInputValue(value);
  };

  const inputProps = {
    placeholder: 'New name',
    className: 'textInput',
    onValueChange,
  };

  useEffect(() => {
    setInputValue(phaseName);
  }, [phaseName]);

  const _onClose = () => {
    setInputValue(null);
    onClose();
  };

  const doRenamePhase = async () => {
    const newName = inputValue;
    if (!newName?.length) return;
    try {
      await execUpdateProjectPhase({ variables: { projectId, phaseId, name: newName } });
      dismissAllToasts();
    } catch (error) {
      return toast('An unexpected error occurred renaming the phase', 'error');
    }
  };

  useEffect(() => {
    if (data && !error) _onClose();
    // eslint-disable-next-line
  }, [data, error]);

  const buttonDisabled = !inputValue?.trim().length;

  return (
    <Modal isOpen={isOpen} className={'modal-window confirmation-modal'} onRequestClose={_onClose} defaultStyles={defaultStyles}>
      <ConfirmationModal
        trailingText={'Are you sure you would like to rename this phase?'}
        inputValue={inputValue}
        inputProps={inputProps}
        withInput
        onClose={_onClose}
        onConfirm={doRenamePhase}
        loading={loading}
        buttonDisabled={buttonDisabled}
        title={'Rename this phase'}
        buttonText={'Save'}
        testId={'rename-phase-modal'}
      />
    </Modal>
  );
};

export default RenamePhaseModal;
