import '../AddView.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Metro from 'model/Metro';
import ProjectPhaseId from 'model/ProjectPhaseId';
import TextInput from 'views/components/TextInput';
import Button from 'views/components/Button';
import MetrosTable from './MetrosTable';

const AddMetroView = ({ phaseId, projectId, metros = [], onClose, activeMetros }) => {
  const [searchText, setSearchText] = useState('');

  const _onSearchChange = (newValue) => setSearchText(newValue);

  const filteredMetros = metros.filter((m) => {
    return m.code.toLowerCase() === searchText.toLowerCase() || m.name.toLowerCase().startsWith(searchText.toLowerCase());
  });
  return (
    <div className="AddView">
      <h2>Add New Metros</h2>

      <div className="where">
        <p>
          Choose from the list of available Equinix Metros to add one to this phase. Alternatively, you can enable any Metro by clicking on its point on the
          map.
        </p>
        <h3>Where would you like to add a metro?</h3>

        <TextInput placeholder="Type to search Equinix Metros..." className="textInput" value={searchText} onValueChange={_onSearchChange} />
        <MetrosTable metros={filteredMetros} activeMetros={activeMetros} phaseId={phaseId} projectId={projectId} />
      </div>
      <div className="buttons">
        <Button color="red" text={'Close'} onClick={onClose} />
      </div>
    </div>
  );
};

AddMetroView.propTypes = {
  phaseId: PropTypes.instanceOf(ProjectPhaseId).isRequired,
  projectId: PropTypes.string.isRequired,
  metros: PropTypes.arrayOf(PropTypes.instanceOf(Metro)).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddMetroView;
