import { useCurrentProject } from 'providers/ProjectProvider';
import React from 'react';
import GroupItemsList from './GroupItemList';
import LocationItemList from './LocationItemList';
import ServiceItemList from './ServiceItemList';
import useOnCheckedListItem from './useOnCheckListItem';
import useOnDropOnGroup from './useOnDropOnGroup';

const RenderList = ({ groups, locations, services, metroId, phase, selectedItem, onSelect, onClose }) => {
  const onCheck = useOnCheckedListItem(phase);
  const onDropOnGroup = useOnDropOnGroup(onClose);
  const { isEditing } = useCurrentProject();

  return (
    <React.Fragment>
      <GroupItemsList
        groups={groups}
        metroId={metroId}
        onCheck={onCheck}
        onDropOnGroup={onDropOnGroup}
        selectedItem={selectedItem}
        onSelect={onSelect}
        isEditing={isEditing}
      />
      <LocationItemList
        locations={locations}
        metroId={metroId}
        onCheck={onCheck}
        onDropOnGroup={onDropOnGroup}
        selectedItem={selectedItem}
        onSelect={onSelect}
        onClose={onClose}
        isEditing={isEditing}
      />
      <ServiceItemList
        services={services}
        metroId={metroId}
        onCheck={onCheck}
        selectedItem={selectedItem}
        onSelect={onSelect}
        onClose={onClose}
        isEditing={isEditing}
      />
    </React.Fragment>
  );
};

export default RenderList;
