import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';

const useAddEdgeDeviceToMetro = () => {
  const [doAddEdgeDevice, { data, loading, error }] = useMutation(Mutations.ADD_NETWORK_EDGE_DEVICE_TO_METRO);

  const addEdgeDeviceToMetro = (projectId, phaseId, metroCode, deviceId = null) => doAddEdgeDevice({ variables: { projectId, phaseId, metroCode, deviceId } });

  return {
    addEdgeDeviceToMetro,
    dataAddEdgeDevice: data,
    loadingAddEdgeDevice: loading,
    errorAddEdgeDevice: error,
  };
};

export default useAddEdgeDeviceToMetro;
