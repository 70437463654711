import React, { useState } from 'react';
import { Marker, Pane, withLeaflet } from 'react-leaflet';
import InfraComponentsCard from './InfraComponentsCard';
import './InfraComponentsMarker.scss';
import L from 'leaflet';
import * as ReactDOMServer from 'react-dom/server';
import Point from 'model/Point';

const InfraComponentsMarker = ({ position, iconSize = 1, infraComponents, leaflet, updateDeploymentOffset, deploymentOffsets, updateOffsetLoading }) => {
  const savedOffset = deploymentOffsets.find((infraComp) => infraComp.deployment === 'COLOCATION')?.offset || { x: 0, y: 0 };
  const [positionState, setPositionState] = useState({ x: savedOffset.x, y: savedOffset.y });

  const constants = {
    ANCHOR: -5 * iconSize,
    OFFSET_X: -15,
    OFFSET_Y: -15,
    MARGIN: 4 * iconSize,
    SIZE_X: (50 / 2) * (iconSize / 2),
    SIZE_Y: (20 / 2) * (iconSize + 3),
  };
  const zoom = leaflet?.map.getZoom();
  const OFFSET_X = (positionState.x + constants.OFFSET_X) * iconSize * zoom;
  const OFFSET_Y = (positionState.y + constants.OFFSET_Y) * iconSize * zoom;

  const handleDragStart = () => {
    if (line) {
      line.leafletElement.setOpacity(0);
    }
  };
  const handleDragEnd = (e) => {
    const zoom = leaflet.map.getZoom();
    const startPoint = e.target._map.project(position);
    const endPoint = e.target._map.project(e.target.getLatLng());
    let offsetX = positionState.x;
    let offsetY = positionState.y;
    offsetX += Math.floor((startPoint.x - endPoint.x) / iconSize / zoom);
    offsetY += Math.floor((startPoint.y - endPoint.y) / iconSize / zoom);
    e.target.setLatLng(position);
    if (line) {
      line.leafletElement.setOpacity(1);
    }
    setPositionState({ x: offsetX, y: offsetY });
    updateDeploymentOffset('COLOCATION', new Point(offsetX, offsetY));
  };

  const sortedKeys = Object.keys(infraComponents).sort();
  const sortedDeployments = {};
  sortedKeys.forEach((deployment) => {
    sortedDeployments[deployment] = infraComponents[deployment];
  });

  let line = (ox, oy, iconSize) => {
    let h, w, x1, y1, x2, y2, ax, ay;

    if (ox < 0) {
      w = -ox;
      x1 = iconSize;
      x2 = -ox;
      ax = -x1;
    } else {
      w = ox + iconSize;
      x1 = ox;
      x2 = iconSize;
      ax = x2 + w;
    }
    if (oy < 0) {
      h = -oy;
      y1 = iconSize;
      y2 = -oy;
      ay = -y1;
    } else {
      h = oy + iconSize;
      y1 = oy;
      y2 = iconSize;
      ay = y2 + h;
    }

    const svg = `
    <svg width="${w}" height="${h}" viewBox="0 0 ${w} ${h}" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter_serviceLine)">
        <line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke="red" stroke-width="${2 * iconSize}" stroke-dasharray="10,5"/>
    </g>
    <defs>
    <filter id="filter_serviceLine" x="0" y="0" width="${w}" height="${h}" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
</defs>
</svg>
        `;
    const sanitizedSvg = svg.replace(/\n/g, '').replace(/#/g, '%23');
    return L.icon({
      iconUrl: `data:image/svg+xml;utf8,${sanitizedSvg}`,
      iconAnchor: L.point(ax, ay),
    });
  };

  const icon = L.divIcon({
    html: ReactDOMServer.renderToString(<InfraComponentsCard iconSize={iconSize} infraComponent={sortedDeployments} />),
    iconSize: L.point(0, 0),
    iconAnchor: L.point(OFFSET_X, OFFSET_Y),
    className: '',
  });

  return (
    <>
      <Pane style={{ zIndex: 1100 }} className="InfraComponent-marker">
        <Marker position={position} icon={icon} draggable={!updateOffsetLoading} onDragStart={handleDragStart} onDragEnd={handleDragEnd} />
      </Pane>
      <Pane style={{ zIndex: 400 }}>
        <Marker
          ref={(r) => (line = r)}
          key="line"
          position={position}
          icon={line(OFFSET_X - constants.SIZE_X / 2, OFFSET_Y - constants.SIZE_Y / 2, iconSize)}
          interactive={false}
        />
      </Pane>
    </>
  );
};

export default withLeaflet(InfraComponentsMarker);
