import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Mutations, Queries } from 'gql';
import Modal from 'react-modal';
import defaultStyles from './defaultStyles';
import TextInput from 'views/components/TextInput';
import Button from 'views/components/Button';
import Checkbox from 'views/components/Checkbox';
import { LoggerFactory } from 'logger';
import { toast } from 'utils/Toast';

const RestoreCompanyModal = ({ isOpen, onClose, company }) => {
  const logger = LoggerFactory.getLogger('RestoreCompany');

  const [restoreCompany, { loading, error }] = useMutation(Mutations.RESTORE_COMPANY);
  const [companyName, setCompanyName] = useState('');
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (error) {
      logger.error(`An error occurred executing mutation: ${error.message}`);
      toast('An unexpected error occurred restoring company', 'error');
    }
    // eslint-disable-next-line
  }, [error]);

  const _onClose = () => {
    onClose();
    setCompanyName('');
    setChecked(false);
  };

  const onRestore = async () => {
    await restoreCompany({ variables: { companyId: company.id, sendEmail: checked }, refetchQueries: [{ query: Queries.GET_COMPANIES }] });
    _onClose();
  };

  const renderProgress = () => {
    return (
      <div className="modal-submit">
        <div className="loading"></div>
      </div>
    );
  };

  const isDisabled = () => companyName.toLowerCase() !== company?.name?.toLowerCase();

  const renderButtons = () => {
    return (
      <div className="modal-submit">
        <Button text="Cancel" color="red" onClick={_onClose} isInverse={true} />
        <Button text="Yes, Restore" color="red" onClick={onRestore} isDisabled={isDisabled()} data-testId={'restore-company-modal'} />
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} className="modal-window" onRequestClose={_onClose} contentLabel="Restore Company" defaultStyles={defaultStyles}>
      <div>
        <div className="modal-header">
          <h3>Restore {company.name}?</h3>
          <button className="close-modal" onClick={_onClose}>
            <svg height="12" width="12">
              <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
            </svg>
          </button>
        </div>
        <div className={'modal-fields modal-fields--full modal-text'}>
          <p>
            This will restore the entire company account, including all users, projects, and settings. All users in this company will immediately be able to log
            back in to their accounts.{' '}
          </p>
        </div>
        <div className="horizontal-line" />
        <div className="modal-fields modal-fields--full">
          <label>Confirm Restore</label>
          <TextInput
            value={companyName}
            placeholder="Type company name to confirm restore*"
            className="textInput"
            onValueChange={(newValue) => {
              setCompanyName(newValue);
            }}
          />
        </div>
        <div className="modal-fields modal-fields--full modal-fields--checkbox">
          <Checkbox type="checkbox" checked={checked} onCheck={() => setChecked(!checked)} /> <p>Notify all company users by email</p>
        </div>
        {loading ? renderProgress() : renderButtons()}
      </div>
    </Modal>
  );
};

export default RestoreCompanyModal;
