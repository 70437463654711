import Button from 'views/components/Button';
import classnames from 'classnames';
import debounce from 'debounce';
import ProjectPhaseId from 'model/ProjectPhaseId';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { dismissAllToasts } from 'utils/Toast';
import TextInput from 'views/components/TextInput';
import '../AddView.scss';
import './EditGroupView.scss';
import { Mutations } from 'gql';
import { useMutation } from '@apollo/react-hooks';
import LocationsTable from './LocationsTable';

export const COLORS = ['#ED1C24', '#F58E43', '#F6D048', '#44971D', '#3FD1DA', '#4F98ED', '#8E53EF', '#757379'];

const EditGroupView = ({ phaseId, projectId, selectedItem, onClose, loading, onChangeZoom }) => {
  const defaultGroup = {
    groupId: selectedItem?.id || '',
    groupName: selectedItem?.name || '',
    groupColor: selectedItem?.color || '',
    groupLocations: selectedItem?.locations || [],
  };

  const [deleteLocationGroup] = useMutation(Mutations.DELETE_PROJECT_ITEMS);
  const [updateLocationGroup] = useMutation(Mutations.UPDATE_LOCATION_GROUP);
  const [ungroupAndDeleteLocationGroup] = useMutation(Mutations.UNGROUP_AND_DELETE_LOCATION_GROUP);

  const [group, setGroup] = useState(defaultGroup);
  const [isLocationsTableLoading, setIsLocationsTableLoading] = useState(false);

  const scrollElementRef = useRef();

  useEffect(() => {
    if (!selectedItem) {
      setGroup(defaultGroup);
    }

    //if this is a different group, clear the state
    else if (!selectedItem?.id.equals(group.groupId)) {
      setGroup(defaultGroup);
    }

    //otherwise, favor local state over the passed in group props (for editing)
    setGroup((prevState) => {
      return { ...prevState, groupLocations: selectedItem?.locations || [] };
    });
    // eslint-disable-next-line
  }, [selectedItem]);

  const debounceUpdateGroup = useCallback(
    debounce((updates) => updateGroup(updates), 300),
    [group.groupId]
  );

  const updateGroup = (updates) => {
    const { projectId, phaseId, groupId: locationGroupId, groupName: name, groupColor: color } = updates;
    updateLocationGroup({
      variables: { projectId, phaseId, locationGroupId, name, color },
    });
  };

  const deleteGroup = async () => {
    // It's possible for the props.selectedItem to be null when
    // changing to a new selected item, so need this null check.
    if (!selectedItem) return;

    await deleteLocationGroup({
      variables: {
        projectId,
        phaseId,
        locationIds: [],
        locationGroupIds: [selectedItem.id],
        serviceConnectionIds: [],
      },
    });
    dismissAllToasts();
    onClose();
  };

  const onSplit = async () => {
    await ungroupAndDeleteLocationGroup({
      variables: {
        projectId,
        phaseId,
        locationGroupId: selectedItem.id,
      },
    });
    dismissAllToasts();
    onClose();
  };

  const onChange = (key) => (newValue) => {
    setGroup((prevState) => {
      return { ...prevState, [key]: newValue };
    });
    const changes = { [key]: newValue };
    const group = { groupId: selectedItem.id, groupName: selectedItem.name, groupColor: selectedItem.color };
    const updates = {
      projectId,
      phaseId,
      ...group,
      ...changes,
    };
    debounceUpdateGroup(updates);
  };

  const onLocationTableLoadingStateChange = (isLoading) => {
    setIsLocationsTableLoading(isLoading);
  };

  const renderProgress = () => {
    return (
      <div className="buttons">
        <div className="loading"></div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="buttons">
        <Button color="red" text="Delete Group" onClick={deleteGroup} isStretch={true} />
      </div>
    );
  };

  // Check whether this component's server requests are loading, or if the
  // LocationsTable's server requests are loading.
  const isLoading = loading || isLocationsTableLoading;

  const onScroll = () => {
    document.querySelectorAll('.szh-menu').forEach((e) => e.classList.replace('szh-menu--state-open', 'szh-menu--state-closed'));
  };

  const onSelect = (location) => {
    const selected = selectedItem.locations.filter((loc) => loc.id.value === location.id.value)[0];
    onChangeZoom(selected);
  };

  return (
    <div className="AddView EditGroupView" ref={scrollElementRef} onScroll={onScroll}>
      <TextInput
        placeholder="Enter a Label..."
        value={group.groupName}
        onValueChange={(newValue) => {
          onChange('groupName')(newValue);
        }}
      />

      <div className="text">
        <p>
          Groups are clusters of sites organized around a common region, campus, or function. They will function as one object in the phase hierarchy, while
          plotting multiple addresses on the map.
        </p>
      </div>

      <div className="color">
        <h3>Choose a Marker Color</h3>
        <div className="list">
          {COLORS.map((c) => (
            <div
              data-testid={'color-option'}
              key={c}
              className={classnames('item', { selected: group.groupColor === c })}
              style={{ backgroundColor: c }}
              onClick={() => {
                onChange('groupColor')(c);
              }}
            />
          ))}
        </div>
      </div>

      <div className="locations-table">
        <div className="header">
          <h3>Customer Sites ({group.groupLocations.length})</h3>
          <button onClick={onSplit} data-testid={'split-button'}>
            Split into Multiple Objects
            {group.groupLocations.length > 50 && <span className="warning">!</span>}
            {group.groupLocations.length > 50 && <span className="tooltip">This operation may cause performance issues</span>}
          </button>
        </div>
        <LocationsTable
          onLoadingStateChange={onLocationTableLoadingStateChange}
          scrollElementRef={scrollElementRef}
          projectId={projectId}
          phaseId={phaseId}
          group={selectedItem}
          onSelect={onSelect}
        />
      </div>

      {isLoading ? renderProgress() : renderButtons()}
    </div>
  );
};

EditGroupView.propTypes = {
  phaseId: PropTypes.instanceOf(ProjectPhaseId).isRequired,
  projectId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
export default EditGroupView;
