import React from 'react';

const PointsMenuIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clipPath="url(#clip0_3365_4773)">
        <circle cx="6.5" cy="11.75" r="1.5" transform="rotate(-90 6.5 11.75)" fill={color} />
        <circle cx="6.5" cy="6.5" r="1.5" transform="rotate(-90 6.5 6.5)" fill={color} />
        <circle cx="6.5" cy="1.25" r="1.5" transform="rotate(-90 6.5 1.25)" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_3365_4773">
          <rect width={width} height={height} fill="white" transform="translate(0 13) rotate(-90)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PointsMenuIcon;
