import React, { useState } from 'react';
import Select from 'react-select';
import 'react-notion/src/styles.css';
import cn from 'classnames';
import './TeamView.scss';
import DropdownStyles from 'views/components/DropdownStyles';
import { getTheme } from 'utils/SelectTheme';
import TrashIcon from 'views/components/icons/Trash';
import { getUserRoleLabel, MENU_OPTIONS, USER_ROLES } from '../../../model/userRoles';
import Modal from 'react-modal';
import ConfirmationModal from '../modals/ConfirmationModal';
import defaultStyles from '../modals/defaultStyles';

const TeamListItem = ({ member, onDelete, onUpdate, user }) => {
  const options = user.role !== USER_ROLES.teamAdmin.value ? MENU_OPTIONS : [MENU_OPTIONS[1], MENU_OPTIONS[2]];
  const [modalOpen, setModalOpen] = useState(false);
  const [newValue, setNewValue] = useState();

  const updateRole = (e) => {
    if (user.id.value === member.id) {
      setNewValue(e?.value);
      setModalOpen(true);
    } else {
      onUpdate(member, e?.value);
    }
  };

  const calculateElapsedTime = (lastLogInDate) => {
    if (lastLogInDate === 0) {
      return 'Never';
    }

    const currentDate = new Date();
    const timeDifference = currentDate - lastLogInDate;
    const oneDay = 1000 * 60 * 60 * 24;
    const oneMonth = oneDay * 30.44;

    if (timeDifference < oneDay) {
      return 'Today';
    } else if (timeDifference < oneMonth) {
      const daysAgo = Math.floor(timeDifference / oneDay);
      return `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`;
    } else {
      const monthsAgo = Math.floor(timeDifference / oneMonth);
      return `${monthsAgo} month${monthsAgo === 1 ? '' : 's'} ago`;
    }
  };

  const _onUpdate = () => {
    onUpdate(member, newValue);
    setModalOpen(false);
  };

  return (
    <li className={cn('projects-list_item team', { 'current-user': user.id.value === member.id })}>
      <div className={cn('project-meta')}>
        <span className="customer-name">{member.email}</span>
      </div>
      <div className={'project-controls options-menu-open'}>
        <div className="information-wrapper">
          <p className={'gray-information'}>{calculateElapsedTime(member.lastLoginDate)}</p>
        </div>
        {user.role < USER_ROLES.teamMember.value && member.role > USER_ROLES.workbenchAdmin.value ? (
          <div className="select-wrapper">
            <Select
              isDisabled={member.role === USER_ROLES.companyOwner.value && user.role === USER_ROLES.teamAdmin.value}
              classNamePrefix="user"
              onChange={updateRole}
              options={options}
              isSearchable={false}
              theme={getTheme}
              menuPosition={'fixed'}
              value={MENU_OPTIONS.find((option) => option.value === member.role)}
              styles={DropdownStyles}
              data-testid={'roles-select'}
            />
          </div>
        ) : (
          <span className="role-information" data-testid={'role-label'}>
            {getUserRoleLabel(member.role)}
          </span>
        )}
        <div className="trash">
          {(user.role < USER_ROLES.teamMember.value || member.email === user.email) &&
            !(user.role === USER_ROLES.teamAdmin.value && member.role === USER_ROLES.companyOwner.value) && (
              <TrashIcon
                onClick={() => {
                  onDelete();
                }}
                width={15}
                height={15}
                data-testid={'delete-button'}
              />
            )}
        </div>
      </div>
      <Modal isOpen={modalOpen} className={'modal-window confirmation-modal'} onRequestClose={() => setModalOpen(false)} defaultStyles={defaultStyles}>
        <ConfirmationModal
          onClose={() => setModalOpen(false)}
          onConfirm={_onUpdate}
          title={'User role downgrade warning'}
          trailingText={'description'}
          buttonText={'Confirm'}
        />
      </Modal>
    </li>
  );
};

export default TeamListItem;
