import { Mutations } from 'gql';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useMutation } from 'react-apollo';
import { dismissAllToasts, toast } from 'utils/Toast';
import 'views/components/InfoPanel/children/TableStyles.scss';
import TextAndToggleTable from 'views/components/InfoPanel/children/TextAndToggleTable';
import ConfirmationModal from 'views/ProjectListView/modals/ConfirmationModal';
import defaultStyles from 'views/ProjectDetailView/modals/defaultStyles';

const EdgesWrapper = ({ networkEdgeCategories, projectId, phaseId, metroCode }) => {
  const [deprecatedEdge, setDeprecatedEdge] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [enableEdgeCategory, { loading: loadingEnableEdge, error: errorEnableEdge }] = useMutation(Mutations.ENABLE_NETWORK_EDGE);
  const [disableEdgeCategory, { loading: loadingDisableEdge, error: errorDisableEdge }] = useMutation(Mutations.DISABLE_NETWORK_EDGE);

  errorEnableEdge && toast("Couldn't enable Network Edge Category", 'error');
  errorDisableEdge && toast("Couldn't disable Network Edge Category", 'error');

  const onEnableEdgeCategory = async (category) => {
    await enableEdgeCategory({ variables: { projectId, phaseId, metroCode, categoryId: category.id } });
    dismissAllToasts();
  };
  const onDisableEdgeCategory = async (category) => {
    if (category.deprecated) {
      setDeprecatedEdge(category);
      setOpenModal(true);
    } else {
      await disableEdgeCategory({ variables: { projectId, phaseId, metroCode, categoryId: category.id } });
      dismissAllToasts();
    }
  };

  const removeDeprecatedEdge = async () => {
    await disableEdgeCategory({ variables: { projectId, phaseId, metroCode, categoryId: deprecatedEdge.id } });
    setDeprecatedEdge(null);
    setOpenModal(false);
    dismissAllToasts();
  };

  const filteredEdges = networkEdgeCategories.filter(({ checked, deprecated }) => checked || !deprecated);

  return (
    <>
      <TextAndToggleTable
        items={filteredEdges}
        onEnable={onEnableEdgeCategory}
        onDisable={onDisableEdgeCategory}
        isLoading={loadingEnableEdge || loadingDisableEdge}
      />
      <Modal isOpen={openModal} className={'modal-window confirmation-modal'} onRequestClose={() => setOpenModal(false)} defaultStyles={defaultStyles}>
        <ConfirmationModal
          onClose={() => setOpenModal(false)}
          onConfirm={removeDeprecatedEdge}
          loading={loadingDisableEdge}
          title={'Disable network edge'}
          trailingText={
            'This network edge is no longer available. After removing it you will be unable to add it back to your project. Are you sure you want to remove it from your project?'
          }
          buttonText={'Remove Permanently'}
        />
      </Modal>
    </>
  );
};

export default EdgesWrapper;
