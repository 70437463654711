import { Mutations, withMutation } from 'gql';
import { useMutation } from 'react-apollo';

export const withDeleteService = withMutation({
  name: 'DELETE_SERVICE',
  mutation: Mutations.DELETE_PROJECT_ITEMS,
  mutateProp: 'deleteService',
  getMutationArgs(projectId, phaseId, serviceConnection) {
    return {
      variables: {
        projectId,
        phaseId,
        locationIds: [],
        locationGroupIds: [],
        serviceConnectionIds: [serviceConnection.id],
      },
    };
  },
});

export const useDeleteService = () => {
  const [doDeleteService] = useMutation(Mutations.DELETE_PROJECT_ITEMS);

  const deleteService = async (projectId, phaseId, serviceConnection) => {
    await doDeleteService({
      variables: {
        projectId,
        phaseId,
        locationIds: [],
        locationGroupIds: [],
        serviceConnectionIds: [serviceConnection.id],
      },
    });
  };
  return { deleteService };
};
