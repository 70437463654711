import Assert from './Assert';

export default class ArrayUtils {
  static indexOf(items, item) {
    Assert.isArray(items);

    //if the item is null, nothing to look for
    if (item == null) {
      return -1;
    }

    //if the object implements equals(), use that
    if (typeof item.equals === 'function') {
      return items.findIndex((a) => item.equals(a));
    }

    //otherwise, just do normal array.indexOf()
    return items.indexOf(item);
  }
}
