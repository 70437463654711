import React from 'react';
import { Mutation } from '@apollo/react-components';
import { Mutations } from 'gql';

const withLeaveProject = (WrappedComponent) => {
  return class WithLeaveProject extends React.Component {
    state = { data: undefined };
    constructor(props) {
      super(props);

      this.onCompleted = this.onCompleted.bind(this);
    }

    onCompleted(data) {
      this.setState({ data });
    }

    render() {
      return (
        <Mutation mutation={Mutations.UNSHARE_PROJECT} onCompleted={this.onCompleted}>
          {(leaveProject, { loading, error }) => {
            const leaveProjectObj = {
              mutation: leaveProject,
              data: this.state.data,
              loading,
              error,
            };
            return <WrappedComponent leaveProject={leaveProjectObj} {...this.props} />;
          }}
        </Mutation>
      );
    }
  };
};

export default withLeaveProject;
