import React from 'react';
import L from 'leaflet';

import { MarkerClusterGroup, MarkerCluster } from 'leaflet.markercluster';
import cn from 'classnames';

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import './Clusterer.scss';

import ContextMenu from './ContextMenu';
import withRemoveMetro from '../hocs/withRemoveMetro';
import withDeleteProjectItems from '../PhasePanel/hocs/withDeleteProjectItems';
import { icons } from './icons';

import { MapLayer, withLeaflet, LeafletProvider } from 'react-leaflet';
import ProductEvents from 'analytics/ProductEvents';
import { MetroStatus } from 'model/Metro';
import { dismissAllToasts } from 'utils/Toast';

class IOAMarkerClusterGroup extends MarkerClusterGroup {
  getCenter() {
    return this.getBounds().getCenter();
  }
}

class IOAMarkerCluster extends MarkerCluster {
  getCenter() {
    return this.getBounds().getCenter();
  }

  _recalculateBounds() {
    const markers = this._markers;
    const childClusters = this._childClusters;
    let latSum = 0;
    let lngSum = 0;
    let totalWt = 0;
    let i, child, childLatLng;

    // Case where all markers are removed from the map and we are left with just an empty _topClusterLevel.
    if (this._childCount === 0) {
      return;
    }

    // Reset rather than creating a new object, for performance.
    this._resetBounds();

    // Child markers.
    for (i = 0; i < markers.length; i++) {
      childLatLng = markers[i]._latlng;
      const childWt = markers[i].options.weight || 1;

      this._bounds.extend(childLatLng);

      totalWt += childWt;
      latSum += childLatLng.lat * childWt;
      lngSum += childLatLng.lng * childWt;
    }

    // Child clusters.
    for (i = 0; i < childClusters.length; i++) {
      child = childClusters[i];

      // Re-compute child bounds and weighted position first if necessary.
      if (child._boundsNeedUpdate) {
        child._recalculateBounds();
      }

      this._bounds.extend(child._bounds);

      childLatLng = child._wLatLng;
      const childWt = child._childWt;

      totalWt += childWt;
      latSum += childLatLng.lat * childWt;
      lngSum += childLatLng.lng * childWt;
    }

    this._latlng = this._wLatLng = new L.LatLng(latSum / totalWt, lngSum / totalWt);
    this._childWt = totalWt;

    // Reset dirty flag.
    this._boundsNeedUpdate = false;
  }
}

const ptToStyle = (p) => `width: ${p.x}px; height: ${p.y}px;`;
const ptToTopRight = (p) => `top: -${p.x / 2.5}px; right: -${p.y / 2.5}px`;
const ptToBottomLeft = (p) => `bottom: -${p.x / 2.5}px; left: -${p.y / 2.5}px`;

function findClusterForZoomLevel(cluster, zoom) {
  if (cluster) {
    if (cluster._zoom === zoom - 1) {
      return [...cluster._childClusters, ...cluster._markers];
    } else if (cluster._childClusters.length) {
      return cluster._childClusters.reduce((res, c) => res.concat(findClusterForZoomLevel(c, zoom)), cluster._markers);
    } else {
      return cluster._markers;
    }
  } else {
    return [];
  }
}

class Clusterer extends MapLayer {
  state = {};

  createLeafletElement(props) {
    const { leaflet, connectToPosition, customPaneZIndex } = props;
    let clusterPane;
    const getIconSize = (x, y, a = 0) => {
      const { iconSize } = this.props;
      const factor = a * iconSize;
      return L.point(x * iconSize + factor, y * iconSize + factor);
    };
    const sToLh = (s) => `line-height:${s * this.props.iconSize}px;`;
    if (customPaneZIndex) {
      clusterPane = leaflet.map.createPane('clusterPane');
      leaflet.map.getPane('clusterPane').style.zIndex = customPaneZIndex ?? 600;
    }
    const clusterGroup = new IOAMarkerClusterGroup({
      ...(clusterPane && { clusterPane }),
      spiderfyOnMaxZoom: true,
      zoomToBoundsOnClick: false,
      showCoverageOnHover: false,
      maxClusterRadius: () => 40 * this.props.iconSize,
      iconCreateFunction: (cluster) => {
        const children = cluster.getAllChildMarkers();
        const metroMarker = children.find((m) => m.options.icon.name === 'metroActive');
        if (metroMarker) {
          const oc = children.filter((m) => m.options.icon.name === 'office').length;
          const dc = children.filter((m) => m.options.icon.name === 'datacenter').length;
          // const os = getIconSize(15, 15, (oc - dc) / (oc + dc) * 7);
          // const ds = getIconSize(15, 15, (dc - oc) / (dc + oc) * 7);
          const os = getIconSize(20, 20);
          const ds = getIconSize(20, 20);

          const classNames = cn('marker', 'metro', {
            existing: this.props.metro.existing,
            underAcquisition: this.props.metro.status === MetroStatus.UNDER_ACQUISITION,
          });

          let html = `
                    <div class="${classNames}" style="${sToLh(33)}">
                        ${cluster.getChildCount() - 1}
                    </div>
                    `;
          if (oc > 0) {
            html += `
                        <div
                            class="office"
                            style="${ptToStyle(os)}${ptToTopRight(os)}"
                        >
                            ${icons.office}
                        </div>
                        `;
          }
          if (dc > 0) {
            html += `
                        <div
                            class="datacenter"
                            style="${ptToStyle(ds)}${ptToBottomLeft(ds)}"
                        >
                            ${icons.datacenter}
                        </div>
                        `;
          }

          return L.divIcon({
            html,
            iconSize: getIconSize(33, 33),
            className: 'cluster',
          });
        } else {
          return L.divIcon({
            html: `<div class="marker locations" style="${sToLh(25)}">
                            ${cluster.getChildCount()}
                        </div>`,
            iconSize: getIconSize(25, 25),
            className: 'cluster',
          });
        }
      },
    });
    clusterGroup._markerCluster = IOAMarkerCluster;
    clusterGroup.on('clusterclick', (a) => {
      // a.layer is actually a cluster
      const children = a.layer.getAllChildMarkers();
      const metroMarker = children.find((m) => m.options.icon.name === 'metroActive');
      if (metroMarker) {
        metroMarker.options.onClick();
      } else {
        a.layer.zoomToBounds({ padding: [20, 20] });
      }
    });
    clusterGroup.on('clustercontextmenu', (a) => {
      const childMarkers = a.layer.getAllChildMarkers();
      const metroMarker = childMarkers.find((m) => m.options.icon.name === 'metroActive');
      this.setState({ contextMenuPosition: a.latlng, contextMenuMetro: metroMarker, childMarkers });
    });

    if (connectToPosition) {
      leaflet.map.on('zoomend', () => this.recomputeLines());
      clusterGroup.on('layeradd', () => setTimeout(this.recomputeLines, 0));

      setTimeout(this.recomputeLines, 0);
    }
    this.contextValue = { ...leaflet, layerContainer: clusterGroup, popupContainer: clusterGroup };
    return clusterGroup;
  }
  recomputeLines = () => {
    const { leaflet, connectToPosition, hideCustomerLines } = this.props;

    if (!connectToPosition) return;

    if (this.linesGroup) {
      this.linesGroup.clearLayers();
      this.linesGroup.remove();
    }
    this.linesGroup = L.layerGroup();
    this.linesGroup.addTo(leaflet.layerContainer);

    const zoom = Math.round(leaflet.map._zoom);
    const visibleItems = findClusterForZoomLevel(this.leafletElement._topClusterLevel, zoom);

    if (!hideCustomerLines) {
      visibleItems.forEach((i) => {
        const line = L.polyline([i.getLatLng(), connectToPosition], {
          color: '#000000',
          dashArray: '3 3',
          weight: 1,
        });
        line.addTo(this.linesGroup);
      });
    }
  };

  updateLeafletElement = (fromProps, toProps) => {
    if (React.Children.count(fromProps.children) !== React.Children.count(toProps.children)) {
      this.recomputeLines();
    }
  };

  componentWillUnmount() {
    if (this.linesGroup) {
      this.linesGroup.clearLayers();
      this.linesGroup.remove();
    }
  }

  onRemoveMetro = async () => {
    const { removeMetro, projectId, phaseId } = this.props;
    const { contextMenuMetro } = this.state;
    this.setState({ contextMenuPosition: false });
    await removeMetro(projectId, phaseId, contextMenuMetro.options.metro.id);
    dismissAllToasts();
    ProductEvents.metroRemoved(contextMenuMetro.options.metro.name);
  };

  onRemoveLocations = async () => {
    const { deleteProjectItems, projectId, phaseId } = this.props;
    const { childMarkers } = this.state;
    this.setState({ contextMenuPosition: false });
    const locationIds = childMarkers
      .map((m) => m.options.location)
      .filter((l) => !!l)
      .map((l) => l.id);
    await deleteProjectItems(projectId, phaseId, locationIds);
    dismissAllToasts();
  };
  render() {
    const { children } = this.props;
    const { contextMenuPosition, contextMenuMetro } = this.state;

    const contextMenu = contextMenuPosition ? (
      <ContextMenu position={contextMenuPosition} onClose={() => this.setState({ contextMenuPosition: false })}>
        {contextMenuMetro && <button onClick={this.onRemoveMetro}>Remove Metro</button>}
        <button onClick={this.onRemoveLocations}>Delete all locations</button>
      </ContextMenu>
    ) : null;

    return this.contextValue == null ? (
      <React.Fragment>
        {children}
        {contextMenu}
      </React.Fragment>
    ) : (
      <LeafletProvider value={this.contextValue}>
        {children}
        {contextMenu}
      </LeafletProvider>
    );
  }
}

Clusterer = withLeaflet(Clusterer);
Clusterer = withDeleteProjectItems(Clusterer);
Clusterer = withRemoveMetro(Clusterer);

export default Clusterer;
