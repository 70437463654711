import FeatureEvents from 'analytics/FeatureEvents';
import Button from 'views/components/Button';
import { Mutations, Queries } from 'gql';
import ProjectPhaseId from 'model/ProjectPhaseId';
import Service from 'model/Service';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import Select from 'react-select';
import { getTheme } from 'utils/SelectTheme';
import { dismissAllToasts } from 'utils/Toast';
import '../AddView.scss';
import DropdownStyles from 'views/components/DropdownStyles';
import AccountStrategyPlan from 'model/AccountStrategyPlan';
import { LoggerFactory } from 'logger';
import { useMutation, useQuery } from '@apollo/react-hooks';

const ASPImportView = ({ projectId, phaseId, onClose, loading }) => {
  const { data, loading: accountStrategyPlansLoading } = useQuery(Queries.GET_ACCOUNT_STRATEGY_PLANS);
  const [addASP] = useMutation(Mutations.ADD_ACCOUNT_STRATEGY_PLAN);

  const logger = useMemo(() => LoggerFactory.getLogger('ASPImportView'), []);

  const [accountStrategyPlan, setAccountStrategyPlan] = useState();
  const [accountStrategyPlans, setAccountStrategyPlans] = useState();

  useEffect(() => {
    if (!data) return;
    setAccountStrategyPlans(AccountStrategyPlan.fromJSONArray(data.accountStrategyPlans));
  }, [data]);

  const onASPValueChange = (newValue) => setAccountStrategyPlan(newValue);

  const onImport = async () => {
    FeatureEvents.aspImported(phaseId);
    await addASP({ variables: { projectId, phaseId, aspId: accountStrategyPlan.value } });
    logger.debug(`DSP added: ${accountStrategyPlan.value}`);
    dismissAllToasts();
    onClose();
  };

  const renderProgress = () => {
    return (
      <div className="buttons">
        <div className="loading"></div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="buttons">
        <Button color="red" isInverse={true} onClick={onClose} text="Discard" />
        <Button color="red" isDisabled={!accountStrategyPlan} text="Import" onClick={onImport} data-testid={'import-button'} />
      </div>
    );
  };

  return (
    <div className="AddView" data-testid={'ASP-view'}>
      <h2>Import Sites via DSP</h2>

      <div className="where">
        <p>
          Choose a DSP dataset below to bring in a number of locations at once. These will be created and managed as individual locations in your phase
          hierarchy.
        </p>
        <h3>Choose DSP Dataset</h3>

        <Select
          placeholder="Search for a DSP..."
          options={(accountStrategyPlans || []).map((asp) => ({ value: asp.id.value, label: asp.company }))}
          value={accountStrategyPlan}
          isLoading={accountStrategyPlansLoading}
          onChange={onASPValueChange}
          theme={getTheme}
          styles={DropdownStyles}
          data-testid={'ASP-select'}
        />
      </div>

      {loading ? renderProgress() : renderButtons()}
    </div>
  );
};

ASPImportView.propTypes = {
  projectId: PropTypes.string.isRequired,
  phaseId: PropTypes.instanceOf(ProjectPhaseId).isRequired,
  services: PropTypes.arrayOf(PropTypes.instanceOf(Service)).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ASPImportView;
