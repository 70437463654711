import React from 'react';
import { Mutation } from '@apollo/react-components';
import { ProjectEvents } from 'analytics';
import { Mutations } from 'gql';

const withDeleteProject = (WrappedComponent) => {
  return class WithDeleteProject extends React.Component {
    state = {
      data: undefined,
    };
    constructor(props) {
      super(props);

      this.onCompleted = this.onCompleted.bind(this);
    }

    onCompleted(data) {
      this.setState({ data });
      ProjectEvents.projectDeleted(this.props.project.id.toString());
    }

    render() {
      return (
        <Mutation mutation={Mutations.DELETE_PROJECT} onCompleted={this.onCompleted}>
          {(deleteProject, { loading, error }) => {
            const deleteProjectObj = {
              mutation: deleteProject,
              loading,
              data: this.state.data,
              error,
            };
            return <WrappedComponent deleteProject={deleteProjectObj} {...this.props} />;
          }}
        </Mutation>
      );
    }
  };
};

export default withDeleteProject;
