import ArgumentError from '../error/ArgumentError';
import ObjectUtils from '../utils/ObjectUtils';

const DEFAULT_MESSAGE = 'Assertion failed';
const DEFAULT_NULL_MESSAGE = 'The value cannot be null';
const DEFAULT_NOT_EMPTY_MESSAGE = 'The value cannot be a null or empty string';

export default class Assert {
  static isTrue(expression, message) {
    if (!expression) {
      throw new ArgumentError(message || DEFAULT_MESSAGE);
    }
  }

  static isFalse(expression, message) {
    if (expression) {
      throw new ArgumentError(message || DEFAULT_MESSAGE);
    }
  }

  static notNull(value, message) {
    Assert.isTrue(value != null, message || DEFAULT_NULL_MESSAGE);
  }

  static notEmpty(value, message) {
    Assert.notNull(value, message || DEFAULT_NOT_EMPTY_MESSAGE);
    Assert.instanceOf(value, String);
    Assert.isFalse(value === '', message || DEFAULT_NOT_EMPTY_MESSAGE);
  }

  static instanceOf(value, type) {
    //first ensure the value is not null
    Assert.notNull(value);

    if (type === String && ObjectUtils.isString(value)) {
      return;
    }

    if (!(value instanceof type)) {
      throw new TypeError(`Expected value '${value}' to be of type ${type.name}`);
    }
  }

  static isNullOrInstanceOf(value, type) {
    if (!value) {
      return;
    }

    Assert.instanceOf(value, type);
  }

  static isBoolean(value) {
    let type = typeof value;
    if (type !== 'boolean') {
      throw new TypeError(`Expected value '${value}' to be a boolean but was ${type}.`);
    }
  }

  static isString(value) {
    Assert.instanceOf(value, String);
  }

  static isDate(value) {
    Assert.instanceOf(value, Date);
  }

  static isInteger(value) {
    if (!Number.isInteger(value)) {
      let type = typeof value;

      //if it's numeric but not an int, it's a double
      if (type === 'number') {
        type = 'double';
      }

      throw new TypeError(`Expected value '${value}' to be an integer but was ${type}.`);
    }
  }

  static isPositiveInteger(value) {
    //first ensure the value is an integer
    Assert.isInteger(value);

    //throw if the value is less than 0
    if (value < 0) {
      throw new RangeError(`Expected value '${value}' to be a positive integer.`);
    }
  }

  static isNumber(value) {
    const type = typeof value;
    if (type !== 'number') {
      throw new TypeError(`Expected value '${value}' to be a number but was ${type}.`);
    }
  }

  static isArray(value) {
    if (!Array.isArray(value)) {
      const type = typeof value;
      throw new TypeError(`Expected value ${value} to be an array but was ${type}`);
    }
  }
}
