import React, { useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import ConfirmationModal from './ConfirmationModal';
import withDeleteProject from '../hocs/withDeleteProject';
import { Queries } from 'gql';
import { toast } from 'utils/Toast';
import Project from 'model/Project';
import SelectedModalItem from './SelectedModalItem';
import defaultStyles from './defaultStyles';
/**
 * GraphQL DeleteProject Mutation Object
 * @typedef {Object} DeleteProjectMutation
 * @property {function} mutation - Delete Project Mutation function.
 * @property {ApolloError} error - Stores a potential error.
 * @property {boolean} loading - Indicates whether the mutation is in progress.
 */

/**
 * @param {Project} project - Project Object to be deleted
 * @param {boolean} isOpen - Indicates whether the modal is open or not
 * @param {function} onClose - Function to be fired when trying to close the modal
 * @param {DeleteProjectMutation} deleteProject
 */
const DeleteProjectModal = ({ project, isOpen, onClose, deleteProject }) => {
  useEffect(() => {
    if (deleteProject.error) {
      toast('An unexpected error occurred deleting project', 'error');
    } else if (deleteProject.data && !deleteProject.error) {
      onClose();
    }
    // eslint-disable-next-line
  }, [deleteProject]);

  const deleteMutation = () => {
    const deleteProjectArgs = {
      variables: { id: project.id },
      refetchQueries: [{ query: Queries.GET_PROJECTS }],
    };
    deleteProject.mutation(deleteProjectArgs);
  };

  return (
    <Modal isOpen={isOpen} className={'modal-window confirmation-modal'} onRequestClose={onClose} defaultStyles={defaultStyles}>
      <ConfirmationModal
        mainText={<SelectedModalItem boldText={project.customer.name} regularText={project.name} />}
        onClose={onClose}
        onConfirm={deleteMutation}
        loading={deleteProject.loading}
        title={'Delete this project'}
        trailingText={'Are you sure you would like to permanently delete this project?'}
        buttonText={'Confirm Delete'}
      />
    </Modal>
  );
};

DeleteProjectModal.propTypes = {
  project: PropTypes.instanceOf(Project).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteProject: PropTypes.shape({
    mutation: PropTypes.func.isRequired,
    data: PropTypes.any,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.shape({}),
  }).isRequired,
};

export default withDeleteProject(DeleteProjectModal);
