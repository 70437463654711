import React from 'react';

export default class Expandable extends React.Component {
  state = {
    open: this.props.initOpen || false,
  };
  render() {
    const { children } = this.props;
    const { open } = this.state;
    const toggle = () => this.setState({ open: !open });
    return children(open, toggle);
  }
}
