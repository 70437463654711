import React from 'react';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import HamburgerMenuIcon from 'views/components/icons/HamburgerMenu';
import LogoutIcon from 'views/components/icons/Logout';
import About from './About';

const UserMenu = ({ onLogoutClicked }) => {
  return (
    <div>
      <Menu
        menuClassName={'default-menu'}
        align={'end'}
        menuButton={
          <MenuButton className={'user_menu'} data-testid={'user-menu'}>
            <HamburgerMenuIcon />
          </MenuButton>
        }
        transition>
        <MenuItem data-testid={'user-menu-about'}>
          <About />
        </MenuItem>
        <MenuItem onClick={onLogoutClicked} data-testid={'user-menu-logout'}>
          <LogoutIcon /> <span>Logout</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;
