import React, { useEffect, useState } from 'react';
import 'react-notion/src/styles.css';
import Button from 'views/components/Button';
import PlusIcon from 'views/components/icons/Plus';
import { useMutation, useQuery } from 'react-apollo';
import { Mutations, Queries } from 'gql';
import TeamsView from '../TeamView/Index';
import CompanyModal from '../modals/CompanyModal';
import DeleteCompanyModal from '../modals/DeleteCompanyModal';
import RestoreCompanyModal from '../modals/RestoreCompanyModal';
import { LoggerFactory } from 'logger';
import { toast } from 'utils/Toast';
import CompaniesList from './CompaniesList';
import { areDomainsEquals } from 'views/components/ProjectMap/helpers/compareDomains';

const CompaniesView = ({ user }) => {
  const logger = LoggerFactory.getLogger('Company');
  const { data: companiesData, loading: companiesDataLoading } = useQuery(Queries.GET_COMPANIES);
  const [addCompany, { loading: loadingCreate, error: errorCreate }] = useMutation(Mutations.CREATE_COMPANY);
  const [deleteCompany, { loading: loadingError, error: errorDelete }] = useMutation(Mutations.DELETE_COMPANY);
  const [updateCompany, { loading: loadingUpdate, error: errorUpdate }] = useMutation(Mutations.UPDATE_COMPANY);
  const [companySelected, setCompanySelected] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [usedDomains, setUsedDomains] = useState([]);

  const [teamView, setTeamView] = useState(false);
  const [modalOpen, setModalOpen] = useState('');

  useEffect(() => {
    if (!companiesData) return;
    setCompanies(companiesData.companies);
    const allDomains = companiesData.companies.map((company) => company.domains);
    setUsedDomains(allDomains.flat());
  }, [companiesData]);

  useEffect(() => {
    if (errorDelete) {
      logger.error(`An error occurred executing mutation: ${errorDelete.message}`);
      toast('An unexpected error occurred deleting company', 'error');
    }
    // eslint-disable-next-line
  }, [errorDelete]);

  useEffect(() => {
    if (errorCreate) {
      logger.error(`An error occurred executing mutation: ${errorCreate.message}`);
      toast('An unexpected error occurred creating company', 'error');
    }
    // eslint-disable-next-line
  }, [errorCreate]);

  useEffect(() => {
    if (errorUpdate) {
      logger.error(`An error occurred executing mutation: ${errorUpdate.message}`);
      toast('An unexpected error occurred creating company', 'error');
    }
    // eslint-disable-next-line
  }, [errorUpdate]);

  const onDeleteCompany = async (companyId, sendEmail) => {
    await deleteCompany({ variables: { companyId, sendEmail }, refetchQueries: [{ query: Queries.GET_COMPANIES }] });
    closeModal();
  };

  const onCreateCompany = async (companyName, toggleChecked, addedDomains) => {
    await addCompany({
      variables: { name: companyName, isDSPAllowed: toggleChecked, domains: addedDomains },
      refetchQueries: [{ query: Queries.GET_COMPANIES }],
    });
  };

  const onUpdateCompany = async (companyName, toggleChecked, addedDomains) => {
    if (companyName !== companySelected.name || toggleChecked !== companySelected.isDSPAllowed || !areDomainsEquals(addedDomains, companySelected.domains)) {
      await updateCompany({
        variables: { companyId: companySelected.id, name: companyName, isDSPAllowed: toggleChecked, domains: addedDomains },
      });
    }
  };

  const onOpenCompanyClick = (company) => {
    setCompanySelected(company);
    setTeamView(true);
  };

  const onGoBackClick = () => {
    setCompanySelected(false);
    setTeamView(false);
  };

  const changeModalState = (newState, company) => {
    if (company) setCompanySelected(company);
    setModalOpen(newState);
  };

  const closeModal = () => {
    changeModalState('');
    setCompanySelected(false);
  };

  const renderLoading = () => {
    return (
      <div className="loading-projects">
        <span className="loading-message">
          <div className="spinner"></div>
          Loading Companies
        </span>
      </div>
    );
  };

  return (
    <>
      {!teamView ? (
        <>
          <div className="list-heading">
            <h1>Companies</h1>
            <Button
              onClick={() => {
                changeModalState('create');
              }}
              color={'gray'}
              text="Add Company"
              Icon={PlusIcon}
              iconColor={'white'}
              iconSize={14}
            />
          </div>
          <CompanyModal
            userRole={user.role}
            isOpen={modalOpen === 'create'}
            onClose={closeModal}
            onSave={onCreateCompany}
            loading={loadingCreate}
            usedDomains={usedDomains}
          />
          <CompanyModal
            userRole={user.role}
            isOpen={modalOpen === 'edit'}
            onClose={closeModal}
            onSave={onUpdateCompany}
            loading={loadingUpdate}
            company={companySelected}
            usedDomains={usedDomains}
          />
          <DeleteCompanyModal
            loading={loadingError}
            onDelete={onDeleteCompany}
            isOpen={modalOpen === 'delete'}
            onClose={closeModal}
            company={companySelected}
          />
          <RestoreCompanyModal isOpen={modalOpen === 'restore'} onClose={closeModal} company={companySelected} />

          {companiesDataLoading ? (
            renderLoading()
          ) : (
            <CompaniesList companies={companies} onOpenCompanyClick={onOpenCompanyClick} openModal={changeModalState} user={user} />
          )}
        </>
      ) : (
        <TeamsView companyId={companySelected.id} onGoBackClick={onGoBackClick} user={user} usedDomains={usedDomains} />
      )}
    </>
  );
};

export default CompaniesView;
