import Delaunator from 'delaunator';
import _ from 'lodash';
import React from 'react';
import buildPacificConnections from './helpers/buildPacificConnections';
import getPacificConnections from './helpers/getPacificConnections';
import PolylineWithText from './PolylineWithText';

function flatten(array) {
  return array.reduce(function (accumulator, current) {
    return accumulator.concat(Array.isArray(current) ? flatten(current) : current);
  }, []);
}
// eslint-disable-next-line
Array.prototype.flatten = function() {return flatten(this);}

function buildLines(metro1, metro2, allMetros) {
  const connectionLines = [];

  const line = [metro1.coordinates.leaflet, metro2.coordinates.leaflet];

  const { tos } = metro1.getPaths([metro2], allMetros);
  if (!tos[0]) return false; // Don't make connections if the metro doesn't have connectedMetros.

  const distance = tos[0].distance;
  const region1 = metro1.region;
  const region2 = metro2.region;

  const pacificConnections = buildPacificConnections(region1, region2, metro1.coordinates, metro2.coordinates);

  // buildPacificConnections splits 1 line to 2
  if (pacificConnections) {
    const { lineFrom, lineTo } = pacificConnections;
    const line1 = lineFrom.map(({ x, y }) => [x, y]);
    const line2 = lineTo.map(({ x, y }) => [x, y]);

    connectionLines.push(
      {
        line: line1,
        distance,
        toMetro: `to ${[metro1, metro2].find(({ region }) => region === 'APAC').name}`,
      },
      {
        line: line2,
        distance,
        toMetro: `to ${[metro1, metro2].find(({ region }) => region === 'AMER').name}`,
      }
    );
  } else {
    connectionLines.push({
      line,
      distance,
    });
  }

  return { connectionLines };
}

export default function MetroConnections(props) {
  const { activeMetros, metros, connectionType, noText, iconSize, isExport } = props;
  if (!activeMetros || activeMetros.length < 1) return null;

  if (connectionType === 'logical') {
    // Find the activeMetros with their connectedMetros and then keep only the ones who have connectedMetros
    const fullActiveMetros = activeMetros
      .map((activeMetro) => metros.find((metro) => metro.id.value === activeMetro.id.value))
      .filter((m) => m.connectedMetros.length);

    if (fullActiveMetros.length < 2) return null;

    const activeMetrosCoords = fullActiveMetros.map((m) => m.coordinates.leaflet);
    const linesMetros = []; // metros grouped by 2 to build the line later
    const lines = []; // the object to draw the lines

    if (activeMetrosCoords.length >= 3) {
      // Delanuator returns an array of indexes. Each 3 is the triangle
      const { triangles } = Delaunator.from(activeMetrosCoords);

      for (let i = 0; i < triangles.length; i += 3) {
        const metro1 = fullActiveMetros[triangles[i]];
        const metro2 = fullActiveMetros[triangles[i + 1]];
        const metro3 = fullActiveMetros[triangles[i + 2]];

        const triangleCombinations = [
          [metro1, metro2],
          [metro1, metro3],
          [metro2, metro3],
        ];

        triangleCombinations.forEach((combination) => {
          const sameLine = linesMetros.some((lineMetros) => _.isEqual(_.sortBy(lineMetros), _.sortBy(combination)));
          if (!sameLine) linesMetros.push(combination);
        });
      }
    } else {
      linesMetros.push(fullActiveMetros);
    }
    linesMetros.forEach((lineMetros) => {
      const { connectionLines } = buildLines(...lineMetros, metros);
      if (!connectionLines) return;

      lines.push(...connectionLines);
    });
    return lines.map(({ line, distance, toMetro }, index) => {
      return (
        <PolylineWithText positions={line} color="#ED1C24" iconSize={iconSize} text={noText ? '' : `${distance}ms ${toMetro ? toMetro : ''}`} key={index} />
      );
    });
  }

  return activeMetros
    .map((from, index) => {
      // Compute the paths from the current metro to the metros after the current one in the array.
      // Previous ones were already computed in previous iterations.
      // i.e If we computed A -> B, B -> A will be the same.
      // We'll need to change this if it turns out the latencies aren't the same in both directions.
      const { paths } = from.getPaths(activeMetros.slice(index + 1), metros);
      return paths.map((path) =>
        path.map((to, index) => {
          const from = path[index - 1];
          if (from) {
            // make sure we have the same key regardless of the link direction
            const key = [from.metro.id, to.metro.id].sort().join('->');
            if (from.metro.region === 'AMER' && to.metro.region === 'APAC') {
              const { lineFrom, lineTo } = getPacificConnections(from.metro.coordinates, to.metro.coordinates, isExport);
              return (
                <React.Fragment key={key}>
                  <PolylineWithText
                    positions={lineFrom.map((p) => [p.x, p.y])}
                    color="#ED1C24"
                    text={noText ? '' : `${to.latency}ms to ${to.metro.name}`}
                    iconSize={iconSize}
                  />
                  <PolylineWithText
                    positions={lineTo.map((p) => [p.x, p.y])}
                    color="#ED1C24"
                    text={noText ? '' : `${to.latency}ms to ${from.metro.name}`}
                    iconSize={iconSize}
                  />
                </React.Fragment>
              );
            }
            if (from.metro.region === 'APAC' && to.metro.region === 'AMER') {
              const { lineFrom, lineTo } = getPacificConnections(to.metro.coordinates, from.metro.coordinates, isExport);
              return (
                <React.Fragment key={key}>
                  <PolylineWithText
                    key={`AMER->${key}`}
                    positions={lineFrom.map((p) => [p.x, p.y])}
                    color="#ED1C24"
                    text={noText ? '' : `${to.latency}ms to ${from.metro.name}`}
                    iconSize={iconSize}
                  />
                  <PolylineWithText
                    key={`${key}->APAC`}
                    positions={lineTo.map((p) => [p.x, p.y])}
                    color="#ED1C24"
                    text={noText ? '' : `${to.latency}ms to ${to.metro.name}`}
                    iconSize={iconSize}
                  />
                </React.Fragment>
              );
            }
            return (
              <PolylineWithText
                key={key}
                positions={[from.metro.coordinates.toLeaflet(), to.metro.coordinates.toLeaflet()]}
                color="#ED1C24"
                text={noText ? '' : `${to.latency}ms`}
                iconSize={iconSize}
              />
            );
          }
          return null;
        })
      );
    })
    .flatten()
    .filter((el) => el) // remove undefined/null/falsy elements
    .filter((el, idx, arr) => {
      // make sure each line is unique
      const res = !arr.slice(0, idx).find((e) => e.key === el.key);
      return res;
    });
}
