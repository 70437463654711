import React from 'react';
import PropTypes from 'prop-types';
import LOGGER from './logger';
import getActivityMonitorService from './ActivityMonitorService';

const TIMEOUT_MS = 1000 * 60 * 3; // 3min

/**
 * Monitors mouse activity in the webapp to determine whether a user is active or not.
 * Sets an {@code isUserActive} state that is passed through React props.
 */
export default class ActivityMonitor extends React.Component {
  static defaultProps = {
    trackClicks: false,
    trackMouseMove: false,
  };

  static propTypes = {
    trackClicks: PropTypes.bool,
    trackMouseMove: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      isUserActive: true,
    };

    this._onActivity();
  }

  get isUserActive() {
    return this.state.isUserActive;
  }

  set isUserActive(value) {
    //if our state hasn't changed, nothing to do
    if (this.isUserActive === value) {
      return;
    }

    const activityMonitorService = getActivityMonitorService();
    activityMonitorService._isUserActive = value;

    this.setState({ isUserActive: value });
  }

  _onActivity = () => {
    //LOGGER.debug("Resetting activity timer, old value: " + this.isUserActive);

    clearTimeout(this._timeout);

    this.isUserActive = true;

    this._timeout = setTimeout(() => (this.isUserActive = false), TIMEOUT_MS);
  };

  componentDidMount() {
    const { trackClicks, trackMouseMove } = this.props;

    if (trackClicks) {
      LOGGER.debug('Monitoring mouse clicks as indicator of user activity');
      window.addEventListener('click', this._onActivity);
    }

    if (trackMouseMove) {
      LOGGER.debug('Monitoring mouse movement as indicator of user activity');
      window.addEventListener('mousemove', this._onActivity);
    }
  }

  componentWillUnmount() {
    const { trackClicks, trackMouseMove } = this.props;

    if (trackClicks) {
      LOGGER.debug('Stopped monitoring mouse clicks');
      window.removeEventListener('click', this._onActivity);
    }

    if (trackMouseMove) {
      LOGGER.debug('Stopped monitoring mouse movement');
      window.removeEventListener('mousemove', this._onActivity);
    }
  }

  render() {
    const child = React.Children.only(this.props.children);
    return React.cloneElement(child, { isUserActive: this.state.isUserActive });
  }
}
