import ArgumentError from 'error/ArgumentError';
import { LoggerFactory } from 'logger';
import React from 'react';
import { Mutation } from 'react-apollo';

/**
 * Component that makes a query to GraphQL for necessary data to drive the component's view state.
 */
export default class GqlMutationComponent extends React.Component {
  constructor(props) {
    super(props);
    this._logger = LoggerFactory.getLogger(this);
  }

  _renderMutation = (mutate, { loading, error, data }) => {
    const transformed = loading || error || data == null ? null : this.transform(data);

    //this._logger.debug(`GraphQL mutation succeeded with data: ${transformed}`);

    return this.renderMutation(mutate, loading, error, transformed);
  };

  _onCompleted = async (data) => this.onCompleted(data);

  _onError = async (error) => this.onError(error);

  /**
   * Callback occurring when a mutation completes successfully.
   * @param data The returned data.
   * @virtual
   */
  onCompleted() {
    //nothing to do by default
  }

  /**
   * Callback occurring when a mutation fails to complete.
   * @param error The error message.
   * @virtual
   */
  onError() {
    //nothing to do by default
  }

  /**
   * Render the GraphQL loading, error, and complete states.
   * This method delegates to GqlMutationComponent#renderLoading(),
   * GqlMutationComponent#renderError(error), and GqlMutationComponent#renderData(data)
   * respectively unless this method is overridden.
   *
   * @param doMutate The mutation function.
   * @param loading Whether or not this mutation is loading, {@code true} if data is loading, {@code false} if it is not.
   * @param error The error that occurred in the process of executing the mutation, will not be set if no error occurred.
   * @param data On mutation success, this will be the returned data.
   * @returns {null}
   * @virtual
   */
  renderMutation(doMutate, loading, error, data) {
    if (loading) {
      this._logger.debug('GraphQL mutation loading');
      return this.renderLoading();
    }

    if (error) {
      this._logger.error('GraphQL mutation failed');
      return this.renderError(error);
    }

    return this.renderData(doMutate, data);
  }

  /**
   * Render the loading state for this view while the GraphQL query completes.
   * Defaults to render {@code null}
   *
   * @returns {null}
   * @virtual
   */
  renderLoading() {
    return null;
  }

  /**
   * Render the error state for this view if the GraphQL query fails.
   * Defaults to render {@code null}
   *
   * @returns {null}
   * @virtual
   */
  renderError() {
    return null;
  }

  /**
   * Render the data for this view if the GraphQL query completes successfully.
   *
   * @param doMutate The mutation function.
   * @param data The GraphQL query result transformed via {@link GqlComponent#transform()}.
   * @returns {null}
   * @virtual
   */
  renderData() {
    return null;
  }

  /**
   * The mutation GraphQL will execute.
   * This method must be overridden or an error will occur.
   *
   * @abstract
   */
  mutation() {
    throw new ArgumentError(`GqlMutationComponent.mutation() must be overridden in '${this.constructor.name}' component and return a valid GraphQL mutation`);
  }

  /**
   * Transform the GraphQL query result before the component calls {@link GqlComponent#renderData()}.
   * This method is optional to override, and defaults to returning the raw GraphQL query result as-is.
   *
   * @param data The raw GraphQL query result to transform.
   * @returns {*}
   * @virtual
   */
  transform(data) {
    return data;
  }

  render() {
    return (
      <Mutation mutation={this.mutation()} onError={this._onError} onCompleted={this._onCompleted}>
        {this._renderMutation}
      </Mutation>
    );
  }
}
