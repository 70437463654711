import React from 'react';

const EnvelopeIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M3.07617 1H17.0762C18.0387 1 18.8262 1.7875 18.8262 2.75V13.25C18.8262 14.2125 18.0387 15 17.0762 15H3.07617C2.11367 15 1.32617 14.2125 1.32617 13.25V2.75C1.32617 1.7875 2.11367 1 3.07617 1Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M18.8262 2.75L10.0762 8.875L1.32617 2.75" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default EnvelopeIcon;
