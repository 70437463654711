import UserId from './UserId';
import Assert from '../utils/Assert';
import CompanyId from './CompanyId';

export default class User {
  constructor(id, email, role, companyId) {
    Assert.instanceOf(id, UserId);
    Assert.isString(email);
    Assert.isInteger(role);
    Assert.instanceOf(companyId, CompanyId);

    this.id = id;
    this.email = email;
    this.role = role;
    this.companyId = companyId;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof User)) {
      return false;
    }

    return this.id.equals(other.id);
  }

  toString() {
    return `${this.constructor.name}{ id:${this.id}, email:'${this.email}', role:${this.role}, companyId:${this.companyId} }`;
  }

  toJSON() {
    const { id, email, role, companyId } = this;
    return { id, email, role, companyId };
  }

  static fromJSON(json) {
    if (!json) {
      return null;
    }

    const { id, email, role, companyId } = json;

    return new User(new UserId(id), email, role, new CompanyId(companyId));
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = User.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}
