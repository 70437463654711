import React, { useState, useCallback } from 'react';
import UsersIcon from 'views/components/icons/Users';
import ReactTooltip from 'react-tooltip';
import RefreshIcon from 'views/components/icons/Refresh';
import useIsSmallScreen from 'hooks/useIsSmallScreen';
import ShareProjectModalView from 'views/ProjectListView/modals/ShareProjectModal';
import ReactModal from 'react-modal';

ReactModal.setAppElement('*');

const UsersOnline = ({ user, project }) => {
  const [someoneEdited, setSomeoneEdited] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [usersOnline, setUsersOnline] = useState(0);

  const openSharedModal = (e) => {
    e.preventDefault();
    setShareModal(true);
  };

  const closeSharedModal = () => setShareModal(false);

  const onRefresh = () => {
    window.location.reload();
  };

  const isSmallScreen = useIsSmallScreen();

  const calculateUsersOnline = useCallback(
    (presence) => {
      const online = presence.reduce((count, userOnline) => (userOnline.status !== 'IDLE' ? count + 1 : count), 0);
      setUsersOnline(online);

      if (!someoneEdited) {
        const isSomeoneEditing = presence?.some((user) => user.status === 'EDITING');
        setSomeoneEdited(isSomeoneEditing);
      }
    },
    // eslint-disable-next-line
    [user]
  );

  return (
    <>
      {someoneEdited && (
        <>
          <ReactTooltip id={'refresh-updates-info'} place={'bottom'} type={'dark'} effect={'solid'}>
            <b className={'refresh-tooltip-message'}>Refresh to see updates from other users</b>
          </ReactTooltip>
          <RefreshIcon width={18} height={18} color="#ED1C24" data-tip data-for={'refresh-updates-info'} className={'refresh-icon'} onClick={onRefresh} />
        </>
      )}
      <div className="users-online user_linked-icon" data-testid={'users-online'}>
        <a className="user_linked-icon-link user_linked-icon-link--usersOnline" onClick={openSharedModal} href="#online">
          <UsersIcon width={18} height={16} color="black" />
          {`${usersOnline}${!isSmallScreen ? ` User${usersOnline === 1 ? '' : 's'} Online` : ''}`}
        </a>
      </div>

      {project && (
        <ShareProjectModalView isOpen={shareModal} onClose={closeSharedModal} project={project} user={user} calculateUsersOnline={calculateUsersOnline} />
      )}
    </>
  );
};

export default UsersOnline;
