import React from 'react';

const MetroIcon = ({ width = 10, height = 10, color, ...rest }) => {
  return (
    <svg width={width} height={height} fill={color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M7.65625 3.44531H7.68359L13.0156 5.60547C13.0703 5.64193 13.1159 5.64193 13.1523 5.60547C13.207 5.56901 13.2344 5.52344 13.2344 5.46875V3.19922C13.2344 3.16276 13.2253 3.13542 13.207 3.11719C13.1888 3.08073 13.1615 3.05339 13.125 3.03516L8.09375 0.902344C8.0026 0.884115 7.91146 0.875 7.82031 0.875C7.7474 0.875 7.67448 0.902344 7.60156 0.957031L0.875 5.22266C0.838542 5.24089 0.811198 5.26823 0.792969 5.30469C0.792969 5.32292 0.792969 5.35026 0.792969 5.38672V6.97266C0.792969 7.04557 0.820312 7.10026 0.875 7.13672C0.929688 7.17318 0.984375 7.17318 1.03906 7.13672L7.65625 3.44531ZM7.62891 8.47656L7.65625 8.50391V8.47656V8.50391L12.9062 10.2266C12.9792 10.2448 13.043 10.2357 13.0977 10.1992C13.1706 10.1445 13.207 10.0807 13.207 10.0078V8.3125C13.207 8.25781 13.1888 8.21224 13.1523 8.17578C13.1341 8.13932 13.1068 8.11198 13.0703 8.09375L7.90234 6.125C7.82943 6.08854 7.7474 6.07031 7.65625 6.07031C7.58333 6.07031 7.51042 6.08854 7.4375 6.125L0.875 9.48828C0.838542 9.50651 0.811198 9.53385 0.792969 9.57031C0.77474 9.60677 0.765625 9.65234 0.765625 9.70703V11.2109C0.765625 11.3021 0.792969 11.375 0.847656 11.4297C0.920573 11.4661 1.0026 11.4661 1.09375 11.4297L7.62891 8.50391V8.47656ZM0.792969 13.8633V14.6836C0.792969 14.7383 0.811198 14.7839 0.847656 14.8203C0.884115 14.8568 0.929688 14.875 0.984375 14.875H13.0703C13.1068 14.875 13.1432 14.8568 13.1797 14.8203C13.2161 14.7839 13.2344 14.7383 13.2344 14.6836V12.7969C13.2344 12.7604 13.2253 12.724 13.207 12.6875C13.1888 12.651 13.1523 12.6237 13.0977 12.6055L7.68359 11.2109C7.66536 11.2109 7.64714 11.2109 7.62891 11.2109C7.61068 11.2109 7.59245 11.2109 7.57422 11.2109L0.902344 13.6992C0.865885 13.7174 0.838542 13.7448 0.820312 13.7812C0.802083 13.7995 0.792969 13.8268 0.792969 13.8633ZM7.65625 3.44531H7.68359H7.65625Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};

export default MetroIcon;
