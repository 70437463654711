const category = 'Feature Events';

module.exports = [
  {
    fn: 'aspImported',
    category,
    name: 'ASP Imported',
    trigger: 'ASPImported',
    params: ['projectId'],
  },
  {
    fn: 'excelUploaded',
    category,
    name: 'Excel Uploaded',
    trigger: 'ExcelUploaded',
    params: ['projectId'],
  },
  {
    fn: 'mapDownloaded',
    category,
    name: 'Map Downloaded',
    trigger: 'MapDownloaded',
    params: ['projectId', 'mapType'],
  },
  {
    fn: 'pdfDownloaded',
    category,
    name: 'PDF Downloaded',
    trigger: 'PDFDownloaded',
    params: ['projectId', 'pdfType'],
  },
  {
    fn: 'mapLayersShown',
    category,
    name: 'Map Layers Shown',
    trigger: 'MapLayersShown',
    params: ['projectId', 'phaseId'],
  },
  {
    fn: 'serviceDensityShown',
    category,
    name: 'Service Density Shown',
    trigger: 'ServiceDensityShown',
    params: ['projectId', 'phaseId'],
  },
];
