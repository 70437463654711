import Assert from '../utils/Assert';
import GroupLocationId from './GroupLocationId';
import Address from './Address';

export default class GroupLocationForEditGroupView {
  constructor(id, address) {
    Assert.instanceOf(id, GroupLocationId);
    Assert.instanceOf(address, Address);

    this.id = id;
    this.address = address;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof GroupLocationForEditGroupView)) {
      return false;
    }

    return this.id.equals(other.id);
  }

  toString() {
    return `${this.constructor.name}{ address:${this.address}}`;
  }

  toJSON() {
    const { id, address } = this;
    return { id, address: address.toJSON() };
  }

  static fromJSON(json) {
    const { id, address } = json;

    return new GroupLocationForEditGroupView(new GroupLocationId(id), Address.fromJSON(address));
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = GroupLocationForEditGroupView.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}
