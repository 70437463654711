import React from 'react';
import { Network } from './Network';
import { Security } from './Security';
import { Data } from './Data';
import { Applications } from './Applications';

const UseCases = ({ metro, phase }) => {
  const phaseName = phase.name || 'this phase';

  return (
    <div className="content-view">
      <div className="content-section">
        <p className="instructions">
          Use cases represent groups of Design Patterns that will be useful as {phaseName} begins to implement IOA<sup>®</sup>. Activate key use cases so that
          we can generate relevant briefing materials for the customer.
        </p>
        <Network active on />
        <Security active on={metro.services.length > 0} />
        <Data />
        <Applications />
      </div>
    </div>
  );
};

export default UseCases;
