/**
 * Computes the dafault position of a label.
 *
 * Labels are by default arranged on concentric circles, the first ones
 * closer to the metro
 */

function generateOffset(index = 0) {
  const CIRCLE_QTY = 8; // Number of labels on one circle "level"

  const baseAngle = Math.PI / (CIRCLE_QTY / 2);
  const distance = 10 + 10 * (Math.floor(index / CIRCLE_QTY) + 1);
  const angularIndex = index % CIRCLE_QTY;
  const angle = baseAngle * angularIndex;
  const x = Math.floor(distance * Math.sin(angle));
  const y = Math.floor(distance * Math.cos(angle));
  return [x, y].join(',');
}

export default generateOffset;
