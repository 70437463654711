import React, { useEffect, useState } from 'react';
import { LoggerFactory } from 'logger';
import { toast } from 'utils/Toast';
import { useMutation } from 'react-apollo';
import Modal from 'react-modal';
import { Mutations, Queries } from 'gql';
import defaultStyles from './defaultStyles';
import TextInput from 'views/components/TextInput';
import Button from 'views/components/Button';
import { emailStringToArray, isEmailValid } from 'utils/emails';
import './CompanyModals.scss';
import { USER_ROLES } from '../../../model/userRoles';
import ConfirmationModal from './ConfirmationModal';

const AddUserToCompanyModal = ({ isOpen, onClose, companyId, ownerExist, userRole }) => {
  const logger = LoggerFactory.getLogger('AddUserToCompany');

  const [addUserToCompany, { loading, error }] = useMutation(Mutations.ADD_USER_TO_COMPANY);
  const [userEmails, setUserEmails] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(4);

  const onEmailValueChange = (newValue) => setUserEmails(newValue);
  const isMultiple = userEmails.includes(',');

  useEffect(() => {
    if (error) {
      logger.error(`An error occurred executing mutation: ${error.message}`);

      error.graphQLErrors.forEach((error) => {
        if (error.extensions['exception type'].includes('ExistingUserException')) {
          const emails = error.message.slice(1, -1);
          toast(`The following email${emails.includes(',') ? `s are` : ' is'} already registered: ${emails}`, 'error');
        } else {
          toast('An unexpected error occurred adding user to company', 'error');
        }
      });
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (isMultiple && selectedRole === USER_ROLES.companyOwner.value) {
      setSelectedRole(USER_ROLES.teamMember.value);
    }
    // eslint-disable-next-line
  }, [userEmails, isMultiple]);

  const renderProgress = () => {
    return (
      <div className="modal-submit">
        <div className="loading"></div>
      </div>
    );
  };

  const isDisabled = () => !userEmails;

  const _onClose = () => {
    onClose();
    setUserEmails('');
    setSelectedRole(4);
  };

  const onAdd = async () => {
    const emailArray = emailStringToArray(userEmails).filter((email) => email !== '');
    const invalid = emailArray.find((mail) => !isEmailValid(mail));
    if (invalid) return toast('An email address is invalid', 'error');
    if (ownerExist && selectedRole === USER_ROLES.companyOwner.value && !modalOpen) {
      setModalOpen(true);
    } else {
      await addUserToCompany({
        variables: { companyId, userEmail: emailArray, userRole: selectedRole },
        refetchQueries: [{ query: Queries.GET_COMPANY, variables: { companyId } }],
      });
      _onClose();
      setModalOpen(false);
    }
  };

  const renderButtons = () => {
    return (
      <div className="modal-submit">
        <Button text="Cancel" color="red" onClick={_onClose} isInverse={true} />
        <Button text="Save" color="red" onClick={onAdd} isDisabled={isDisabled()} data-testid={'save-button'} />
      </div>
    );
  };

  const handleRoleChange = (event) => {
    setSelectedRole(parseInt(event.target.value));
  };

  return (
    <Modal isOpen={isOpen} className="modal-window" onRequestClose={_onClose} contentLabel="Add New Team Member" defaultStyles={defaultStyles}>
      <div>
        <div className="modal-header">
          <h3>Add New Team Member</h3>
          <button className="close-modal" onClick={_onClose} data-testid={'close-button'}>
            <svg height="12" width="12">
              <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
            </svg>
          </button>
        </div>
        <div className="modal-fields modal-fields--full ">
          <label>Type user email*</label>
          <TextInput value={userEmails} placeholder="e.g. jsmith@equinix.com" className="textInput" onValueChange={onEmailValueChange} />
        </div>

        <div className="modal-fields modal-fields--full">
          <p>Role assignation</p>
          <div className="radio-list">
            <label>
              <input type="radio" value={USER_ROLES.teamMember.value} checked={selectedRole === USER_ROLES.teamMember.value} onChange={handleRoleChange} />
              {USER_ROLES.teamMember.label}
            </label>
            <p>Can create and share projects</p>
            <label>
              <input type="radio" value={USER_ROLES.teamAdmin.value} checked={selectedRole === USER_ROLES.teamAdmin.value} onChange={handleRoleChange} />
              {USER_ROLES.teamAdmin.label}
            </label>
            <p>Can add and remove users, and change user roles</p>
            {userRole !== USER_ROLES.teamAdmin.value && (
              <>
                <label>
                  <input
                    data-testid={'company-owner-radio'}
                    disabled={isMultiple}
                    type="radio"
                    value={USER_ROLES.companyOwner.value}
                    checked={selectedRole === USER_ROLES.companyOwner.value && !isMultiple}
                    onChange={handleRoleChange}
                  />
                  {USER_ROLES.companyOwner.label}
                </label>
                {isMultiple && (
                  <p className="modal-warning-text" data-testid={'company-owner-warning-a'}>
                    There can only be one Company Owner. To assign an Owner, enter one user email above.
                  </p>
                )}
                {ownerExist && (
                  <p className="modal-warning-text" data-testid={'company-owner-warning-b'}>
                    There is already a Company Owner. This role will be transferred.
                  </p>
                )}
                <p>Can edit Company settings</p>
              </>
            )}
          </div>
        </div>

        {loading ? renderProgress() : renderButtons()}
      </div>
      <Modal isOpen={modalOpen} className={'modal-window confirmation-modal'} onRequestClose={() => setModalOpen(false)} defaultStyles={defaultStyles}>
        <ConfirmationModal
          onClose={() => setModalOpen(false)}
          onConfirm={onAdd}
          title={'Credentials Transfer Warning'}
          trailingText={
            'There can only be one Company Owner. This action will transfer the Company Owner role to the user email provided. The previous Company Owner will be given the role of Team Admin.'
          }
          buttonText={'Confirm'}
        />
      </Modal>
    </Modal>
  );
};

export default AddUserToCompanyModal;
