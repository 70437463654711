import ObjectUtils from './utils/ObjectUtils';

const LOGGER = {
  log: (info) => {
    info.timestamp = new Date();
    const level = info.level.toUpperCase();
    const message = info.stacktrace
      ? `${info.timestamp.toISOString()} [${level}] ${info.logger} - ${info.message}\n${info.stacktrace}`
      : `${info.timestamp.toISOString()} [${level}] ${info.logger} - ${info.message}`;

    if (level === 'INFO') {
      console.info(message);
    } else if (level === 'WARN') {
      console.warn(message);
    } else if (level === 'ERROR') {
      if (info.exception) {
        console.error(message, info.exception);
      } else {
        console.error(message);
      }
    } else {
      console.log(message);
    }
  },
};

const LOGGERS = {};

export class LoggerFactory {
  static getLogger(logger) {
    const name = ObjectUtils.isString(logger) ? logger : logger.constructor.name;
    const instance = LOGGERS[name] || new Logger(name);
    LOGGERS[name] = instance;

    return instance;
  }
}

export class Logger {
  constructor(name) {
    this.name = name || 'default';
  }

  _log(level, message, ex) {
    const info = { level, message, logger: this.name };
    if (ex) {
      info.exception = ex;
      info.stacktrace = ex.stack;
    }

    LOGGER.log(info);
  }

  trace(msg, ex) {
    this._log('trace', msg, ex);
  }

  debug(msg, ex) {
    this._log('debug', msg, ex);
  }

  info(msg, ex) {
    this._log('info', msg, ex);
  }

  warn(msg, ex) {
    this._log('warn', msg, ex);
  }

  error(msg, ex) {
    this._log('error', msg, ex);
  }
}
