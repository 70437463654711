import Config from 'Config';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import CloseIcon from 'views/components/icons/Close2';
import { DEPLOYMENT_TYPES } from 'model/deploymentTypes';

const DeviceInput = ({ recommendedDevices, setInfraComponentState, infraComponent }) => {
  const [inputValue, setInputValue] = useState(infraComponent.device || '');
  const [options, setOptions] = useState(null);
  const placeHolderText = `Specify a${
    infraComponent.deployment === 'METAL' ? `n ${DEPLOYMENT_TYPES.METAL.label}` : ` ${DEPLOYMENT_TYPES.EDGE.label}`
  } device...`;

  const onSetDeviceDebounced = useCallback(
    debounce((device) => setInfraComponentState({ ...infraComponent, device }), Config.TEXT_INPUT_DELAY),
    [infraComponent, setInfraComponentState]
  );

  useEffect(() => {
    return () => onSetDeviceDebounced.cancel();
  }, [onSetDeviceDebounced]);

  const onInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    const trimmedValue = value?.trim();
    const recommendedDevicesList = trimmedValue ? recommendedDevices() : null;

    setOptions(recommendedDevicesList?.filter(({ label }) => label.toLowerCase().includes(trimmedValue.toLowerCase())) || []);

    onSetDeviceDebounced(e.target.value);
  };

  const handleOnClick = (device) => {
    onSetDeviceDebounced.cancel();
    setInputValue(device);
    setInfraComponentState({ ...infraComponent, device });
    setOptions(null);
  };

  const onClose = () => {
    setInfraComponentState({ ...infraComponent, device: '' });
    setInputValue('');
  };

  return (
    <div className="expandable-content" data-testId="device-container">
      <div className="input-button-container">
        <div className="resize-container">
          <span className="resize-text">{inputValue || placeHolderText}</span>
          <input className="resize-input" value={inputValue} onChange={onInputChange} placeholder={placeHolderText} autoFocus />
        </div>

        {inputValue && (
          <button className={'expand-content close-icon'} onClick={onClose} data-testId="delete-icon">
            <CloseIcon width={15} height={15} color="#333333" />
          </button>
        )}
      </div>
      {options?.length > 0 && (
        <div className="content-options">
          <ul>
            <p className="title">At this metro</p>
            {options.map((option, index) => (
              <li key={`${option.name}-${index}`} onClick={() => handleOnClick(option.label)}>
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DeviceInput;
