import AddressAutocompleteWithType from 'views/components/AddressAutocompleteWithType';
import React, { useEffect } from 'react';
import TextSelect from 'views/components/TextSelect';
import UploadExcel from './UploadExcel';
import './AddLocationsView.scss';
import { useState } from 'react';
import GeoCoordinates from 'model/GeoCoordinates';
import ConnectIcon from 'views/components/icons/Connect';
import UserCircleIcon from 'views/components/icons/UserCircle';
import DatacenterIcon from 'views/components/icons/Datacenter';
import { useMutation } from '@apollo/react-hooks';
import { Mutations } from 'gql';
import { toast } from 'utils/Toast';
import { useMoveProjectItems } from 'views/components/PhasePanel/hocs/withMoveProjectItems';
import LocationId from 'model/LocationId';
import LocationCard from './LocationCard';
import { LoggerFactory } from 'logger';
import Location from 'model/Location';
import Button from 'views/components/Button';

const logger = LoggerFactory.getLogger('create location');
const connectIcon = <ConnectIcon height={12} width={16} color="#2D2D2D" />;

const AddLocationsView = ({ phase, onSelectionChanged, projectId, phaseId, onClose, isInfoOpen }) => {
  const [metros, setMetros] = useState([]);
  const [metro, setMetro] = useState();
  const [newLocations, setNewLocations] = useState([]);

  const { moveProjectItems } = useMoveProjectItems();
  const [execDeleteItem] = useMutation(Mutations.DELETE_PROJECT_ITEMS);
  const [execCreateLocation] = useMutation(Mutations.ADD_LOCATION);

  const optionsTypeLocations = [
    {
      icon: <UserCircleIcon color={'black'} width={18} height={15} />,
      label: 'Office',
      value: 'OFFICE',
    },
    {
      icon: <DatacenterIcon width={14} height={14} color={'black'} />,
      label: 'Data Center',
      value: 'DATACENTER',
    },
  ];

  const [typeLocation, setTypeLocation] = useState(optionsTypeLocations[0]);
  const onChangeMetro = (option) => {
    setMetro(!option.value ? null : option);
  };

  const onChangeLocationAddress = (address) => {
    if (address) {
      createLocation(address);
    }
  };

  const onChangeTypeLocation = (option) => {
    setTypeLocation(option);
  };

  const deleteLocation = async (locationId) => {
    logger.debug(`Deleting location: ${locationId} from phase with id: ${phaseId}`);

    setNewLocations((oldNewLocations) => oldNewLocations.filter((location) => location.id.value !== locationId.value));

    await execDeleteItem({
      variables: {
        projectId,
        phaseId,
        locationIds: [locationId],
        locationGroupIds: [],
        serviceConnectionIds: [],
      },
    });
  };

  const normalizeMetros = () => {
    const options = phase.metros.map((metro) => ({
      label: metro.name,
      value: metro.id.value,
      object: metro,
    }));

    return options;
  };

  const createLocation = async (address) => {
    try {
      const { coordinates, ...restAddress } = address;

      const params = {
        variables: {
          projectId: projectId,
          phaseId: phase.id,
          name: null,
          type: typeLocation.value,
          address: restAddress,
          coordinates: GeoCoordinates.parse(coordinates),
        },
      };

      const { data } = await execCreateLocation(params);
      const location = data.addProjectLocation.locations.filter((item) => item.address.label === address.label && item.name === null).pop();
      logger.debug(`Add location: ${location.id} mutation completed successfully`);

      if (metro) {
        await moveLocationToMetro(location.id);
      }

      const newLocation = Location.fromJSON({ ...location });

      setNewLocations((oldNewLocations) => [...oldNewLocations, { ...newLocation, metroId: metro?.value }]);
    } catch (error) {
      return toast('An unexpected error occurred creating a location', 'error');
    }
  };

  const moveLocationToMetro = async (locationId) => {
    await moveProjectItems(projectId, phase.id, metro.value, [new LocationId(locationId)]);
    logger.debug(`Add location: ${locationId} in metro ${metro.value} completed successfully`);
  };

  useEffect(() => {
    const options = normalizeMetros();

    if (metro && !options.some((option) => option.label === metro.label)) {
      setMetro(null);
    }

    setMetros(options);
    // eslint-disable-next-line
  }, [phase.metros]);

  useEffect(() => {
    if (!isInfoOpen) setNewLocations([]);
  }, [isInfoOpen]);

  return (
    <div className="AddView AddLocationsView">
      <h2>Add Customer Sites</h2>
      <UploadExcel onSelectionChanged={onSelectionChanged} />

      <div className="location">
        <TextSelect
          icon={connectIcon}
          label={'Connect to:'}
          placeholder={'Select a Metro...'}
          options={metros}
          onChangeTextSelect={onChangeMetro}
          value={metro}
          isClereable
        />
        <AddressAutocompleteWithType
          options={optionsTypeLocations}
          onChangeLocationAddress={onChangeLocationAddress}
          typeLocation={typeLocation}
          onChangeTypeLocation={onChangeTypeLocation}
          clearSelected={true}
        />
      </div>
      <div className="card-list">
        {newLocations.map((location) => (
          <LocationCard
            key={location.id}
            metros={metros}
            location={location}
            projectId={projectId}
            phaseId={phaseId}
            optionsTypeLocations={optionsTypeLocations}
            deleteLocation={deleteLocation}
          />
        ))}
      </div>

      <div className="buttons">
        <Button color="red" text={'Close'} onClick={onClose} />
      </div>
    </div>
  );
};

export default AddLocationsView;
