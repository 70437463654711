import React from 'react';
import './InfraComponentsMarker.scss';
import cn from 'classnames';
import DeploymentContainer from './DeploymentContainer';
import { DEPLOYMENT_TYPES } from 'model/deploymentTypes';

const InfraComponentsCard = ({ infraComponent, iconSize }) => {
  return (
    <div className={cn('infraComponent-container', { smallIcon: iconSize < 1 }, { verySmallIcon: iconSize < 0.5 })}>
      {Object.keys(infraComponent).map((deployment, index) => (
        <DeploymentContainer
          key={index}
          iconSize={iconSize}
          name={DEPLOYMENT_TYPES[deployment].label}
          icon={DEPLOYMENT_TYPES[deployment].icon('white', 16, 16)}
          sectionInfraComponent={infraComponent[deployment]}
        />
      ))}
    </div>
  );
};

export default InfraComponentsCard;
