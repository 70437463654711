import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'debounce';
import Config from 'Config';
import TextInput from 'views/components/TextInput';

import { dismissAllToasts } from 'utils/Toast';
import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';

const Notes = ({ projectId, phaseId, metro }) => {
  const [updateMetro] = useMutation(Mutations.UPDATE_METRO);
  const [notes, setNotes] = useState(metro.notes || '');
  const [metroId, setMetroId] = useState(metro.id);
  const [phaseIdState, setPhaseId] = useState(phaseId);

  useEffect(() => {
    if (!metro) return;
    if (!metro.id.equals(metroId) || phaseId !== phaseIdState) {
      setNotes(metro.notes || '');
      setMetroId(metro.id);
      setPhaseId(phaseId);
    } // eslint-disable-next-line
  },[metro])

  const updateMetroDebounced = useCallback(
    debounce(async (notes) => {
      await updateMetro({ variables: { projectId, phaseId, metroId: metro.id, notes, existing: metro.existing } });
      dismissAllToasts();
    }, Config.TEXT_INPUT_DELAY),
    [metro, phaseId]
  );

  const handleNotesChange = (newValue) => {
    setNotes(newValue);
    updateMetroDebounced(newValue);
  };

  return (
    <>
      <h3>Add a note</h3>
      <TextInput
        placeholder="Enter an important detail or note about this metro..."
        multiline={true}
        className="multiline"
        value={notes}
        onValueChange={handleNotesChange}
      />
    </>
  );
};

export default Notes;
