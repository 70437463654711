import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';

const useEnableEdgeCategory = () => {
  const [doEnableEdge, { data, loading, error }] = useMutation(Mutations.ENABLE_NETWORK_EDGE);

  const enableEdgeCategory = (projectId, phaseId, metroCode, categoryId = null) => doEnableEdge({ variables: { projectId, phaseId, metroCode, categoryId } });

  return {
    enableEdgeCategory,
    dataEnableEdge: data,
    loadingEnableEdge: loading,
    errorEnableEdge: error,
  };
};

export { useEnableEdgeCategory };
