import React from 'react';
import ArrowUpCircleIcon from 'views/components/icons/ArrowUpCircle';
import LockIcon from 'views/components/icons/Lock';
import EyeIcon from 'views/components/icons/Eye';
import SavedIcon from 'views/components/icons/Saved';

const TemplateImageButtons = ({ isOwner, published, openPublishModal, timesSaved, updateAvailable, isSaved, openUpdateModal, onClickSaveButton }) => {
  return isOwner ? (
    <div className={'saved-information public'} onClick={openPublishModal} data-testid={'saved-information'}>
      {published ? (
        <>
          <EyeIcon width={18} height={18} color="#2D2D2D" />
          Public • Saved {timesSaved} time{timesSaved === 1 ? '' : 's'}
        </>
      ) : (
        <>
          <LockIcon width={18} height={18} color="#2D2D2D" />
          Private
        </>
      )}
    </div>
  ) : (
    <>
      {isSaved && updateAvailable ? (
        <div className={'saved-information long'} data-testid={'saved-information'}>
          <div className={'item fill'} onClick={openUpdateModal}>
            <ArrowUpCircleIcon width={20} height={20} color="#2D2D2D" />
            Update Available
          </div>
          <div className={'item end'} onClick={onClickSaveButton}>
            {timesSaved}
            <SavedIcon width={13} height={15} isFill={isSaved} lineColor="#2D2D2D" />
          </div>
        </div>
      ) : (
        <div className={'saved-information'} onClick={onClickSaveButton} data-testid={'saved-information'}>
          {timesSaved}
          <SavedIcon width={13} height={15} isFill={isSaved} lineColor="#2D2D2D" />
        </div>
      )}
    </>
  );
};

export default TemplateImageButtons;
