import gql from 'graphql-tag';
import {
  PROJECT_FRAGMENT,
  LOCATION_GROUP_FOR_EDIT_GROUP_VIEW_FRAGMENT,
  PROJECT_LIST_FRAGMENT,
  SHARED_PROJECT_LIST_FRAGMENT,
  PROJECT_PRESENCE_FRAGMENT,
  METRO_FRAGMENT,
  SERVICE_FRAGMENT,
  METAL_PLAN_FRAGMENT,
  NETWORK_EDGE_CATEGORY_FRAGMENT,
  PHASE_FRAGMENT,
  PHASE_METADATA_FRAGMENT,
  MARKETPLACE_SEGMENTS_FRAGMENT,
  TEMPLATE_FRAGMENT,
  TEMPLATE_PROJECT_FRAGMENT,
  COMPANY_FRAGMENT,
  USER_FRAGMENT,
} from './Fragments';

const GET_USER_AND_VERSION = gql`
    query GetUserAndVersion {
        user {
            ...UserField
        }
        version {
            major
            minor
            offset
            branch
            commit
        }
    }
    ${USER_FRAGMENT}
`;

const GET_PROJECTS = gql`
    query GetProjects {
        projects {
            ...ProjectListFields
        }
        sharedProjects {
            ...SharedProjectListFields
        }
    }
    ${PROJECT_LIST_FRAGMENT}
    ${SHARED_PROJECT_LIST_FRAGMENT}
`;

const GET_PROJECT_DETAIL = gql`
    query GetProjectDetail($id: Long!) {
        project(projectId: $id) {
            ...ProjectFields
        }
        presence(projectId: $id) {
            ...ProjectPresenceFields
        }
    }
    ${PROJECT_FRAGMENT}
    ${PROJECT_PRESENCE_FRAGMENT}
`;

const GET_PROJECT = gql`
    query GetProject($projectId: Long!) {
        project(projectId: $projectId) {
            id
            name
            customer {
                id
                name
            }
            phases {
                ...PhaseMetadata
            }
            sharedUsers {
                ...UserField
            }
            owner {
                ...UserField
            }
        } 
    }
    ${USER_FRAGMENT}
    ${PHASE_METADATA_FRAGMENT}
`;

const GET_PHASE = gql`
    query GetProjectPhase($projectId: Long!, $phaseId: Long!) {
        phase(projectId: $projectId, phaseId: $phaseId) {
            ...PhaseFields
        }
    }
    ${PHASE_FRAGMENT}
`;

const GET_TEMPLATE = gql`
    query GetTemplate($templateId: Long!)  {
        template(templateId: $templateId) {
            ...TemplateFields
        }
    }
    ${TEMPLATE_FRAGMENT}
`;

const GET_LATEST_TEMPLATE_VERSION = gql`
    query GetLatestTemplateVersion($templateId: Long!)  {
        getLatestTemplateVersion(templateId: $templateId) {
            ...TemplateFields
        }
    }
    ${TEMPLATE_FRAGMENT}
`;

const GET_TEMPLATES = gql`
    query GetTemplates($scope: TemplateScope!) {
        templates(scope: $scope) {
            ...TemplateFields
        }
    }
    ${TEMPLATE_FRAGMENT}
`;

const GET_TEMPLATE_PROJECT = gql`
    query GetTemplate($templateId: Long) {
        template(templateId: $templateId) {
            ...TemplateProjectFields
        }
    }
    ${TEMPLATE_PROJECT_FRAGMENT}
`;

const GET_METROS = gql`
    query GetMetros {
        metros {
            ...MetroFields
        }
    }
    ${METRO_FRAGMENT}
`;

const GET_PROJECT_PRESENCE = gql`
    query GetProjectPresence($projectId: Long!) {
        presence(projectId: $projectId) {
            ...ProjectPresenceFields
        }
    }
    ${PROJECT_PRESENCE_FRAGMENT}
`;

const GET_PROJECT_LOCATION_GROUP_FOR_EDIT_GROUP_VIEW = gql`
    query GetProjectLocationGroupForEditGroupView($projectId: Long!, $phaseId: Long!, $locationGroupId: Long!) {
        locationGroup(projectId: $projectId, phaseId: $phaseId, locationGroupId: $locationGroupId) {
            ...LocationGroupForEditGroupViewFields
        }
    }
    ${LOCATION_GROUP_FOR_EDIT_GROUP_VIEW_FRAGMENT}
`;

const GET_SERVICE_METRO_CONNECTIONS = gql`
    query GetServiceMetroConnections($projectId: Long!, $phaseId: Long!, $serviceId: Long!) {
        serviceMetroConnections(projectId: $projectId, phaseId: $phaseId, serviceId: $serviceId) {
            id
            name
            local
            connected
        }
    }
`;

const GET_SERVICES = gql`
    query GetServices {
        validServices {
            ...ServiceFields
        }
    }
    ${SERVICE_FRAGMENT}
`;

const GET_METAL_PLANS = gql`
    query GetMetalPlans($metroCode: String) {
        metalPlans(metroCode: $metroCode) {
            ...MetalPlanFields
        }
    }
    ${METAL_PLAN_FRAGMENT}
`;

const GET_NETWORK_EDGE_CATEGORIES = gql`
    query GetNetworkEdgeCategories($metroCode: String) {
        networkEdgeCategories(metroCode: $metroCode) {
            ...NetworkEdgeCategoryFields
        }
    }
    ${NETWORK_EDGE_CATEGORY_FRAGMENT}
`;

const GET_SEGMENTS = gql`
    query GetSegments {
        segments {
            id
            name
            subSegments {
                id
                name
            }
        }
    }
`;

const GET_DATA_SOURCES = gql`
    query GetDataSources {
        dataSources {
            id
            name
            source
            lastUpdated
        }
    }
`;

const GET_CUSTOMERS = gql`
    query GetCustomers {
        customers {
            id
            name
            segment {
                id
                name
            }
            subSegment {
                id
                name
            }
        }
    }
`;

const GET_ACCOUNT_STRATEGY_PLANS = gql`
    query GetAccountStrategyPlans {
        accountStrategyPlans {
            id
            company
            lastUpdated
            segmentId
            subSegmentId
        }
    }
`;

const GET_METRO_LABELS = gql`
    query GetMetroLabels($projectId: Long!, $phaseId: Long!) {
        metroLabels(projectId: $projectId, phaseId: $phaseId)
    }
`;

const GET_MARKETPLACE_SEGMENTS = gql`
    query GetMarketplaceSegments  {
        marketplaces {
           ...MarketplaceSegmentsFields
        }
    }
    ${MARKETPLACE_SEGMENTS_FRAGMENT}
`;

const GET_SLEEKPLAN_SSO_TOKEN = gql`
    query GetSleekplanSsoToken {
        sleekplanSsoToken
    }
`;

const GET_COMPANIES = gql`
    query getCompanies {
        companies {
            ...CompanyField
        }
    }
    ${COMPANY_FRAGMENT}
`;

const GET_COMPANY = gql`
    query getCompany($companyId: String!) {
        company(companyId: $companyId){
            ...CompanyField
        }
    }
    ${COMPANY_FRAGMENT}
`;

export default {
  GET_USER_AND_VERSION,
  GET_PROJECTS,
  GET_PROJECT_DETAIL,
  GET_METROS,
  GET_PROJECT_PRESENCE,
  GET_PROJECT_LOCATION_GROUP_FOR_EDIT_GROUP_VIEW,
  GET_SERVICE_METRO_CONNECTIONS,
  GET_SERVICES,
  GET_METAL_PLANS,
  GET_NETWORK_EDGE_CATEGORIES,
  GET_DATA_SOURCES,
  GET_SEGMENTS,
  GET_CUSTOMERS,
  GET_ACCOUNT_STRATEGY_PLANS,
  GET_METRO_LABELS,
  GET_PROJECT,
  GET_PHASE,
  GET_MARKETPLACE_SEGMENTS,
  GET_TEMPLATE,
  GET_TEMPLATES,
  GET_TEMPLATE_PROJECT,
  GET_LATEST_TEMPLATE_VERSION,
  GET_SLEEKPLAN_SSO_TOKEN,
  GET_COMPANIES,
  GET_COMPANY,
};
