import React, { useEffect, useMemo, useState } from 'react';
import { Menu, MenuItem, MenuButton, MenuDivider, MenuHeader } from '@szhsin/react-menu';
import { useMutation } from '@apollo/react-hooks';
import { Mutations } from 'gql';
import { useCurrentProject } from 'providers/ProjectProvider';
import { dismissAllToasts, toast } from 'utils/Toast';
import { ProjectEvents } from 'analytics';

const AddPhaseButton = ({ phases, onChangePhase }) => {
  const { projectId } = useCurrentProject();
  const [data, setData] = useState();
  const [execAddProjectPhase, { data: addPhaseData, error: addPhaseError, loading: addPhaseLoading }] = useMutation(Mutations.ADD_PROJECT_PHASE);
  const [execDuplicateProjectPhase, { data: dupPhaseData, error: dupPhaseError, loading: dupPhaseLoading }] = useMutation(Mutations.DUPLICATE_PROJECT_PHASE);

  const loading = useMemo(() => addPhaseLoading || dupPhaseLoading, [addPhaseLoading, dupPhaseLoading]);
  const error = useMemo(() => addPhaseError || dupPhaseError, [addPhaseError, dupPhaseError]);

  useEffect(() => {
    if (!addPhaseData) return;
    setData(addPhaseData?.addProjectPhase);
  }, [addPhaseData]);

  useEffect(() => {
    if (!dupPhaseData) return;
    setData(dupPhaseData?.duplicatePhase);
  }, [dupPhaseData]);

  const createPhase = async () => {
    try {
      await execAddProjectPhase({ variables: { projectId } });
      ProjectEvents.phaseAdded(projectId);
      dismissAllToasts();
    } catch (error) {
      return toast('An unexpected error occurred creating a phase', 'error');
    }
  };

  const duplicatePhase = async (phaseId) => {
    try {
      await execDuplicateProjectPhase({ variables: { projectId, phaseId } });
      ProjectEvents.phaseCopied(projectId);
      dismissAllToasts();
    } catch (error) {
      return toast('An unexpected error occurred copying the phase', 'error');
    }
  };

  useEffect(() => {
    if (data && !error) {
      const phasesList = data.phases;
      if (!phasesList) return;
      const { id } = phasesList[phasesList.length - 1];
      onChangePhase(id);
    }
    // eslint-disable-next-line
  }, [data, error]);

  return (
    <Menu
      menuClassName={'default-menu'}
      menuButton={
        <MenuButton disabled={loading} className={'phase-menu-button'} data-testid={'add-phase-button'}>
          {loading ? <div className={'loading'}></div> : '+ Add a Phase'}
        </MenuButton>
      }
      transition>
      <MenuItem onClick={createPhase}>Create an empty phase</MenuItem>
      <MenuDivider />
      <MenuHeader>COPY FROM...</MenuHeader>
      {phases?.map((phase) => (
        <MenuItem onClick={() => duplicatePhase(phase.id)} key={`add-phase-menu-${phase.id}`}>
          {phase.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default AddPhaseButton;
