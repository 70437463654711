import { Mutations } from 'gql';
import LocationId from 'model/LocationId';
import { useMutation } from 'react-apollo';

const useAddLocationGroup = () => {
  const [doAddLocationGroup] = useMutation(Mutations.ADD_LOCATION_GROUP);

  const addLocationGroup = async (projectId, phaseId, name, color, itemIds) => {
    const locations = itemIds.filter((i) => i instanceof LocationId);

    await doAddLocationGroup({
      variables: {
        projectId,
        phaseId,
        name,
        color,
        locations,
      },
    });
  };
  return { addLocationGroup };
};

export default useAddLocationGroup;
