import React from 'react';
import { withLeaflet } from 'react-leaflet';
import FeatureEvents from '../../../analytics/FeatureEvents';
import Config from '../../../Config';
import getAuthenticationService from '../../../service/AuthenticationService';
import { Actions } from '../../../stores/UserStore';
import MapButton from './MapButton';

class ExportButton extends React.Component {
  state = {
    exportAuthorized: false,
  };
  _export = async () => {
    const win = window.open('about:blank'); // open the window first so the popup isn't blocked
    const { project, phase, connectionType } = this.props;
    const tokenStr = window.localStorage['com.ioaworkbench.authToken'];
    const token = JSON.parse(tokenStr);
    let { exportAuthorized } = this.state;
    if (!exportAuthorized) {
      const res = await fetch(`${Config.REPORTS_URL}authorize`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `${token.value}`,
        },
      });
      if (res.status === 401) {
        // the token probably expired or is no longer valid
        const authenticationService = getAuthenticationService();
        const token = authenticationService.getToken();
        if (!token || token.isExpired) {
          Actions.expired();
        }
      }
      const text = await res.text();
      exportAuthorized = text === 'OK';
      this.setState({ exportAuthorized });
    }
    if (exportAuthorized) {
      const map = this.props.leaflet.map;
      const center = map.getCenter();
      const zoom = map.getZoom();
      const size = map.getSize();
      const { layers, marketplaceSegment, mapType } = this.props;
      const query = {
        center: `${center.lat},${center.lng}`,
        zoom,
        ...(layers.latencyRings && {
          rl: this.props.ringLatency,
          rc: this.props.ringColor,
        }),
        size: `${size.x},${size.y}`,
        connectionType,
        marketplaceSegment: encodeURIComponent(JSON.stringify(marketplaceSegment)),
        mapType,
        options: [
          layers.activeMetros ? 'a' : '',
          layers.inactiveMetros ? 'i' : '',
          layers.customerLocations ? 'o' : '',
          layers.services ? 's' : '',
          layers.activeRegions ? 'R' : '',
          layers.inactiveRegions ? 'r' : '',
          layers.latencies ? 'l' : '',
          layers.serviceLatencies ? 'L' : '',
          layers.connections ? 'c' : '',
          layers.directConnections ? 'd' : '',
          layers.labels ? 'C' : '',
          layers.latencyRings ? 'f' : '',
          layers.customerLocationLines ? 'y' : '',
        ].join(''),
      };
      const queryStr = Object.keys(query)
        .map((k) => `${k}=${query[k]}`)
        .join('&');
      const url = `${Config.REPORTS_URL}map/${project.id}/${phase.id}/${encodeURIComponent(project.name)} - ${encodeURIComponent(
        phase.name
      )} - manual.png?${queryStr}`;
      win.location = url;
      FeatureEvents.mapDownloaded(project.id, 'manual');
    } else {
      win.close();
    }
  };

  render() {
    return <MapButton className="snapshot-button" position="topright" text="Snapshot" onClick={this._export} data-testid={'snapshot'} />;
  }
}

export default withLeaflet(ExportButton);
