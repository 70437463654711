import React from 'react';
import Select, { components } from 'react-select';
import { getTheme } from 'utils/SelectTheme';
import './TextSelect.scss';
import DropdownStyles from 'views/components/DropdownStyles';
import cn from 'classnames';
import TriangleIcon from '../icons/Triangle';

const TextSelect = ({ icon, label, options, placeholder, onChangeTextSelect, value, isClereable, isDeploymentSelect, isDisabled = false }) => {
  const clearOption = {
    label: 'Clear selection',
    value: null,
  };

  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <TriangleIcon width={6} height={5} color="black" />
      </components.DropdownIndicator>
    );
  };

  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        {isDeploymentSelect && <div className="deployment-icon">{icon}</div>}
        {children}
      </components.Control>
    );
  };

  return (
    <div className={cn('TextSelect', { DeploymentSelect: isDeploymentSelect })}>
      {!isDeploymentSelect && icon}
      <p>{label}</p>
      <Select
        classNamePrefix="select"
        placeholder={placeholder}
        value={value ?? undefined}
        onChange={onChangeTextSelect}
        options={isClereable && value ? [clearOption, ...options] : options}
        isSearchable={false}
        theme={getTheme}
        styles={DropdownStyles}
        data-testid={'text-select'}
        isDisabled={isDisabled}
        components={{ Control: Control, DropdownIndicator: isDeploymentSelect ? CustomDropdownIndicator : components.DropdownIndicator }}
      />
    </div>
  );
};

export default TextSelect;
