import React from 'react';

const LogoutIcon = ({ width = '20', height = '20', color = 'black', ...rest }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M8 16H5.33333C4.97971 16 4.64057 15.8595 4.39052 15.6095C4.14048 15.3594 4 15.0203 4 14.6667V5.33333C4 4.97971 4.14048 4.64057 4.39052 4.39052C4.64057 4.14048 4.97971 4 5.33333 4H8"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.6665 13.3337L15.9998 10.0003L12.6665 6.66699" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 10H8" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default LogoutIcon;
