import { withMutation, Mutations } from 'gql';

const withUpdateCustomLabelOffset = withMutation({
  name: 'UPDATE_METRO_LABEL',
  mutation: Mutations.UPDATE_METRO_LABEL,
  mutateProp: 'updateCustomLabelOffset',
  getMutationArgs(x, y) {
    const { projectId, phaseId, metro, label } = this.props;
    return {
      variables: {
        projectId,
        phaseId,
        metroId: metro.id,
        labelId: label.id,
        label: {
          value: label.value,
          color: label.color,
          offset: `${x},${y}`,
        },
      },
    };
  },
});

export default withUpdateCustomLabelOffset;
