import { notify } from 'react-notify-toast';
import { toast as toastifyToast } from 'react-toastify';

const notifyToastQueue = notify.createShowQueue();

export const dismissAllToasts = () => toastifyToast.dismiss();

export const clearWaitingQueue = () => toastifyToast.clearWaitingQueue();

export const updateToast = (id, options) => toastifyToast.update(id, options);

export const toast = (message, spec) => {
  if (typeof spec === 'string') {
    notifyToastQueue(message, spec);
  } else {
    toastifyToast(message, spec);
  }
};

export const toastConfig = {
  limit: 1,
  position: 'bottom-center',
  autoClose: 10000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: false,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  containerId: 'toast-root',
  className: 'default-toast',
  theme: 'dark',
};
