import Assert from '../utils/Assert';
import ServiceConnectionId from './ServiceConnectionId';
import Service from './Service';
import ServiceRegion from './ServiceRegion';
import MetroId from './MetroId';

export default class ServiceConnection {
  constructor(id, service, metroId, local, visible, notes, regions) {
    Assert.instanceOf(id, ServiceConnectionId);
    Assert.instanceOf(service, Service);

    this.id = id;
    this.service = service;
    this.metroId = metroId || null;
    this.notes = notes || null;
    this.local = local || false;
    this.visible = visible || false;
    this.regions = regions;
    Object.freeze(this);
  }

  get projectId() {
    return this.id ? this.id.projectId : null;
  }

  get serviceId() {
    return this.id ? this.id.serviceId : null;
  }

  get name() {
    return this.service ? this.service.name : null;
  }

  get isConnectedToMetro() {
    return this.metroId != null;
  }

  get logo() {
    return this.service.logo;
  }

  equals(other) {
    if (!(other instanceof ServiceConnection)) {
      return false;
    }

    return this.id.equals(other.id);
  }

  compare(other) {
    if (!(other instanceof ServiceConnection)) {
      throw new Error('Cannot compare to other type!');
    }
    const myLabel = this.name;
    const theirLabel = other.name;
    if (myLabel > theirLabel) {
      return 1;
    } else if (myLabel < theirLabel) {
      return -1;
    } else {
      return 0;
    }
  }

  toString() {
    return `${this.constructor.name}{ id:${this.id}, name:'${this.name}' local:${this.local} }`;
  }

  toJSON() {
    const { id, service, metroId, local, notes, visible } = this;
    return { id, service: service.toJSON(), metro: { id: metroId }, local, notes, visible };
  }

  static fromJSON(json) {
    const { id, service, local, visible, notes, metro, regions } = json;

    return new ServiceConnection(
      ServiceConnectionId.fromJSON(id),
      Service.fromJSON(service),
      metro == null ? null : new MetroId(metro.id),
      local,
      visible,
      notes,
      ServiceRegion.fromJSONArray(regions)
    );
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = ServiceConnection.fromJSON(json);
      items.push(p);
    }

    items.sort((a, b) => {
      if (a.local && !b.local) {
        return -1;
      } else if (!a.local && b.local) {
        return 1;
      } else {
        return 0;
      }
    });

    return items;
  }
}
