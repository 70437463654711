import React from 'react';
import './ErrorView.scss';
import Config from '../../Config';

const ErrorView = ({ onResetState, history, isProjectDetailView }) => {
  const supportEmail = Config.SUPPORT_EMAIL;

  const onReloadPage = () => {
    onResetState();
    window.location.reload();
  };
  const onReturnToProjectList = () => {
    onResetState();
    history.push('/projects');
  };

  return (
    <div className={'error-view-wrapper'}>
      <div className={'error-view-info-wrapper'}>
        <h1>This was unexpected.</h1>
        <p>Pardon the interruption, we ran into a minor error. Refreshing the page should resolve things.</p>
        <p>
          If you continue to see this error, please contact us{' '}
          {!!supportEmail && (
            <>
              at <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            </>
          )}
        </p>
        <div className={'error-view-actions'}>
          <button className={'primary-button'} onClick={onReloadPage}>
            Reload the page
          </button>
          {isProjectDetailView && (
            <button className={'text-only-button'} onClick={onReturnToProjectList}>
              Return to my project list
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ErrorView;
