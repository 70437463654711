import BaseModel from './BaseModel';

import LabelId from './LabelId';
import Point from './Point';

export default class Label extends BaseModel {
  static fields = [
    { key: 'id', type: LabelId, stringify: true },
    { key: 'value', type: 'string', stringify: true },
    { key: 'color', type: 'string', stringify: true },
    { key: 'offset', type: Point },
  ];
}
