import { useQuery, useMutation } from '@apollo/react-hooks';
import { ApiEvents } from 'analytics';
import { Queries, Mutations } from 'gql';
import _ from 'lodash';
import { LoggerFactory } from 'logger';
import Group from 'model/Group';
import GroupId from 'model/GroupId';
import Location from 'model/Location';
import LocationId from 'model/LocationId';
import Metro from 'model/Metro';
import MetroId from 'model/MetroId';
import Service from 'model/Service';
import ServiceConnection from 'model/ServiceConnection';
import ServiceConnectionId from 'model/ServiceConnectionId';
import ServiceId from 'model/ServiceId';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import DocumentTitle from 'react-document-title';
import ObjectUtils from 'utils/ObjectUtils';
import Page from 'utils/Page';
import StopWatch from 'utils/StopWatch';
import ProjectDetailViewHeader from 'views/components/Header/ProjectDetailViewHeader';

import AspDataChangeNotification from '../components/AspDataChangeNotification';
import ExportModal from '../components/ExportModal';
import InfoPanel from '../components/InfoPanel';

import MetrosModal from '../components/MetrosModal';
import ProjectMap from '../components/ProjectMap';
import PhasePanel from '../components/PhasePanel';
import DeletePhaseModal from './modals/DeletePhaseModal';
import RenamePhaseModal from './modals/RenamePhaseModal';
import './ProjectDetailView.scss';
import './Sidebar.scss';
import ProjectMetro from 'model/ProjectMetro';
import { useCurrentProject, withProjectProvider } from 'providers/ProjectProvider';
import { dismissAllToasts, toast } from 'utils/Toast';
import useKeyUp from 'hooks/useKeyUp';
import cn from 'classnames';
import { withMarketplaceProvider } from 'providers/MarketplaceProvider';
import FeatureEvents from 'analytics/FeatureEvents';

const ProjectDetailView = ({ user }) => {
  const ref = useRef(null);
  const {
    projectId,
    project,
    isTemplate,
    template,
    phase,
    services,
    changePhase,
    phaseLoading,
    projectLoading,
    error: projectError,
    isEditing,
  } = useCurrentProject();
  const [trackedProjectLoad, setTrackedProjectLoad] = useState(false);
  const { data: metrosData, loading: metrosLoading, error: metrosError } = useQuery(Queries.GET_METROS);
  const { data: companyData } = useQuery(Queries.GET_COMPANY, { variables: { companyId: user.companyId } });
  const [execReorderPhases] = useMutation(Mutations.UPDATE_PROJECT_PHASE);

  const [metros, setMetros] = useState(null);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [infoView, setInfoView] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isMetrosModalOpen, setIsMetrosModalOpen] = useState(false);
  const [isAspUpdateModalOpen, setIsAspUpdateModalOpen] = useState(true);
  const [openPhasesModals, setOpenPhasesModals] = useState({
    rename: false,
    delete: false,
  });
  const [phaseModalData, setPhaseModalData] = useState({});
  const [connectionType, setConnectionType] = useState('topological');
  const [infraComponentCard, setInfraComponentCard] = useState('stacked');
  const [ringLatency, setRingLatency] = useState(5);
  const [ringColor, setRingColor] = useState('red');
  const [availableRegion, setAvailableRegion] = useState('all');
  const [metroType, setMetroType] = useState('all');
  const [metroPanelTab, setMetroPanelTab] = useState(0); //FIXME it's not working
  const [platform, setPlatform] = useState(null);
  const [platformMetros, setPlatformMetros] = useState(null);
  const [product, setProduct] = useState(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [zoom, setZoom] = useState();
  const [layers, setLayers] = useState({
    activeMetros: true,
    inactiveMetros: true,
    customerLocations: true,
    services: true,
    activeRegions: true,
    inactiveRegions: true,
    latencies: true,
    connections: true,
    directConnections: true,
    labels: true,
    latencyRings: false,
    availableRegions: true,
    customerLocationLines: true,
  });
  const [openMapMenu, setOpenMapMenu] = useState(null);
  const [mapType, setMapType] = useState('heatmap');
  const [marketplaceSegment, setMarketplaceSegment] = useState(null);
  const _logger = LoggerFactory.getLogger('ProjectDetailView');
  const sw = StopWatch.startNew();

  const loading = useMemo(() => projectLoading || metrosLoading || !phase || !project, [projectLoading, metrosLoading, project, phase]);
  const error = useMemo(() => !loading && (projectError || metrosError), [projectError, metrosError, loading]);

  //TODO: this should be updated to call _onSelectionChanged once map uses actual data (Metro, Location, Service) objects
  const select = (type, selectedItem) => {
    if (selectedItem instanceof ServiceConnection) {
      return _onSelectionChanged(selectedItem);
    }

    if (selectedItem) {
      const infoView = selectedItem instanceof ProjectMetro ? 'EditMetroView' : 'EditLocationView';
      openInfoView(infoView, selectedItem.id);
    } else {
      setSelectedItemId(null);
      setIsInfoOpen(false);
    }
  };

  const onChangePhase = (phaseId) => {
    try {
      dismissAllToasts();
      setSelectedItemId(null);
      setIsInfoOpen(false);
      changePhase(phaseId);
      _onClose();
    } catch (error) {
      return toast('An unexpected error occurred while changing phases', 'error');
    }
  };

  useEffect(() => {
    if (!ref?.current || !phase) return;
    const phasesHeader = ref.current.querySelector('.phases-header');
    const phaseTab = phasesHeader?.querySelector(`[id='${phase.id.value}']`);
    if (!phaseTab) return;
    phaseTab.scrollIntoView();
  }, [phase, ref]);

  const onReorderPhases = async (phaseId, order) => {
    if (!phaseId) return;
    try {
      await execReorderPhases({ variables: { projectId, phaseId, order } });
      dismissAllToasts();
    } catch (error) {
      return toast('An unexpected error occurred while reordering phases', 'error');
    }
  };

  const changeOpenMapMenu = (menuName) => {
    setOpenMapMenu((prev) => (prev === menuName ? null : menuName));
    if (menuName === 'layers') FeatureEvents.mapLayersShown(projectId, phase.id);
  };

  const _onClose = async () => {
    setIsInfoOpen(false);
    setSelectedItemId(null);
    setInfoView(null);
  };

  useKeyUp('Escape', _onClose);

  const _onError = () => {
    setIsInfoOpen(false);
    setSelectedItemId(null);
    setInfoView(null);
  };

  const openInfoView = (viewName, itemId = null) => {
    setIsInfoOpen(true);
    setSelectedItemId(itemId);
    setInfoView(viewName);
  };

  const _onAddLocations = () => openInfoView('AddLocationsView');

  const _onAddMultipleLocations = () => openInfoView('AddMultipleLocationsView');

  const _onASPImport = () => openInfoView('ASPImportView');

  const _onAddService = () => openInfoView('AddServiceView');

  const _onAddMetro = () => openInfoView('AddMetroView');

  const _onAddMetal = () => {
    openInfoView('AddMetalView');
    setPlatform('metal');
  };

  const _onAddEdge = () => {
    openInfoView('AddEdgeView');
    setPlatform('edge');
  };

  const _onExportBriefing = async () => setIsExportModalOpen(true);

  const _onManageMetros = () => setIsMetrosModalOpen(true);

  const _onMetroPanelTabChange = (metroPanelTab) => setMetroPanelTab(metroPanelTab);

  const _toggleLayer = (layer) => (e) => {
    if (e && e.stopPropagation) e.stopPropagation();
    setLayers((prev) => ({ ...prev, [layer]: !prev[layer] }));
  };

  const _toggleAvailableRegions = (layer) => (e) => {
    if (e && e.stopPropagation) e.stopPropagation();
    if (!layers[layer]) {
      setAvailableRegion('all');
      setLayers((prev) => ({
        ...prev,
        availableRegions: true,
        activeRegions: true,
        inactiveRegions: true,
      }));
    } else {
      setLayers((prev) => ({ ...prev, availableRegions: false, activeRegions: false, inactiveRegions: false }));
    }
  };

  const _onCloseExportBriefing = () => setIsExportModalOpen(false);
  const _onCloseMetrosModal = () => setIsMetrosModalOpen(false);
  const _onCloseAspUpdateModal = () => setIsAspUpdateModalOpen(false);

  const onOpenPhasesModals = (modalName, modalData) => {
    _onClosePhasesModals();
    setOpenPhasesModals((prevState) => ({ ...prevState, [modalName]: true }));
    setPhaseModalData({ ...modalData, projectId });
  };

  const _onClosePhasesModals = () => {
    setOpenPhasesModals({
      rename: false,
      delete: false,
    });
    setPhaseModalData({});
  };

  const phaseModalsProps = {
    onClose: _onClosePhasesModals,
    phaseModalData,
  };

  const _onSelectionChanged = async (selectedItem, keepOpen) => {
    if (!selectedItem) {
      if (keepOpen) {
        setSelectedItemId(null);
        return;
      }
      setSelectedItemId(null);
      setInfoView(null);
      setIsInfoOpen(false);
      return;
    }
    if (selectedItem.id && selectedItem.id.equals(selectedItemId)) {
      return; // do nothing, it's the same item
    }
    _logger.debug(`Selected item changed: ${selectedItem}`);

    //depending on the selected object type, we'll open a certain edit view
    const isMetro = selectedItem instanceof ProjectMetro;
    const isGroup = selectedItem instanceof Group;
    const isLocation = selectedItem instanceof Location;
    const isService = selectedItem instanceof ServiceConnection;
    const isUnconnectedService = selectedItem instanceof Service;
    const isAspSummary = selectedItem === 'aspSummary';
    const isMultipleLocations = selectedItem === 'multipleLocations';

    if (isMetro) {
      openInfoView('EditMetroView', selectedItem.id);
    } else if (isGroup) {
      openInfoView('EditGroupView', selectedItem.id);
    } else if (isLocation) {
      openInfoView('EditLocationView', selectedItem.id);
    } else if (isService) {
      openInfoView('EditServiceView', selectedItem.id);
    } else if (isUnconnectedService) {
      openInfoView('AddServiceView', selectedItem.id);
      setSelectedRegions([]);
    } else if (isAspSummary) {
      openInfoView('ASPInfo', 'aspSummary');
    } else if (isMultipleLocations) {
      openInfoView('AddMultipleLocationsView');
    }
  };

  const _enableRegion = (region) => {
    setSelectedRegions(selectedRegions.concat([region]));
  };

  const _disableRegion = (region) => {
    setSelectedRegions(selectedRegions.filter((r) => r && !r.equals(region)));
  };

  const _onPlatformMetrosChange = (newPlatformMetros, newProduct) => {
    if (!_.isEqual(newPlatformMetros, platformMetros) || !_.isEqual(newProduct, product)) {
      setPlatformMetros(newPlatformMetros);
      setProduct(newProduct);
    }
  };

  const _onChangeZoom = (coordinates) => {
    setZoom(coordinates);
  };

  useEffect(() => {
    return () => dismissAllToasts();
  }, []);

  useEffect(() => {
    if (platform !== null) {
      if (infoView !== 'AddMetalView' && infoView !== 'AddEdgeView') {
        setPlatform(null);
      }
    }
  }, [platform, infoView]);

  useEffect(() => {
    if (!metrosData) return;
    const metros = Metro.fromJSONArray(metrosData.metros);

    setMetros(metros);
  }, [metrosData]);

  useEffect(() => {
    if (trackedProjectLoad || !project) {
      return;
    }

    _logger.debug(`Project loaded successfully in ${sw.milliseconds}ms`);

    //track project load timing
    ApiEvents.projectLoaded(sw.milliseconds);

    sw.stop();
    setTrackedProjectLoad(true);
    // eslint-disable-next-line
  }, [project]);

  const getSelectedItem = (selectedItemId, data) => {
    const { phase } = data;
    const isMetro = selectedItemId instanceof MetroId;
    const isGroup = selectedItemId instanceof GroupId;
    const isLocation = selectedItemId instanceof LocationId;
    const isService = selectedItemId instanceof ServiceConnectionId;
    const isUnconnectedService = selectedItemId instanceof ServiceId;

    if (isMetro) {
      return phase.metros.find((item) => ObjectUtils.equals(selectedItemId, item.id));
    } else if (isGroup) {
      return phase.allGroups.find((item) => ObjectUtils.equals(selectedItemId, item.id));
    } else if (isLocation) {
      return phase.allLocations.find((item) => ObjectUtils.equals(selectedItemId, item.id));
    } else if (isService) {
      const services = phase.allServices;
      return services.find((item) => ObjectUtils.equals(selectedItemId, item.id));
    } else if (isUnconnectedService) {
      return phase.services.find((item) => ObjectUtils.equals(selectedItemId, item.id));
    }
  };

  const _setAvailableRegion = (region) => {
    if (region === 'all') {
      setAvailableRegion(region);
      setLayers((prev) => ({ ...prev, activeRegions: true, inactiveRegions: true }));
    } else {
      setAvailableRegion(region);
      setLayers((prev) => ({ ...prev, activeRegions: true, inactiveRegions: false }));
    }
  };

  const collapseSidebar = () => {
    setIsSidebarCollapsed((prev) => !prev);
  };

  if (error) {
    return (
      <DocumentTitle title={Page.title('Error! | Projects')}>
        <p style={{ margin: '100px' }}>ERROR! {error.message}</p>
      </DocumentTitle>
    );
  }

  if (loading) {
    return (
      <DocumentTitle title={Page.title('(Loading) | Projects')}>
        <div className="loading-container">
          <span className="loading-text">
            <div className="spinner"></div>
            Loading {isTemplate ? 'Template' : 'Project'}
          </span>
        </div>
      </DocumentTitle>
    );
  }

  const selectedItem = getSelectedItem(selectedItemId, { phase, metros, services });
  const isAspInfoOpen = isInfoOpen && selectedItemId === 'aspSummary';

  return (
    <DocumentTitle title={Page.title(`${project.name} | Projects`)}>
      <DndProvider backend={Backend}>
        <div className={`app ${isInfoOpen ? 'info-panel-open' : ''}`} ref={ref}>
          {isExportModalOpen && (
            <ExportModal
              project={project}
              phase={phase}
              metros={metros}
              onClose={_onCloseExportBriefing}
              connectionType={connectionType}
              layers={layers}
              ringLatency={ringLatency}
              ringColor={ringColor}
              metroType={metroType}
              mapType={mapType}
              marketplaceSegment={marketplaceSegment}
            />
          )}

          <DeletePhaseModal isOpen={openPhasesModals.delete} onChangePhase={onChangePhase} {...phaseModalsProps} />
          <RenamePhaseModal isOpen={openPhasesModals.rename} {...phaseModalsProps} />

          {isMetrosModalOpen && <MetrosModal projectId={projectId} phase={phase} metros={metros} onClose={_onCloseMetrosModal} />}

          {isAspUpdateModalOpen && <AspDataChangeNotification projectId={projectId} phase={phase} onClose={_onCloseAspUpdateModal} />}

          <ProjectDetailViewHeader user={user} onChangePhase={onChangePhase} onReorderPhases={onReorderPhases} onOpenPhasesModals={onOpenPhasesModals} />

          <div className={cn('sidebar', { isSidebarCollapsed })}>
            <PhasePanel
              phase={phase}
              projectId={projectId}
              selectedItem={selectedItem}
              onAddLocations={_onAddLocations}
              onAddMultipleLocations={_onAddMultipleLocations}
              onASPImport={_onASPImport}
              onAddService={_onAddService}
              onAddMetro={_onAddMetro}
              onAddMetal={_onAddMetal}
              onAddEdge={_onAddEdge}
              onExportBriefing={_onExportBriefing}
              onSelectionChanged={_onSelectionChanged}
              onClose={_onClose}
              onError={_onError}
              isAspInfoOpen={isAspInfoOpen}
              loadingPhase={phaseLoading}
              collapseSidebar={collapseSidebar}
              isSidebarCollapsed={isSidebarCollapsed}
              template={template}
              mapConfig={{
                connectionType,
                layers,
                ringLatency,
                ringColor,
                metroType,
                mapType,
                marketplaceSegment,
              }}
              companyId={user.companyId}
              isDSPAllowed={companyData.company.isDSPAllowed}
            />

            <InfoPanel
              phaseId={phase.id}
              servicesConnections={phase.unconnectedServices}
              projectId={projectId}
              phase={phase}
              selectedItem={selectedItem}
              selectedRegions={selectedRegions}
              activeMetros={phase.metros}
              directConnections={phase.directConnections}
              metros={metros}
              services={services}
              view={infoView}
              onClose={_onClose}
              isInfoOpen={isInfoOpen}
              onMetroPanelTabChange={_onMetroPanelTabChange}
              metroPanelTab={metroPanelTab}
              onError={_onError}
              onSelectionChanged={_onSelectionChanged}
              onEnableRegion={_enableRegion}
              onDisableRegion={_disableRegion}
              onPlatformMetrosChange={_onPlatformMetrosChange}
              onChangeZoom={_onChangeZoom}
            />
          </div>

          <ProjectMap
            phaseId={phase.id}
            projectId={projectId}
            project={project}
            phase={phase}
            metros={metros}
            isInfoOpen={isInfoOpen}
            onSelect={select}
            onEnableRegion={_enableRegion}
            onDisableRegion={_disableRegion}
            onManageMetros={_onManageMetros}
            selected={selectedItem}
            selectedRegions={selectedRegions}
            connectionType={connectionType}
            setConnectionType={setConnectionType}
            setInfraComponentCard={setInfraComponentCard}
            infraComponentCard={infraComponentCard}
            layers={layers}
            toggleLayer={_toggleLayer}
            ringLatency={ringLatency}
            setRingLatency={setRingLatency}
            ringColor={ringColor}
            setRingColor={setRingColor}
            availableRegion={availableRegion}
            onMetroPanelTabChange={_onMetroPanelTabChange}
            setAvailableRegion={_setAvailableRegion}
            metroType={metroType}
            setMetroType={setMetroType}
            toggleAvailableRegions={_toggleAvailableRegions}
            platform={platform}
            platformMetros={platformMetros}
            product={product}
            onClose={_onClose}
            isSidebarCollapsed={isSidebarCollapsed}
            openMapMenu={openMapMenu}
            changeOpenMapMenu={changeOpenMapMenu}
            mapType={mapType}
            marketplaceSegment={marketplaceSegment}
            onChangeMapType={setMapType}
            onChangeMarketplaceSegment={setMarketplaceSegment}
            isEditing={isEditing}
            zoom={zoom}
          />
        </div>
      </DndProvider>
    </DocumentTitle>
  );
};

export default withProjectProvider(withMarketplaceProvider(ProjectDetailView));
