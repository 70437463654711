import { useLazyQuery } from '@apollo/react-hooks';
import { Queries } from 'gql';
import Project from 'model/Project';
import ProjectPhase from 'model/ProjectPhase';
import { useHistory } from 'react-router';
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';

import { useLocation, useParams } from 'react-router';
import { toast } from 'utils/Toast';
import { PROJECT_LIST_VIEW_SELECTION, TEMPLATE_LIST_VIEW_SELECTION } from 'views/ProjectListView/DefaultListViewSelection';

const ProjectContext = createContext(null);

const LOCAL_STORAGE_KEY = 'selectedPhases';
const getStoredSelectedPhases = (projectId) => {
  const storedItem = localStorage.getItem(LOCAL_STORAGE_KEY);
  return storedItem && JSON.parse(storedItem)[projectId];
};

const storeSelectedPhase = (projectId, phaseId) => {
  const selectedPhase = JSON.stringify({ [projectId]: phaseId });
  localStorage.setItem(LOCAL_STORAGE_KEY, selectedPhase);
};

const setDefaultListViewSelection = (isTemplate) => {
  const linkState = isTemplate ? TEMPLATE_LIST_VIEW_SELECTION : PROJECT_LIST_VIEW_SELECTION;
  localStorage.setItem('listViewSelection', JSON.stringify(linkState));
};

const ProjectProvider = ({ children, user }) => {
  const history = useHistory();
  const [execGetTemplate, { data: templateData, error: templateError }] = useLazyQuery(Queries.GET_TEMPLATE, { fetchPolicy: 'cache-and-network' });
  const [execGetLatestTemplate, { data: latestTemplateData, error: latestTemplateError }] = useLazyQuery(Queries.GET_LATEST_TEMPLATE_VERSION, {
    fetchPolicy: 'cache-and-network',
  });
  const [execGetProject, { data: projectData, loading: projectLoading, error: projectError }] = useLazyQuery(Queries.GET_PROJECT);
  const [execGetPhase, { data: phaseData, loading: phaseLoading, error: phaseError }] = useLazyQuery(Queries.GET_PHASE, { fetchPolicy: 'network-only' });
  const location = useLocation();
  const lastTemplateVersion = location.state?.lastTemplateVersion;
  const { projectId: paramProjectId, templateId } = useParams();
  const isTemplate = useMemo(() => !!templateId, [templateId]);

  useMemo(() => setDefaultListViewSelection(isTemplate), [isTemplate]);

  const [template, setTemplate] = useState();
  const projectId = useMemo(() => paramProjectId || template?.project.id, [paramProjectId, template]);
  const [project, setProject] = useState();
  const [phase, setPhase] = useState();
  const [services, setServices] = useState([]);
  const [processLoading, setProcessLoading] = useState(false);

  const changeProcessLoading = (isLoading) => {
    setProcessLoading(isLoading);
  };

  const [selectedPhaseId, setSelectedPhaseId] = useState(getStoredSelectedPhases(projectId));
  const selectedPhase = useMemo(() => project?.phases?.find((phase) => phase.id.value === selectedPhaseId), [project, selectedPhaseId]);

  const changePhase = (newSelectedPhaseId) => {
    setSelectedPhaseId(newSelectedPhaseId);
    storeSelectedPhase(projectId, newSelectedPhaseId);
  };

  const isOwner = useMemo(() => (isTemplate ? user?.id.value === template?.owner.id : user?.id.value === project?.owner.id.value), [
    isTemplate,
    template,
    project,
    user,
  ]);

  const [isEditing, setIsEditing] = useState(location.state?.edit || !isTemplate);
  const toggleEditing = () => setIsEditing((prev) => !prev);

  useEffect(() => {
    setTemplate();
    setProject();
    setPhase();
    setSelectedPhaseId();
    if (isTemplate) {
      const getTemplate = lastTemplateVersion ? execGetLatestTemplate : execGetTemplate;
      getTemplate({ variables: { templateId } });
    } else {
      setIsEditing(true);
      execGetProject({ variables: { projectId } });
    }
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (!templateData && !latestTemplateData) return;
    const template = lastTemplateVersion ? latestTemplateData?.getLatestTemplateVersion : templateData.template;
    setTemplate(template);
    // eslint-disable-next-line
  }, [templateData, latestTemplateData]);

  useEffect(() => {
    if (!projectId) return;
    execGetProject({ variables: { projectId } });
    // eslint-disable-next-line
  }, [template]);

  useEffect(() => {
    if (!projectData) return;
    const project = Project.fromJSON(projectData.project);
    setProject(project);
    const storedPhaseId = getStoredSelectedPhases(projectData.project.id);
    const firstPhaseId = project.phases[0].id.value;

    const phaseId = storedPhaseId || firstPhaseId;

    if (!storedPhaseId) storeSelectedPhase(projectId, phaseId);
    setSelectedPhaseId(phaseId);
    // eslint-disable-next-line
  }, [projectData]);

  useEffect(() => {
    if (!projectId || !selectedPhaseId) return;
    execGetPhase({ variables: { projectId, phaseId: selectedPhaseId } });
    // eslint-disable-next-line
  }, [project]);

  useEffect(() => {
    if (!phaseData) return;
    const phase = ProjectPhase.fromJSON(phaseData.phase);
    const services = phase ? phase.services : [];

    setPhase(phase);
    setServices(services);
  }, [phaseData]);

  useEffect(() => {
    if (!projectId || !selectedPhase) return;
    execGetPhase({ variables: { projectId, phaseId: selectedPhaseId } });
    // eslint-disable-next-line
  }, [selectedPhaseId]);

  const error = templateError || latestTemplateError || projectError || phaseError;

  useEffect(() => {
    if (error) {
      toast('An unexpected error occurred loading the project', 'error');
      history.push({
        pathname: `/`,
      });
    }
    // eslint-disable-next-line
  }, [error]);

  return (
    <ProjectContext.Provider
      value={{
        project,
        projectId,
        template,
        templateId,
        phase,
        selectedPhase,
        selectedPhaseId,
        services,
        changePhase,
        isOwner,
        phaseLoading,
        projectLoading,
        error,
        toggleEditing,
        isEditing,
        isTemplate,
        lastTemplateVersion,
        processLoading,
        changeProcessLoading,
      }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useCurrentProject = () => {
  const projectContext = useContext(ProjectContext);

  if (!projectContext) {
    throw new Error('ProjectContext not found');
  }

  return projectContext;
};

export const withProjectProvider = (Component) => {
  return (props) => (
    <ProjectProvider {...props}>
      <Component {...props} />
    </ProjectProvider>
  );
};

export default ProjectProvider;
