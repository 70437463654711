export function getTheme(theme) {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary: '#58B8E6',
      neutral20: '#999',
    },
  };
}
