import React, { useState } from 'react';
import WarningIcon from 'views/components/icons/Warning';
import { MetroStatus } from 'model/Metro';
import FabricConnectionsTable from './FabricConnectionsTable';
import DirectConnectionsTable from './DirectConnectionsTable';

const Connections = ({ latencies, metro, activeMetros, directConnections, projectId, phaseId }) => {
  const [currentPicker, setCurrentPicker] = useState(null);
  const otherMetros = activeMetros.filter((m) => !metro.id.equals(m.id));
  const directConnection = directConnections.find((dm) => metro.id.equals(dm.source.id));
  const connectedTo = directConnection?.connectedTo ?? [];

  const isMetroUnderAcquisition =
    metro.status === MetroStatus.UNDER_ACQUISITION &&
    // Handle edge case where Metro is both under acquisition
    // and is an existing metro.
    !metro.existing;

  const handleCurrentPicker = (id) => {
    setCurrentPicker(id);
  };

  return (
    <div className="content-view">
      <div className="content-section">
        {isMetroUnderAcquisition ? (
          <div className="warning-message">
            <WarningIcon width={23} height={24} color="black" /> This metro is currently Under Acquisition
          </div>
        ) : null}

        <h3>Fabric Connection Latency to Other Metros</h3>
        <FabricConnectionsTable latencies={latencies} />
        <h3>Enable Direct Connections to Other Metros</h3>
        <DirectConnectionsTable
          otherMetros={otherMetros}
          connectedTo={connectedTo}
          projectId={projectId}
          phaseId={phaseId}
          metro={metro}
          directConnections={directConnections}
          handleCurrentPicker={handleCurrentPicker}
          currentPicker={currentPicker}
        />
      </div>
    </div>
  );
};

export default Connections;
