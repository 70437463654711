import Assert from '../utils/Assert';
import MetroId from './MetroId';

export default class ServiceMetroConnection {
  constructor(id, name, local, connected) {
    Assert.instanceOf(id, MetroId);
    Assert.isString(name);
    Assert.isBoolean(local);
    Assert.isBoolean(connected);

    this.id = id;
    this.name = name;
    this.local = local || false;
    this.connected = connected || false;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof ServiceMetroConnection)) {
      return false;
    }

    return this.id.equals(other.id);
  }

  toString() {
    return `${this.constructor.name}{ id:${this.id}, name:'${this.name}' local:${this.local} connected:${this.connected} }`;
  }

  toJSON() {
    const { id, name, local, connected } = this;
    return { id, name, local, connected };
  }

  static fromJSON(json) {
    const { id, name, local, connected } = json;

    return new ServiceMetroConnection(MetroId.fromJSON(id), name, local, connected);
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = ServiceMetroConnection.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}
