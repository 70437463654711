import React from 'react';

const InfoIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7 9.4V7" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 4.59961H7.01" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default InfoIcon;
