import React, { useState } from 'react';
import MarketplaceChildItem from './MarketplaceChildItem';
import TriangleIcon from 'views/components/icons/Triangle';
import classNames from 'classnames';
import { useMarketplaceSegments } from 'providers/MarketplaceProvider';

const MarketplaceCategoryItem = ({ item: { name, count, subSegments }, calculateWidth }) => {
  const [showSubSegments, setShowSubSegments] = useState(false);
  const { marketplaceSegments } = useMarketplaceSegments();

  let { average } = marketplaceSegments.find((segment) => segment.name === name);
  average = Math.round(average);

  const countWidth = calculateWidth(count);
  const countStyle = { width: countWidth };
  const averageWidth = calculateWidth(average);
  const averageStyle = { width: averageWidth };

  const hasSubSegments = !!subSegments.length;
  const subSegmentsText = subSegments.length ? `(${subSegments.length})` : '';

  const toggle = () => {
    setShowSubSegments((prev) => !prev);
  };

  return (
    <div className="marketplace-item">
      <div className={classNames('title', { expandable: hasSubSegments })}>
        <span onClick={toggle}>{`${name} ${subSegmentsText}`}</span>
        {hasSubSegments && (
          <div onClick={toggle} data-testid={'open-icon'} className={classNames('open-icon', { open: showSubSegments })}>
            <TriangleIcon width={6} height={5} color="black" />
          </div>
        )}
      </div>

      <div className="bar-wrapper">
        <span className="value">{count}</span>
        <div className="bar" style={countStyle}></div>
      </div>

      <div className="bar-wrapper avg">
        <span className="value">avg {average}</span>
        <div className="bar" style={averageStyle}></div>
      </div>

      {hasSubSegments &&
        showSubSegments &&
        subSegments
          .sort((a, b) => (a.count < b.count ? 1 : -1))
          .map((child) => <MarketplaceChildItem key={child.id} item={child} calculateWidth={calculateWidth} />)}
    </div>
  );
};

export default MarketplaceCategoryItem;
