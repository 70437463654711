import React from 'react';

const PlusSaveIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M31 40L16 29.1667L1 40V5.33333C1 4.18406 1.45153 3.08186 2.25526 2.2692C3.05898 1.45655 4.14907 1 5.28571 1H26.7143C27.8509 1 28.941 1.45655 29.7447 2.2692C30.5485 3.08186 31 4.18406 31 5.33333V40Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="31" cy="18" r="10" fill="#F5F5F5" stroke={color} strokeWidth="2" />
      <line x1="31" y1="14" x2="31" y2="22" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <line x1="27" y1="18" x2="35" y2="18" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default PlusSaveIcon;
