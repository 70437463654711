import React, { Fragment } from 'react';
import Circle from '../Circle';
import getSegmentRadius from '../helpers/getSegmentRadius';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import getHeatmapPoints from '../helpers/getHeatmapPoints';

const getMaxValue = (heatmapPoints) => {
  const values = heatmapPoints.map((point) => parseFloat(point[2]));
  return Math.max(...values);
};

const MarketplaceLayer = ({ mapType, marketplaceSegment, metros, zoom, isMinimap }) => {
  const radius = isMinimap ? 8 : zoom <= 2 ? 20 : zoom > 2 && zoom < 3.2 ? 28 : zoom >= 3.2 && zoom < 4.4 ? 40 : 50;
  const blur = isMinimap ? 10 : 20;

  let heatmapPoints, maxCount;
  if (marketplaceSegment) {
    heatmapPoints = getHeatmapPoints(metros, marketplaceSegment);
    maxCount = getMaxValue(heatmapPoints);
  }
  return (
    <Fragment>
      {mapType === 'heatmap' && marketplaceSegment && (
        <HeatmapLayer
          points={heatmapPoints}
          longitudeExtractor={(m) => m[1]}
          latitudeExtractor={(m) => m[0]}
          intensityExtractor={(m) => parseFloat(m[2])}
          radius={radius}
          max={maxCount}
          maxZoom={1}
          blur={blur}
          minOpacity={0}
          gradient={{
            0.4: 'blue',
            0.6: 'cyan',
            0.7: 'lime',
            0.8: 'yellow',
            1.0: '#F1684B',
          }}
        />
      )}
      {mapType === 'bubblePlot' &&
        marketplaceSegment &&
        metros.map((metro) => {
          const marketplaceSegmentRadius = getSegmentRadius({ marketplaceSegment, metros, metro });
          if (!marketplaceSegmentRadius) return null;
          return (
            <Circle
              key={`marketplace-circle-${metro.code}`}
              radiusLine
              center={metro.coordinates.toLeaflet()}
              radius={marketplaceSegmentRadius}
              color={'#006BD9'}
              fillOpacity={0.25}
              interactive={false}
            />
          );
        })}
    </Fragment>
  );
};

export default MarketplaceLayer;
