import Assert from '../utils/Assert';

export default class AuthToken {
  constructor(authorizationCode, value, expires) {
    Assert.isString(authorizationCode);
    Assert.isString(value);
    Assert.isDate(expires);

    this.authorizationCode = authorizationCode;
    this.value = value;
    this.expires = expires;

    Object.freeze(this);
  }

  get isExpired() {
    const now = new Date();

    return now.getTime() >= this.expires.getTime();
  }

  equals(other) {
    if (!(other instanceof AuthToken)) {
      return false;
    }

    return this.authorizationCode === other.authorizationCode && this.value === other.value && this.expires.getTime() === other.expires.getTime();
  }

  toString() {
    return `${this.constructor.name}{ authorizationCode:'${this.authorizationCode}', expires:'${this.expires}' }`;
  }

  toJSON() {
    const { authorizationCode, value, expires } = this;
    return { authorizationCode, value, expires: expires.getTime() };
  }

  static fromJSON(json) {
    const { authorizationCode, value, expires } = json;

    return new AuthToken(authorizationCode, value, new Date(expires));
  }
}
