import BaseModel from './BaseModel';
import NetworkEdgeCategoryId from './NetworkEdgeCategoryId';
import NetworkEdgeDevice from './NetworkEdgeDevice';

export default class NetworkEdgeCategory extends BaseModel {
  static fields = [
    { key: 'id', type: NetworkEdgeCategoryId, stringify: true },
    { key: 'name', type: 'string', stringify: true },
    { key: 'enabled', type: 'boolean' },
    { key: 'deprecated', type: 'boolean' },
    { key: 'metroCodes', type: 'array', of: 'string' },
    { key: 'devices', type: 'array', of: NetworkEdgeDevice },
  ];
}
