import Group from 'model/Group';
import Location from 'model/Location';
import { usePhaseItems } from 'providers/PhaseItemsProvider';
import { useCurrentProject } from 'providers/ProjectProvider';
import useAddLocationGroup from './useAddLocationGroup';

const useOnGroup = ({ selectedItem, onClose }) => {
  const { checkedItems, pendingMoves, setPendingMoves, setCheckedItems, clearBulkActionUI } = usePhaseItems();
  const { projectId, selectedPhaseId: phaseId } = useCurrentProject();
  const { addLocationGroup } = useAddLocationGroup();

  return async () => {
    const locations = checkedItems.filter((item) => item instanceof Location);
    if (locations.length <= 0) {
      return; // Don't do anything if we don't have locations to group.
    }
    if (!!checkedItems.includes(selectedItem)) {
      onClose();
    }
    const newPendingMoves = pendingMoves.concat(locations.map((item) => ({ item, metroId: '__GROUP__' }))).concat([
      {
        item: Group.fromJSON({
          // create a temporary group to show loading state
          id: '__GROUP__',
          name: 'New Group',
          color: '#4F98ED',
          locations: locations,
        }),
        metroId: undefined,
      },
    ]);
    setPendingMoves((prev) => [...prev, ...newPendingMoves]);

    await addLocationGroup(
      projectId,
      phaseId,
      'New Group',
      '#4F98ED',
      locations.map((l) => l.id)
    );
    const pendingMovesFiltered = pendingMoves.filter((m) => m.item.id.value !== '__GROUP__').filter((m) => !locations.find((i) => m.item.equals(i)));
    setPendingMoves(pendingMovesFiltered);
    setCheckedItems([]);
    clearBulkActionUI();
  };
};

export default useOnGroup;
