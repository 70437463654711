import { now } from 'usertiming';

/**
 * Private fields.
 * @type {WeakMap}
 */
const PRIVATE = new WeakMap();

/**
 * The number of milliseconds in 1 second.
 * @type {number}
 */
const MILLISECONDS_IN_SECOND = 1000;

const getNow = () => {
  return window.performance ? window.performance.now() : now();
};

export default class StopWatch {
  constructor() {
    //default private values
    const p = {
      _startTime: null,
      _stopTime: null,
    };

    PRIVATE.set(this, p);

    Object.freeze(this);
  }

  get running() {
    const p = PRIVATE.get(this);
    const startTime = p._startTime;
    const stopTime = p._stopTime;

    return startTime !== null && stopTime === null;
  }

  get milliseconds() {
    const p = PRIVATE.get(this);
    const startTime = p._startTime;
    const stopTime = p._stopTime;

    if (startTime === null) {
      return 0;
    }

    const stop = stopTime || getNow();

    return Math.round(stop - startTime);
  }

  get seconds() {
    return this.milliseconds / MILLISECONDS_IN_SECOND;
  }

  static startNew() {
    const sw = new StopWatch();
    sw.start();

    return sw;
  }

  start() {
    const p = PRIVATE.get(this);

    p._startTime = getNow();
  }

  stop() {
    const p = PRIVATE.get(this);

    p._stopTime = getNow();
  }

  toString() {
    return `StopWatch{time:"${this.milliseconds}ms"}`;
  }
}
