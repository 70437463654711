import React from 'react';
import { Pane, Rectangle, SVGOverlay } from 'react-leaflet';
import L from 'leaflet';

const defaultBounds = new L.LatLngBounds([
  [-100, -200],
  [100, 200],
]);

const GrayOverlay = () => (
  <Pane name="gray-overlay" style={{ zIndex: 1700 }}>
    <SVGOverlay bounds={defaultBounds}>
      <rect opacity="0.5" width="100%" height="100%" fill="#333333" />
    </SVGOverlay>

    <Rectangle className="gray-overlay" bounds={defaultBounds} stroke={false} interactive={true} color="#333333" fillColor="#333333" fillOpacity="0" />
  </Pane>
);

export default GrayOverlay;
