import React from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { dismissAllToasts } from 'utils/Toast';
import withDeleteProjectItems from '../PhasePanel/hocs/withDeleteProjectItems';
import ContextMenu from './ContextMenu';
import { getIcon } from './icons';

class PhaseLocation extends React.Component {
  state = {};

  onDelete = async () => {
    const { deleteProjectItems, projectId, phaseId, location, onClose, selected } = this.props;
    this.setState({ contextMenu: false });
    if (selected?.id === location.id) {
      onClose();
    }
    await deleteProjectItems(projectId, phaseId, [location.id]);
    dismissAllToasts();
  };

  _onContextMenu = () => {
    const { isEditing } = this.props;
    if (!isEditing) return;
    this.setState({ contextMenu: true });
  };

  render() {
    const { location, selected, onSelect, loading, iconSize = 1 } = this.props;
    const { contextMenu } = this.state;
    const type = location.type.value.toLowerCase();
    return (
      <Marker
        location={location}
        position={location.coordinates.toLeaflet()}
        icon={getIcon(type, iconSize, selected && location.id.equals(selected.id))}
        onClick={() => onSelect(type, location)}
        onContextMenu={this._onContextMenu}
        opacity={loading ? 0.8 : 1}
        zIndexOffset={300}>
        {location.name && (
          <Tooltip pane={'tooltipPane'} direction="top" offset={[0, -18]}>
            {location.name}
          </Tooltip>
        )}
        {contextMenu && (
          <ContextMenu onClose={() => this.setState({ contextMenu: false })}>
            <button onClick={this.onDelete}>Delete Site</button>
          </ContextMenu>
        )}
      </Marker>
    );
  }
}

export default withDeleteProjectItems(PhaseLocation);
