import { Mutations } from 'gql';
import withMutation from 'gql/withMutation';

import GroupId from 'model/GroupId';
import LocationId from 'model/LocationId';
import ServiceConnectionId from 'model/ServiceConnectionId';
import { useMutation } from 'react-apollo';

export default withMutation({
  name: 'MOVE_PROJECT_ITEMS',
  mutation: Mutations.MOVE_PROJECT_ITEMS,
  mutateProp: 'moveProjectItems',
  getMutationArgs(projectId, phaseId, newMetroId, itemIds) {
    const locationIds = itemIds.filter((i) => i instanceof LocationId);
    const locationGroupIds = itemIds.filter((i) => i instanceof GroupId);
    const serviceConnectionIds = itemIds.filter((i) => i instanceof ServiceConnectionId);
    return {
      variables: {
        projectId,
        phaseId,
        locationIds,
        locationGroupIds,
        serviceConnectionIds,
        newMetroId,
      },
    };
  },
});

export const useMoveProjectItems = () => {
  const [doMoveProjectItems] = useMutation(Mutations.MOVE_PROJECT_ITEMS);

  const moveProjectItems = async (projectId, phaseId, newMetroId, itemIds) => {
    const locationIds = itemIds.filter((i) => i instanceof LocationId);
    const locationGroupIds = itemIds.filter((i) => i instanceof GroupId);
    const serviceConnectionIds = itemIds.filter((i) => i instanceof ServiceConnectionId);

    await doMoveProjectItems({
      variables: {
        projectId,
        phaseId,
        locationIds,
        locationGroupIds,
        serviceConnectionIds,
        newMetroId,
      },
    });
  };
  return { moveProjectItems };
};
