import BaseModel from './BaseModel';
import MetalPlanId from './MetalPlanId';
// import Metro from "./Metro";

export default class MetalPlan extends BaseModel {
  static fields = [
    { key: 'id', type: MetalPlanId, stringify: true },
    { key: 'name', type: 'string', stringify: true },
    { key: 'deprecated', type: 'boolean' },
  ];
}
