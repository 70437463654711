import React, { useState, useEffect, useMemo } from 'react';
import TemplateCard from './TemplateCard';
import SortingControlsTemplates from './SortingControlsTemplates';
import { useHistory } from 'react-router-dom';
import { DIRECTION, FILTER, SORT } from './sortingTemplates';
import Pagination from './Pagination';
import { getCachedSort, setCachedSorted } from '../cachedSortingState';

const TemplatesList = ({ templates, user, renderNoTemplates }) => {
  const cachedStateKey = 'templatesList';
  const userId = user.id.value;
  const { category, direction } = getCachedSort({ cachedStateKey, userId });
  const [templatesFormatted, setTemplatesFormatted] = useState(templates);
  const history = useHistory();
  const [searchBy, setSearchBy] = useState('');
  const [filterBy, setFilterBy] = useState(FILTER.ALL_CATEGORIES);
  const [sortBy, setSortBy] = useState(category || SORT.TIMES_SAVED);
  const [sortDirection, setSortDirection] = useState(direction || DIRECTION.ASCENDING);
  const [currentTemplates, setCurrentTemplates] = useState();
  const itemsPerPage = useMemo(() => 5, []);

  const setSearchText = (value) => {
    setSearchBy(value.trim().toLowerCase());
  };

  const setFilter = (value) => {
    setFilterBy(value);
  };

  const setSort = (value) => {
    setSortBy(value);
  };

  const setDirection = (value) => {
    setSortDirection(value);
  };

  const SORT_FUNCTIONS = useMemo(
    () => ({
      TIMES_SAVED: {
        [DIRECTION.ASCENDING]: (a, b) => a.timesSaved - b.timesSaved,
        [DIRECTION.DESCENDING]: (a, b) => b.timesSaved - a.timesSaved,
      },
      TIMES_USED: {
        [DIRECTION.ASCENDING]: (a, b) => a.timesUsed - b.timesUsed,
        [DIRECTION.DESCENDING]: (a, b) => b.timesUsed - a.timesUsed,
      },
      UPDATED_DATE: {
        [DIRECTION.ASCENDING]: (a, b) => a.lastUpdated - b.lastUpdated,
        [DIRECTION.DESCENDING]: (a, b) => b.lastUpdated - a.lastUpdated,
      },
      CREATED_DATE: {
        [DIRECTION.ASCENDING]: (a, b) => a.createdOn - b.createdOn,
        [DIRECTION.DESCENDING]: (a, b) => b.createdOn - a.createdOn,
      },
    }),
    []
  );

  const filterByText = (templates, searchBy) => {
    return templates.filter(
      (template) =>
        template.name.toLowerCase().includes(searchBy) ||
        template.description.toLowerCase().includes(searchBy) ||
        template.owner.email.toLowerCase().includes(searchBy)
    );
  };

  useEffect(() => {
    if (!templates) return;
    const filteredBySearch = !searchBy ? templates : filterByText(templates, searchBy);
    const filteredByfFilter = !filterBy ? filteredBySearch : filteredBySearch.filter((template) => template.categories.includes(filterBy));
    const sortedBySort = !sortBy ? filteredByfFilter : [...filteredByfFilter].sort(SORT_FUNCTIONS[sortBy][sortDirection]);
    setTemplatesFormatted(sortedBySort);
    setCachedSorted({ userId, cachedStateKey, category: sortBy, direction: sortDirection });
    // eslint-disable-next-line
  }, [searchBy, sortBy, filterBy, sortDirection, templates]);

  const renderTemplates = () => {
    return (
      <div>
        <SortingControlsTemplates
          searchBy={searchBy}
          setSearchBy={setSearchText}
          setFilterBy={setFilter}
          filterBy={filterBy}
          setSortBy={setSort}
          sortBy={sortBy}
          setSortDirection={setDirection}
          sortDirection={sortDirection}
        />

        <div className="cards-container">
          {currentTemplates?.map((template) => (
            <TemplateCard key={template.id} template={template} history={history} user={user} />
          ))}
        </div>

        <Pagination items={templatesFormatted} setCurrentItems={setCurrentTemplates} itemsPerPage={itemsPerPage} />
      </div>
    );
  };

  return templates?.length ? renderTemplates() : renderNoTemplates();
};

export default TemplatesList;
