import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { LoggerFactory } from 'logger';
import { toast } from 'utils/Toast';
import { useMutation } from 'react-apollo';
import { Mutations, Queries } from 'gql';
import debounce from 'debounce';
import { LocationType } from 'model/Location';
import ProjectPhaseId from 'model/ProjectPhaseId';
import GeoCoordinates from 'model/GeoCoordinates';
import AddressAutocompleteTextBox from 'views/components/AddressAutocompleteTextBox';
import Button from 'views/components/Button';
import LocationTypeList from 'views/components/LocationTypeList';
import TextInput from 'views/components/TextInput';
import Config from 'Config';
import '../AddLocationView.scss';
import '../AddView.scss';

const EditLocationView = ({ selectedItem, phaseId, projectId, onClose, onError }) => {
  const [execUpdateLocation, { error: updateError }] = useMutation(Mutations.UPDATE_LOCATION);
  const [deleteProjectItems, { loading: loadingDelete, error: errorDelete }] = useMutation(Mutations.DELETE_PROJECT_ITEMS);

  const [location, setLocation] = useState(selectedItem);
  const employeesValid = selectedItem?.employees > 0;

  useEffect(() => {
    if (selectedItem) {
      setLocation(selectedItem);
    }
  }, [selectedItem]);

  const loading = false;

  const logger = useMemo(() => LoggerFactory.getLogger('EditServiceView'), []);

  if (errorDelete) {
    logger.error(`An error occurred executing mutation: ${errorDelete.message}`);
    toast('An unexpected error occurred deleting location', 'error');
    onError(errorDelete);
  }

  if (updateError) {
    logger.error(`An error occurred executing mutation: ${updateError.message}`);
    toast('An unexpected error occurred updating location', 'error');
    onError(updateError);
  }

  const updateLocation = useCallback(
    debounce(async (newLocation) => {
      logger.debug(`Updating location with id: '${location.id}'`);
      const { name, address, coordinates, employees, notes, type, id: locationId } = newLocation;

      const variables = {
        projectId,
        phaseId,
        locationId,
        name,
        address,
        coordinates,
        employees,
        notes,
        type: type.value,
      };

      await execUpdateLocation({ variables }, { refetchQueries: Queries.GET_PHASE, variables: { projectId, phaseId } });
    }, Config.TEXT_INPUT_DELAY),
    []
  );

  const onChange = (key, value) => {
    let newLocation;

    if (key === 'address') {
      const { label, city, state, postalCode, country } = value;
      const address = { label, city, state, postalCode, country };
      const coordinates = GeoCoordinates.parse(value.coordinates);

      newLocation = { ...location, address, coordinates };
    } else {
      newLocation = { ...location, [key]: value };
    }

    setLocation(newLocation);
    updateLocation(newLocation);
  };

  const onDeleteClick = async () => {
    logger.debug(`Delete location with id: '${location.id}'`);

    const variables = {
      projectId,
      phaseId,
      locationIds: [location.id],
      locationGroupIds: [],
      serviceConnectionIds: [],
    };

    onClose();
    await deleteProjectItems({ variables });
  };

  const renderProgress = () => {
    return (
      <div className="buttons">
        <div className="loading"></div>
      </div>
    );
  };

  const renderButtons = (onDeleteClick) => {
    return (
      <div className="buttons">
        <Button color="red" text="Delete Location" onClick={onDeleteClick} isStretch={true} />
      </div>
    );
  };

  if (!location) {
    return null;
  }

  return (
    <div className="AddView AddLocationView">
      <TextInput placeholder="Enter a Label..." value={location.name} onValueChange={(value) => onChange('name', value)} />

      <div className="type" data-testid={'location-type-list'}>
        <p>Customer sites are discrete, physical addresses within Acme’s network: data centers, offices, factories, business centers, branch locations, etc.</p>
        <h3>What type of customer site?</h3>
        <LocationTypeList items={Array.from(LocationType.values)} selectedItem={location.type} onSelectionChanged={(value) => onChange('type', value)} />
      </div>

      <div className="where">
        <h3>Where is it located?</h3>
        <AddressAutocompleteTextBox
          placeholder="Enter an address or city name..."
          defaultValue={location.address.label}
          value={location.address}
          onAddressChange={(value) => onChange('address', value)}
        />
      </div>

      <div className="employees" data-testid={'employees'}>
        <h3>Number of employees</h3>
        <span className="input">
          <TextInput
            className={employeesValid ? '' : 'error'}
            type="number"
            value={location.employees}
            onValueChange={(value) => onChange('employees', value ? parseInt(value, 10) : null)}
          />
          Employees at location
        </span>
        <span className="buttons-legend">Or, pick a size:</span>
        <div className="size-buttons">
          <Button color={location.employees > 0 && location.employees < 100 ? 'red' : 'lightGray'} text={'S'} onClick={() => onChange('employees', 50)} />
          <Button color={location.employees > 100 && location.employees < 500 ? 'red' : 'lightGray'} text={'M'} onClick={() => onChange('employees', 250)} />
          <Button color={location.employees >= 500 ? 'red' : 'lightGray'} text={'L'} onClick={() => onChange('employees', 1000)} />
        </div>
      </div>
      <div className="note" data-testid={'note'}>
        <h3>Add a note (optional)</h3>
        <TextInput
          placeholder="Enter an important detail or note about this customer site..."
          multiline={true}
          className="multiline"
          value={location.notes}
          onValueChange={(value) => onChange('notes', value)}
        />
      </div>

      {loading || loadingDelete ? renderProgress() : renderButtons(onDeleteClick)}
    </div>
  );
};

EditLocationView.propTypes = {
  phaseId: PropTypes.instanceOf(ProjectPhaseId).isRequired,
  projectId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default EditLocationView;
