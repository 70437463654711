import React from 'react';
import classNames from 'classnames';
import Expandable from './Expandable';

export function Network(props) {
  const { active, on } = props;
  return (
    <Expandable>
      {(open, toggle) => (
        <div className={classNames('expandable use-case', { active, open, closed: !open })}>
          <div className="expandable-header use-case-header">
            <button className="expand-content" onClick={toggle}>
              <h3>Network</h3>
            </button>
            <div className={classNames('status', { on, active })}>
              {on ? 'Solved' : ''}
              {active ? '' : 'Coming Soon...'}
            </div>
          </div>
          <div className="expandable-content use-case-content">
            <p>
              To architect for the digital edge you need to bring the WAN and LAN together and create a Digital Edge Node. Learn how to do this and more with
              technical blueprints and design patterns tailored to meet your networking needs.
            </p>
            <ul className="design-patterns">
              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Localize & Optimize Traffic</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-localize-optimize-traffic"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>Redefine the edge by establishing a node in a metro area closer to customers and where business is conducted.</p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Broadband</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  In telecommunications, broadband is a wide bandwidth data transmission with an ability to simultaneously transport multiple
                                  signals and traffic types. The medium can be coaxial cable, optical fiber, radio or twisted pair. In the context of Internet
                                  access, broadband is used to mean any high-speed Internet access that is always on and faster than traditional dial-up access.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>

                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Cellular</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Local Links refer to the use of dedicated network connections between two endpoints. In this workbench, local links emphasize
                                  the use of private backbones between a client site and an Equinix IBX data center.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>

                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Internet - ISP</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  An Internet service provider (ISP) is an organization that provides services for accessing and using the Internet. Services
                                  typically provided by ISPs include Internet access, Internet transit, domain name registration, web hosting, Usenet service,
                                  and colocation.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>

                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Local Links</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Local Links refer to the use of dedicated network connections between two endpoints. In this workbench, local links emphasize
                                  the use of private backbones between a client site and an Equinix IBX data center.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>

                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>MPLS</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Multiprotocol Label Switching (MPLS) is a type of data transmission technique used in high-performance telecommunications
                                  networks that directs data from one network node to the next based on short path labels rather than long network addresses,
                                  avoiding complex lookups in a routing table. The labels identify virtual links (paths) between distant nodes rather than
                                  endpoints. MPLS can encapsulate packets of various network protocols, hence its name 'multiprotocol'.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>

                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>SD WAN</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  SD-WAN represents 'software-defined' networking in a wide area network (WAN). An SD-WAN simplifies the management and
                                  operation of a WAN by decoupling (separating) the networking hardware from its control mechanism. This concept is similar to
                                  how software-defined networking implements virtualization technology to improve data center management and operation
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>

                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Traffic Management</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Network Traffic management is grounded in how the available network bandwidth is managed given the changing nature of real
                                  time demand. Bandwidth management is the process of measuring and controlling the communications (traffic, packets) on a
                                  network link, to avoid filling the link to capacity or overfilling the link, which would result in network congestion and poor
                                  performance of the network. Bandwidth management is measured in bits per second (bit/s) or bytes per second (B/s).
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Segment Traffic Flows</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-segment-traffic-flows"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>Prepare for multicloud and partner network integration as well as digital service flow isolation.</p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Load Balancing</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Network load balancing is the ability to balance through allocation, the traffic flow across multiple WAN links without using
                                  complex routing protocols like BGP. This capability balances network sessions like Web, email, etc. over multiple connections
                                  in order to spread out the amount of bandwidth used by each LAN user, thus increasing the total amount of bandwidth available.
                                  This plays an important roles when trying to enforce priority polices during high traffic peaks
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>

                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>SDN / NFV</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Software-defined networking (SDN) is an approach to computer networking that allows network administrators to manage network
                                  services through abstraction of lower-level functionality. SDN is meant to address the fact that the static architecture of
                                  traditional networks doesn't support the dynamic, scalable computing and storage needs of more modern computing environments
                                  such as data centers. This is done by decoupling or disassociating the system that makes decisions about where traffic is sent
                                  (the control plane) from the underlying systems that forward traffic to the selected destination (the data plane). Network
                                  functions virtualization (NFV) is a network architecture concept that uses the technologies of IT virtualization to virtualize
                                  entire classes of network node functions into building blocks that may connect, or chain together, to create communication
                                  services.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Establish Multi-Cloud Connectivity</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-establish-multicloud-connectivity"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        Service chain clouds, applications and data together across locally cross-connected cloud providers, accessing SaaS ervices as needed.
                      </p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Cloud Connect</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  A connection that allows Equinix customers to establish one physical connection while enabling the virtual connections to many
                                  cloud providers in an IBX.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Offload Internet at the Edge</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-offload-internet-at-edge"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        Bring all traffic into the edge node and benefit from added control and reduced risk, while routing public internet traffic directly
                        with internet peering.
                      </p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>WAN Acceleration</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Wide Area Network (WAN) acceleration employs techniques to optimize the available bandwidth to maximize data transfer
                                  efficiency at the Transport layer level ( TCP). The most common measures of TCP data-transfer efficiencies (i.e.,
                                  optimization) are throughput, bandwidth requirements, latency, protocol optimization, and congestion, as manifested in dropped
                                  packets.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Connect to Digital Ecosystems</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-connect-digital-ecosystems"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>Cross connect to business partners and ecosystems for digital commerce and/or data exchanges.</p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>MarketPlace Connection</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>Enables a customer to programmatically discover ecosystem partners within Platfform Equinix</p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>
            </ul>
          </div>
        </div>
      )}
    </Expandable>
  );
}
