import React, { useState } from 'react';
import Modal from 'react-modal';
import 'views/components/InfoPanel/children/TableStyles.scss';
import { dismissAllToasts } from 'utils/Toast';
import TextAndToggleTable from 'views/components/InfoPanel/children/TextAndToggleTable';
import ConfirmationModal from 'views/ProjectListView/modals/ConfirmationModal';
import defaultStyles from 'views/ProjectDetailView/modals/defaultStyles';
import { Mutations } from 'gql';
import { useMutation } from 'react-apollo';

const MetalsWrapper = ({ metalPlans, projectId, phaseId, metroCode }) => {
  const [addMetalToMetro, { loading: loadingAddMetalToMetro }] = useMutation(Mutations.ADD_METAL_TO_METRO);
  const [removeMetalFromMetro, { loading: loadingRemoveMetalFromMetro }] = useMutation(Mutations.REMOVE_METAL_FROM_METRO);
  const [deprecatedMetal, setDeprecatedMetal] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const onEnableMetalProduct = async (metalPlan) => {
    await addMetalToMetro({ variables: { projectId, phaseId, metroCode, planId: metalPlan.id } });
    dismissAllToasts();
  };
  const onDisableMetalProduct = async (metalPlan) => {
    if (metalPlan.deprecated) {
      setDeprecatedMetal(metalPlan);
      setOpenModal(true);
    } else {
      await removeMetalFromMetro({ variables: { projectId, phaseId, metroCode, planId: metalPlan.id } });
      dismissAllToasts();
    }
  };

  const removeDeprecatedMetalPlan = async () => {
    await removeMetalFromMetro({ variables: { projectId, phaseId, metroCode, planId: deprecatedMetal.id } });
    setDeprecatedMetal(null);
    setOpenModal(false);
    dismissAllToasts();
  };

  const filteredMetal = metalPlans.filter(({ checked, deprecated }) => checked || !deprecated);
  return (
    <>
      <TextAndToggleTable
        items={filteredMetal}
        onEnable={onEnableMetalProduct}
        onDisable={onDisableMetalProduct}
        isLoading={loadingAddMetalToMetro || loadingRemoveMetalFromMetro}
      />
      <Modal isOpen={openModal} className={'modal-window confirmation-modal'} onRequestClose={() => setOpenModal(false)} defaultStyles={defaultStyles}>
        <ConfirmationModal
          onClose={() => setOpenModal(false)}
          onConfirm={removeDeprecatedMetalPlan}
          loading={loadingRemoveMetalFromMetro}
          title={'Disable metal plan'}
          trailingText={
            'This metal plan is no longer available. After removing it you will be unable to add it back to your project. Are you sure you want to remove it from your project?'
          }
          buttonText={'Remove Permanently'}
        />
      </Modal>
    </>
  );
};
export default MetalsWrapper;
