import React, { useRef, useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ArrowIcon from 'views/components/icons/Arrow';
import PhasesMenu from './PhasesMenu';
import composeRefs from '@seznam/compose-react-refs';
import useWindowSize from 'hooks/useWindowSize';
import { getMaxScroll, isNearLeft, isNearRight, scrollToRight, scrollToLeft } from 'utils/scroll';
import reorderList from 'utils/reorderList';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',

  // styles we need to apply on draggables
  ...draggableStyle,
});

/**
 * @param {bool} isDraggingOver to manage drag state styles
 * @returns {*} style object
 */
const getListStyle = () => ({
  display: 'flex',
  overflowX: 'hidden',
  position: 'relative',
});

const DraggablePhases = ({ phases, onChangePhase, onReorderPhases, onOpenPhasesModals, isEditing, processLoading }) => {
  const [arrows, setArrows] = useState({ left: false, right: false });
  const [scroll, setScroll] = useState({ left: null, max: null });
  const [orderedPhases, setOrderedPhases] = useState(phases);
  const scrollRef = useRef(null);
  const windowSize = useWindowSize();

  useEffect(() => {
    setOrderedPhases(phases);
  }, [phases]);

  useEffect(() => {
    if (!scrollRef) return;
    handleArrowsUpdate(scrollRef);
    // eslint-disable-next-line
  }, [windowSize]);

  const onDragEnd = ({ source, destination, draggableId }) => {
    // dropped outside the list
    if (!destination) return;

    const reorderedItems = reorderList(phases, source.index, destination.index);
    const order = destination.index;
    const phase = phases.find((phase) => phase.id.value === draggableId);

    if (!phase) return;

    setOrderedPhases(reorderedItems);
    onReorderPhases(phase.id.value, order);
  };

  const handleArrowsUpdate = (element) => {
    if (!element) return;
    const scrollLeft = element.scrollLeft;
    const maxScroll = getMaxScroll(element);

    if (scrollLeft === scroll.left && maxScroll === scroll.max) return;
    setScroll({ left: scrollLeft, max: maxScroll });

    setArrows({ right: !isNearRight(element), left: !isNearLeft(element) });
  };

  const onSelectPhase = (phase, e) => {
    if (e && e.stopPropagation) e.stopPropagation();
    if (phase.selected) {
      const childMenuButton = e?.currentTarget?.querySelector('.phase-menu-button');
      if (!childMenuButton || !isEditing) return;
      childMenuButton.click();
    } else {
      if (processLoading) return;
      onChangePhase(phase.id.value);
    }
  };

  const onWheelScroll = (deltaY) => {
    const direction = Math.sign(deltaY) > 0 ? 'left' : 'right';
    if (direction === 'left') return scrollToLeft(scrollRef.current);
    if (direction === 'right') return scrollToRight(scrollRef.current);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {arrows.left && (
        <div className={'scroll-arrow left'}>
          <div className={'scroll-arrow-content'} onClick={() => scrollToLeft(scrollRef.current)}>
            <ArrowIcon className={'rotate180-icon'} color="#333333" width={8} height={13} />
          </div>
        </div>
      )}

      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, snapshot) => (
          <div
            ref={composeRefs((ref) => {
              handleArrowsUpdate(ref);
              provided.innerRef(ref);
            }, scrollRef)}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
            onScroll={(e) => handleArrowsUpdate(e.target)}
            onWheel={(e) => onWheelScroll(e.deltaY)}>
            {orderedPhases.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id.toString()} index={index} isDragDisabled={!isEditing}>
                {(provided, snapshot) => (
                  <div
                    data-testid={'phase-button'}
                    id={item.id}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    onClick={(e) => onSelectPhase(item, e)}>
                    <PhasesMenu
                      phase={item}
                      phases={phases}
                      onOpenPhasesModals={onOpenPhasesModals}
                      onChangePhase={onChangePhase}
                      isEditing={isEditing}
                      processLoading={processLoading}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {arrows.right && (
        <div className={'scroll-arrow right'}>
          <div className={'scroll-arrow-content'} onClick={() => scrollToRight(scrollRef.current)}>
            <ArrowIcon color="#333333" width={8} height={13} />
          </div>
        </div>
      )}
    </DragDropContext>
  );
};

export default DraggablePhases;
