import React, { useState } from 'react';
import Modal from 'react-modal';
import defaultStyles from './defaultStyles';
import TextInput from 'views/components/TextInput';
import Button from 'views/components/Button';
import Checkbox from 'views/components/Checkbox';

const DeleteCompanyModal = ({ isOpen, onDelete, loading, onClose, company }) => {
  const [companyName, setCompanyName] = useState('');
  const [checked, setChecked] = useState(false);

  const _onClose = () => {
    onClose();
    setCompanyName('');
    setChecked(false);
  };

  const renderProgress = () => {
    return (
      <div className="modal-submit">
        <div className="loading"></div>
      </div>
    );
  };

  const isDisabled = () => companyName.toLowerCase() !== company?.name?.toLowerCase();

  const renderButtons = () => {
    return (
      <div className="modal-submit">
        <Button text="Cancel" color="red" onClick={_onClose} isInverse={true} />
        <Button
          data-testId={'delete-company-modal'}
          text="Yes, delete"
          color="red"
          onClick={() => {
            onDelete(company.id, checked);
            _onClose();
          }}
          isDisabled={isDisabled()}
        />
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} className="modal-window" onRequestClose={_onClose} contentLabel="Delete Company" defaultStyles={defaultStyles}>
      <div>
        <div className="modal-header">
          <h3>Delete {company?.name}?</h3>
          <button className="close-modal" onClick={_onClose}>
            <svg height="12" width="12">
              <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
            </svg>
          </button>
        </div>
        <div className={'modal-fields modal-fields--full modal-text'}>
          <>
            <p>
              Company status will immediatly change to “Pending Deletion” which will be recoverable until complete deletion. All Company information will be
              deleted after certain period of time.
            </p>
            <p>
              Users will recieve an email explaning what is going on and will immediatly lose access to the platform. All users will be completely deleted after
              certain period of time.
            </p>
            <p>All Templates and Projects will be deleted after a certain period of time.</p>
          </>
        </div>
        <div className="horizontal-line" />
        <div className="modal-fields modal-fields--full">
          <label>Confirm Deletion</label>
          <TextInput
            value={companyName}
            placeholder="Type company name to confirm deletion*"
            className="textInput"
            onValueChange={(newValue) => {
              setCompanyName(newValue);
            }}
          />
        </div>
        <div className="modal-fields modal-fields--full modal-fields--checkbox">
          <Checkbox type="checkbox" checked={checked} onCheck={() => setChecked(!checked)} /> <p>Notify all company users by email</p>
        </div>
        {loading ? renderProgress() : renderButtons()}
      </div>
    </Modal>
  );
};

export default DeleteCompanyModal;
