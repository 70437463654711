import React from 'react';
import Config from 'Config';
import EnvelopeIcon from 'views/components/icons/Envelope';

const Contact = () => {
  return (
    <a href={`mailto:${Config.SUPPORT_EMAIL}`} rel="noopener noreferrer" className="user_linked-icon-link user_linked-icon-link--help" data-testid={'contact'}>
      <EnvelopeIcon />
      <span>Contact us</span>
    </a>
  );
};

export default Contact;
