import React from 'react';

const Ring = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={size / 2} cy={size / 2} r={(size / 2).toFixed(0)} fill="url(#paint0_radial)" stroke={color} />
      <defs>
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform={`translate(${size / 2} ${size / 2}) rotate(90) scale(${size / 2})`}>
          <stop stopColor={color} stopOpacity="0" />
          <stop offset="0.317708" stopColor={color} stopOpacity="0" />
          <stop offset="1" stopColor={color} stopOpacity="0.15" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default Ring;
