import AddEdgeView from 'views/components/InfoPanel/children/AddEdgeView';
import AddLocationsView from 'views/components/InfoPanel/children/AddLocationsView';
import AddMetalView from 'views/components/InfoPanel/children/AddMetalView';
import AddMetroView from 'views/components/InfoPanel/children/AddMetroView';
import AddMultipleLocationsView from 'views/components/InfoPanel/children/AddMultipleLocationsView';
import AddServiceView from 'views/components/InfoPanel/children/AddServiceView';
import ASPImportView from 'views/components/InfoPanel/children/ASPImportView';
import ASPInfo from 'views/components/InfoPanel/children/ASPInfoView';
import EditGroupView from 'views/components/InfoPanel/children/EditGroupView';
import EditLocationView from 'views/components/InfoPanel/children/EditLocationView';
import EditMetroView from 'views/components/InfoPanel/children/EditMetroView';
import EditServiceView from 'views/components/InfoPanel/children/EditServiceView';

const children = {
  EditMetroView,
  EditLocationView,
  EditGroupView,
  EditServiceView,
  AddLocationsView,
  AddMultipleLocationsView,
  ASPImportView,
  AddServiceView,
  AddMetroView,
  AddMetalView,
  AddEdgeView,
  ASPInfo,
};

export default children;
