import React, { useState, useEffect } from 'react';
import TriangleIcon from 'views/components/icons/Triangle';
import DragIndicatorIcon from 'views/components/icons/DragIndicator';
import cn from 'classnames';
import { Menu, MenuButton } from '@szhsin/react-menu';
import { useCurrentProject } from 'providers/ProjectProvider';
import PhasesMenuItems from './PhasesMenuItems';
import { toast } from 'utils/Toast';
import { Mutations } from 'gql';
import { useMutation } from '@apollo/react-hooks';
import { ProjectEvents } from 'analytics';

const PhasesMenu = ({ phase, phases, onOpenPhasesModals, onChangePhase, isEditing }) => {
  const { projectId, selectedPhase, processLoading } = useCurrentProject();
  const [execDuplicateProjectPhase, { data, error, loading }] = useMutation(Mutations.DUPLICATE_PROJECT_PHASE);
  const [isOpen, setIsOpen] = useState(false);
  const onMenuChangeHandler = (open) => {
    setIsOpen(open);
  };

  const onDelete = (e) => {
    e.syntheticEvent.stopPropagation();
    const isSelected = selectedPhase.id === phase.id;
    const phaseIndex = phases.indexOf(selectedPhase);
    onOpenPhasesModals('delete', { phaseId: phase.id, isSelected, phaseIndex });
  };

  const onCopy = async (e) => {
    e.syntheticEvent.stopPropagation();
    try {
      await execDuplicateProjectPhase({ variables: { projectId, phaseId: phase.id } });
      ProjectEvents.phaseCopied(projectId);
    } catch (error) {
      return toast('An unexpected error occurred copying the phase', 'error');
    }
  };

  const onRename = (e) => {
    e.syntheticEvent.stopPropagation();
    onOpenPhasesModals('rename', { phaseId: phase.id, phaseName: phase.name, phaseOrder: phase.order });
  };

  useEffect(() => {
    if (data && !error) {
      const phasesList = data.duplicatePhase?.phases;
      if (!phasesList) return;
      const { id } = phasesList[phasesList.length - 1];
      onChangePhase(id);
    }
    // eslint-disable-next-line
  }, [data, error]);

  const isPhaseSelected = phase.selected;

  return (
    <div className={cn('button-group', { selected: phase.selected, open: isOpen, disabled: processLoading })}>
      {isEditing && <DragIndicatorIcon width={8} height={3} color="#999999" className={'drag-indicator'} />}
      <button className="phase-name">{phase.name}</button>
      <Menu
        menuClassName={'default-menu'}
        align={'start'}
        onMenuChange={({ open }) => onMenuChangeHandler(open)}
        menuButton={
          <MenuButton disabled={loading} className={'phase-menu-button'} onClick={(e) => e.stopPropagation()}>
            {loading ? (
              <div className={'loading'}></div>
            ) : (
              isEditing && (
                <div className="triangle-container" data-testid={'triangle-icon'}>
                  <TriangleIcon width={6} height={5} color="black" />
                </div>
              )
            )}
          </MenuButton>
        }
        transition
        portal
        data-testid={'phases-menu'}>
        <PhasesMenuItems isPhaseSelected={isPhaseSelected} onCopy={onCopy} onRename={onRename} onDelete={onDelete} />
      </Menu>
    </div>
  );
};

export default PhasesMenu;
