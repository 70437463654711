import React from 'react';

const CheckCircleIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12.3334 6.96334V7.5C12.3327 8.75792 11.9254 9.9819 11.1722 10.9894C10.419 11.9969 9.36033 12.7339 8.15404 13.0906C6.94775 13.4473 5.65848 13.4044 4.47852 12.9685C3.29856 12.5326 2.29113 11.7269 1.60648 10.6716C0.92182 9.61636 0.596626 8.36804 0.679393 7.11285C0.762159 5.85766 1.24845 4.66286 2.06574 3.70663C2.88304 2.7504 3.98754 2.08398 5.21453 1.80675C6.44151 1.52953 7.72524 1.65637 8.87425 2.16834"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.3333 2.83331L6.5 8.67248L4.75 6.92248" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CheckCircleIcon;
