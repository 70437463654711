import React from 'react';
import DocumentTitle from 'react-document-title';
import Page from 'utils/Page';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import AuthenticatedRoute from 'common/AuthenticatedRoute';
import UserView from 'views/UserView';

import ProjectListView from 'views/ProjectListView';
import ProjectDetailView from 'views/ProjectDetailView';
import AuthCallbackView from 'views/AuthCallbackView';
import ErrorBoundary from 'common/ErrorBoundary';
import isProjectDetailView from 'utils/isProjectDetailView';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import Header from 'views/components/Header';
import './Menu.scss';
import { toastConfig } from 'utils/Toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.scss';
import { withListViewProvider } from 'providers/ListViewProvider';
import Config from 'Config';
import MaintenanceView from 'views/MaintenanceView';
import LoginView from 'views/LoginView';
import './base.scss';

class App extends UserView {
  componentDidMount() {
    window.$sleek = [];
    let d, s;

    window.SLEEK_PRODUCT_ID = Config.SLEEKPLAN_PRODUCT_ID;
    (function () {
      d = document;
      s = d.createElement('script');
      s.src = 'https://client.sleekplan.com/sdk/e.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }

  render() {
    const routes = (
      <Switch>
        <Route exact path="/" component={LoginView} />
        <Route exact path="/authcallback" component={AuthCallbackView} />
        <AuthenticatedRoute exact path="/projects" user={this.user} component={ProjectListView} />
        <AuthenticatedRoute exact path="/projects/:projectId" user={this.user} component={ProjectDetailView} />
        <AuthenticatedRoute exact path="/templates/:templateId" user={this.user} component={ProjectDetailView} />
        <Redirect from="*" to="/" />
      </Switch>
    );

    const isProjectDetail = isProjectDetailView(this.props.location.pathname);

    return (
      <ErrorBoundary>
        <DocumentTitle title={Page.title()}>
          {Config.MAINTENANCE_RUNNING_TEXT ? (
            <MaintenanceView />
          ) : (
            <main className="App">
              <Notifications options={{ zIndex: 200 }} />
              <ToastContainer {...toastConfig} />

              {!isProjectDetail && <Header user={this.user} />}

              {this.isUserLoading ? null : routes}
            </main>
          )}
        </DocumentTitle>
      </ErrorBoundary>
    );
  }
}

export default withListViewProvider(withRouter(App));
