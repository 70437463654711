import React from 'react';
import PropTypes from 'prop-types';
import './ConfirmationModal.scss';
import CloseIcon from 'views/components/icons/Close';
import TextInput from 'views/components/TextInput';
import Button from 'views/components/Button';
import CancelButton from 'views/components/CancelButton';

/**
 * @param {*} mainText - Main item that will be emphasized in the UI
 * @param {function} onConfirm - Function to be fired when the confirmation button is clicked
 * @param {function} onClose - Function to be fired when the cancel button or the close icon is clicked
 * @param {boolean} loading - Loading state for the confirmation action
 * @param {string} title - Title for the modal
 * @param {string} trailingText - Text to show after the main data
 * @param {string} buttonText - Custom text for the confirmation button
 * @param {string} buttonClass - Custom className for the confirmation button
 * @param {boolean} withInput - Wether or not it has a text input
 * @param {string} inputValue - Value for the optative input
 * @param {any} inputProps - Props for the optative input
 * @param {boolean} buttonDisabled - Disables the confirmation button
 */
const ConfirmationModal = ({
  mainText,
  onConfirm,
  onClose,
  loading,
  title,
  trailingText,
  buttonText = 'Confirm',
  withInput,
  inputValue,
  inputProps,
  buttonDisabled,
  testId,
}) => {
  return (
    <div className={'modal-wrapper'} data-testid={testId}>
      <div className={'modal-header'}>
        <h3>{title}</h3>
        <button className={'close-modal'} onClick={onClose}>
          <CloseIcon width={12} height={12} color="#000000" />
        </button>
      </div>
      {!!withInput && (
        <div>
          <TextInput value={inputValue} {...inputProps} />
        </div>
      )}
      {!!mainText && <div className={'modal-main-text'}>{mainText}</div>}
      <p className={'trailing-text'}>{trailingText}</p>
      <div className={'modal-submit'}>
        {!loading ? (
          <>
            <CancelButton onClick={onClose} />
            <Button text={buttonText} onClick={onConfirm} isDisabled={buttonDisabled} color={'red'} />
          </>
        ) : (
          <div className={'confirmation-loader'}>
            <div className={'loading'}></div>
          </div>
        )}
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  mainText: PropTypes.any,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.string,
  trailingText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonClass: PropTypes.string,
  withInput: PropTypes.bool,
  inputValue: PropTypes.string,
  inputProps: PropTypes.any,
  testId: PropTypes.string,
};

export default ConfirmationModal;
