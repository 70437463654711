import React from 'react';
import './ImportationIssuesModal.scss';
import Modal from 'react-modal';
import CloseIcon from 'views/components/icons/Close';
import defaultStyles from 'views/ProjectDetailView/modals/defaultStyles';

const ImportationIssuesModal = ({ isOpen, onClose, title, importationIssues }) => {
  return (
    <Modal isOpen={isOpen} className={'modal-window importation-issues-modal'} onRequestClose={onClose} defaultStyles={defaultStyles}>
      <div className={'modal-wrapper'}>
        <div className={'modal-header'}>
          <h3>{title}</h3>
          <button className={'close-modal'} onClick={onClose}>
            <CloseIcon width={12} height={12} color="#000000" />
          </button>
        </div>

        <table className={'wb-table importation-issues-table'}>
          <tbody>
            {importationIssues.map((issue) => (
              <tr key={`${issue.rowNumber}-${issue.description}`}>
                <td>
                  <p className={'row-number'}>{`#${issue.rowNumber}`}</p>
                  <p className={'row-message'}>{issue.description}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default ImportationIssuesModal;
