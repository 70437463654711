import Assert from '../utils/Assert';
import ServiceId from './ServiceId';
import ServiceRegion from './ServiceRegion';

export default class Service {
  constructor(id, name, logo, regions, deprecated) {
    Assert.instanceOf(id, ServiceId);
    Assert.isString(name);
    logo == null || Assert.isString(logo);
    deprecated == null || Assert.isBoolean(deprecated);

    this.id = id;
    this.name = name;
    this.logo = logo;
    this.regions = regions;
    this.deprecated = deprecated ?? false;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof Service)) {
      return false;
    }

    return this.id.equals(other.id);
  }

  toString() {
    return `${this.constructor.name}{ id:${this.id}, name:'${this.name}', deprecated:'${this.deprecated}' }`;
  }

  toJSON() {
    const { id, name, logo, regions, deprecated } = this;
    return { id, name, logo, regions, deprecated };
  }

  static fromJSON(json) {
    const { id, name, logo, regions, deprecated } = json;

    return new Service(new ServiceId(id), name, logo, ServiceRegion.fromJSONArray(regions), deprecated);
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = Service.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}
