import DunsNumber from './DunsNumber';
import Timestamp from './Timestamp';
import Assert from '../utils/Assert';
import Segment from './Segment';
import SubSegment from './SubSegment';
import ItSpend from './ItSpend';

export default class AccountStrategyPlanForPhase {
  constructor(id, company, segment, subSegment, employees, itSpends, adoptionProfiles, adoptionAttributes, lastUpdated, updateAvailable, updateAcknowledged) {
    Assert.instanceOf(id, DunsNumber);
    Assert.isString(company);
    Assert.instanceOf(segment, Segment);
    Assert.instanceOf(subSegment, SubSegment);
    Assert.isInteger(employees);
    Assert.instanceOf(lastUpdated, Timestamp);
    Assert.isBoolean(updateAvailable);
    Assert.isBoolean(updateAcknowledged);

    this.id = id;
    this.company = company;
    this.segment = segment;
    this.subSegment = subSegment;
    this.employees = employees;
    this.itSpends = itSpends;
    this.adoptionProfiles = adoptionProfiles;
    this.adoptionAttributes = adoptionAttributes;
    this.lastUpdated = lastUpdated;
    this.updateAvailable = updateAvailable;
    this.updateAcknowledged = updateAcknowledged;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof AccountStrategyPlanForPhase)) {
      return false;
    }

    return this.id.equals(other.id);
  }

  toString() {
    return `${this.constructor.name}{ id:${this.id}, company:'${this.company}', lastUpdated:'${this.lastUpdated}', updateAvailable:${this.updateAvailable} }`;
  }

  toJSON() {
    const {
      id,
      company,
      segment,
      subSegment,
      employees,
      itSpends,
      adoptionProfiles,
      adoptionAttributes,
      lastUpdated,
      updateAvailable,
      updateAcknowledged,
    } = this;
    return { id, company, segment, subSegment, employees, itSpends, adoptionProfiles, adoptionAttributes, lastUpdated, updateAvailable, updateAcknowledged };
  }

  static fromJSON(json) {
    if (!json) {
      return null;
    }

    const {
      id,
      company,
      segment,
      subSegment,
      employees,
      itSpends,
      adoptionProfiles,
      adoptionAttributes,
      lastUpdated,
      updateAvailable,
      updateAcknowledged,
    } = json;

    return new AccountStrategyPlanForPhase(
      new DunsNumber(id),
      company,
      Segment.fromJSON(segment),
      SubSegment.fromJSON(subSegment),
      employees,
      ItSpend.fromJSONArray(itSpends),
      adoptionProfiles,
      adoptionAttributes,
      Timestamp.fromJSON(lastUpdated),
      updateAvailable,
      updateAcknowledged
    );
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      items.push(AccountStrategyPlanForPhase.fromJSON(json));
    }

    return items;
  }
}
