import getAllSegmentsRadius from './getAllSegmetnsRadius';
import getMaxAndMinSegmentCount from './getMaxAndMinSegmentCount';

const getSegmentRadius = ({ marketplaceSegment, metros, metro }) => {
  if (!marketplaceSegment) return 0;

  if (marketplaceSegment.name === 'All Participants') return getAllSegmentsRadius({ metros, metro });

  const densities = metro.marketplace?.buyers;
  let count = densities?.find((density) => density.name === marketplaceSegment.name)?.count;

  if (!count) {
    densities.some((density) => {
      count = density.subSegments.find((subSegment) => subSegment.name === marketplaceSegment.name)?.count;
      return !!count;
    });
  }

  if (!count) return 0;

  const { maxSegmentCount, minSegmentCount } = getMaxAndMinSegmentCount({ marketplaceSegment, metros });

  const minRadius = 75000;
  const maxRadius = minRadius * 10;

  const normalizedCountValue = ((count - minSegmentCount) / (maxSegmentCount - minSegmentCount)) * maxRadius;

  return normalizedCountValue < minRadius ? minRadius : normalizedCountValue;
};

export default getSegmentRadius;
