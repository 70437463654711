import BaseModel from './BaseModel';
import NetworkEdgeDeviceId from './NetworkEdgeDeviceId';
// import Metro from "./Metro";

export default class NetworkEdgeDevice extends BaseModel {
  static fields = [
    { key: 'id', type: NetworkEdgeDeviceId, stringify: true },
    { key: 'name', type: 'string', stringify: true },
    { key: 'enabled', type: 'boolean' },
    { key: 'deprecated', type: 'boolean' },
    { key: 'metroCodes', type: 'array', of: 'string' },
  ];
}
