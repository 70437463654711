import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';

const useAddMetalToMetro = () => {
  const [doAddMetalToMetro, { data, loading, error }] = useMutation(Mutations.ADD_METAL_TO_METRO);

  const addMetalToMetro = (projectId, phaseId, metroCode, planId) => doAddMetalToMetro({ variables: { projectId, phaseId, metroCode, planId } });

  return {
    addMetalToMetro,
    dataAddMetalToMetro: data,
    loadingAddMetalToMetro: loading,
    errorAddMetalToMetro: error,
  };
};

export default useAddMetalToMetro;
