import React from 'react';

const EyeballIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M0.886696 4.2953C0.789031 4.18395 0.705062 4.08431 0.635815 4C0.705062 3.91569 0.789031 3.81605 0.886696 3.7047C1.20392 3.34304 1.66255 2.86137 2.22766 2.38094C3.37288 1.40734 4.88346 0.5 6.5 0.5C8.11654 0.5 9.62712 1.40734 10.7723 2.38094C11.3374 2.86137 11.7961 3.34304 12.1133 3.7047C12.211 3.81605 12.2949 3.91569 12.3642 4C12.2949 4.08431 12.211 4.18395 12.1133 4.2953C11.7961 4.65696 11.3374 5.13863 10.7723 5.61906C9.62712 6.59266 8.11654 7.5 6.5 7.5C4.88346 7.5 3.37288 6.59266 2.22766 5.61906C1.66255 5.13863 1.20392 4.65696 0.886696 4.2953Z"
        stroke={color}
      />
      <circle cx="6.5" cy="4" r="1.5" fill={color} />
    </svg>
  );
};

export default EyeballIcon;
