import React from 'react';
import Location from 'model/Location';
import useDeleteLocation from 'hooks/useDeleteLocation';
import { usePhaseItems } from 'providers/PhaseItemsProvider';
import { useCurrentProject } from 'providers/ProjectProvider';
import { LocationItemFDrag } from '../LocationItem';

const LocationItemList = ({ locations, metroId, onCheck, onDropOnGroup, onSelect, selectedItem, onClose, isEditing }) => {
  const { pendingProps, defaultProps, pendingRemoval, isContextMenuOpen, onContextMenu } = usePhaseItems();
  const { projectId, selectedPhaseId: phaseId } = useCurrentProject();
  const { deleteLocation } = useDeleteLocation();

  const onDeleteLocation = async (e, location) => {
    e.syntheticEvent.stopPropagation();
    if (selectedItem.id === location.id) onClose();
    await deleteLocation(projectId, phaseId, location);
  };

  const locationProps = locations
    .filter((item) => !pendingRemoval.find((i) => item.equals(i)))
    .map(defaultProps)
    .concat(pendingProps(Location, metroId))
    .sort((a, b) => a.item.compare(b.item));

  return locationProps.map((props, index) => (
    <LocationItemFDrag
      isEditing={isEditing}
      onSelect={onSelect}
      onCheck={onCheck}
      onDropped={onDropOnGroup}
      selectedItem={selectedItem}
      {...props}
      onContextMenu={(e) => onContextMenu(e, 'location', props.item.id.value)}
      onDeleteLocation={(e) => onDeleteLocation(e, props.item)}
      isContextMenuOpen={isContextMenuOpen.location === props.item.id.value}
      key={`location-item-${metroId}-${index}`}
    />
  ));
};

export default LocationItemList;
