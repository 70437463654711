import React from 'react';

// https://fontawesome.com/icons/times-circle
const TimesCircleSolidIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z" fill={color} />
    <path d="M8.45005 4.55L4.55005 8.45" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.55005 4.55L8.45005 8.45" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default TimesCircleSolidIcon;
