import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ProjectPhaseId from 'model/ProjectPhaseId';
import ServiceConnection from 'model/ServiceConnection';
import ToggleButton from 'views/components/ToggleButton';
import { useMutation, useQuery } from 'react-apollo';
import { Mutations, Queries } from 'gql';
import ServiceMetroConnection from 'model/ServiceMetroConnection';
import './ServiceMetroConnectionsView.scss';
import { LoggerFactory } from 'logger';
import { dismissAllToasts, toast } from 'utils/Toast';
import ProjectPhase from 'model/ProjectPhase';
import ObjectUtils from 'utils/ObjectUtils';
import ServiceConnectionId from 'model/ServiceConnectionId';

const ServiceMetroConnectionsView = ({ projectId, phaseId, serviceConnection, onSelectionChanged, onClose, onError }) => {
  const { data, error, loading } = useQuery(Queries.GET_SERVICE_METRO_CONNECTIONS, {
    variables: { projectId: projectId, phaseId: phaseId, serviceId: serviceConnection.serviceId },
  });

  const [addService, { loading: loadingAddService }] = useMutation(Mutations.ADD_SERVICE);
  const [removeService, { loading: loadingRemoveService }] = useMutation(Mutations.DELETE_PROJECT_ITEMS);

  const [metroConnections, setMetroConnections] = useState(null);

  const logger = useMemo(() => LoggerFactory.getLogger('ServiceMetroConnectionsView'), []);

  useEffect(() => {
    if (data && data.serviceMetroConnections) {
      const metros = ServiceMetroConnection.fromJSONArray(data.serviceMetroConnections);
      setMetroConnections(metros);
    }
  }, [data]);

  if (error) {
    logger.error(`An error occurred executing query: ${error.message}`);
    toast('An unexpected error occurred getting services metro', 'error');
    onError(error);
  }

  const onConnectionToggled = async (checked, metroConnection) => {
    if (checked) {
      logger.debug(`Connecting service: '${serviceConnection.name}' to metro: '${metroConnection.name}'`);

      const args = {
        variables: {
          projectId,
          phaseId,
          serviceId: serviceConnection.serviceId,
          metroId: metroConnection.id,
          serviceRegionIds: [],
        },
        refetchQueries: [{ query: Queries.GET_SERVICE_METRO_CONNECTIONS, variables: { projectId, phaseId, serviceId: serviceConnection.serviceId } }],
      };

      const result = await addService(args);
      dismissAllToasts();

      if (!serviceConnection.metroId) {
        // this is the case where we are connecting an unconnected metro.
        const phase = ProjectPhase.fromJSON(result.data.addProjectService);
        const movedService = phase.services.find((item) => ObjectUtils.equals(serviceConnection.serviceId, item.id.serviceId) && item.metroId);
        onClose();
        if (movedService) {
          onSelectionChanged(movedService);
        }
      }
    } else {
      logger.debug(`Disconnecting service: ${serviceConnection} from metro: ${metroConnection.name}`);
      const serviceConnectionId = new ServiceConnectionId(phaseId, serviceConnection.id.serviceId, metroConnection.id);

      const args = {
        variables: { projectId, phaseId, locationIds: [], locationGroupIds: [], serviceConnectionIds: [serviceConnectionId] },
        refetchQueries: [{ query: Queries.GET_SERVICE_METRO_CONNECTIONS, variables: { projectId, phaseId, serviceId: serviceConnection.serviceId } }],
      };

      await removeService(args);

      dismissAllToasts();
    }
  };

  // if (loading) {
  //   return ;
  // }

  return (
    <div className="table-container">
      {loading ? (
        <div className="ServiceMetroConnectionsView loading" />
      ) : (
        <div className="table">
          {metroConnections &&
            metroConnections.map((metroConnection) => (
              <div key={metroConnection.id} className={metroConnection.connected ? 'column' : ' column disabled'}>
                <p className="item first">{metroConnection.name}</p>
                <p className="item">{metroConnection.local ? 'Local' : 'Remote'}</p>
                <div className="item last" data-testid={'toggle-container'}>
                  <ToggleButton
                    enabled={loadingAddService || loadingRemoveService ? false : true}
                    checked={metroConnection.connected}
                    onToggled={(checked) => onConnectionToggled(checked, metroConnection)}
                    small
                  />
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

ServiceMetroConnectionsView.propTypes = {
  projectId: PropTypes.string.isRequired,
  phaseId: PropTypes.instanceOf(ProjectPhaseId).isRequired,
  serviceConnection: PropTypes.instanceOf(ServiceConnection).isRequired,
  onError: PropTypes.func.isRequired,
};

export default ServiceMetroConnectionsView;
