import React, { useEffect, useState } from 'react';
import { Queries, Mutations } from 'gql';
import { useQuery, useMutation } from '@apollo/react-hooks';
import TemplatesList from './TemplatesList';
import TemplateModal from '../modals/TemplateModal';
import PlusSaveIcon from 'views/components/icons/PlusSave';
import { toast } from 'utils/Toast';
import { useHistory } from 'react-router';
import { LoggerFactory } from 'logger';
import Config from 'Config';
import { TemplateEvents } from 'analytics';
import Button from 'views/components/Button';
import ArrowIcon from 'views/components/icons/Arrow2';

const TemplatesView = ({ type, onSelect, user }) => {
  const history = useHistory();
  const logger = LoggerFactory.getLogger('Template Modal');
  const TYPES = {
    Explore: { title: 'Explore Templates', queryFilter: { scope: 'PUBLISHED' } },
    Saved: { title: 'Saved Templates', queryFilter: { scope: 'SAVED' } },
    Templates: { title: 'My Templates', queryFilter: { scope: 'MINE' } },
  };

  const { title, queryFilter } = TYPES[type];

  const [templates, setTemplates] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { data, loading } = useQuery(Queries.GET_TEMPLATES, { variables: { ...queryFilter }, fetchPolicy: 'cache-and-network' });
  const [createTemplate, { loading: createLoading }] = useMutation(Mutations.CREATE_TEMPLATE);

  useEffect(() => {
    if (!data) return;
    setTemplates(data.templates);
  }, [data]);

  const renderLoading = () => {
    return (
      <div className="loading-projects">
        <span className="loading-message">
          <div className="spinner"></div>
          Loading Templates
        </span>
      </div>
    );
  };

  const onOpenModal = () => {
    setModalIsOpen(true);
  };

  const onCloseModal = () => {
    setModalIsOpen(false);
  };

  const renderNoTemplates = () => {
    return (
      <div className="no-projects">
        {type === 'Templates' && (
          <>
            <h3>No Templates Created Yet</h3>
            <p>Get started on creating your first Template</p>
          </>
        )}
        {type === 'Explore' && (
          <>
            <h3>No Templates Published Yet</h3>
            <p>Get started on creating your first Template</p>
          </>
        )}
        {type === 'Saved' && (
          <>
            <PlusSaveIcon width={42} height={41} color="#999999" />
            <h3>Save your favorite templates to quickly return to them anytime.</h3>
            <div
              onClick={() => {
                onSelect({ category: 'Templates', selected: 'Explore' });
              }}
              className="redirect">
              Explore available templates <ArrowIcon color="#006BD9" />
            </div>
          </>
        )}
      </div>
    );
  };

  const onCreateTemplate = async (templateName, categoriesFormatted) => {
    logger.debug(`Creating new template with name: '${templateName}'`);
    try {
      const { data } = await createTemplate({
        variables: { name: templateName, categories: categoriesFormatted },
        refetchQueries: [{ query: Queries.GET_TEMPLATES, variables: { ...queryFilter } }],
      });
      TemplateEvents.templateCreated(data.createTemplate.id);
      history.push({
        pathname: `/templates/${data.createTemplate.id}`,
        state: { template: data.createTemplate, edit: true },
      });
    } catch (err) {
      logger.error(`An unexpected error occurred creating template: '${templateName}'`);
      return toast('An unexpected error occurred creating template', 'error');
    }
  };

  return (
    <>
      {Config.MAINTENANCE_TEXT && <div className="maintenance-banner">{Config.MAINTENANCE_TEXT}</div>}
      <div className="list-heading">
        <h1>{title}</h1>
        <Button color={'gray'} onClick={onOpenModal} text="New Template" />
      </div>
      {loading ? renderLoading() : <TemplatesList templates={templates} renderNoTemplates={renderNoTemplates} user={user} />}
      <TemplateModal isOpen={modalIsOpen} onClose={onCloseModal} onSave={onCreateTemplate} loading={createLoading} />
    </>
  );
};

export default TemplatesView;
