import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const StonlyScriptProvider = ({ wid, children, enabled }) => (
  <>
    {enabled && !!wid && (
      <Helmet>
        <script data-test-id="StonlyVar">{`var STONLY_WID = "${wid}";`}</script>
        <script data-test-id="StonlyScript">
          {`!function(s,t,o,n,l,y,w,g)
            {s.StonlyWidget ||
                (((w = s.StonlyWidget = function () {
                    w._api
                        ? w._api.apply(w, arguments)
                        : w.queue.push(arguments);
                }).queue = []),
                ((y = t.createElement(o)).async = !0),
                (g = new XMLHttpRequest()).open(
                    'GET',
                    n + 'version?v=' + Date.now(),
                    !0
                ),
                (g.onreadystatechange = function () {
                    4 === g.readyState &&
                        ((y.src =
                            n +
                            'stonly-widget.js?v=' +
                            (200 === g.status ? g.responseText : Date.now())),
                        (l = t.getElementsByTagName(
                            o
                        )[0]).parentNode.insertBefore(y, l));
                }),
                g.send())}
            (window,document,"script","https://stonly.com/js/widget/v2/");`}
        </script>
      </Helmet>
    )}
    {children}
  </>
);

StonlyScriptProvider.propTypes = {
  enabled: PropTypes.bool,
  wid: PropTypes.string,
  children: PropTypes.node,
};

export default StonlyScriptProvider;
