import React from 'react';

const UserCircleIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M10 0.25C8.61979 0.25 7.31771 0.510417 6.09375 1.03125C4.89583 1.55208 3.84115 2.26823 2.92969 3.17969C2.01823 4.09115 1.30208 5.14583 0.78125 6.34375C0.260417 7.56771 0 8.86979 0 10.25C0 11.6302 0.260417 12.9323 0.78125 14.1562C1.30208 15.3542 2.01823 16.4089 2.92969 17.3203C3.84115 18.2318 4.89583 18.9479 6.09375 19.4688C7.31771 19.9896 8.61979 20.25 10 20.25C11.3802 20.25 12.6823 19.9896 13.9062 19.4688C15.1042 18.9479 16.1589 18.2318 17.0703 17.3203C17.9818 16.4089 18.6979 15.3542 19.2188 14.1562C19.7396 12.9323 20 11.6302 20 10.25C20 8.86979 19.7396 7.56771 19.2188 6.34375C18.6979 5.14583 17.9818 4.09115 17.0703 3.17969C16.1589 2.26823 15.1042 1.55208 13.9062 1.03125C12.6823 0.510417 11.3802 0.25 10 0.25ZM10 4.11719C10.9896 4.11719 11.8229 4.46875 12.5 5.17188C13.2031 5.84896 13.5547 6.68229 13.5547 7.67188C13.5547 8.66146 13.2031 9.50781 12.5 10.2109C11.8229 10.888 10.9896 11.2266 10 11.2266C9.01042 11.2266 8.16406 10.888 7.46094 10.2109C6.78385 9.50781 6.44531 8.66146 6.44531 7.67188C6.44531 6.68229 6.78385 5.84896 7.46094 5.17188C8.16406 4.46875 9.01042 4.11719 10 4.11719ZM10 17.9844C8.82812 17.9844 7.72135 17.737 6.67969 17.2422C5.66406 16.7474 4.80469 16.0833 4.10156 15.25C4.46615 14.5208 5 13.9349 5.70312 13.4922C6.40625 13.0495 7.1875 12.8281 8.04688 12.8281C8.09896 12.8281 8.15104 12.8411 8.20312 12.8672C8.25521 12.8672 8.30729 12.8672 8.35938 12.8672C8.61979 12.9453 8.88021 13.0104 9.14062 13.0625C9.42708 13.1146 9.71354 13.1406 10 13.1406C10.2865 13.1406 10.5599 13.1146 10.8203 13.0625C11.1068 13.0104 11.3802 12.9453 11.6406 12.8672C11.6927 12.8672 11.7448 12.8672 11.7969 12.8672C11.849 12.8411 11.901 12.8281 11.9531 12.8281C12.8125 12.8281 13.5938 13.0495 14.2969 13.4922C15 13.9349 15.5339 14.5208 15.8984 15.25C15.1953 16.0833 14.3229 16.7474 13.2812 17.2422C12.2656 17.737 11.1719 17.9844 10 17.9844Z"
        fill={color}
      />
    </svg>
  );
};

export default UserCircleIcon;
