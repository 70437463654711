export const USER_ROLES = {
  workbenchAdmin: {
    label: 'Workbench Admin',
    value: 1,
  },
  companyOwner: {
    label: 'Company Owner',
    value: 2,
  },
  teamAdmin: {
    label: 'Team Admin',
    value: 3,
  },
  teamMember: {
    label: 'Team Member',
    value: 4,
  },
};

export const MENU_OPTIONS = [USER_ROLES.companyOwner, USER_ROLES.teamAdmin, USER_ROLES.teamMember];

export const getUserRoleLabel = (value) => {
  for (const key in USER_ROLES) {
    if (USER_ROLES[key].value === value) {
      return USER_ROLES[key].label;
    }
  }
  return 'Unknown Role';
};
