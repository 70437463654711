import cn from 'classnames';
import L from 'leaflet';
import React from 'react';
import { Marker, Pane, withLeaflet } from 'react-leaflet';
import { dismissAllToasts } from 'utils/Toast';
import withDisableEdge from '../hocs/withDisableEdge';
import withDisableMetal from '../hocs/withDisableMetal';
import withHideService from '../hocs/withHideService';
import withUpdateServiceOffset from '../hocs/withUpdateServiceOffset';
import withDeleteProjectItems from '../PhasePanel/hocs/withDeleteProjectItems';
import ContextMenu from './ContextMenu';
import './ServiceMarker.scss';
import markerData from './ServiceMarker.json';

function getConstants(iconSize) {
  return {
    ANCHOR: -5 * iconSize,
    OFFSET_X: -15,
    OFFSET_Y: -15,
    MARGIN: 4 * iconSize,
    SIZE_X: (150 / 2) * iconSize,
    SIZE_Y: (80 / 2) * iconSize,
    MAX_ROWS: 5,
  };
}

class ServiceMarker extends React.Component {
  constructor(props) {
    super(props);
    let { x, y } = props.serviceOffset || {};
    this.state = {
      offsetX: x,
      offsetY: y,
    };
  }

  handleDragStart = () => {
    if (this.line) {
      this.line.leafletElement.setOpacity(0);
    }
    if (this.markers) {
      this.markers.leafletElement.getElement().classList.add('dragging');
    }
  };
  handleDragEnd = (e) => {
    const { position, iconSize = 1, updateServiceOffset, leaflet } = this.props;
    const zoom = leaflet.map.getZoom();
    let { offsetX, offsetY } = this.state;
    const startPoint = e.target._map.project(position);
    const endPoint = e.target._map.project(e.target.getLatLng());
    offsetX += Math.floor((startPoint.x - endPoint.x) / iconSize / zoom);
    offsetY += Math.floor((startPoint.y - endPoint.y) / iconSize / zoom);
    e.target.setLatLng(position);
    this.setState({ offsetX, offsetY });
    if (this.line) {
      this.line.leafletElement.setOpacity(1);
    }
    if (this.markers) {
      this.markers.leafletElement.getElement().classList.remove('dragging');
    }
    updateServiceOffset({
      x: offsetX,
      y: offsetY,
    });
  };

  onHide = async () => {
    const { hideService, projectId, phaseId, services } = this.props;
    const { contextMenuService } = this.state;
    // We use "==" on purpose here, as we NEED type conversion
    const service = services.find((s) => s.id == contextMenuService); //eslint-disable-line
    this.setState({ contextMenuOffset: null });
    await hideService(projectId, phaseId, service.id);
    dismissAllToasts();
  };

  onDelete = async () => {
    const { deleteProjectItems, projectId, phaseId, services, onClose, selected } = this.props;
    const { contextMenuService } = this.state;
    // We use "==" on purpose here, as we NEED type conversion
    const service = services.find((s) => s.id == contextMenuService); //eslint-disable-line
    if (selected?.id === service.id) {
      onClose();
    }
    this.setState({ contextMenuOffset: null });
    await deleteProjectItems(projectId, phaseId, [service.id]);
    dismissAllToasts();
  };

  onDisableMetal = async () => {
    const { projectId, phaseId, metro, disableMetal } = this.props;
    this.setState({ contextMenuOffset: null });
    await disableMetal(projectId, phaseId, metro.code);
    dismissAllToasts();
  };

  onDisableEdge = async () => {
    const { projectId, phaseId, metro, disableEdge } = this.props;
    this.setState({ contextMenuOffset: null });
    await disableEdge(projectId, phaseId, metro.code, null);
    dismissAllToasts();
  };

  render() {
    const { position, metro, services, iconSize = 1, selected, onSelect, leaflet, loading, onMetroPanelTabChange } = this.props;
    const zoom = leaflet.map.getZoom();
    const c = getConstants(iconSize);
    const { offsetX, offsetY, contextMenuOffset, contextMenuService } = this.state;
    const OFFSET_X = (offsetX + c.OFFSET_X) * iconSize * zoom;
    const OFFSET_Y = (offsetY + c.OFFSET_Y) * iconSize * zoom;
    if (!services?.length && !metro.metalEnabled && !metro.networkEdgeEnabled) return null;
    const BACKGROUND_SIZE = 4180; // It's the width of the SVG
    const paddingX = iconSize < 0.7 ? 2.5 : 10;
    const paddingY = iconSize < 0.7 ? 1 : 5;
    const bgSize = (BACKGROUND_SIZE / 2) * iconSize; // the sprite is too big by a factor of two vs our base size
    const baseCardStyle = `width: ${c.SIZE_X}px; height: ${c.SIZE_Y}px; background-size: ${bgSize}px;`;
    const platformCardWrapperClassName = cn('platform-card-wrapper service', { small: iconSize < 0.7 });

    const getMetalCard = () => `
            <div class="${platformCardWrapperClassName}" data-metal="${metro.id}">
                <div class="platform-card" style="${baseCardStyle}">Equinix Metal</div>
            </div>`;

    const getEdgeCard = () => `
            <div class="${platformCardWrapperClassName}" data-edge="${metro.id}">
                <div class="platform-card" style="${baseCardStyle}">Network Edge</div>
            </div>`;

    const getServiceWrapperClassName = (service) => {
      return cn('service', {
        small: iconSize < 0.7,
        local: service.local,
        remote: !service.local,
        selected: service.id.equals(selected && selected.id),
        // We use "==" on purpose here, as we NEED type conversion
        loading: loading && service.id == contextMenuService, //eslint-disable-line
      });
    };

    const getServiceCard = (service) => {
      const wrapperClassName = getServiceWrapperClassName(service);
      const logo = markerData[service.logo] || { x: 0, y: 0 };
      const posX = (logo.x / 2) * iconSize;
      const posY = (logo.y / 2) * iconSize;
      const style = `${baseCardStyle} background-position: -${posX}px -${posY}px`;
      let content = `<div class="serviceMarker" data-service="${service.id}" style="${style}"></div>`;
      if (service.logo === 'Default') {
        const fontSizeRate = service.name.length > 14 ? 10 : 12;
        content = `<span class="serviceMarker no-logo" style="font-size: ${iconSize * fontSizeRate}px;">${service.name}</span>`;
      }

      return `
            <div class="${wrapperClassName}" data-service="${service.id}">
                    ${content}
            </div>
            `;
    };

    const handleClick = (e) => {
      const { metal, edge, service: id } = e.originalEvent.target.closest('.service').dataset;

      if (metal || edge) {
        onSelect('metro', metro);
        onMetroPanelTabChange(0);
        return;
      }
      // We use "==" on purpose here, as we NEED type conversion
      const service = services.find((s) => s.id == id); //eslint-disable-line
      onSelect('service', service);
    };

    const handleRightClick = (e) => {
      const { isEditing } = this.props;
      if (!isEditing) return;

      const { metal, edge, service } = e.originalEvent.target.closest('.service').dataset;
      const markerPos = e.layerPoint;
      const mousePos = this.props.leaflet.map.mouseEventToLayerPoint(e.originalEvent);
      const offsetX = mousePos.x - markerPos.x;
      const offsetY = mousePos.y - markerPos.y;
      this.setState({
        contextMenuOffset: [offsetX, offsetY],
        contextMenuService: service,
        contextMenuMetal: metal,
        contextMenuEdge: edge,
      });
    };

    const metalCard = metro.metalEnabled && getMetalCard();
    const edgeCard = metro.networkEdgeEnabled && getEdgeCard();
    const serviceCards = services?.map((service) => getServiceCard(service));

    const filteredCards = [metalCard, edgeCard, ...serviceCards].filter((item) => !!item);

    const icon = L.divIcon({
      html: filteredCards.join(''),
      iconSize: L.point(c.SIZE_X + paddingX * 2, (c.SIZE_Y + paddingY * 5) * c.MAX_ROWS),
      iconAnchor: L.point(OFFSET_X, OFFSET_Y),
      className: cn('servicesMarker', { smallIcon: iconSize < 0.7 }),
    });

    return (
      <>
        <Pane style={{ zIndex: 1100 }}>
          <Marker
            ref={(r) => (this.markers = r)}
            icon={icon}
            position={position}
            onClick={handleClick}
            onContextMenu={handleRightClick}
            draggable
            onDragStart={this.handleDragStart}
            onDragEnd={this.handleDragEnd}>
            {contextMenuOffset && (
              <ContextMenu offset={contextMenuOffset} onClose={() => this.setState({ contextMenuOffset: null })}>
                {this.state.contextMenuService && (
                  <>
                    <button onClick={() => this.onHide()}>Hide Service</button>
                    <button onClick={() => this.onDelete()}>Delete Service</button>
                  </>
                )}
                {this.state.contextMenuMetal && <button onClick={() => this.onDisableMetal()}>Disable Equinix Metal</button>}
                {this.state.contextMenuEdge && <button onClick={() => this.onDisableEdge()}>Disable Network Edge</button>}
              </ContextMenu>
            )}
          </Marker>
        </Pane>
        <Pane style={{ zIndex: 400 }}>
          <Marker
            ref={(r) => (this.line = r)}
            key="line"
            position={position}
            icon={line(OFFSET_X - c.SIZE_X / 2, OFFSET_Y - c.SIZE_Y / 2, iconSize)}
            interactive={false}
          />
        </Pane>
      </>
    );
  }
}

function line(ox, oy, iconSize) {
  let h, w, x1, y1, x2, y2, ax, ay;

  if (ox < 0) {
    w = -ox;
    x1 = iconSize;
    x2 = -ox;
    ax = -x1;
  } else {
    w = ox + iconSize;
    x1 = ox;
    x2 = iconSize;
    ax = x2 + w;
  }
  if (oy < 0) {
    h = -oy;
    y1 = iconSize;
    y2 = -oy;
    ay = -y1;
  } else {
    h = oy + iconSize;
    y1 = oy;
    y2 = iconSize;
    ay = y2 + h;
  }

  const svg = `
  <svg width="${w}" height="${h}" viewBox="0 0 ${w} ${h}" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter_serviceLine)">
      <line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke="red" stroke-width="${2 * iconSize}" stroke-dasharray="10,5"/>
  </g>
  <defs>
    <filter id="filter_serviceLine" x="0" y="0" width="${w}" height="${h}" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
</defs>
</svg>
      `;
  const sanitizedSvg = svg.replace(/\n/g, '').replace(/#/g, '%23');
  return L.icon({
    iconUrl: `data:image/svg+xml;utf8,${sanitizedSvg}`,
    iconAnchor: L.point(ax, ay),
  });
}

ServiceMarker = withUpdateServiceOffset(ServiceMarker);
ServiceMarker = withDeleteProjectItems(ServiceMarker);
ServiceMarker = withHideService(ServiceMarker);
ServiceMarker = withLeaflet(ServiceMarker);
ServiceMarker = withDisableMetal(ServiceMarker);
ServiceMarker = withDisableEdge(ServiceMarker);

export default ServiceMarker;
