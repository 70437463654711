import React from 'react';
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import SunIcon from 'views/components/icons/Sun';
import GettingStarted from './GettingStarted';
import Contact from './Contact';
import MessageCircleIcon from 'views/components/icons/MessageCircle';

const HelpAndFeedback = ({ openWidget }) => {
  return (
    <div>
      <Menu
        menuClassName={'default-menu'}
        menuButton={
          <MenuButton className={'user_menu'} data-testid={'user-menu'}>
            <MessageCircleIcon height={16} width={16} />
            Help & Feedback
          </MenuButton>
        }
        transition>
        <MenuItem onClick={() => openWidget('feedback.add')}>
          <SunIcon /> <span>Feedback & Ideas</span>
        </MenuItem>
        <MenuItem>
          <GettingStarted />
        </MenuItem>
        <MenuItem>
          <Contact />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default HelpAndFeedback;
