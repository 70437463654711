import React from 'react';

const EyeOffIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      vectorEffect="non-scaling-stroke"
      fill="none"
      {...rest}>
      <g clipPath="url(#clip0_7282_17863)">
        <path
          d="M11.9603 11.9603C10.8207 12.829 9.43306 13.3103 8.00033 13.3337C3.33366 13.3337 0.666992 8.00034 0.666992 8.00034C1.49625 6.45494 2.64642 5.10475 4.04033 4.04034M6.60033 2.82701C7.05921 2.7196 7.52904 2.6659 8.00033 2.66701C12.667 2.66701 15.3337 8.00034 15.3337 8.00034C14.929 8.75741 14.4464 9.47017 13.8937 10.127M9.41366 9.41368C9.23056 9.61017 9.00976 9.76778 8.76443 9.87709C8.5191 9.9864 8.25426 10.0452 7.98572 10.0499C7.71718 10.0547 7.45043 10.0053 7.2014 9.90467C6.95236 9.80408 6.72614 9.65436 6.53622 9.46444C6.34631 9.27453 6.19659 9.0483 6.096 8.79927C5.99541 8.55023 5.94601 8.28349 5.95075 8.01495C5.95549 7.74641 6.01426 7.48157 6.12358 7.23624C6.23289 6.99091 6.39049 6.77011 6.58699 6.58701"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M0.666992 0.666992L15.3337 15.3337" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7282_17863">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeOffIcon;
