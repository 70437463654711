import React from 'react';

const TrashIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M1 3.3999H2.2H11.8" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M4.0002 3.4V2.2C4.0002 1.88174 4.12662 1.57652 4.35167 1.35147C4.57671 1.12643 4.88194 1 5.2002 1H7.6002C7.91846 1 8.22368 1.12643 8.44872 1.35147C8.67377 1.57652 8.8002 1.88174 8.8002 2.2V3.4M10.6002 3.4V11.8C10.6002 12.1183 10.4738 12.4235 10.2487 12.6485C10.0237 12.8736 9.71846 13 9.4002 13H3.4002C3.08194 13 2.77671 12.8736 2.55167 12.6485C2.32662 12.4235 2.2002 12.1183 2.2002 11.8V3.4H10.6002Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.2002 6.3999V9.9999" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.6001 6.3999V9.9999" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default TrashIcon;
