import React from 'react';
import './AddLocationsView.scss';
import OpenLink from 'views/components/icons/OpenLink';

const UploadExcel = ({ onSelectionChanged }) => {
  const onUploadExcel = () => {
    onSelectionChanged('multipleLocations');
  };
  return (
    <div className="upload-file">
      <p>Have a lot of locations?</p>
      <div className="link" onClick={onUploadExcel}>
        <p>Upload an Excel File</p>
        <OpenLink width={12} height={11} color="#333333" />
      </div>
    </div>
  );
};

export default UploadExcel;
