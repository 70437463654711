import React, { useEffect, useState } from 'react';
import { Marker, Pane, withLeaflet } from 'react-leaflet';
import L from 'leaflet';
import * as ReactDOMServer from 'react-dom/server';
import DeploymentContainer from './DeploymentContainer';
import './InfraComponentsMarker.scss';
import Point from 'model/Point';
import { DEPLOYMENT_TYPES } from 'model/deploymentTypes';

const InfraComponentMarker = ({
  deployment,
  position,
  iconSize = 1,
  infraComponents,
  leaflet,
  updateDeploymentOffset,
  deploymentOffsets,
  updateOffsetLoading,
}) => {
  const savedOffset = deploymentOffsets.find((infraComp) => infraComp.deployment === deployment)?.offset || { x: 0, y: 0 };
  const [positionState, setPositionState] = useState({ x: savedOffset.x, y: savedOffset.y });
  const [offsetState, setOffsetState] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (!!deploymentOffsets.find((infraComp) => infraComp.deployment === deployment)?.offset) return;
    setOffsetState({ x: getRandomIntInclusive(-20, 20), y: getRandomIntInclusive(-20, 20) });
    // eslint-disable-next-line
  }, []);

  const constants = {
    ANCHOR: -5 * iconSize,
    OFFSET_X: offsetState.x,
    OFFSET_Y: offsetState.y,
    MARGIN: 4 * iconSize,
    SIZE_X: (150 / 2) * (iconSize / 2),
    SIZE_Y: (50 / 2) * (iconSize + 3),
  };
  const zoom = leaflet.map.getZoom();
  const OFFSET_X = (positionState.x + constants.OFFSET_X) * iconSize * zoom;
  const OFFSET_Y = (positionState.y + constants.OFFSET_Y) * iconSize * zoom;

  const getRandomIntInclusive = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  const handleDragStart = () => {
    if (line) {
      line.leafletElement.setOpacity(0);
    }
  };
  const handleDragEnd = (e) => {
    const zoom = leaflet.map.getZoom();
    const startPoint = e.target._map.project(position);
    const endPoint = e.target._map.project(e.target.getLatLng());
    let offsetX = positionState.x;
    let offsetY = positionState.y;
    offsetX += Math.floor((startPoint.x - endPoint.x) / iconSize / zoom);
    offsetY += Math.floor((startPoint.y - endPoint.y) / iconSize / zoom);
    e.target.setLatLng(position);
    if (line) {
      line.leafletElement.setOpacity(1);
    }
    setPositionState({ x: offsetX, y: offsetY });
    updateDeploymentOffset(deployment, new Point(offsetX, offsetY));
  };

  let line = (ox, oy, iconSize) => {
    let h, w, x1, y1, x2, y2, ax, ay;

    if (ox < 0) {
      w = -ox;
      x1 = iconSize;
      x2 = -ox;
      ax = -x1;
    } else {
      w = ox + iconSize;
      x1 = ox;
      x2 = iconSize;
      ax = x2 + w;
    }
    if (oy < 0) {
      h = -oy;
      y1 = iconSize;
      y2 = -oy;
      ay = -y1;
    } else {
      h = oy + iconSize;
      y1 = oy;
      y2 = iconSize;
      ay = y2 + h;
    }

    const svg = `
    <svg width="${w}" height="${h}" viewBox="0 0 ${w} ${h}" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter_serviceLine)">
        <line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke="red" stroke-width="${2 * iconSize}" stroke-dasharray="10,5"/>
    </g>
    <defs>
    <filter id="filter_serviceLine" x="0" y="0" width="${w}" height="${h}" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
    </filter>
</defs>
</svg>
        `;
    const sanitizedSvg = svg.replace(/\n/g, '').replace(/#/g, '%23');
    return L.icon({
      iconUrl: `data:image/svg+xml;utf8,${sanitizedSvg}`,
      iconAnchor: L.point(ax, ay),
    });
  };

  const icon = L.divIcon({
    html: ReactDOMServer.renderToString(
      <DeploymentContainer
        individual={true}
        iconSize={iconSize}
        name={DEPLOYMENT_TYPES[deployment].label}
        icon={DEPLOYMENT_TYPES[deployment].icon('white', 16, 16)}
        sectionInfraComponent={infraComponents}
      />
    ),
    iconSize: L.point(0, 0),
    iconAnchor: L.point(OFFSET_X, OFFSET_Y),
    className: '',
  });

  return (
    <>
      <Pane style={{ zIndex: 1100 }}>
        <Marker position={position} icon={icon} draggable={!updateOffsetLoading} onDragStart={handleDragStart} onDragEnd={handleDragEnd} />
      </Pane>
      <Pane style={{ zIndex: 400 }}>
        <Marker
          ref={(r) => (line = r)}
          key="line"
          position={position}
          icon={line(OFFSET_X - constants.SIZE_X / 2, OFFSET_Y - constants.SIZE_Y / 2, iconSize)}
          interactive={false}
        />
      </Pane>
    </>
  );
};

export default withLeaflet(InfraComponentMarker);
