import ArgumentError from '../error/ArgumentError';
import Assert from '../utils/Assert';
import ObjectUtils from '../utils/ObjectUtils';

const RADIX = 10;
const VERSION_PATTERN = /^v?([0-9]+)(?:\.([0-9]+)(?:\.([0-9]+)(?:-(.*))?)?)?$/i;

export default class Version {
  constructor(major, minor, revision, tag) {
    Assert.isPositiveInteger(major);
    Assert.isPositiveInteger(minor);
    Assert.isPositiveInteger(revision);
    Assert.isTrue(tag == null || ObjectUtils.isString(tag), 'Expected tag to be of type string, but was not');

    this.major = major || 0;
    this.minor = minor || 0;
    this.revision = revision || 0;
    this.tag = tag || null;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof Version)) {
      return false;
    }

    return this.major === other.major && this.minor === other.minor && this.revision === other.revision && this.tag === other.tag;
  }

  toJSON() {
    const { major, minor, revision, tag } = this;
    return { major, minor, revision, tag };
  }

  static fromJSON(json) {
    const { major, minor, revision, tag } = json;
    return new Version(major, minor, revision, tag);
  }

  toString() {
    return this.tag == null ? `${this.major}.${this.minor}.${this.revision}` : `${this.major}.${this.minor}.${this.revision}-${this.tag}`;
  }

  static parse(s) {
    Assert.isString(s);

    const matches = VERSION_PATTERN.exec(s);
    if (!matches) {
      throw new ArgumentError(`Could not parse version from string: '${s}', version string should be in the format: '0.0.0' or '0.0.0-my-tag'`);
    }

    //NOTE: leading ',' skips the first array item without assigning to an (unused) variable
    const [, major, minor, revision, tag] = matches;

    return new Version(parseInt(major, RADIX), parseInt(minor, RADIX), parseInt(revision, RADIX), tag);
  }
}
