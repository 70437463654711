import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import TextInput from 'views/components/TextInput';
import ToggleButton from 'views/components/ToggleButton';
import ColorPicker from './ColorPicker';
import COLORS from './colors';
import Config from 'Config';
import { dismissAllToasts } from 'utils/Toast';
import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';

const DirectConnectionForm = ({ from, to, handleCurrentPicker, loading, projectId, phaseId, connection, formId, isPickerOpen }) => {
  const [addDirectConnection] = useMutation(Mutations.ADD_DIRECT_CONNECTION);
  const [updateDirectConnection] = useMutation(Mutations.UPDATE_DIRECT_CONNECTION);
  const [deleteDirectConnection] = useMutation(Mutations.DELETE_DIRECT_CONNECTION);

  const [connectionState, setConnectionState] = useState({ label: connection?.label || '', color: connection?.color || COLORS[0] });

  useEffect(() => {
    if (connectionState !== connection) {
      setConnectionState({ ...connection, color: connection?.color || COLORS[0], label: connection?.label || '' });
    }
    // eslint-disable-next-line
  },[connection,from])


  const onAdd = async () => {
    await addDirectConnection({
      variables: {
        projectId,
        phaseId,
        sourceMetroId: from.id,
        destinationMetroId: to.id,
        label: connectionState.label,
        color: connectionState.color,
      },
    });
    dismissAllToasts();
  };
  const onRemove = async () => {
    await deleteDirectConnection({
      variables: {
        projectId,
        phaseId,
        sourceMetroId: from.id,
        destinationMetroId: to.id,
      },
    });
    dismissAllToasts();
  };

  const onUpdate = async (newValue) => {
    const updateVariables = {
      projectId,
      phaseId,
      sourceMetroId: from.id,
      destinationMetroId: to.id,
    };
    await updateDirectConnection({
      variables: {
        ...updateVariables,
        ...newValue,
      },
    });
    dismissAllToasts();
  };
  const onUpdateDebounced = useCallback(
    debounce((newValue) => onUpdate(newValue), Config.TEXT_INPUT_DELAY),
    [formId, from]
  );

  const onChangeText = (newValue) => {
    setConnectionState((prev) => {
      return { ...prev, label: newValue };
    });
    onUpdateDebounced({ label: newValue, color: connectionState.color });
  };

  const onChangeColor = (newValue) => {
    setConnectionState((prev) => {
      return { ...prev, color: newValue };
    });
    onUpdate({ color: newValue, label: connectionState.label });
  };

  const onColorClick = (color) => {
    handleCurrentPicker(null);
    onChangeColor(color);
  };

  const togglePicker = (id) => {
    handleCurrentPicker(id);
  };

  const active = !!connection;
  return (
    <div className="column">
      <div className="item first">{to.name}</div>
      {active && (
        <div className="input container item">
          <TextInput className="textInput" value={connectionState.label} onValueChange={onChangeText} />
          <ColorPicker
            value={connectionState.color}
            onColorClick={onColorClick}
            onChange={onChangeColor}
            open={isPickerOpen}
            togglePicker={togglePicker}
            formId={formId}
          />
        </div>
      )}
      <div className="item last">
        <ToggleButton small checked={active} enabled={!loading} onChecked={onAdd} onUnchecked={onRemove} />
      </div>
    </div>
  );
};

export default DirectConnectionForm;
