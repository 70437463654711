import React from 'react';

const ArrowIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox={`1 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M6.52699 9L5.76136 8.24432L8.91335 5.09233H0.75V3.99858H8.91335L5.76136 0.856534L6.52699 0.0909092L10.9815 4.54545L6.52699 9Z" fill={color} />
    </svg>
  );
};

export default ArrowIcon;
