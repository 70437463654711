export const areDomainsEquals = (arr1, arr2) => {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Check each object in the arrays
  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];
    const propertiesToCompare = ['domain', 'isEnabled'];
    // Check relevant properties only
    if (!propertiesToCompare.every((prop) => obj1[prop] === obj2[prop])) {
      return false;
    }
  }

  // If all objects are equal based on relevant properties
  return true;
};
