import { useCurrentProject } from 'providers/ProjectProvider';
import React, { useMemo } from 'react';
import Header from 'views/components/Header';
import PhasesHeader from 'views/components/Header/PhasesHeader';

const ProjectDetailViewHeader = ({ user, onChangePhase, onReorderPhases, onOpenPhasesModals }) => {
  const { selectedPhaseId, project, isTemplate, template } = useCurrentProject();
  const parsedPhases = useMemo(() => project.phases.map((phase) => ({ ...phase, selected: phase.id.value === selectedPhaseId })), [project, selectedPhaseId]);

  return (
    <div>
      <Header project={project} user={user} isTemplate={isTemplate} template={template} />

      <PhasesHeader phases={parsedPhases} onChangePhase={onChangePhase} onReorderPhases={onReorderPhases} onOpenPhasesModals={onOpenPhasesModals} />
    </div>
  );
};

export default ProjectDetailViewHeader;
