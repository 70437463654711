import { MenuItem } from '@szhsin/react-menu';
import cn from 'classnames';
import { MetroStatus } from 'model/ProjectMetro';
import React, { useState } from 'react';
import { DropTarget } from 'react-dnd';
import ContextMenu from '../ContextMenu';
import Metro2Icon from '../icons/Metro2';
import ToggleButton from '../ToggleButton';
import { dropCollect, target } from './dndSpecs';

const MetroListItem = ({
  item,
  children,
  selectedItem,
  isOver,
  connectDropTarget,
  hasMetalProducts,
  hasEdgeCategories,
  onRemoveMetro,
  onContextMenu,
  onCloseContextMenu,
  isContextMenuOpen,
  anchorPoint,
  pendingItems,
  onSelect,
}) => {
  const [expanded, setExpanded] = useState(true);

  const onToggled = (checked) => setExpanded(checked);

  const HoverOverlay = () => {
    return (
      <div className="hover-overlay">
        <p>Move</p>
      </div>
    );
  };

  const className = expanded || isOver ? 'expanded' : null;
  const listItemHeight = 41;
  const childrenCount = item.groups.length + item.locations.length + item.services.length + pendingItems;
  const style = {
    maxHeight: expanded || isOver ? childrenCount * listItemHeight + 'px' : 0,
  };

  const metroClassName = cn('metro', {
    existing: item.existing,
    underAcquisition: item.status === MetroStatus.UNDER_ACQUISITION,
  });

  const hasServices = true;

  const servicesLength = item.services.length;
  const isMetalEnabled = item.metalEnabled;
  const isEdgeEnabled = item.networkEdgeEnabled;
  const isSelected = selectedItem === item;

  return connectDropTarget(
    <li className={cn('project-item', { selected: isSelected })}>
      <ContextMenu isContextMenuOpen={isContextMenuOpen} onCloseContextMenu={onCloseContextMenu} anchorPoint={anchorPoint}>
        <MenuItem onClick={onRemoveMetro}>Remove Metro</MenuItem>
      </ContextMenu>
      <div className={metroClassName} onClick={() => onSelect(item)} onContextMenu={onContextMenu}>
        <Metro2Icon width={20} height={20} color={'white'} />
        <ToggleButton checked={expanded} onToggled={onToggled} />
        <span className="name">{item.name}</span>
        <span className="use-cases">
          {hasServices && (
            <span className={cn('platform-tag', { 'tag-enabled': !!servicesLength })}>
              {!!servicesLength && <>{servicesLength} </>}
              Service
              {servicesLength === 1 ? '' : 's'}
            </span>
          )}
          {hasMetalProducts && <span className={cn('platform-tag', { 'tag-enabled': isMetalEnabled })}>Equinix Metal</span>}
          {hasEdgeCategories && <span className={cn('platform-tag', { 'tag-enabled': isEdgeEnabled })}>Network Edge</span>}
        </span>
      </div>
      <ul key="metro-components" className={className} style={style}>
        {children}
      </ul>
      {isOver ? <HoverOverlay /> : null}
    </li>
  );
};

export default DropTarget('projectItem', target, dropCollect)(MetroListItem);
