import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GroupLocationForEditGroupView from 'model/GroupLocationForEditGroupView';
import Group from 'model/Group';
import { useQuery } from '@apollo/react-hooks';
import { Mutations, Queries } from 'gql';
import ProjectPhaseId from 'model/ProjectPhaseId';
import 'views/components/InfoPanel/children/TableStyles.scss';
import './LocationsTable.scss';
import { useMutation } from '@apollo/react-hooks';
import LocationContextMenu from './LocationContextMenu';

const LocationsTable = ({ onLoadingStateChange, scrollElementRef, projectId, phaseId, group, onSelect }) => {
  // NOTE:
  // Since the LocationsTable will be rendered everytime a new group is selected,
  // want to avoid making a server request every single time.
  // Use only the cache, if possible.
  const { data, loading, error } = useQuery(Queries.GET_PROJECT_LOCATION_GROUP_FOR_EDIT_GROUP_VIEW, {
    variables: { projectId, phaseId, locationGroupId: group?.id.value },
    fetchPolicy: 'cache-first',
  });
  const [ungroupLocations] = useMutation(Mutations.UNGROUP_LOCATION_GROUP_LOCATIONS);
  const [deleteLocations] = useMutation(Mutations.DELETE_LOCATION_GROUP_LOCATIONS);

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    onLoadingStateChange(loading);
    // eslint-disable-next-line
  }, [loading]);

  useEffect(() => {
    if (!data || !data.locationGroup || !data.locationGroup.locations || !scrollElementRef.current) return;
    setLocations(data.locationGroup.locations.map(GroupLocationForEditGroupView.fromJSON));
    // eslint-disable-next-line
  }, [data]);

  const ungroup = (locations) => {
    const locationIds = locations.map((l) => l.id);
    ungroupLocations({ variables: { projectId, phaseId, locationGroupId: group.id, locations: locationIds } });
  };

  const remove = (locations) => {
    const locationIds = locations.map((l) => l.id);
    deleteLocations({ variables: { projectId, phaseId, locationGroupId: group.id, locations: locationIds } });
  };

  const onClick = (e, location) => {
    e.stopPropagation();
    onSelect(location);
  };

  const renderLoading = () => {
    return (
      <div className="status-container">
        <span className="status-message">
          <div className="spinner"></div>
          Loading Locations
        </span>
      </div>
    );
  };

  const renderError = (error) => {
    return (
      <div className="status-container">
        <span className="status-message">{error ? error.toString() : 'Error'}</span>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        renderLoading()
      ) : error ? (
        renderError()
      ) : (
        <>
          <div className="row table-header">Address</div>
          <div className="table">
            {locations.map((location) => {
              return (
                <div className="column" key={location.id.value} onClick={(event) => onClick(event, location)}>
                  <p>{location.address.label}</p>
                  <LocationContextMenu ungroup={ungroup} remove={remove} location={location} />
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

LocationsTable.propTypes = {
  onLoadingStateChange: PropTypes.func.isRequired,
  scrollElementRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
  projectId: PropTypes.string.isRequired,
  phaseId: PropTypes.instanceOf(ProjectPhaseId).isRequired,
  group: PropTypes.instanceOf(Group),
};

export default LocationsTable;
