import Assert from '../utils/Assert';
import ArgumentError from '../error/ArgumentError';

const RADIX = 10;

export default class Point {
  static ZERO = new Point(0, 0);

  constructor(x, y) {
    Assert.isInteger(x);
    Assert.isInteger(y);

    this.x = x;
    this.y = y;

    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof Point)) {
      return false;
    }

    return this.x === other.x && this.y === other.y;
  }

  toString() {
    return `${this.x},${this.y}`;
  }

  toJSON() {
    const { x, y } = this;
    return { x, y };
  }

  static fromJSON(json) {
    const { x, y } = json;
    return new Point(x, y);
  }

  static parse(s) {
    if (s instanceof Point) {
      return s;
    }

    Assert.isString(s);

    const parts = s.split(',');
    if (parts.length !== 2) {
      throw new ArgumentError("Expected point to be in '{x},{y}' format");
    }

    return new Point(parseInt(parts[0].trim(), RADIX), parseInt(parts[1].trim(), RADIX));
  }
}
