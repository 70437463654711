import React, { useMemo } from 'react';
import { Circle as LeafletCircle, CircleMarker as LeafletCircleMarker, Polygon } from 'react-leaflet';

Math.toRadians = (degrees) => {
  return (degrees * Math.PI) / 180;
};

Math.toDegrees = (radians) => {
  return (radians * 180) / Math.PI;
};

const destinationPoint = (origin, distance, bearing) => {
  const radius = 6371e3;
  const Ad = distance / radius;
  const br = Math.toRadians(bearing);

  const lat1 = Math.toRadians(origin[0]),
    lon1 = Math.toRadians(origin[1]);

  const sinlat2 = Math.sin(lat1) * Math.cos(Ad) + Math.cos(lat1) * Math.sin(Ad) * Math.cos(br);
  const lat2 = Math.asin(sinlat2);
  const y = Math.sin(br) * Math.sin(Ad) * Math.cos(lat1);
  const x = Math.cos(Ad) - Math.sin(lat1) * sinlat2;
  const lon2 = lon1 + Math.atan2(y, x);

  const lat = Math.toDegrees(lat2);
  const lon = Math.toDegrees(lon2);

  return [lat, lon];
};

const Circle = ({
  usePixelRadius,
  radiusLine = false,
  center,
  radius,
  color,
  opacity = 0.4,
  fillColor,
  fillOpacity = 0.1,
  stroke = true,
  weight = 1,
  ...rest
}) => {
  const circleBorder = useMemo(() => destinationPoint(center, radius, 228), [center, radius]);
  const circleProps = { center, radius, color, opacity, fillColor, fillOpacity, stroke, weight, ...rest };
  return usePixelRadius ? (
    <LeafletCircleMarker {...circleProps} />
  ) : (
    <LeafletCircle {...circleProps}>{radiusLine && <Polygon weight="1.5" color={color} positions={[center, circleBorder]} />}</LeafletCircle>
  );
};

export default Circle;
