import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SuccessIcon from 'views/components/icons/Success';
import ErrorIcon from 'views/components/icons/Error';
import ImportationIssuesModal from 'views/ProjectListView/modals/ImportationIssuesModal';

const ResultMessage = ({ type, message, details }) => {
  const [issueModalOpen, setIssueModalOpen] = useState(false);
  const ICONS = {
    success: <SuccessIcon width={20} height={20} color="#4CAF50" />,
    error: <ErrorIcon width={22} height={19} color="#F44336" />,
  };

  const onOpenModal = () => {
    setIssueModalOpen(true);
  };

  const onCloseModal = () => {
    setIssueModalOpen(false);
  };

  const icon = ICONS[type];
  return (
    <div className={`result-message ${type} download-template`}>
      <div className={'result-message-info-wrapper'}>
        {icon} <p>{message}</p>
      </div>{' '}
      {!!details?.length && <span onClick={onOpenModal}>View Details</span>}
      {issueModalOpen && (
        <ImportationIssuesModal
          importationIssues={details}
          title={`Unable to import ${details?.length} locations`}
          onClose={onCloseModal}
          isOpen={issueModalOpen}
        />
      )}
    </div>
  );
};

ResultMessage.propTypes = {
  type: PropTypes.oneOf(['success', 'error']).isRequired,
  message: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(PropTypes.object),
};

export default ResultMessage;
