import { useQuery } from 'react-apollo';
import { Queries } from 'gql';
import { useActivityMonitor } from 'activity';
import ProjectPresence from 'model/ProjectPresence';
import Config from 'Config';
import { LoggerFactory } from 'logger';

const logger = LoggerFactory.getLogger('presence');

const useProjectPresence = (project) => {
  const { isUserActive } = useActivityMonitor();

  if (!project?.isShared) {
    logger.info('Project is not shared, rendering without periodic presence updates');
  } else {
    logger.info('Project is shared, rendering with periodic presence updates');
  }

  //update frequently if the project is shared and the user is active, otherwise don't update at all
  const pollInterval = isUserActive ? +Config.ACTIVE_UPDATE_INTERVAL : 0;

  const { data = [] } = useQuery(Queries.GET_PROJECT_PRESENCE, {
    variables: { projectId: project.id },
    skip: !project?.isShared,
    pollInterval,
    onCompleted: (data) => ProjectPresence.fromJSONArray(data?.presence),
  });

  return { presence: data.presence || [] };
};

export default useProjectPresence;
