import Reflux from 'reflux';
import { LoggerFactory } from 'logger';
import UserStore, { Actions } from 'stores/UserStore';
import AuthToken from 'model/AuthToken';
import User from 'model/User';

class UserView extends Reflux.Component {
  constructor(props) {
    super(props);

    this._logger = LoggerFactory.getLogger(this);

    let stores = [];
    for (let i = 0; i < arguments.length; i++) {
      const arg = arguments[i];
      if (arg != null && arg !== UserStore && arg.prototype instanceof Reflux.Store) {
        stores.push(arg);
      }
    }

    this.state = {};
    this.stores = [...stores, UserStore];
  }

  get authToken() {
    return this.state.authToken || null;
  }

  get user() {
    return this.state.user || null;
  }

  get isUserLoading() {
    return this.state.loadingUser;
  }

  get isAuthenticated() {
    return this.authToken instanceof AuthToken && this.user instanceof User;
  }

  beginLogin(email) {
    this._logger.debug(`Beginning login process for email: '${email}'`);
    Actions.beginLogin(email);
  }

  login(authorizationCode) {
    this._logger.debug(`Logging in user for auth code: ${authorizationCode}`);
    Actions.login(authorizationCode);
  }

  logout() {
    this._logger.debug(`Logging out user: ${this.user}`);
    Actions.logout();
  }
}

export default UserView;
