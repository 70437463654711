import L from 'leaflet';
import { DivOverlay, withLeaflet } from 'react-leaflet';

const LeafletContextMenu = L.Popup.extend({
  options: {
    offset: [0, 0],
  },

  setOffset: function (offset) {
    this.options.offset = offset;
    if (this._map) {
      this._updatePosition();
      this._adjustPan();
    }
    return this;
  },

  _updatePosition: function () {
    if (!this._map) {
      return;
    }

    let pos = this._map.latLngToLayerPoint(this._latlng);
    const offset = L.point(this.options.offset);
    let anchor = this._getAnchor();

    if (this._zoomAnimated) {
      L.DomUtil.setPosition(this._container, pos.add(anchor).add(offset));
    }
  },
});

class ContextMenu extends DivOverlay {
  static defaultProps = {
    pane: 'popupPane',
  };

  getOptions(props) {
    return {
      ...super.getOptions(props),
      autoPan: false,
    };
  }

  createLeafletElement(props) {
    const options = this.getOptions(props);
    options.autoPan = props.autoPan !== false;
    options.className = (options.className || '') + ' context-menu';
    const element = new LeafletContextMenu(options, props.leaflet.popupContainer);
    if (props.position) {
      element.setLatLng(props.position);
    }
    return element;
  }

  updateLeafletElement(fromProps, toProps) {
    if (toProps.position !== fromProps.position) {
      this.leafletElement.setLatLng(toProps.position);
    }
    if (toProps.offset !== fromProps.offset) {
      this.leafletElement.setOffset(toProps.offset);
    }
  }

  componentDidMount() {
    const { position } = this.props;
    const { map, popupContainer } = this.props.leaflet;
    const el = this.leafletElement;
    map.getPane('popupPane').style.zIndex = 2000;

    if (popupContainer) {
      // Attach to container component
      popupContainer.bindPopup(el);
      popupContainer.on({
        popupopen: this.onPopupOpen,
        popupclose: this.onPopupClose,
      });
      if (position) {
        popupContainer.openPopup(position);
      } else {
        popupContainer.openPopup();
      }
    } else {
      // Attach to a Map
      if (position) {
        el.setLatLng(position);
      }
      map.on({
        popupopen: this.onPopupOpen,
        popupclose: this.onPopupClose,
      });
      el.openOn(map);
    }
  }

  componentWillUnmount() {
    const { map, popupContainer } = this.props.leaflet;

    if (popupContainer) {
      popupContainer.off({
        popupopen: this.onPopupOpen,
        popupclose: this.onPopupClose,
      });
      popupContainer.closePopup();
      popupContainer.unbindPopup();
    } else {
      map.off({
        popupopen: this.onPopupOpen,
        popupclose: this.onPopupClose,
      });
      map.removeLayer(this.leafletElement);
    }

    super.componentWillUnmount();
  }

  onPopupOpen = ({ popup }) => {
    if (popup === this.leafletElement) {
      this.onOpen();
    }
  };

  onPopupClose = ({ popup }) => {
    if (popup === this.leafletElement) {
      this.onClose();
    }
  };

  // onRender = () => {
  //     if (this.props.autoPan !== false && this.leafletElement.isOpen()) {
  //         if (this.leafletElement._map && this.leafletElement._map._panAnim) {
  //             this.leafletElement._map._panAnim = undefined;
  //         }
  //         this.leafletElement._adjustPan();
  //     }
  // }
}

export default withLeaflet(ContextMenu);
