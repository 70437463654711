import React from 'react';
import { CSVLink } from 'react-csv';
import FeatureEvents from 'analytics/FeatureEvents';
import Config from 'Config';
import ExportMap from '../ProjectMap/ExportMap';
import getAuthenticationService from 'service/AuthenticationService';
import { Actions } from 'stores/UserStore';
import { toast } from 'utils/Toast';

import './ExportModal.scss';
import ServiceError from 'error/ServiceError';

class MiniMap extends React.Component {
  state = { exporting: false };
  export = () => {
    if (this._link) {
      this._link.click();
    }
  };

  render() {
    const { name, bounds, project, phase, metros, connectionType, layers, size, ringLatency, ringColor, metroType, marketplaceSegment, mapType } = this.props;
    const boundsParam = bounds.map((i) => i.join(',')).join(',');
    const query = {
      bounds: boundsParam,
      connectionType,
      metroType,
      ...(layers.latencyRings && {
        rl: this.props.ringLatency,
        rc: this.props.ringColor,
      }),
      marketplaceSegment: encodeURIComponent(JSON.stringify(marketplaceSegment)),
      mapType,
      options: [
        layers.activeMetros ? 'a' : '',
        layers.inactiveMetros ? 'i' : '',
        layers.customerLocations ? 'o' : '',
        layers.services ? 's' : '',
        layers.activeRegions ? 'R' : '',
        layers.inactiveRegions ? 'r' : '',
        layers.latencies ? 'l' : '',
        layers.serviceLatencies ? 'L' : '',
        layers.connections ? 'c' : '',
        layers.directConnections ? 'd' : '',
        layers.labels ? 'C' : '',
        layers.latencyRings ? 'f' : '',
        layers.customerLocationLines ? 'y' : '',
      ].join(''),
    };
    if (size) {
      query.size = size.join(',');
    }
    const queryStr = Object.keys(query)
      .map((k) => `${k}=${query[k]}`)
      .join('&');
    const url = `${Config.REPORTS_URL}map/${project.id}/${phase.id}/${encodeURIComponent(project.name)} - ${encodeURIComponent(
      phase.name
    )} - ${name}.png?${queryStr}`;
    return (
      <div className={`mini-map map ${name}`}>
        {this.state.exporting ? (
          <div className="loading">
            <div className="spinner"></div>
          </div>
        ) : (
          <a
            className="download-link"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            ref={(ref) => (this._link = ref)}
            onClick={() => FeatureEvents.mapDownloaded(project.id, name)}>
            {' '}
            {/* TODO: revisar qué hace el evento con project*/}
            <span className="icon">
              <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.51358 8.15713L4.16002 8.51069L4.51358 8.86424L4.86713 8.51069L4.51358 8.15713ZM8.27321 3.69039L4.16002 7.80358L4.86713 8.51069L8.98032 4.3975L8.27321 3.69039ZM4.86713 7.80358L0.753944 3.69039L0.0468372 4.3975L4.16002 8.51069L4.86713 7.80358ZM5.01358 8.15713V0H4.01358V8.15713H5.01358ZM0 11.5H9V10.5H0V11.5Z"
                  fill="white"
                />
              </svg>
            </span>
            <span className="text">Map-{name.toUpperCase()} &middot; PNG</span>
          </a>
        )}
        <ExportMap
          bounds={bounds}
          project={project}
          phase={phase}
          metros={metros}
          mini
          connectionType={connectionType}
          layers={layers}
          ringLatency={ringLatency}
          ringColor={ringColor}
          metroType={metroType}
          marketplaceSegment={marketplaceSegment}
          mapType={mapType}
        />
      </div>
    );
  }
}

function DownloadPdf(props) {
  const { href, download, projectId, type } = props;
  return (
    <a
      className="download-pdf"
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      download={download}
      onClick={() => FeatureEvents.pdfDownloaded(projectId, type)}>
      <svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.15811 9.04385L4.80455 9.3974L5.15811 9.75096L5.51166 9.3974L5.15811 9.04385ZM8.91774 4.57711L4.80455 8.6903L5.51166 9.3974L9.62485 5.28422L8.91774 4.57711ZM5.51166 8.6903L1.39848 4.57711L0.691368 5.28422L4.80455 9.3974L5.51166 8.6903ZM5.65811 9.04385V0.886719H4.65811V9.04385H5.65811ZM0.644531 12.3867H9.64453V11.3867H0.644531V12.3867Z"
          fill="black"
        />
      </svg>
    </a>
  );
}

class PDFPreview extends React.Component {
  componentDidMount() {
    this.updateScale();
  }
  componentDidUpdate() {
    this.updateScale();
  }
  updateScale() {
    if (this.container && this.iframe) {
      const containerWidth = this.container.clientWidth;
      const iframeWidth = this.iframe.clientWidth;
      const iframeHeight = this.iframe.clientHeight;
      const scale = containerWidth / iframeWidth;
      this.iframe.style = `transform: scale(${scale});`;
      if (this.content) {
        this.content.style = `width: ${containerWidth}px; height: ${iframeHeight * scale}px;`;
      }
    }
  }
  render() {
    const { authorized, suffix, id, project, phase } = this.props;
    const title = `${project.name} - ${phase.name} - ${suffix}.pdf`;
    const previewUrl = `${Config.REPORTS_URL}${id}/${project.id}/${phase.id}`;
    const downloadUrl = `${previewUrl}/${encodeURIComponent(title)}`;
    return (
      <div className="report-container" ref={(el) => (this.container = el)}>
        <div className="report-topbar">
          <span className="report-title">{title}</span>
          {authorized ? <DownloadPdf href={downloadUrl} download={title} projectId={project.id} type={suffix} /> : null}
        </div>
        <div className="report-content" ref={(el) => (this.content = el)}>
          {authorized ? (
            <div className="frame-container">
              <iframe title={title} className="report-frame" src={previewUrl} ref={(el) => (this.iframe = el)} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default class ExportModal extends React.Component {
  state = {
    authorized: false,
  };

  _closeWithEscHandler = (e) => {
    if (e.key === 'Escape') {
      this.props.onClose();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this._closeWithEscHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._closeWithEscHandler);
  }

  UNSAFE_componentWillMount() {
    const { project, phase } = this.props;
    const authenticationService = getAuthenticationService();
    const token = authenticationService.getToken();

    fetch(`${Config.REPORTS_URL}authorize`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `${token.value}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        }

        if (res.status === 401) {
          // the token probably expired or is no longer valid
          const token = authenticationService.getToken();
          if (!token || token.isExpired) {
            Actions.expired();
          }
        }

        throw new ServiceError(`Service returned status: ${res.status}`);
      })
      .then((text) => this.setState({ authorized: text === 'OK' }))
      .catch((error) => toast(error.message, 'error'));

    this.setState({ csvData: null }, () => {
      const formData = new FormData();
      formData.append('projectId', project.id);
      formData.append('phaseId', phase.id);

      fetch(`${Config.API_URL}deployment-plan`, {
        method: 'POST',
        headers: {
          Accept: 'text/csv',
          Authorization: `Bearer ${token.value}`,
        },
        body: formData,
      })
        .then((res) => {
          if (res.ok) {
            return res.text();
          }

          // the token probably expired or is no longer valid
          const token = authenticationService.getToken();
          if (!token || token.isExpired) {
            Actions.expired();
          }

          throw new ServiceError(`Service returned status: ${res.status}`);
        })
        .then((text) => this.setState({ csvData: text }))
        .catch((error) => toast(error.message, 'error'));
    });
  }

  exportAll = () => {
    const maps = [this._worldMap, this._amerMap, this._emeaMap, this._apacMap];
    maps.forEach((map) => {
      if (map) {
        map.export();
      }
    });
  };

  render() {
    const { project, phase, metros, onClose, connectionType, layers, ringLatency, ringColor, metroType, marketplaceSegment, mapType } = this.props;
    const { authorized } = this.state;

    return (
      <div className="export" onClick={onClose}>
        <div className="modal" onClick={(e) => e.stopPropagation()}>
          <button className="close" onClick={onClose}>
            &times;
          </button>
          <h2>Maps by region</h2>
          <div className="maps">
            <MiniMap
              name="world"
              bounds={[
                [60, -160],
                [-50, 160],
              ]}
              project={project}
              phase={phase}
              metros={metros}
              ref={(ref) => (this._worldMap = ref)}
              connectionType={connectionType}
              layers={layers}
              ringLatency={ringLatency}
              ringColor={ringColor}
              metroType={metroType}
              marketplaceSegment={marketplaceSegment}
              mapType={mapType}
            />
            <MiniMap
              name="amer"
              bounds={[
                [54.6, -125.7],
                [-34.7, -37.7],
              ]}
              project={project}
              phase={phase}
              metros={metros}
              size={[2460, 3000]}
              ref={(ref) => (this._amerMap = ref)}
              connectionType={connectionType}
              layers={layers}
              ringLatency={ringLatency}
              ringColor={ringColor}
              metroType={metroType}
              marketplaceSegment={marketplaceSegment}
              mapType={mapType}
            />
            <MiniMap
              name="emea"
              bounds={[
                [63.14, -10.31],
                [24.03, 67.27],
              ]}
              project={project}
              phase={phase}
              metros={metros}
              ref={(ref) => (this._emeaMap = ref)}
              connectionType={connectionType}
              layers={layers}
              ringLatency={ringLatency}
              ringColor={ringColor}
              metroType={metroType}
              marketplaceSegment={marketplaceSegment}
              mapType={mapType}
            />
            <MiniMap
              name="apac"
              bounds={[
                [42.93, 91.66],
                [-42.76, 178],
              ]}
              project={project}
              phase={phase}
              metros={metros}
              size={[2260, 3000]}
              ref={(ref) => (this._apacMap = ref)}
              connectionType={connectionType}
              layers={layers}
              ringLatency={ringLatency}
              ringColor={ringColor}
              metroType={metroType}
              marketplaceSegment={marketplaceSegment}
              mapType={mapType}
            />
          </div>

          {this.state.csvData ? (
            <CSVLink
              data={this.state.csvData}
              filename={`${project.name.replace('[\\\\\\\\/:*?\\"<>(|]', '_')}-${phase.name.replace('[\\\\\\\\/:*?\\"<>(|]', '_')}-Deployment Plan.csv`}
              target="_blank"
              className="deployment-plan-link">
              Download Deployment Plan CSV (In Development)
            </CSVLink>
          ) : null}

          <h2>Generated Documents</h2>
          <div className="pdf-reports">
            <PDFPreview authorized={authorized} suffix="IOA Datasheet" id="briefing_materials" project={project} phase={phase} />
          </div>
        </div>
      </div>
    );
  }
}
