const category = 'Product Events';

module.exports = [
  {
    fn: 'metroAdded',
    category,
    name: 'Metro Added',
    trigger: 'MetroAdded',
    params: ['metro'],
  },
  {
    fn: 'metroRemoved',
    category,
    name: 'Metro Removed',
    trigger: 'MetroRemoved',
    params: ['metro'],
  },
  {
    fn: 'serviceAdded',
    category,
    name: 'Service Added',
    trigger: 'ServiceAdded',
    params: ['service'],
  },
  {
    fn: 'serviceRemoved',
    category,
    name: 'Service Removed',
    trigger: 'ServiceRemoved',
    params: ['service'],
  },
  {
    fn: 'serviceRegionAdded',
    category,
    name: 'Service Region Added',
    trigger: 'ServiceRegionAdded',
    params: ['serviceAndRegion'],
  },
  {
    fn: 'serviceRegionRemoved',
    category,
    name: 'Service Region Removed',
    trigger: 'ServiceRegionRemoved',
    params: ['serviceAndRegion'],
  },
  {
    fn: 'locationAdded',
    category,
    name: 'Location Added',
    trigger: 'LocationAdded',
    params: ['type'],
  },
  {
    fn: 'locationRemoved',
    category,
    name: 'Location Removed',
    trigger: 'LocationRemoved',
    params: ['type'],
  },
];
