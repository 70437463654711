import React, { useEffect, useState } from 'react';
import { dismissAllToasts, toast } from 'utils/Toast';
import TabPanel from '../../TabPanel';
import TabPanelContent from '../../TabPanelContent';
import EdgesWrapper from './index';
import TabPanelProductsSide from '../../TabPanelProductsSide';
import { useMutation, useQuery } from 'react-apollo';
import { Mutations, Queries } from 'gql';

const EdgeTabPanel = ({ projectId, phaseId, metro }) => {
  const metroCode = metro.code;
  const [networkEdgeCategories, setNetworkEdgeCategories] = useState([]);
  const { data, loading: loadingEdgeCategories, error: errorEdgeCategories } = useQuery(Queries.GET_NETWORK_EDGE_CATEGORIES, {
    variables: { metroCode },
    fetchPolicy: 'no-cache',
  });
  const [enableEdgeCategory, { loading: loadingEnableEdge, error: errorEnableEdge }] = useMutation(Mutations.ENABLE_NETWORK_EDGE);
  const [disableEdgeCategory, { loading: loadingDisableEdge, error: errorDisableEdge }] = useMutation(Mutations.DISABLE_NETWORK_EDGE);

  useEffect(() => {
    if (!data) return;
    setNetworkEdgeCategories(data.networkEdgeCategories);
  }, [data]);
  const title = 'Network Edge';
  const productName = 'product';

  const edgeEnabled = metro.networkEdgeEnabled;
  const edgeDeprecated = metro.networkEdgeCategories
    // eslint-disable-next-line
  .map((edge) => {
      if (edge.deprecated) {
        return {
          ...edge,
          name: `${edge.name} (No longer available)`,
          id: edge.id.value,
          checked: true,
        };
      }
    })
    .filter((m) => m);

  const edgesInMetro = metro.networkEdgeCategories || [];

  const edgesChecked = edgesInMetro
    .map((category) => {
      const id = category.id.value;
      const devicesIds = category.devices.map((device) => device.id.value);
      return [id, ...devicesIds];
    })
    .flat();

  const edgesFormatted = networkEdgeCategories
    .map((edge) => {
      return {
        ...edge,
        checked: edgesChecked.includes(edge.id),
        devices: edge.devices.map((device) => ({ ...device, checked: edgesChecked.includes(device.id) })),
      };
    })
    .filter(({ checked, deprecated, devices }) => {
      if (checked) return true;
      if (deprecated) {
        const deviceChecked = devices.some((device) => device.checked);
        return deviceChecked;
      }
      return true;
    });

  const onEnableEdge = async () => {
    await enableEdgeCategory({ variables: { projectId, phaseId, metroCode, categoryId: null } });
    dismissAllToasts();
  };
  const onDisableEdge = async () => {
    await disableEdgeCategory({ variables: { projectId, phaseId, metroCode, categoryId: null } });
    dismissAllToasts();
  };

  const calculateProductsEnabled = (edgesInMetro) => {
    const productCount = edgesInMetro.length; // TODO review when devices are added
    const productType = productName + (productCount > 1 ? 's' : '');
    return `${productCount} ${productType} enabled`;
  };

  errorEnableEdge && toast("Couldn't enable Network Edge", 'error');
  errorDisableEdge && toast("Couldn't disable Network Edge", 'error');

  const isLoading = loadingEdgeCategories || loadingEnableEdge || loadingDisableEdge;

  const edgeAvailable = !!networkEdgeCategories.length;
  const productsText = !edgesInMetro.length ? '' : calculateProductsEnabled(edgesInMetro);

  const sideSection = (
    <TabPanelProductsSide
      productsText={productsText}
      productEnabled={edgeEnabled}
      productAvailable={edgeAvailable}
      onChecked={onEnableEdge}
      onUnchecked={onDisableEdge}
      loading={isLoading}
    />
  );

  return (
    <TabPanel title={title} sideSection={sideSection} productAvailable={edgeAvailable} loading={isLoading} error={errorEdgeCategories}>
      {edgeAvailable && (
        <TabPanelContent>
          <EdgesWrapper projectId={projectId} phaseId={phaseId} metroCode={metroCode} networkEdgeCategories={edgesFormatted.concat(edgeDeprecated)} />
        </TabPanelContent>
      )}
    </TabPanel>
  );
};

export default EdgeTabPanel;
