const DropdownStyles = {
  control: (provided, state) => ({
    ...provided,
    'boxShadow': 'none',
    'border': `1px solid ${state.isFocused ? '#006BD9' : '#999'}`,
    'fontSize': '14px',
    'borderRadius': '0px',
    '&:hover': {
      border: '1px solid #006BD9',
    },
    'cursor': state.selectProps.isSearchable ? 'text' : 'pointer',
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#707073',
    };
  },
  option: (provided, state) => ({
    ...provided,
    'cursor': 'pointer',
    'color': state.isSelected ? 'white' : provided.color,
    'backgroundColor': state.isSelected ? '#006BD9' : state.isFocused ? 'rgba(0, 107, 217, .25)' : provided.backgroundColor,
    '&:active': {
      backgroundColor: 'rgba(0, 107, 217, .25)',
    },
    'svg': {
      path: {
        fill: state.isSelected && 'white',
      },
    },
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};

export default DropdownStyles;
