import React from 'react';
import { DropTarget } from 'react-dnd';

import { DisconnectHoverOverlay } from './PhaseHeader';
import { target, dropCollect } from './dndSpecs';

const UnconnectedList = ({ children, isOver, connectDropTarget }) => {
  return connectDropTarget(
    <ul className="UnconnectedList">
      {children}
      {isOver ? <DisconnectHoverOverlay /> : null}
    </ul>
  );
};

export default DropTarget('projectItem', target, dropCollect)(UnconnectedList);
