import Assert from '../utils/Assert';

export default class BaseModel {
  constructor(/* list of attributes */) {
    Assert.isArray(this.constructor.fields);

    for (let i = 0; i < this.constructor.fields.length; i++) {
      const field = this.constructor.fields[i];
      const data = arguments[i];
      Assert.isString(field.key);
      if (data || field.required) {
        if (field.type === 'string') {
          Assert.isString(data);
        } else if (field.type === 'number') {
          Assert.isNumber(data);
        } else if (field.type === 'array') {
          Assert.isArray(data);
        } else if (field.type === 'boolean') {
          Assert.isBoolean(data);
        } else if (typeof field.type === 'function') {
          Assert.instanceOf(data, field.type);
        } else {
          throw new Error('Unrecognized field type!');
        }
      }
      this[field.key] = data;
    }
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof this.constructor)) {
      return false;
    } else if (this.id) {
      return this.id.equals(other.id);
    } else {
      return false;
    }
  }

  toString() {
    const str = this.constructor.fields
      .filter((f) => f.stringify)
      .map((f) => `${f.key}:${this[f.key]}`)
      .join(', ');
    return `${this.constructor.name}{ ${str} }`;
  }

  toJSON() {
    return this.constructor.fields.reduce((json, field) => {
      let data = this[field.key];
      if (data.toJSON) {
        data = data.toJSON();
      }
      return {
        ...json,
        [field.key]: data,
      };
    }, {});
  }

  static fromJSON(json) {
    if (!json) {
      return json;
    }
    const args = this.fields.map((field) => {
      let data = json[field.key];
      if (data && typeof field.type === 'function') {
        data = field.type.fromJSON(data);
      } else if (field.type === 'array') {
        data = field.of === 'string' || field.of === 'number' || field.of === 'boolean' ? data : field.of.fromJSONArray(data);
      }
      return data;
    });
    return new this(...args);
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = this.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}
