import React from 'react';
import './LoginView.scss';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { LoggerFactory } from 'logger';
import TextInput from '../components/TextInput';
import { toast } from 'utils/Toast';

import Config from 'Config';
import UserView from '../UserView';
import MetroIcon from '../components/icons/Metro';
import CloudsIcon from '../components/icons/Clouds';
import PinMapIcon from '../components/icons/PinMap';
import DocumentsIcon from '../components/icons/Documents';
import Button from '../components/Button';
import VersionStamp from 'views/components/VersionStamp';
import EquinixLogo from 'views/components/icons/EquinixLogo';
import ArrowIcon from 'views/components/icons/Arrow2';

class LoginView extends UserView {
  constructor(props) {
    super(props);
    this._logger = LoggerFactory.getLogger(this);
  }

  _onLoginClicked = (email) => this.beginLogin(email);

  componentDidMount() {
    if (this.isAuthenticated) {
      this._logger.info(`User ${this.user} already logged in, redirecting to /projects`);
      this.props.history.replace('/projects');
    }
  }

  render() {
    return (
      <div className="content-login-view">
        <div className="login">
          <div className="introduction">
            <p className="title">IOA WorkBench</p>
            <p className="subtitle">
              Visualize your infrastructure on Platform Equinix <span>®</span>
            </p>
          </div>

          <LoginForm emailSent={this.state.emailSent} onLoginClicked={this._onLoginClicked} />

          <div className="information">
            <p>
              <MetroIcon width={14} height={20} color="white" viewBox={`0 0 ${14} ${20}`} style={{ height: '25px' }} /> Quickly model global digital
              infrastructure solutions
            </p>
            <p>
              <PinMapIcon width={20} height={19} color="white" viewBox={`0 0 ${20} ${19}`} /> Design multi-phase expansion across the platform
            </p>
            <p>
              <CloudsIcon width={26} height={25} color="white" viewBox={`0 0 ${26} ${25}`} />
              Demonstrate cloud region, network, and ecosystem density
            </p>
            <p>
              <DocumentsIcon width={18} height={21} color="white" viewBox={`0 0 ${18} ${21}`} />
              Export as PNG or PDF to share with customers
            </p>
          </div>

          <div className="footer">
            <p>
              <a href="https://www.equinix.com/about/legal/terms" target="_blank" rel="noopener noreferrer">
                Terms of Use
              </a>
              <a href="https://www.equinix.com/about/trust-security" target="_blank" rel="noopener noreferrer">
                Security Trust & Transparency
              </a>
              <a href="https://www.equinix.com/about/legal/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Statement
              </a>
            </p>
          </div>
        </div>
        <VersionStamp version={Config.VERSION} />
      </div>
    );
  }
}

class LoginForm extends React.Component {
  static defaultProps = {
    emailSent: false,
    onLoginClicked: null,
  };

  static propTypes = {
    emailSent: PropTypes.bool,
    onLoginClicked: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };
  }

  _onValueChange = (newValue) => this.setState({ email: newValue.trim() });

  _onLoginClick = (e) => {
    e.preventDefault();

    if (!this.state.email || !this.isValidEmail(this.state.email)) {
      toast('Email is not formatted correctly', 'error');
      return;
    }

    this.props.onLoginClicked(this.state.email);
  };

  _onLoginEquinixSSOClick = () => {
    window.location.assign(`${Config.AUTH_URL}oauth2/authorization/sso.equinix.com`);
  };

  isValidEmail = (email) => {
    //EQX-2744: To specify which special characters you want to be valid before the @, add them after the \\?
    const regex = /^[\w-\\.\\?+']+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
  };

  renderUnsent() {
    return (
      <form>
        <p className="sign-in">Sign In</p>
        <div className="inputs">
          <Button
            type="button"
            color="red"
            text="Login with Equinix SSO"
            isStretch
            Icon={EquinixLogo}
            iconColor="white"
            EndIcon={ArrowIcon}
            onClick={this._onLoginEquinixSSOClick}
          />
          <p>Or, get a Login Link</p>
          <div className="input-container">
            <TextInput className="textInput" placeholder="Enter your email..." value={this.state.email} onValueChange={this._onValueChange} autoFocus={true} />
            <Button color="gray" text="Login" onClick={this._onLoginClick} />
          </div>
        </div>

        <p>
          Can't log in?
          <a href={`mailto:${Config.SUPPORT_EMAIL}`}> Get help or request access.</a>
        </p>
      </form>
    );
  }

  renderSent() {
    return (
      <form>
        <h2>Your link has been sent.</h2>
        <p>Check your inbox for a single-use login link.</p>
      </form>
    );
  }

  render() {
    return this.props.emailSent ? this.renderSent() : this.renderUnsent();
  }
}

export default withRouter(LoginView);
