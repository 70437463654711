import React from 'react';
import cn from 'classnames';
import UserRoleSideNav from './UserRoleSideNav';

const SideNav = ({ selected, onSelect, user }) => {
  return (
    <div className="sidenav">
      <div onClick={() => onSelect({ category: 'Projects', selected: 'MyProjects' })} className={cn('item option', { selected: selected === 'MyProjects' })}>
        My Projects
      </div>
      <div className="item title">Templates</div>
      <div onClick={() => onSelect({ category: 'Templates', selected: 'Explore' })} className={cn('item option', { selected: selected === 'Explore' })}>
        Explore
      </div>
      <div onClick={() => onSelect({ category: 'Templates', selected: 'Saved' })} className={cn('item option', { selected: selected === 'Saved' })}>
        Saved Templates
      </div>
      <div onClick={() => onSelect({ category: 'Templates', selected: 'Templates' })} className={cn('item option', { selected: selected === 'Templates' })}>
        My Templates
      </div>
      <UserRoleSideNav selected={selected} onSelect={onSelect} user={user} />
      <div className="item title">Resources</div>
      <div
        onClick={() => onSelect({ category: 'Resources', selected: 'Getting Started' })}
        className={cn('item option', { selected: selected === 'Getting Started' })}>
        Getting Started
      </div>
    </div>
  );
};

export default SideNav;
