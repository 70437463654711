import { useQuery } from '@apollo/react-hooks';
import { Queries } from 'gql';
import React, { createContext, useContext, useState, useEffect } from 'react';

import { toast } from 'utils/Toast';

const MarketplaceContext = createContext(null);

const sortByName = (arr) => arr?.sort((a, b) => (a.name > b.name ? 1 : -1));

const MarketplaceProvider = ({ children }) => {
  const [marketplaceSegments, setMarketplaceSegments] = useState([]);
  const [maxAverage, setMaxAverage] = useState();
  const { data, error } = useQuery(Queries.GET_MARKETPLACE_SEGMENTS);

  useEffect(() => {
    if (error) toast('An unexpected error occurred loading the project', 'error');
  }, [error]);

  useEffect(() => {
    if (!data) return;
    const buyers = [...data.marketplaces.buyers];
    const sorted = sortByName(buyers);
    sorted.forEach(({ subSegments }) => sortByName(subSegments));

    const total = sorted.reduce((sum, { count }) => sum + count, 0);

    const all = {
      id: 'all',
      name: 'All Participants',
      count: total || 9850,
      subSegments: [],
    };

    sorted.unshift(all);
    setMarketplaceSegments(sorted);

    const maxAvg = sorted.reduce((max, { average }) => (average > max ? average : max), 0);
    setMaxAverage(maxAvg);
  }, [data]);

  return <MarketplaceContext.Provider value={{ marketplaceSegments, maxAverage }}>{children}</MarketplaceContext.Provider>;
};

export const useMarketplaceSegments = () => {
  const marketplaceContext = useContext(MarketplaceContext);

  if (!marketplaceContext) {
    throw new Error('MarketplaceContext not found');
  }

  return marketplaceContext;
};

export const withMarketplaceProvider = (Component) => {
  return (props) => (
    <MarketplaceProvider {...props}>
      <Component {...props} />
    </MarketplaceProvider>
  );
};

export default MarketplaceProvider;
