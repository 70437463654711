import React from 'react';
import 'views/components/InfoPanel/children/TableStyles.scss';

const FabricConnectionsTable = ({ latencies }) => {
  return (
    <div className="table" data-testid={'fabric-latencies'}>
      {latencies.length ? (
        latencies.map((to) => (
          <div className={'column'} key={to.metro.id}>
            <p>{to.metro.name}</p>
            <p>{to.distance}ms</p>
          </div>
        ))
      ) : (
        <div className={'column'}>
          <p className="item">No connected metros</p>
        </div>
      )}
    </div>
  );
};

export default FabricConnectionsTable;
