import LOGGER from './logger';

export class ActivityMonitorService {
  constructor() {
    LOGGER.debug('Initializing ActivityMonitorService');

    this._active = true;
  }

  get isUserActive() {
    return this._active;
  }

  set _isUserActive(value) {
    if (this._active === value) {
      return;
    }

    this._active = value;
  }
}

//singleton
let instance = null;
const getInstance = () => {
  if (instance) {
    return instance;
  }

  instance = new ActivityMonitorService();

  return instance;
};

export default getInstance;
