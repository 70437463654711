import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { toast } from 'utils/Toast';
import { LoggerFactory } from 'logger';
import UserView from './UserView';

class AuthCallbackView extends UserView {
  constructor(props) {
    super(props);
    this._logger = LoggerFactory.getLogger(this);
  }

  componentDidMount() {
    //if the user is auth'd, redirect to /projects
    if (this.isAuthenticated) {
      this._logger.debug('User authenticated, redirecting to /projects');
      this.props.history.replace('/projects');
      return;
    }

    if (this.state.errors.length > 0) {
      this._logger.warn('Errors exist, redirecting to /');
      this.props.history.replace('/');
      return;
    }

    //parse the query string
    const q = qs.parse(this.props.location.search.substring(1));

    //if the login verification failed, we will get ?error=invalid_code
    if (q.error) {
      this._logger.error(`There was an error logging in: ${q.error}`);
      toast('Authorization code is not valid or has expired', 'error');
      this.props.history.replace('/');
      return;
    }

    this._logger.debug(`Callback received from authentication service`);

    //log the user in for this auth code
    this.login(q.authorization_code);
  }

  render() {
    return null;
  }
}

export default withRouter(AuthCallbackView);
