import React, { useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { dragCollect, dropCollect, groupTarget, source } from './dndSpecs';
import Checkbox from '../Checkbox';
import useItemParams from './useItemParams';
import useUpdateItemPreview from './useUpdateItemPreview';
import { useCurrentProject } from 'providers/ProjectProvider';

function HoverOverlay() {
  return (
    <div className="hover-overlay group">
      <p>Add to Group</p>
    </div>
  );
}

const GroupItemF = ({ item, pendingAdd, isOver, connectDragSource, connectDropTarget, sourceItem, onSelect, onCheck, selectedItem, connectDragPreview }) => {
  const sourceType = sourceItem?.type;

  const baseClassName = 'group';
  const scrollTargetRef = useRef();
  const { isEditing } = useCurrentProject();
  const { isSelected, isChecked, classNames } = useItemParams({ item, selectedItem, baseClassName, pendingAdd, isEditing });
  useUpdateItemPreview({ item, connectDragPreview, isChecked, isSelected, scrollTargetRef, selectedItem });

  const _onSelect = () => {
    onSelect(item);
  };

  const _onCheck = (e) => {
    e.stopPropagation();
    onCheck(item, e.shiftKey);
  };

  return connectDropTarget(
    connectDragSource(
      <li ref={scrollTargetRef} className={classNames} onClick={_onSelect}>
        {isEditing && <Checkbox checked={isChecked} onCheck={_onCheck} />}
        <span className="item-text">
          {item.name} ({item.locations.length}){pendingAdd ? <span className="loading" /> : null}
        </span>
        <span className="dot" style={{ backgroundColor: item.color }} />
        {isOver && sourceType && sourceType !== 'service' ? <HoverOverlay /> : null}
      </li>
    )
  );
};

let GroupItemFDrag = DragSource('projectItem', source('group'), dragCollect)(GroupItemF);
GroupItemFDrag = DropTarget('projectItem', groupTarget, dropCollect)(GroupItemFDrag);

export { GroupItemFDrag };
