export const setMapStyle = (style) => {
  localStorage.setItem('mapStyle', JSON.stringify(style));
};

export const getMapStyle = () => {
  return JSON.parse(localStorage.getItem('mapStyle')) || 'newMapStyle';
};

export const getMapStyleUrl = (style) => {
  const mapStyleUrls = {
    newMapStyle: 'https://api.maptiler.com/maps/2f6bd22a-c8ff-4666-8121-bc90ed9433b6/{z}/{x}/{y}.png?key=bCN4JNhprAB6XsA1sy6q',
    oldMapStyle: 'https://api.maptiler.com/maps/d0d9db2f-1d93-47d5-bc21-6037f5fd1d6a/{z}/{x}/{y}.png?key=bCN4JNhprAB6XsA1sy6q',
  };

  return mapStyleUrls[style] || '';
};
