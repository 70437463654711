import DataSourceMetadataId from './DataSourceMetadataId';
import Timestamp from './Timestamp';
import Assert from '../utils/Assert';

export default class DataSourceMetadata {
  constructor(id, name, source, lastUpdated) {
    Assert.instanceOf(id, DataSourceMetadataId);
    Assert.isString(name);
    Assert.isString(source);
    Assert.instanceOf(lastUpdated, Timestamp);

    this.id = id;
    this.name = name;
    this.source = source;
    this.lastUpdated = lastUpdated;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof DataSourceMetadata)) {
      return false;
    }
    return this.id.equals(other.id);
  }

  toString() {
    return `${this.constructor.name}{ id:${this.id}, name:'${this.name}', source:${this.source}, lastUpdated:'${this.lastUpdated}' }`;
  }

  toJSON() {
    const { id, name, source, lastUpdated } = this;
    return { id, name, source, lastUpdated };
  }

  static fromJSON(json) {
    if (!json) {
      return null;
    }

    const { id, name, source, lastUpdated } = json;

    return new DataSourceMetadata(new DataSourceMetadataId(id), name, source, Timestamp.fromJSON(lastUpdated));
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      items.push(DataSourceMetadata.fromJSON(json));
    }

    return items;
  }
}
