import axios from 'axios';
import Config from 'Config';
import { LoggerFactory } from 'logger';
import FileReference from 'model/FileReference';
import getAuthenticationService from 'service/AuthenticationService';

const uploadFile = async (file) => {
  const _logger = LoggerFactory.getLogger('AddMultipleLocationsView');
  _logger.info(`Uploading file: "${file.name}"`);

  const authenticationService = getAuthenticationService();
  const token = authenticationService.getToken();
  const url = `${Config.API_URL}file`;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${token.value}`,
    },
  };

  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post(url, formData, config);
    const fileReference = FileReference.fromJSON(response.data);

    _logger.debug(`File upload succeeded with status: ${response.status}, reference: ${fileReference}`);
    return fileReference;
  } catch (error) {
    _logger.error(`File upload failed: ${error.message}`);
    throw new Error(error.message);
  }
};

export default uploadFile;
