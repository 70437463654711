import React from 'react';
import { LoggerFactory } from '../logger';
import { toast } from '../utils/Toast';
import GqlMutationComponent from './GqlMutationComponent';

const withMutation = (spec) => {
  class MutationWrapper extends GqlMutationComponent {
    displayName = `MutationWrapper(${spec.name || ''})`;

    constructor(props) {
      super(props);
      this._logger = LoggerFactory.getLogger(this);
    }

    mutation() {
      return spec.mutation;
    }

    renderMutation(doMutation, loading) {
      const mutate = async (...args) => {
        const mutationArgs = spec.getMutationArgs.apply(this, args);
        this._logger.debug(`Running mutation: ${spec.name} with arguments: ${JSON.stringify(mutationArgs)}`);
        return doMutation(mutationArgs);
      };
      return this.props.children(mutate, loading, this.onCompleted, this.onError);
    }

    onCompleted(data) {
      this._logger.debug(`Mutation ${spec.name} completed successfully`);
      if (spec.onCompleted) {
        spec.onCompleted(data);
      }
      if (this.props.onCompleted) {
        this.props.onCompleted();
      }
    }

    onError(error) {
      this._logger.error(`An error occurred executing mutation: ${error.message}`);
      if (spec.onError) {
        spec.onError(error);
      } else {
        toast('An unexpected error occurred running operation', 'error');
      }
      if (this.props.onError) {
        this.props.onError(error);
      }
    }
  }
  return (Component) => {
    const Wrapped = (props) => (
      <MutationWrapper {...props}>
        {(mutate, loading) => {
          if (!spec.transformProps) {
            spec.transformProps = (p) => p;
          }
          const mutationProps = spec.transformProps({
            [spec.mutateProp || 'mutate']: mutate,
            [spec.loadingProp || 'loading']: loading || props[spec.loadingProp || 'loading'],
          });
          return <Component {...props} {...mutationProps} />;
        }}
      </MutationWrapper>
    );
    Wrapped.displayName = `withMutation(${Component.displayName || Component.name || 'Anonymous'}:${spec.name})`;
    return Wrapped;
  };
};

export default withMutation;
