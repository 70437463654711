import React, { useEffect, useState } from 'react';
import TabPanel from '../../TabPanel';
import TabPanelContent from '../../TabPanelContent';
import MetalsWrapper from './index';
import TabPanelProductsSide from '../../TabPanelProductsSide';
import { dismissAllToasts } from 'utils/Toast';
import { useMutation, useQuery } from 'react-apollo';
import { Mutations, Queries } from 'gql';

const MetalTabPanel = ({ projectId, phaseId, metro }) => {
  const { data, loading: metalPlansLoading, error: metalPlansError } = useQuery(Queries.GET_METAL_PLANS, { variables: { metroCode: metro?.code } });
  const [enableMetal, { loading: loadingEnableMetal }] = useMutation(Mutations.ENABLE_METAL);
  const [disableMetal, { loading: loadingDisableMetal }] = useMutation(Mutations.DISABLE_METAL);
  const [metalPlans, setMetalPlans] = useState([]);
  const title = 'Equinix Metal\u2122';
  const productName = 'product';

  useEffect(() => {
    if (!data) return;
    setMetalPlans(data.metalPlans);
  }, [data]);

  const metroCode = metro.code;
  const metalEnabled = metro.metalEnabled;
  const metalDeprecated = metro.metalPlans
    // eslint-disable-next-line
    .map((metal) => {
      if (metal.deprecated) {
        return {
          ...metal,
          name: `${metal.name} (No longer available)`,
          id: metal.id.value,
          checked: true,
        };
      }
    })
    .filter((m) => m);

  const metalsInMetro = metro.metalPlans;

  const metalsFormatted = metalPlans.map((metal) => {
    return {
      ...metal,
      checked: metalsInMetro.some(({ id }) => id.value === metal.id),
    };
  });

  const onEnableMetal = async () => {
    await enableMetal({ variables: { projectId, phaseId, metroCode } });
    dismissAllToasts();
  };
  const onDisableMetal = async () => {
    await disableMetal({ variables: { projectId, phaseId, metroCode } });
    dismissAllToasts();
  };

  const isLoading = metalPlansLoading || loadingEnableMetal || loadingDisableMetal;

  const metalAvailable = !!metalPlans.length;
  const productType = productName + (metalsInMetro.length === 1 ? '' : 's');
  const productsText = metalsInMetro.length ? `${metalsInMetro.length} ${productType} enabled` : '';

  const sideSection = (
    <TabPanelProductsSide
      productsText={productsText}
      productEnabled={metalEnabled}
      productAvailable={metalAvailable}
      onChecked={onEnableMetal}
      onUnchecked={onDisableMetal}
      loading={isLoading}
    />
  );

  return (
    <TabPanel title={title} sideSection={sideSection} productAvailable={metalAvailable} loading={isLoading} error={metalPlansError}>
      {metalAvailable && (
        <TabPanelContent>
          <MetalsWrapper metalPlans={metalsFormatted.concat(metalDeprecated)} projectId={projectId} phaseId={phaseId} metroCode={metroCode} />
        </TabPanelContent>
      )}
    </TabPanel>
  );
};

export default MetalTabPanel;
