import React from 'react';

const EquinixLogo = ({ width = 10, height = 10, color = 'black', ...rest }) => (
  // <svg
  //   width={width}
  //   height={height}
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   xmlnsXlink="http://www.w3.org/1999/xlink"
  //   {...rest}>
  //   <rect width={width} height={height} fill={"url(#pattern0)"} />
  //   <defs>
  //     <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
  //       <use xlinkHref="#image0" transform="translate(-0.00986201) scale(0.0216963 0.0384615)" />
  //     </pattern>
  //     <image
  //       id="image0"
  //       width="47"
  //       height="26"
  //       xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAAaCAYAAAAnkAWyAAADj0lEQVRYCe2YT2hcVRTGf+fNdNJgJm01aUuatnkzZqJYrQttQTHqphWVdJGiKUgUQXEhuHDhThARFy5FhG6iUphaa62rFipuhEak0C4UOu+lmUlbgzERU1uSNmbekZvMhJuZm3ZSk5RAH1zeuec75zuHc//MOyMswXOe7b4Qew30VYEQpK+eqe+2cnlyCegXpJAFkRqAy2y5b4LEUeBpkHlcClcF/SRD/sMaqG7LxFuM1wVS7SFt7wdsf9D4XUc2gDxTTlzRAuh1gwkkFXmuzB+Sej2H3/snzQ1l3f993zL5kC2tOfx3A1JnihAo3gcebKgMrOhnHeT99VxrVnTYge8W5MtxGkYD/KMBqe6Q++sq7RYzj7uMc7Q0CYn9IAcUnpKKLeHyARkz+o2MXsvRcMNtY7SyFug2Q4n+yeEfj6HZNIVTAsWF/aoRZ+WFtQfB+xyks7wlql2XRNMoSG+Ed2KAthcXyxjP4e80twRIsoP8G5UECmc86AMaFT6uxG9nruhu4GWQXoEmmyPk3kZl3SnQvhheNs3gFRu35bgg58zxUvR7G5iVdaKD/ONGDmh7C5wLVe12C02SG7+2MPxLiN+vyDe2ucd6KcIukF3T6A7gbRu35aXJxmZcQflu8itY7Hmh7lZ+XjlWcLKqKq/gBbQ9eonWelMj5y/sChav5lDmMyWkrrOeqZOTJJ4cYNvfq6jyiScUNk8SfwWitOI1rJrkI6J/Z5dJngfZaMZc8oKkzX6qeR2XwfA3SERol4taiIlHsX8N2gP8rOg9c8kDO8A7m8M/BzzsIlhOXYT3zhr8EUW+csXJMHgsQrZNI51CNJah8EUc9FvghdKnKoLsdDkvt07g2ZkWphRI0SHQn+y4HRS+tufxDPn952lKxkjui5ADwB65Y7eQjgBHhCibYajfTtQlz1yVDzB2FcYOAYdKfWm3Ij0gj7mcIrz3AlKfCgy5cFt3gdS6IlGPzhbFhkqyjoMeAy/bTv5Hgchh5FRV3fOt/P4XcNCMQfxNTi/EHKquIoy68TntI0UYAa9uXndeguPoDxMUNj0EU3MeixCqkrd9U+TNMs48QrwvYvoP0xoKdJXOSHMZd70F7Gb7InAYomwLwxPG3qcw7vKrVXfT5G2SdgZMX3rcDPMPwBWS+xRMj7v3JmdkVNEjMYrZNBdPC6jNecdlc0YC/DdD/JfKyYT4H4X4exRiZd1yvP8DMlT8XcFKpb4AAAAASUVORK5CYII="
  //     />
  //   </defs>
  // </svg>
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M12.1186 0.56665L9.74488 1.44119V14.1845L8.12073 13.6223V2.0034L3.24829 3.69001V11.8732L1.62415 11.311V4.25222L0 4.81442V12.4979L4.87244 14.1845V4.81442L6.49659 4.25222V14.7467L11.3066 16.4333V2.5656L12.1186 2.25326L12.9307 2.5656V16.4333L17.8031 14.7467V4.25222L19.4273 4.81442V14.1845L24.2997 12.4979V4.81442L22.6756 4.25222V11.311L21.0514 11.8732V3.69001L16.179 2.0034V13.6223L14.5549 14.1845V1.44119L12.1186 0.56665Z"
      fill={color}
    />
    <path
      d="M28.7975 11.9355C29.6096 11.9355 30.2342 11.2484 30.2342 10.4988C30.2342 9.68668 29.6096 9.06201 28.7975 9.06201C27.9854 9.06201 27.3607 9.74915 27.3607 10.5612C27.2983 11.3108 27.923 11.9355 28.7975 11.9355ZM28.7975 11.8106C28.0479 11.8106 27.4857 11.1859 27.4857 10.4988C27.4857 9.81162 28.0479 9.18695 28.7975 9.18695C29.5471 9.18695 30.1093 9.74915 30.1093 10.4988C30.0468 11.1859 29.4846 11.8106 28.7975 11.8106ZM28.1728 11.1859H28.5476V10.6862H28.7975L29.1098 11.1859H29.5471L29.1723 10.6237C29.3597 10.5612 29.4846 10.3738 29.4846 10.124C29.4846 9.81162 29.2348 9.62422 28.86 9.62422H28.1728V11.1859ZM28.5476 10.4363V9.99902H28.86C29.0474 9.99902 29.1098 10.0615 29.1098 10.1864C29.1098 10.3114 29.0474 10.3738 28.86 10.3738H28.5476V10.4363Z"
      fill={color}
    />
  </svg>
);

export default EquinixLogo;
