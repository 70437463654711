import React from 'react';
import { usePhaseItems } from 'providers/PhaseItemsProvider';
import { ServiceItemFDrag } from '../ServiceItem';
import ServiceConnection from 'model/ServiceConnection';
import { useCurrentProject } from 'providers/ProjectProvider';
import { useDeleteService } from 'views/components/InfoPanel/children/hocs/withDeleteService';
import { useToggleVisibilityService } from 'views/components/InfoPanel/children/hocs/withToggleVisibilityService';

const ServiceItemList = ({ services, metroId, selectedItem, onCheck, onSelect, onClose, isEditing }) => {
  const { pendingProps, defaultProps, pendingRemoval, isContextMenuOpen, onContextMenu } = usePhaseItems();
  const { projectId, selectedPhaseId: phaseId } = useCurrentProject();
  const { deleteService } = useDeleteService();
  const { toggleVisibility } = useToggleVisibilityService();

  const onDeleteService = async (e, serviceConnection) => {
    e.syntheticEvent.stopPropagation();
    await deleteService(projectId, phaseId, serviceConnection);
    if (selectedItem?.id === serviceConnection?.id) onClose();
  };

  const onToggleServiceVisibility = async (e, serviceConnection) => {
    e.syntheticEvent.stopPropagation();
    await toggleVisibility(projectId, phaseId, serviceConnection);
  };

  const serviceProps = services
    .filter((item) => !pendingRemoval.find((i) => item.equals(i)))
    .map(defaultProps)
    .concat(pendingProps(ServiceConnection, metroId))
    .sort((a, b) => a.item.compare(b.item));

  return serviceProps.map((props, index) => (
    <ServiceItemFDrag
      {...props}
      isEditing={isEditing}
      onCheck={onCheck}
      onSelect={onSelect}
      selectedItem={selectedItem}
      onContextMenu={(e) => onContextMenu(e, 'service', props.item.id.value)}
      onToggleServiceVisibility={(e) => onToggleServiceVisibility(e, props.item)}
      onDeleteService={(e) => onDeleteService(e, props.item)}
      isContextMenuOpen={isContextMenuOpen.service === props.item.id.value}
      key={`service-item-${metroId}-${index}`}
    />
  ));
};

export default ServiceItemList;
