import { Mutations } from 'gql';
import withMutation from 'gql/withMutation';

export default withMutation({
  name: 'UPDATE_SERVICE',
  mutation: Mutations.UPDATE_SERVICE,
  mutateProp: 'hideService',
  getMutationArgs(projectId, phaseId, serviceConnectionId) {
    return {
      variables: {
        projectId,
        phaseId,
        serviceId: serviceConnectionId.serviceId,
        metroId: serviceConnectionId.metroId,
        visible: false,
      },
    };
  },
});
