import React from 'react';

const HamburgerMenuIcon = ({ width = '20', height = '20', color = 'black', ...rest }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M4 10H16" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 6H16" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 14H16" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default HamburgerMenuIcon;
