import React, { useState } from 'react';
import cn from 'classnames';
import Dropzone from 'react-dropzone';

const ExcelDropzone = ({ onDropAccepted, onDropRejected, fileName, loading }) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const acceptedFiles = ['.xls', '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
  const maxFileSize = 1000 * 1024;
  const maxFiles = 1;

  const onDragOverDropzone = () => {
    setIsDraggingOver(true);
  };

  const onDragLeaveDropzone = () => {
    setIsDraggingOver(false);
  };

  return (
    <Dropzone
      accept={acceptedFiles}
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      multiple={false}
      maxFiles={maxFiles}
      maxSize={maxFileSize}
      disabled={loading}>
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={cn('dropzone-wrapper', { dragging: isDraggingOver })}
          onDragOver={onDragOverDropzone}
          onDragLeave={onDragLeaveDropzone}>
          {loading ? (
            <>
              <div className={'loader'}></div>
              <p className={'loading-file-title'}>Processing {fileName}...</p>
            </>
          ) : (
            <>
              <input {...getInputProps()} />
              <p className={'dropzone-title'}>
                Drag and drop or <span>select a file</span>
              </p>
              <p className={'dropzone-info'}>Files must be smaller than 1mb.</p>
            </>
          )}
        </div>
      )}
    </Dropzone>
  );
};

export default ExcelDropzone;
