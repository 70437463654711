import Button from 'views/components/Button';
import React from 'react';
import CancelButton from 'views/components/CancelButton';

const RenderButtons = ({ onClick, onClose, valid }) => {
  return (
    <div className="modal-submit">
      <CancelButton onClick={onClose} />
      <Button text={'Create'} onClick={onClick} isDisabled={!valid} color={'red'} data-testid={'create-button'} />
    </div>
  );
};

export default RenderButtons;
