import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
fragment UserField on User {
        id
        email
        companyId
        role
        lastLoginDate
}
`;

export const LOCATION_FRAGMENT = gql`
    fragment LocationFields on Location {
        id
        name
        type
        employees
        notes
        address {
            label
            city
            state
            postalCode
            country
        }
        coordinates {
            latitude
            longitude
        }
        metro {
            id
            name
        }
    }
`;

export const LOCATION_GROUP_FRAGMENT = gql`
    fragment LocationGroupFields on LocationGroup {
        id
        name
        color
        locations {
            id
            name
            type
            employees
            address {
                label
                city
                state
                postalCode
                country
            }
            coordinates {
                latitude
                longitude
            }
        }
        metro {
            id
            name
        }
    }
`;

export const LOCATION_GROUP_FOR_EDIT_GROUP_VIEW_FRAGMENT = gql`
    fragment LocationGroupForEditGroupViewFields on LocationGroup {
        id
        locations {
            id
            address {
                label
                city
                state
                postalCode
                country
            }
        }
    }
`;

export const LOCATION_GROUP_PARTIAL_DATA_FRAGMENT = gql`
    fragment LocationGroupPartialDataFields on LocationGroup {
        id
        name
        color
        locations {
            id
            coordinates {
                latitude
                longitude
            }
        }
        metro {
            id
            name
        }
    }
`;

export const SERVICE_FRAGMENT = gql`
    fragment ServiceFields on Service {
        id
        name
        logo
        deprecated
        regions {
            id
            name
            code
            city {
                name
                region
            }
            coordinates {
                latitude
                longitude
            }
            deprecated
        }
    }
`;

export const METAL_PLAN_FRAGMENT = gql`
    fragment MetalPlanFields on MetalPlan {
        id
        name
        metros {
            id
            name
            code
        }
        deprecated
    }
`;

export const NETWORK_EDGE_CATEGORY_FRAGMENT = gql`
    fragment NetworkEdgeCategoryFields on NetworkEdgeCategory {
        id
        name
        deprecated
        metroCodes
        devices {
            id
            name
            deprecated
            metroCodes 
        }
    }
`;

export const METRO_LABEL_FRAGMENT = gql`
    fragment MetroLabelFields on Label {
        id
        value
        color
        offset {
            x
            y
        }
    }
`;

export const SERVICE_CONNECTION_FRAGMENT = gql`
    fragment ServiceConnectionFields on ServiceConnection {
        id
        local
        visible
        notes
        service {
            ...ServiceFields
        }
        regions {
            id
            name
            code
            city {
                name
                region
            }
            coordinates {
                latitude
                longitude
            }
            deprecated
        }
        metro {
            id
        }
    }
    ${SERVICE_FRAGMENT}
`;

export const PROJECT_LIST_FRAGMENT = gql`
    fragment ProjectListFields on Project {
        id
        name
        customer {
            id
            name
            segment {
                id
                name
            }
            subSegment {
                id
                name
            }
        }
        owner {
            ...UserField
        }
        sharedUsers {
            ...UserField
        }
        createdOn
        lastUpdated
    }
    ${USER_FRAGMENT}
`;

export const SHARED_PROJECT_LIST_FRAGMENT = gql`
    fragment SharedProjectListFields on Project {
        id
        name
        customer {
            id
            name
        }
        owner {
            ...UserField
        }
        sharedUsers {
            ...UserField
        }
        createdOn
        lastUpdated
    }
    ${USER_FRAGMENT}
`;

export const PHASE_METADATA_FRAGMENT = gql`
    fragment PhaseMetadata on ProjectPhase {
        id
        name
#        order
    }
`;

export const PROJECT_FRAGMENT = gql`
    fragment ProjectFields on Project {
        id
        name
        owner {
            ...UserField
        }
        sharedUsers {
            ...UserField
        }
        customer {
            id
            name
        }
        phases {
            ...PhaseMetadata
        }
    }
    ${USER_FRAGMENT}
    ${PHASE_METADATA_FRAGMENT}
`;

export const PHASE_FRAGMENT = gql`
    fragment PhaseFields on ProjectPhase {
        id
        name
#        order
        locations {
            ...LocationFields
        }
        groups: locationGroups {
            ...LocationGroupPartialDataFields
        }
        services {
            ...ServiceConnectionFields
        }
        metros {
            id
            name
            region
            code
            status
            notes
            serviceOffset {
                x
                y
            }
            existing
            coordinates {
                latitude
                longitude
            }
            locations {
                ...LocationFields
            }
            groups: locationGroups {
                ...LocationGroupPartialDataFields
            }
            services {
                id
                local
                visible
                notes
                service {
                    id
                    name 
                    logo
                    deprecated
                    regions {
                        id
                        name
                        code
                        city {
                            name
                            region
                        }
                        coordinates {
                            latitude
                            longitude
                        }
                        connectedMetros {
                            latency
                            metro {
                                id
                            }
                        }
                        deprecated
                    }
                }
                regions {
                    id
                    name
                    code
                    city {
                        name
                        region
                    }
                    coordinates {
                        latitude
                        longitude
                    }
                    deprecated
                }
                metro {
                    id
                }
            }
            labels {
                ...MetroLabelFields
            }
            metalEnabled
            metalPlans {
                ...MetalPlanFields
            }
            networkEdgeEnabled
            networkEdgeCategories {
                ...NetworkEdgeCategoryFields
            }
            infraComponents {
                id
                name
                deployment
                device
                isEnabled
                isVisible
                isCustom
                deviceFilter
                allowedDeployments
            }
            deploymentOffsets {
                deployment
                offset {
                    x
                    y
                }
            }
        }
        directConnections {
            source {
                id
                coordinates {
                    latitude
                    longitude
                }
            }
            connectedTo {
                metro {
                    id
                    coordinates {
                        latitude
                        longitude
                    }
                }
                label
                color
            }
        }
        accountStrategyPlans {
            id
            company
            segment { id, name }
            subSegment { id, name }
            itSpends { region, percentage }
            adoptionProfiles
            adoptionAttributes
            employees
            lastUpdated
            updateAvailable
            updateAcknowledged
        }
    }
    ${LOCATION_FRAGMENT}
    ${LOCATION_GROUP_PARTIAL_DATA_FRAGMENT}
    ${SERVICE_CONNECTION_FRAGMENT}
    ${METRO_LABEL_FRAGMENT}
    ${METAL_PLAN_FRAGMENT}
    ${NETWORK_EDGE_CATEGORY_FRAGMENT}
`;

export const IMPORTED_PHASE_FRAGMENT = gql`
    fragment ImportedPhaseFields on ImportedPhaseResult {
        phase {
            ...PhaseFields
        }
        importation {
            issues {
                rowNumber
                description
            }
            locationsInserted
            locationsRejected
        }
    }
    ${PHASE_FRAGMENT}
`;

export const METRO_FRAGMENT = gql`
    fragment MetroFields on Metro {
        id
        name
        region
        code
        status
        coordinates {
            latitude
            longitude
        }
        connectedMetros: connectedTo {
            latency
            metro {
                id
                name
                region
                coordinates {
                    latitude
                    longitude
                }
            }
        }
        marketplace {
            buyers {
                id
                name
                count
                subSegments {
                    id
                    name
                    count
                }
            }
        }
    }
`;

export const TEMPLATE_FRAGMENT = gql`
    fragment TemplateFields on Template {
        id
        name
        descriptions {
            phaseId
            description
        }
        project {
            id
            name
            phases {
                id
                name
            }
            owner {
                ...UserField
            }
        }
        description
        published
        categories
        timesSaved
        timesUsed
        createdOn
        lastUpdated
        timesSaved
        isSaved
        updateAvailable
        thumbnail
        owner {
            ...UserField
        }
        deleted
    }
    ${USER_FRAGMENT}
`;

export const TEMPLATE_PROJECT_FRAGMENT = gql`
    fragment TemplateProjectFields on Template {
        id
        name
        descriptions {
            phaseId
            description
        }
        owner {
            ...UserField
        }
        published
        categories
        timesSaved
        isSaved
        updateAvailable
        project {
            ...ProjectFields
        }
    },
    ${USER_FRAGMENT}
    ${PROJECT_FRAGMENT}
`;

export const PROJECT_PRESENCE_FRAGMENT = gql`
    fragment ProjectPresenceFields on ProjectPresence {
        projectId
        userId
        email
        status
    }
`;

export const MARKETPLACE_SEGMENTS_FRAGMENT = gql`
    fragment MarketplaceSegmentsFields on MarketplaceSegmentsList {
        buyers {
            id
            name
            count
            average
            subSegments {
                id
                name
                count
                average
            }
        }
    }
`;

export const COMPANY_FRAGMENT = gql`
    fragment CompanyField on Company {
        id
        name
        isDSPAllowed
        removalDate
        domains{
            domain
            isEnabled
        }
        members{
          ...UserField
        }
    }
    ${USER_FRAGMENT}
`;
