import React from 'react';
import cn from 'classnames';
import Modal from 'react-modal';
import defaultStyles from 'views/ProjectDetailView/modals/defaultStyles';
import { useMutation } from 'react-apollo';
import { Mutations, Queries } from 'gql';
import { toast } from 'utils/Toast';
import RenderProgress from './common/RenderProgress';
import { Link, useHistory } from 'react-router-dom';
import { TemplateEvents } from 'analytics';
import Button from 'views/components/Button';
import CancelButton from 'views/components/CancelButton';

const onUpdateSuccess = () => {
  toast('Template successfully updated.', 'info');
};

const TemplateUpdateAvailableModal = ({ isUpdateModalOpen, closeUpdateModal, templateId, project, onSuccess = onUpdateSuccess, lastTemplateVersion }) => {
  const history = useHistory();
  const [upgradeTemplate, { loading }] = useMutation(Mutations.UPGRADE_TEMPLATE);

  const onUpgradeTemplate = async () => {
    try {
      await upgradeTemplate({
        variables: { templateId },
        refetchQueries: [
          { query: Queries.GET_TEMPLATES, variables: { scope: 'SAVED' } },
          { query: Queries.GET_TEMPLATES, variables: { scope: 'PUBLISHED' } },
        ],
      });
      TemplateEvents.templateUpdated(templateId);
      closeUpdateModal();
      onSuccess();
    } catch (error) {
      closeUpdateModal();
      return toast('An unexpected error occurred upgrading the template.', 'error');
    }
  };

  const onPreview = () => {
    history.push({
      pathname: `/templates/${templateId}`,
      state: { project },
    });
    closeUpdateModal();
  };

  return (
    <Modal isOpen={isUpdateModalOpen} className={'modal-window confirmation-modal'} onRequestClose={closeUpdateModal} defaultStyles={defaultStyles}>
      <div data-testid={'update-template-modal'}>
        <div className="modal-header">
          <h3>Update this saved template?</h3>
          <button className="close-modal" onClick={closeUpdateModal}>
            <svg height="12" width="12">
              <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
            </svg>
          </button>
        </div>
        <p className={'trailing-text'}>
          The creator of this template has made changes since you saved it. Updating will <b>replace your copy</b> of the template with their latest changes.
        </p>
        <button className={cn('blue-button', { disabled: loading, hide: lastTemplateVersion })}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            !lastTemplateVersion && (
              <Link className="button" to={{ pathname: `/templates/${templateId}`, state: { lastTemplateVersion: true } }} onClick={onPreview}>
                Preview the latest version -&gt;
              </Link>
            )
          )}
        </button>
        {loading ? (
          <RenderProgress />
        ) : (
          <div className="modal-submit">
            <CancelButton onClick={closeUpdateModal} />
            <Button text={'Update Now'} onClick={onUpgradeTemplate} color={'red'} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TemplateUpdateAvailableModal;
