import React from 'react';

const DocumentIcon = ({ width = 10, height = 10, color, ...rest }) => {
  return (
    <svg width={width} height={height} fill={color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M6.875 4.59375V0.875H1.40625C1.22396 0.875 1.06901 0.938802 0.941406 1.06641C0.813802 1.19401 0.75 1.34896 0.75 1.53125V14.2188C0.75 14.401 0.813802 14.556 0.941406 14.6836C1.06901 14.8112 1.22396 14.875 1.40625 14.875H10.5938C10.776 14.875 10.931 14.8112 11.0586 14.6836C11.1862 14.556 11.25 14.401 11.25 14.2188V5.25H7.53125C7.34896 5.25 7.19401 5.1862 7.06641 5.05859C6.9388 4.93099 6.875 4.77604 6.875 4.59375ZM8.625 11.0469C8.625 11.138 8.58854 11.2201 8.51562 11.293C8.46094 11.3477 8.38802 11.375 8.29688 11.375H3.70312C3.61198 11.375 3.52995 11.3477 3.45703 11.293C3.40234 11.2201 3.375 11.138 3.375 11.0469V10.8281C3.375 10.737 3.40234 10.6641 3.45703 10.6094C3.52995 10.5365 3.61198 10.5 3.70312 10.5H8.29688C8.38802 10.5 8.46094 10.5365 8.51562 10.6094C8.58854 10.6641 8.625 10.737 8.625 10.8281V11.0469ZM8.625 9.29688C8.625 9.38802 8.58854 9.47005 8.51562 9.54297C8.46094 9.59766 8.38802 9.625 8.29688 9.625H3.70312C3.61198 9.625 3.52995 9.59766 3.45703 9.54297C3.40234 9.47005 3.375 9.38802 3.375 9.29688V9.07812C3.375 8.98698 3.40234 8.91406 3.45703 8.85938C3.52995 8.78646 3.61198 8.75 3.70312 8.75H8.29688C8.38802 8.75 8.46094 8.78646 8.51562 8.85938C8.58854 8.91406 8.625 8.98698 8.625 9.07812V9.29688ZM8.625 7.32812V7.54688C8.625 7.63802 8.58854 7.72005 8.51562 7.79297C8.46094 7.84766 8.38802 7.875 8.29688 7.875H3.70312C3.61198 7.875 3.52995 7.84766 3.45703 7.79297C3.40234 7.72005 3.375 7.63802 3.375 7.54688V7.32812C3.375 7.23698 3.40234 7.16406 3.45703 7.10938C3.52995 7.03646 3.61198 7 3.70312 7H8.29688C8.38802 7 8.46094 7.03646 8.51562 7.10938C8.58854 7.16406 8.625 7.23698 8.625 7.32812ZM11.25 4.21094V4.375H7.75V0.875H7.91406C8.00521 0.875 8.08724 0.893229 8.16016 0.929688C8.2513 0.966146 8.32422 1.01172 8.37891 1.06641L11.0586 3.74609C11.1133 3.80078 11.1589 3.8737 11.1953 3.96484C11.2318 4.03776 11.25 4.11979 11.25 4.21094Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};

export default DocumentIcon;
