import React from 'react';
import UserInfo from './UserInfo';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { LoggerFactory } from 'logger';
import { ReactComponent as EquinixLogo } from 'static/img/wb-only-logo.svg';
import { ReactComponent as EquinixTextLogo } from 'static/img/wb-text-logo.svg';
import { Actions } from 'stores/UserStore';
import Config from 'Config';
import './Header.scss';
import cn from 'classnames';
import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';
import { TEMPLATE_LIST_VIEW_SELECTION, PROJECT_LIST_VIEW_SELECTION } from 'views/ProjectListView/DefaultListViewSelection';
import { useListView } from 'providers/ListViewProvider';

const Header = ({ user, project, history, isTemplate, template }) => {
  const { setListView } = useListView();
  const logger = LoggerFactory.getLogger('Header');
  const [execSignOut, { error }] = useMutation(Mutations.SIGN_OUT);
  error && logger.error(`Error in Signout mutation: ${error}`);

  const location = useLocation();
  const isLogin = location.pathname === '/';

  const onLogoutClicked = async () => {
    logger.debug(`Logging out user: ${user}`);

    await execSignOut();

    Actions.logout();

    window.$sleek.shutdown();

    logger.debug('Redirecting to login view');

    history.replace('/');
  };

  const setView = () => {
    const linkState = isTemplate ? TEMPLATE_LIST_VIEW_SELECTION : PROJECT_LIST_VIEW_SELECTION;
    setListView(linkState);
  };

  return (
    <header className={cn('map-header', { 'project-detail': !!project, 'login-page': isLogin })}>
      <div className={'top-header'}>
        <div className={'top-header-start'}>
          <a href="/" className={'logo'} data-testid={'logo'}>
            {isLogin ? <EquinixTextLogo /> : <EquinixLogo />}
          </a>
          {project && (
            <div className={'project-meta-data'}>
              <Link to={{ pathname: '/projects' }} onClick={setView}>
                {isTemplate ? 'All templates' : 'All projects'}
              </Link>
              <p className={'slash-separator'}>/</p>
              <p className={'project-and-customer-name'} data-testid={'project-name'}>
                {template ? template.name : `${project.customer?.name} • ${project.name}`}
              </p>
            </div>
          )}
        </div>
        {user && <UserInfo user={user} version={Config.VERSION} project={project} isTemplate={isTemplate} onLogoutClicked={onLogoutClicked} />}
      </div>
    </header>
  );
};

export default withRouter(Header);
