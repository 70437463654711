export const CATEGORY = Object.freeze({
  PROJECT_NAME: 'PROJECT_NAME',
  CUSTOMER_NAME: 'CUSTOMER_NAME',
  LAST_UPDATED: 'LAST_UPDATED',
});

export const DIRECTION = Object.freeze({
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
});

export const SORTING_MODE = Object.freeze({
  PROJECT_NAME_ASCENDING: 'PROJECT_NAME_ASCENDING',
  PROJECT_NAME_DESCENDING: 'PROJECT_NAME_DESCENDING',
  CUSTOMER_NAME_ASCENDING: 'CUSTOMER_NAME_ASCENDING',
  CUSTOMER_NAME_DESCENDING: 'CUSTOMER_NAME_DESCENDING',
  LAST_UPDATED_ASCENDING: 'LAST_UPDATED_ASCENDING',
  LAST_UPDATED_DESCENDING: 'LAST_UPDATED_DESCENDING',
});

export const CATEGORY_OPTIONS = Object.freeze({
  [CATEGORY.CUSTOMER_NAME]: {
    label: 'Customer Name',
    value: CATEGORY.CUSTOMER_NAME,
  },
  [CATEGORY.PROJECT_NAME]: {
    label: 'Project Name',
    value: CATEGORY.PROJECT_NAME,
  },
  [CATEGORY.LAST_UPDATED]: {
    label: 'Last Modified',
    value: CATEGORY.LAST_UPDATED,
  },
});

export const FILTER = Object.freeze({
  ALL_PROJECTS: 'ALL_PROJECTS',
  I_SHARED: 'I_SHARED',
  SHARED_WITH_ME: 'SHARED_WITH_ME',
});

export const FILTER_OPTIONS = Object.freeze({
  [FILTER.ALL_PROJECTS]: {
    label: 'All Projects',
    value: FILTER.ALL_PROJECTS,
  },
  [FILTER.I_SHARED]: {
    label: "Projects I've shared",
    value: FILTER.I_SHARED,
  },
  [FILTER.SHARED_WITH_ME]: {
    label: 'Projects Shared with me',
    value: FILTER.SHARED_WITH_ME,
  },
});

export const buildSorting = (category, sorting) => SORTING_MODE[[category, sorting].join('_')];
