import React from 'react';
import Modal from 'react-modal';
import defaultStyles from './defaultStyles';
import Button from 'views/components/Button';
import { getUserRoleLabel } from '../../../model/userRoles';

const DeleteUserModal = ({ isOpen, onClose, member, onDelete, loading }) => {
  const _onDelete = () => {
    onDelete(member.id);
    onClose();
  };

  const renderProgress = () => {
    return (
      <div className="modal-submit">
        <div className="loading"></div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="modal-submit">
        <Button text="Cancel" color="red" onClick={onClose} isInverse={true} />
        <Button text="Yes, deactivate" color="red" onClick={_onDelete} />
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} className="modal-window" onRequestClose={onClose} contentLabel="Delete User" defaultStyles={defaultStyles}>
      <div>
        <div className="modal-header">
          <h3>
            Deactivate {getUserRoleLabel(member.role)} {member.email}?
          </h3>
          <button className="close-modal" onClick={onClose}>
            <svg height="12" width="12">
              <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
            </svg>
          </button>
        </div>
        <div className={'modal-fields modal-fields--full modal-text'}>
          <p className="trailing-text">
            This user will be deactivated. They will be unable to login or access any projects or project templates. The user can be restored by re-inviting
            them to the account.
          </p>
        </div>
        {loading ? renderProgress() : renderButtons()}
      </div>
    </Modal>
  );
};

export default DeleteUserModal;
