import React from 'react';

const CloseIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g opacity="0.5">
        <path d="M11 4.09961L4 11.0996" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4 4.09961L11 11.0996" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};

export default CloseIcon;
