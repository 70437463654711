import ReactDOM from 'react-dom';
import { Control } from 'leaflet';
import { MapControl, withLeaflet } from 'react-leaflet';

class Button extends Control {
  onAdd() {
    const button = document.createElement('button');
    button.ondblclick = (e) => e.stopPropagation();
    this.button = button;
    this.addClassName('map-button');
    const { text, onClick, className } = this.options;
    if (text) this.setText(text);
    if (onClick) this.setOnClick(onClick);
    if (className) this.addClassName(className);
    return button;
  }
  setText(text) {
    if (this.button) this.button.innerText = text;
  }
  setOnClick(onClick) {
    if (this.button) this.button.onclick = onClick;
  }
  addClassName(className) {
    if (this.button) this.button.classList.add(...className.split(' '));
  }
  removeClassName(className) {
    if (this.button) this.button.classList.remove(...className.split(' '));
  }
}

class MapButton extends MapControl {
  createLeafletElement() {
    const b = new Button({
      position: this.props.position,
      text: this.props.text,
      onClick: this.props.onClick,
      className: this.props.className,
    });
    return b;
  }
  updateLeafletElement(fromProps, toProps) {
    if (fromProps.text !== toProps.text) this.leafletElement.setText(toProps.text);
    if (fromProps.onClick !== toProps.onClick) this.leafletElement.setOnClick(toProps.onClick);
    if (fromProps.className !== toProps.className) {
      this.leafletElement.removeClassName(fromProps.className);
      this.leafletElement.addClassName(toProps.className);
    }
  }
  render() {
    if (this.props.children) {
      return ReactDOM.createPortal(this.props.children, document.querySelector('section.map'));
    } else {
      return null;
    }
  }
}

export default withLeaflet(MapButton);
