import { usePhaseItems } from 'providers/PhaseItemsProvider';
import { useCurrentProject } from 'providers/ProjectProvider';
import { useDeleteProjectItems } from '../hocs/withDeleteProjectItems';

const useOnDelete = ({ selectedItem, onClose }) => {
  const { pendingMoves, setPendingMoves, checkedItems, setCheckedItems, clearBulkActionUI } = usePhaseItems();
  const { projectId, selectedPhaseId: phaseId } = useCurrentProject();
  const { deleteProjectItems } = useDeleteProjectItems();

  return async () => {
    const newPendingMoves = pendingMoves.concat(checkedItems.map((item) => ({ item, metroId: item.metroId })));
    setPendingMoves((prev) => [...prev, ...newPendingMoves]);
    const itemIds = checkedItems.map((item) => item.id);
    if (checkedItems.find((item) => item.equals(selectedItem))) {
      onClose();
    }
    await deleteProjectItems(projectId, phaseId, itemIds);
    const pendingMovesFiltered = pendingMoves.filter((m) => !checkedItems.find((i) => i.equals(m.item)));
    setPendingMoves(pendingMovesFiltered);
    setCheckedItems([]);
    clearBulkActionUI();
  };
};

export default useOnDelete;
