import React, { useEffect } from 'react';
import Modal from 'react-modal';
import ConfirmationModal from 'views/ProjectListView/modals/ConfirmationModal';
import { Mutations } from 'gql';
import { useMutation } from '@apollo/react-hooks';
import { dismissAllToasts, toast } from 'utils/Toast';
import defaultStyles from './defaultStyles';

const DeletePhaseModal = ({ isOpen, onClose, onChangePhase, phaseModalData: { projectId, phaseId, isSelected, phaseIndex } }) => {
  const [execDeletePhase, { data, loading, error }] = useMutation(Mutations.DELETE_PROJECT_PHASE);

  const doDeletePhase = async () => {
    try {
      await execDeletePhase({ variables: { projectId, phaseId } });
      dismissAllToasts();
    } catch (error) {
      return toast('An unexpected error occurred deleting the phase', 'error');
    }
  };

  useEffect(() => {
    if (data && !error) {
      if (isSelected) {
        const phasesFromResponse = data.deleteProjectPhase.phases;
        const hasPreviousPhase = phaseIndex > 0;
        onChangePhase(hasPreviousPhase ? phasesFromResponse[phaseIndex - 1]?.id : phasesFromResponse[phaseIndex]?.id);
      }
      onClose();
    }
    // eslint-disable-next-line
  }, [data, error]);

  return (
    <Modal isOpen={isOpen} className={'modal-window confirmation-modal'} onRequestClose={onClose} defaultStyles={defaultStyles}>
      <ConfirmationModal
        trailingText={'Are you sure you would like to delete this phase?'}
        onClose={onClose}
        onConfirm={doDeletePhase}
        loading={loading}
        title={'Delete this phase'}
        buttonText={'Delete'}
      />
    </Modal>
  );
};

export default DeletePhaseModal;
