import LOGGER from './logger';
import { getGtagContext } from './GtagContext';
import events from './events/product';

class ProductEvents {}

for (let event of events) {
  ProductEvents[event.fn] = function () {
    const args = {};
    for (let i = 0; i < event.params.length; i++) {
      const param = event.params[i];
      args[param] = arguments[i];
    }
    LOGGER.debug(`Tracking ${event.name}: ${JSON.stringify(args)}`);

    const gtag = getGtagContext();
    gtag.event(event.trigger, args);
  };
}

export default ProductEvents;
