import { useListView } from 'providers/ListViewProvider';
import React from 'react';
import DocumentTitle from 'react-document-title';
import Page from 'utils/Page';
import ProjectsView from './ProjectsView/ProjectsView';
import './ProjectsView/ProjectListView.scss';
import ResourcesView from './ResourcesView/ResourcesView';
import SideNav from './SideNav';
import TemplatesView from './TemplatesView/TemplatesView';
import CompaniesView from './CompaniesView/Index';
import TeamView from './TeamView/Index';

const ProjectListView = ({ user }) => {
  const { listView, setListView } = useListView();
  const onSelect = (select) => {
    setListView(select);
  };

  const view = {
    Projects: <ProjectsView user={user} />,
    Templates: <TemplatesView type={listView.selected} user={user} onSelect={onSelect} />,
    Companies: <CompaniesView user={user} />,
    Team: <TeamView companyId={user.companyId} user={user} />,
    Resources: <ResourcesView selected={listView.selected} />,
  };

  return (
    <DocumentTitle title={Page.title(listView.category)}>
      <div className="content-page list-view">
        <SideNav selected={listView.selected} onSelect={onSelect} user={user} />
        <section className="list">
          <div className="list-margins">{view[listView.category]}</div>
        </section>
      </div>
    </DocumentTitle>
  );
};

export default ProjectListView;

//TODO: maybe? add continuous polling? don't think this will be necessary, but leaving here commented just in case
/*export default graphql(Queries.GET_PROJECTS, {
    options: { pollInterval: 5000 },
})(withRouter(ProjectListView)); */
