import React from 'react';
import classnames from 'classnames';
import './Checkbox.scss';

const Checkbox = ({ checked, onCheck, disabled }) => {
  return (
    <span data-testid={'checkbox'} className={'checkbox-container'} onClick={onCheck} role={'checkbox'} aria-checked={checked}>
      <span className={classnames('checkbox', { checked, disabled })}></span>
    </span>
  );
};

export default Checkbox;
