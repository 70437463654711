import React from 'react';
import Table from '../Table';
import MetroToggle from '../MetroToggle';
import useKeyUp from 'hooks/useKeyUp';

import './MetrosModal.scss';

const MetrosModal = ({ projectId, phase, metros, onClose }) => {
  useKeyUp('Escape', onClose);
  const regions = ['AMER', 'APAC', 'EMEA'];

  return (
    <div className="manage-metros" onClick={onClose} data-testid={'manage-metros-modal'}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <button className="close" onClick={onClose}>
          x
        </button>
        <h2>Manage Metros</h2>
        <div className="regions">
          {regions.map((region) => {
            return (
              <div className="region" key={region}>
                <h3>{region}</h3>

                <Table>
                  {metros
                    .filter((metro) => metro.region === region)
                    .map((metro) => {
                      const active = !!phase.metros.find((m) => metro.id.value === m.id.value);
                      return (
                        <tr key={metro.id}>
                          <td>{metro.name}</td>
                          <td>
                            <MetroToggle projectId={projectId} phaseId={phase.id} active={active} metro={metro} />
                          </td>
                        </tr>
                      );
                    })}
                </Table>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MetrosModal;
