import React from 'react';
import CloudIcon from 'views/components/icons/Cloud';
import TabPanel from '../../TabPanel';
import TabPanelContent from '../../TabPanelContent';
import Services from './index';

const ServicesTabPanel = ({ projectId, phaseId, metro }) => {
  const title = 'Services';
  const icon = <CloudIcon width={21} height={12} color="black" />;
  const productName = 'service';

  const servicesInMetro = metro.services;
  const serviceEnabled = true; // always true by definition
  const servicesAvailable = true; // is fetched in children <Services/>
  const productType = productName + (servicesInMetro.length === 1 ? '' : 's');
  const productsText = servicesInMetro.length ? `${servicesInMetro.length} ${productType} enabled` : '';

  return (
    <TabPanel icon={icon} title={title} productAvailable={true} productsText={productsText} productEnabled={serviceEnabled} toggleButton={false}>
      {servicesAvailable && (
        <TabPanelContent>
          <Services
            projectId={projectId}
            phaseId={phaseId}
            metro={metro}
            // services={services} // alway comes empty?
          />
        </TabPanelContent>
      )}
    </TabPanel>
  );
};

export default ServicesTabPanel;
