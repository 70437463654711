import React from 'react';
import classNames from 'classnames';
import Expandable from './Expandable';

export function Applications(props) {
  const { active, on } = props;
  return (
    <Expandable>
      {(open, toggle) => (
        <div className={classNames('expandable use-case', { active, open, closed: !open })}>
          <div className="expandable-header use-case-header">
            <button className="expand-content" onClick={toggle}>
              <h3>Applications</h3>
            </button>
            <div className={classNames('status', { on, active })}>
              {on ? 'Solved' : ''}
              {active ? '' : 'Coming Soon...'}
            </div>
          </div>
          <div className="expandable-content use-case-content">
            <p>
              To architect for the digital edge you need to localize application services in the edge node as a multicloud, multi-party business integration
              point. Learn more about enabling applications for the digital edge with technical blueprints and design patterns.
            </p>
            <ul className="design-patterns">
              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Implement API Management</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-api-management"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>Create a managed clearing house for APIs that you are producing, publishing and also consuming—placed in the intersection point.</p>
                      <h5>Data Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Edge Analytics</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Edge Analytics are a tactic to meet the needs of real time analytics that support systems of insight and engagement which
                                  produce results to enhance local customer experiences. The analytics, supporting data collection and repositories must be
                                  placed at the edge to ensure that the results are delivered to users in real time in order to be relevant. This type of
                                  analysis is taking a larger role in analysis where more traditional approaches including batch analysis in BI systems using
                                  data warehouses. Edge analytics enable reaction times in seconds as opposed to minutes. Edge analytics are often paired with
                                  complex event processing to meet business needs.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Mobile Application Services</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Mobile Application services are concerned with the ongoing evolution of applications in the digital age. An end-to-end Mobile
                                  Application Management (MAM) solution provides the ability to: control the provisioning, updating and removal of mobile
                                  applications via an enterprise app store, monitor of application performance and usage, and remotely wipe data from managed
                                  applications. Core features of mobile application management systems include: App delivery (Enterprise App Store), App
                                  updating, App performance monitoring, User authentication, Crash log reporting, User & group access control, App version
                                  management, App configuration management, Push services, Reporting and tracking, Usage analytics, Event management and App
                                  wrapping.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Remote Access</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  The ability to access devices and services without a direct tether or while being attached to the same network. In a
                                  distributed digital world it is essential, but is also challenge prone, especially with security and consistency issues. There
                                  are numerous protocols and procedures to ensure viable stability.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Video Conferencing</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Videoconferencing (VC) is achieved by a set of telecommunication technologies which allow two or more locations to communicate
                                  by simultaneous two-way video and audio transmissions. Videoconferencing differs from videophone calls in that it's designed
                                  to serve a conference or multiple locations rather than individuals. VC has strong user experience requirement to avoid lags
                                  in transmission due to inadequate bandwidth or poor response time and excessive latency which can badly disrupt the meeting.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Plumb for Messaging</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-plumb-for-messaging"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>Plumb your messaging infrastructure for application flows that will traverse the edge node, aligned with traffic segmentation.</p>
                      <h5>Data Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Corporate Media</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  A wide range of internal and outward facing content that has multiple stakeholders, tends to be time sensitive and
                                  occasionally has time based rules on confidentiality. A major component in the digital age is to have systems of engagement
                                  that not only publish information, but can collect responses from analysts, consumers, partners, regulators which in turn can
                                  be used to refine messages and strategy.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Message Gateways</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  A message gateway (firmware based devices or software) executes protocol or format translator to enable system
                                  interoperability. A protocol translation/mapping gateway interconnects networks with different network protocol technologies
                                  by performing the required protocol conversions. It enables many to many virtual connections using abstraction through
                                  messaging.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Apply Distributed Coordination</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-apply-distributed-coordination"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>Scale your distributed coordination and configuration capabilities across clouds and edge nodes.</p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Business Exchanges</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  A community of partners and competitor organizations that participate in a virtual market, breaking or expanding the
                                  traditional operations of a business value chain. Traditional value chain tactics include acquiring vertical depth for all
                                  critical value chain components. Business ecosystems in the digital age focus on fluid partnerships that augment the value
                                  chain instead of outright acquisition. This is enabled and driven by significant changes in network and application
                                  technology, including advanced API and application deployment management.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Mobile Payments</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Mobile payment technology are payment services operated under financial regulation and performed from a mobile device. Instead
                                  of paying with cash, check, or credit cards, a consumer can use a mobile phone to pay for a wide range of services and digital
                                  or hard goods. This has obvious security challenges and response time challenges to ensure the payment is consistently applied
                                  to all stakeholders.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Position Complex Event Processing</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-complex-event-processing"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        With event processing in place at the network edge, intersection points, security checkpoints, data services, APIs and messaging — you
                        need to the capability to manage the bigger picture.
                      </p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Real time Business Analytics</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Real time business analytics use data ingested in real time and leverage a large amount of hostorical data to look for trends
                                  and anomalies that feed business decision makiing. All the data services estabihed in the data blueprint ( e.g. data lakes,
                                  ingestiion, data orchectration and provenance) are leveraged for this pattern.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>

              <Expandable>
                {(open, toggle) => (
                  <li className={classNames('expandable design-pattern', { open, closed: !open })}>
                    <div className="expandable-header design-pattern-header">
                      <button className="expand-content" onClick={toggle}>
                        <h4>Introduce Predictive Algorithmic Services</h4>
                      </button>
                      <a
                        href="https://www.equinix.com/resources/blueprints/design-pattern-predictive-algorithmic-services"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ioakb-link">
                        Learn more
                      </a>
                    </div>
                    <div className="expandable-content design-pattern-content">
                      <p>
                        After you can model the bigger picture, your digital platform needs to start learning and recommending, along with taking preemptive
                        actions.
                      </p>
                      <h5>Performance Hub Components</h5>
                      <ul className="components">
                        <Expandable>
                          {(open, toggle) => (
                            <li className={classNames('expandable component', { open, closed: !open })}>
                              <div className="expandable-header component-header">
                                <button className="expand-content" onClick={toggle}>
                                  <h6>Predictive Analytics</h6>
                                </button>
                              </div>
                              <div className="expandable-content component-content">
                                <p>
                                  Predictive analytics encompasses a variety of statistical techniques from predictive modeling, machine learning, and data
                                  mining that analyze current and historical facts to making predictions about future or otherwise unknown events.
                                </p>
                              </div>
                            </li>
                          )}
                        </Expandable>
                      </ul>
                    </div>
                  </li>
                )}
              </Expandable>
            </ul>
          </div>
        </div>
      )}
    </Expandable>
  );
}
