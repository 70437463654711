import L from 'leaflet';
import { Path } from 'react-leaflet';
import '@elfalem/leaflet-curve';
import GeoCoordinates from '../../../model/GeoCoordinates';

const __onAdd = L.Curve.prototype.onAdd;
const __onRemove = L.Curve.prototype.onRemove;
const __updatePath = L.Curve.prototype._updatePath;
const __bringToFront = L.Curve.prototype.bringToFront;

class LeafletCurveWithText extends L.Curve {
  onAdd(map) {
    __onAdd.call(this, map);
    this._textRedraw();
  }

  onRemove(map) {
    map = map || this._map;
    if (map && this._textNode && map._renderer._container) map._renderer._container.removeChild(this._textNode);
    __onRemove.call(this, map);
  }

  bringToFront() {
    __bringToFront.call(this);
    this._textRedraw();
  }

  _updatePath() {
    __updatePath.call(this);
    this._textRedraw();
  }

  _textRedraw() {
    var text = this._text;
    const options = this._textOptions;
    if (text) {
      this.setText(null).setText(text, options);
    }
  }

  setText(text, options = {}) {
    this._text = text;
    this._textOptions = options;

    /* If not in Canvas mode or Curve not added to map yet return */
    /* setText will be called by onAdd, using value stored in this._text */
    if (!L.Browser.canvas || typeof this._map === 'undefined') {
      return this;
    }
    const renderer = this._map.getRenderer(this);
    if (renderer instanceof L.Canvas && text) {
      this._drawTextCanvas(text, options, renderer);
    } else if (renderer instanceof L.SVG) {
      this._drawTextSvg(text, options, renderer);
    }
    return this;
  }

  _drawTextCanvas(text, options, renderer) {
    // TODO: Better Canvas support
    console.warn('Canvas support is not complete at the moment.');
    const iconSize = options.weight / 3;
    const ctx = renderer._ctx;
    const path = this.getPath();
    const centerPos = this.trace([0.5])[0];
    const center = this._map.latLngToLayerPoint(centerPos);
    // const center = this._map.latLngToLayerPoint(path[path.length - 2]);
    // const center = this._map.latLngToLayerPoint(this.getCenter());
    // const interval = 0.5;
    // const [m, p0, q, p1, p2] = this._points;
    // var x = this._singleQuadraticTrace(interval, p0.x, p1.x, p2.x);
    // var y = this._singleQuadraticTrace(interval, p0.y, p1.y, p2.y);
    // const center = {x, y};
    const from = this._map.latLngToLayerPoint(path[1]);
    const to = this._map.latLngToLayerPoint(path[path.length - 1]);
    // const leftPoint = from[1] <= to[1] ? from : to;
    // const rightPoint = from[1] > to[1] ? from : to;
    // const angle = Math.atan((rightPoint[0] - leftPoint[0]) / (rightPoint[1] - leftPoint[1]));
    let angle = Math.atan2(to.y - from.y, to.x - from.x);
    // Turn it right side up
    if (angle > Math.PI * 0.5 && angle < Math.PI * 1.5) {
      angle = angle + Math.PI;
    } else if (angle < -Math.PI * 0.5 && angle > -Math.PI * 1.5) {
      angle = angle + Math.PI;
    }
    ctx.save();
    ctx.font = `700 ${12 * iconSize}px Arial, sans-serif`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'bottom';
    ctx.fillStyle = options.color || '#000000';
    ctx.translate(center.x, center.y);
    ctx.rotate(angle);
    ctx.fillText(text, 0, 0);
    ctx.restore();
  }
  _drawTextSvg(text, options, renderer) {
    const iconSize = options.weight / 3;
    const svg = renderer._container;
    // remove the text, as SVG layers are not reset like Canvas ones
    if (this._textNode && this._textNode.parentNode) {
      svg.removeChild(this._textNode);
      /* delete the node, so it will not be removed a 2nd time if the layer is later removed from the map */
      delete this._textNode;
    }
    if (!text) {
      return this;
    }
    // create a text path, to follow the line
    const id = 'pathdef-' + L.Util.stamp(this);
    this._path.setAttribute('id', id);
    const textNode = L.SVG.create('text'),
      textPath = L.SVG.create('textPath');
    textPath.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#' + id);
    textNode.setAttribute('fill', options.color || '#000000');
    textNode.setAttribute('style', `font: 700 ${12 * iconSize}px Arial, sans-serif`);
    textPath.appendChild(document.createTextNode(text));
    textNode.appendChild(textPath);
    this._textNode = textNode;
    svg.appendChild(textNode);
    // center
    const visibleCenter = this.getVisibleCenterLength();
    const textLength = textNode.getComputedTextLength();
    textNode.setAttribute('dx', visibleCenter - textLength / 2);
    // flip it if needed
    const path = this.getPath();
    const from = path[1];
    const to = path[path.length - 1];
    const baseShiftValue = 13;
    if (from[1] < to[1]) {
      textNode.setAttribute('dy', baseShiftValue * iconSize);
    } else {
      textNode.setAttribute('dy', -baseShiftValue * iconSize);
    }
  }
  getVisibleCenterLength() {
    const pathLength = this._path.getTotalLength();
    const bounds = this._renderer._bounds;
    const a = L.point(this._points[1]);
    const b = L.point(this._points[this._points.length - 1]);
    const straightLength = a.distanceTo(b);
    const clippedSegment = L.LineUtil.clipSegment(a, b, bounds);
    if (clippedSegment) {
      const [ac, bc] = clippedSegment;
      const clippedLength = ac.distanceTo(bc);
      const ratio = pathLength / straightLength;
      const visibleLength = clippedLength * ratio;
      let visibleCenter = visibleLength / 2;
      if (!a.equals(ac)) {
        // If we're clipping the start of the line, move towards the end instead
        visibleCenter = pathLength - visibleCenter;
      }
      return visibleCenter;
    } else {
      // Segment probably not visible, just return the center point
      return pathLength / 2;
    }
  }
}

class DirectConnectionCurve extends Path {
  createLeafletElement(props) {
    const { from: f, to: t, leaflet, iconSize = 1, color, text, isPacific } = props;
    let from = f;
    let to = t;
    if (f.longitude < t.longitude && from.latitude < to.latitude) {
      from = t;
      to = f;
    }
    const zoom = leaflet.map.getZoom();
    const path = this.computePath(from, to, zoom, isPacific);
    const options = {
      color,
      weight: 3 * iconSize,
    };
    const l = new LeafletCurveWithText(path, options);
    l.setText(text, options);
    return l;
  }
  updateLeafletElement(fromProps, toProps) {
    const { iconSize = 1 } = toProps;
    const options = this.getPathOptions({ ...toProps, weight: 3 * iconSize });
    if (toProps.text !== fromProps.text || toProps.color !== fromProps.color) {
      this.leafletElement.setText(toProps.text, options);
    }
    if (toProps.iconSize !== fromProps.iconSize) {
      this.leafletElement.setStyle(options);
      this.leafletElement.setText(toProps.text, options);
    }
    this.setStyleIfChanged(fromProps, toProps);
  }
  computePath(from, to, zoom, isPacific) {
    const angleVar = isPacific ? 5 : 2;

    const additionalAngle = Math.PI / (angleVar * zoom + 1);
    const x1 = from.longitude;
    const y1 = from.latitude;
    const x2 = to.longitude;
    const y2 = to.latitude;
    const dx = x2 - x1;
    const dy = y2 - y1;
    const distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
    const angle = Math.atan2(dy, dx) + additionalAngle;
    const hDist = distance / 2;
    const hdx = hDist * Math.cos(angle);
    const hdy = hDist * Math.sin(angle);
    const hx = hdx + x1;
    const hy = hdy + y1;

    const halfwayPoint = GeoCoordinates.fromJSON({
      latitude: hy,
      longitude: hx,
    });

    let fromLeaflet = from.toLeaflet();
    let toLeaflet = to.toLeaflet();

    if (to.longitude < from.longitude) {
      fromLeaflet = to.toLeaflet();
      toLeaflet = from.toLeaflet();
    }
    const path = ['M', fromLeaflet, 'Q', halfwayPoint.toLeaflet(), toLeaflet];

    return path;
  }
}

// function DirectConnection(props) {

//     return (
//         <Curve
//             {...props}
//             positions={path}
//             options={{
//                 color:'blue'
//             }}
//         />
//     )
// }

export default DirectConnectionCurve;
