import React from 'react';

// Taken from: https://fontawesome.com/icons/search
const MagnifyingGlassIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M5.5 10C7.98528 10 10 7.98528 10 5.5C10 3.01472 7.98528 1 5.5 1C3.01472 1 1 3.01472 1 5.5C1 7.98528 3.01472 10 5.5 10Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12 12.0001L9.09998 9.1001" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default MagnifyingGlassIcon;
