import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import ProjectEvents from 'analytics/ProjectEvents';
import { Mutations } from 'gql';
import User from 'model/User';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import StringUtils from 'utils/StringUtils';
import { toast } from 'utils/Toast';
import 'views/components/Modal.scss';
import './ShareProjectModal.scss';
import TextInput from 'views/components/TextInput';
import Table from 'views/components/Table';
import ViewingIcon from 'views/components/icons/Viewing';
import EditingIcon from 'views/components/icons/Editing';
import OfflineIcon from 'views/components/icons/Offline';
import defaultStyles from './defaultStyles';
import useProjectPresence from 'hooks/useProjectPresence';
import { emailStringToArray, isEmailValid } from 'utils/emails';
import Button from 'views/components/Button';

const ShareProjectModalView = ({ project, user, isOpen = false, onClose, calculateUsersOnline, refetch }) => {
  const { presence } = useProjectPresence(project);
  const isOwner = project && user && project.owner.id.equals(user.id);
  const [emails, setEmails] = useState('');
  const [shareProject, { loading }] = useMutation(Mutations.SHARE_PROJECT);

  useEffect(() => {
    !!presence.length && calculateUsersOnline && calculateUsersOnline(presence);
  }, [presence, calculateUsersOnline]);

  const handleError = (error) => {
    if (!error) return;

    if (
      error.message ===
      'GraphQL error: Exception while fetching data (/shareProject) : com.ioaworkbench.exception.ProjectSharingException: Could not share project with the specified users (are they whitelisted?)'
    ) {
      toast('Cannot add this user (are they whitelisted?)', 'error');
    } else if (error.message.startsWith("Variable 'emails' has an invalid value.")) {
      toast(error.message, 'error');
    } else if (error.message.includes('following users')) {
      toast("One or more emails couldn't be added", 'warning');
      refetch();
    } else {
      toast('An unexpected error occurred running operation', 'error');
    }
  };

  useEffect(() => {
    //TODO: handling loading
    return;
  }, [loading]);

  const onEmailValueChange = (newEmails) => setEmails(newEmails);

  const getNewUsers = (oldUsers, newUsers) => {
    const oldUserIds = oldUsers.map((u) => u.id).map((uid) => uid.toString());
    return newUsers.filter((u) => !oldUserIds.includes(u.id.toString()));
  };

  const onShareClick = async () => {
    if (StringUtils.isWhitespace(emails)) return toast('Email address can not be empty', 'error');
    const emailArray = emailStringToArray(emails);
    if (!emailArray.every((email) => isEmailValid(email))) return toast('One or more email addresses are invalid', 'error');

    try {
      const result = await shareProject({ variables: { projectId: project.id, emails: emailArray } });

      //get our newly-added users (normally just 1 user) and send tracking events for each user id
      const newUsers = getNewUsers(project.sharedUsers, User.fromJSONArray(result.data.shareProject.sharedUsers));
      newUsers.forEach((u) => ProjectEvents.projectShared(project.id, u.id));
    } catch (error) {
      handleError(error);
    }

    setEmails('');
  };

  const handleKeyUp = async (e) => {
    if (e.keyCode === 13) {
      // ENTER key
      await onShareClick(e);
    }
  };

  const getUserStatus = (user) => {
    if (!presence) return 'OFFLINE';
    const status = presence.find((p) => p.userId === user.id.value)?.status;

    if (!status || status === 'IDLE') return 'OFFLINE';

    return status;
  };

  const makeCollaboratorsList = () => {
    const collaboratorsList = project.sharedUsers.filter((sharedUser) => !sharedUser.id.equals(user.id));
    if (!user.id.equals(project.owner.id)) collaboratorsList.push(project.owner);

    return collaboratorsList;
  };

  //project && user && project.owner.id.equals(user.id);

  return (
    <Modal isOpen={isOpen} className={'modal-window collaborators-modal'} contentLabel="Share a Project" onRequestClose={onClose} defaultStyles={defaultStyles}>
      <div data-testid={'share-modal'}>
        <div className="modal-header">
          <h3>{isOwner ? 'Share This Project' : 'Collaborators'}</h3>
          <button className="close-modal" onClick={onClose}>
            <svg height="12" width="12">
              <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
            </svg>
          </button>
        </div>
        {isOwner && (
          <div className="modal-fields modal-fields--full embedded-button-input">
            <label>Invite a collaborator</label>
            <TextInput value={emails} placeholder="e.g. jsmith@equinix.com" className="textInput" onKeyUp={handleKeyUp} onValueChange={onEmailValueChange} />
            {loading ? (
              <span className="loading embedded" />
            ) : (
              <div className="embedded add-collaborator">
                <Button text={'Add'} onClick={onShareClick} color={'red'} isDisabled={!emails} />
              </div>
            )}
          </div>
        )}
        <div className="modal-fields modal-fields--full">
          {isOwner && <label>Collaborators</label>}
          <div className={'collaborators-table-wrapper'}>
            {project.sharedUsers.length > 0 ? (
              <Table className="collaborators" headers={['User', '', 'Status', '']}>
                {makeCollaboratorsList().map((user) => {
                  const status = getUserStatus(user);
                  return <CollaboratorListItem key={user.id} user={user} project={project} status={status} isOwner={isOwner} />;
                })}
              </Table>
            ) : (
              <div className="form-note">This project has not been shared</div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

ShareProjectModalView.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  project: PropTypes.shape({}).isRequired,
};

const CollaboratorListItem = ({ user, project, status, isOwner }) => {
  const [unshareProject, { loading }] = useMutation(Mutations.UNSHARE_PROJECT);
  const onUnshareClick = async () => {
    await unshareProject({
      variables: {
        projectId: project.id,
        userId: user.id,
      },
    });
  };

  const icons = {
    VIEWING: <ViewingIcon width={20} height={20} color="white" background="#53B7E8" />,
    EDITING: <EditingIcon width={20} height={20} color="white" background="#44971D" />,
    OFFLINE: <OfflineIcon width={20} height={20} color="white" background="#999999" />,
  };

  return (
    <tr className={'collaborator'}>
      <td className={'collaborator-email'} colSpan={2}>
        {user.email}
      </td>
      <td className={`status-name ${status.toLowerCase()}`}>
        {icons[status]} {status.toLowerCase()}
      </td>

      <td> {isOwner && (loading ? <span className="loading" /> : <button onClick={onUnshareClick} />)}</td>
    </tr>
  );
};

export default ShareProjectModalView;
