import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import User from 'model/User';

const _isAuthenticated = (user) => {
  return user instanceof User;
};

const AuthenticatedRoute = ({ user, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (_isAuthenticated(user) ? <Component user={user} {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />)}
  />
);

export default AuthenticatedRoute;
