export default class ObjectUtils {
  static equals(o1, o2) {
    //console.log("Checking equality between: " + o1 + " (" + (o1 == null) + "), and " + o2 + " (" + (o2 == null) + ")");
    if (o1 == null && o2 == null) {
      return true;
    }

    if ((o1 != null && o2 == null) || (o2 != null && o1 == null)) {
      return false;
    }

    if (typeof o1.equals === 'function') {
      return o1.equals(o2);
    }

    if (typeof o2.equals === 'function') {
      return o2.equals(o1);
    }

    return o1 === o2;
    //if the object implements equals(), use that
    /*if (typeof item.equals === "function") {
         return items.findIndex((a) => item.equals(a));
         }

         //otherwise, just do normal array.indexOf()
         return items.indexOf(item); */
  }

  static isString(s) {
    return typeof s === 'string' || s instanceof String;
  }
}
