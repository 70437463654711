import React, { useState, useEffect } from 'react';
import { ProjectEvents } from 'analytics';
import { Mutations, Queries } from 'gql';
import { LoggerFactory } from 'logger';
import Project from 'model/Project';
import { useMutation, useQuery } from 'react-apollo';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Assert from 'utils/Assert';
import { getTheme } from 'utils/SelectTheme';
import StopWatch from 'utils/StopWatch';
import StringUtils from 'utils/StringUtils';
import { toast } from 'utils/Toast';
import { useGetAccountStrategyPlans } from 'views/components/hocs/withAccountStrategyPlans';
import { useGetSegments } from 'views/components/hocs/withSegments';
import 'views/components/Modal.scss';
import PropTypes from 'prop-types';
import TextInput from 'views/components/TextInput';
import RenderButtons from './common/RenderButtons';
import RenderProgress from './common/RenderProgress';
import Customer from 'model/Customer';
import DropdownStyles from 'views/components/DropdownStyles';

const CreateProjectView = ({ onClose, fromTemplate, template, history, companyId }) => {
  const [customer, setCustomer] = useState();
  const [projectName, setProjectName] = useState('');
  const [segment, setSegment] = useState();
  const [subSegment, setSubSegment] = useState();
  const [accountStrategyPlan, setAccountStrategyPlan] = useState();
  const [createFromTemplate, setCreateFromTemplate] = useState(fromTemplate);
  const [customers, setCustomers] = useState([]);

  const { data: customersData, loading: customersLoading, refetch: refetchCustomers } = useQuery(Queries.GET_CUSTOMERS);
  const { data: companyData } = useQuery(Queries.GET_COMPANY, { variables: { companyId } });
  const { segments, segmentsLoading } = useGetSegments();
  const { accountStrategyPlans, accountStrategyPlansLoading } = useGetAccountStrategyPlans();

  useEffect(() => {
    if (!customersData) return;
    setCustomers(Customer.fromJSONArray(customersData.customers));
  }, [customersData]);

  const [execCreateProject, { loading: createProjectLoading }] = useMutation(Mutations.CREATE_PROJECT);
  const [execCreateProjectFromTemplate, { loading: createProjectFromTemplateLoading }] = useMutation(Mutations.CREATE_PROJECT_FROM_TEMPLATE);
  const [refetchLoading, setRefetchLoading] = useState(false);

  const loading = createProjectLoading || createProjectFromTemplateLoading || refetchLoading;

  const logger = LoggerFactory.getLogger('Create Project');

  const onCustomerChange = (customer) => {
    if (!customer) {
      setCustomer(null);
      setSegment(null);
      setSubSegment(null);
      return;
    }
    const propCustomer = customers.find((c) => `${c.id}` === customer.value);
    if (propCustomer && propCustomer.segment) {
      const newSegment = {
        value: propCustomer.segment.id.value,
        label: propCustomer.segment.name,
      };
      setSegment(newSegment);
    }
    if (propCustomer && propCustomer.subSegment) {
      const newSubSegment = {
        value: propCustomer.subSegment.id.value,
        label: propCustomer.subSegment.name,
      };
      setSubSegment(newSubSegment);
    }
    setCustomer(customer);
  };

  const onCustomerCreate = (inputValue) => {
    const newCustomer = { value: null, label: inputValue };
    setCustomer(newCustomer);
  };

  const onProjectValueChange = (newValue) => setProjectName(newValue);

  const onSegmentValueChange = (option) => {
    const selectedSegment = option ? segments.find((s) => s.id.equivalent(option.value)) : null;
    const subSegmentsForSelectedSegment = selectedSegment ? selectedSegment.subSegments : [];

    // If the current subsegment is not a part of the new segment,
    // need to reset the current subsegment.
    const needToResetSubSegment = subSegment && !subSegmentsForSelectedSegment.some((s) => s.id.equivalent(subSegment.value));
    setSegment(option);
    if (needToResetSubSegment) {
      setSubSegment(null);
    }
  };

  const onSubSegmentValueChange = (option) => {
    setSubSegment(option);
  };

  const onASPValueChange = (option) => {
    if (!option) {
      setAccountStrategyPlan(option);
      return;
    }

    const selectAccountStrategyPlan = accountStrategyPlans.find((asp) => asp.id.value === option.value);
    const segment = segments.find((s) => s.id.value === selectAccountStrategyPlan.segmentId.value);
    const subSegment = segment.subSegments.find((s) => s.id.value === selectAccountStrategyPlan.subSegmentId.value);
    const customer = customers.find((c) => c.name === selectAccountStrategyPlan.company) ?? null;

    setAccountStrategyPlan(option);
    setCustomer({
      value: customer == null ? null : customer.id.value,
      label: customer == null ? selectAccountStrategyPlan.company : customer.name,
    });
    setSegment({ value: segment.id.value, label: segment.name });
    setSubSegment({ value: subSegment.id.value, label: subSegment.name });
  };

  const removeFromTemplate = () => {
    setCreateFromTemplate(false);
  };

  useEffect(() => {
    StopWatch.startNew();
  });

  const validateForm = () => {
    if (!customer || !segment || !subSegment || StringUtils.isWhitespace(customer.label) || StringUtils.isWhitespace(projectName)) {
      return false;
    }

    return true;
  };

  const onCreateClick = async (e) => {
    e.stopPropagation();
    if (!validateForm()) return;
    if (createFromTemplate) {
      await onCreateProjectFromTemplate();
    } else {
      await onCreateProject();
    }
  };
  const onCreateProjectFromTemplate = async () => {
    logger.debug(`Creating new project from template '${template.id}' with name: '${projectName}', customer: '${customer}', and segment: ${segment}`);

    // The earlier client-side validation should have these checks covered.
    // But Assert() just in case, to prevent sending the server a null/undefined
    // subsegment.
    Assert.isTrue(() => Boolean(subSegment));

    const aspId = accountStrategyPlan ? accountStrategyPlan.value : null;

    const createProjectArgs = {
      variables: {
        templateId: template.id,
        name: projectName,
        customer: customer.label,
        segmentId: (segment || {}).value,
        subSegmentId: (subSegment || {}).value,
        aspId,
      },
    };
    try {
      const { data } = await execCreateProjectFromTemplate(createProjectArgs);
      setRefetchLoading(true);
      await refetchCustomers();
      setRefetchLoading(false);
      ProjectEvents.templateUsed(template.id);
      const project = Project.fromJSON(data.createProjectFromTemplate);
      history.push({
        pathname: `/projects/${project.id.value}`,
        state: { project },
      });
    } catch (error) {
      logger.error(`An error occurred executing mutation: ${error.message}`);
      toast('An unexpected error occurred creating project from template', 'error');
      onClose();
    }
  };

  const onCreateProject = async () => {
    logger.debug(`Creating new project with name: '${projectName}', customer: '${customer}', and segment: ${segment}`);

    // The earlier client-side validation should have these checks covered.
    // But Assert() just in case, to prevent sending the server a null/undefined
    // subsegment.
    Assert.isTrue(() => Boolean(subSegment));

    const aspId = accountStrategyPlan ? accountStrategyPlan.value : null;

    const createProjectArgs = {
      variables: {
        name: projectName,
        customer: customer.label,
        segmentId: (segment || {}).value,
        subSegmentId: (subSegment || {}).value,
        aspId,
      },
      refetchQueries: [{ query: Queries.GET_PROJECTS }],
    };

    try {
      const { data } = await execCreateProject(createProjectArgs);
      setRefetchLoading(true);
      await refetchCustomers();
      setRefetchLoading(false);
      ProjectEvents.projectCreated(data.createProject.id);
      const project = Project.fromJSON(data.createProject);
      history.push({
        pathname: `/projects/${project.id}`,
        state: { project },
      });
    } catch (error) {
      logger.error(`An error occurred executing mutation: ${error.message}`);
      toast('An unexpected error occurred creating project', 'error');
      onClose();
    }
  };

  const selectedSegment = segments.find((s) => segment && s.id.equivalent(segment.value));
  const subSegments = selectedSegment ? selectedSegment.subSegments : [];

  return (
    <div data-testid={'create-project-modal'}>
      <div className="modal-header">
        <h3>Create a Project</h3>
        <button className="close-modal" onClick={onClose}>
          <svg height="12" width="12">
            <path d="M0 0 L12 12 M12 0 L0 12" width="12" height="12" />
          </svg>
        </button>
      </div>
      {companyData?.company.isDSPAllowed && (
        <div className="asp-select-box">
          <div className="modal-fields modal-fields--full">
            <label>Select a DSP to populate project data (optional)</label>
            <Select
              className="react-select-container"
              maxMenuHeight={200}
              menuPlacement="auto"
              menuPosition="fixed"
              placeholder="No DSP chosen..."
              options={accountStrategyPlans.map((asp) => ({
                value: asp.id.value,
                label: asp.company,
              }))}
              value={accountStrategyPlan}
              isClearable
              isLoading={accountStrategyPlansLoading}
              onChange={onASPValueChange}
              styles={DropdownStyles}
              theme={getTheme}
            />
          </div>
        </div>
      )}

      {createFromTemplate && (
        <div className="modal-fields  modal-fields--full">
          <label>Use Template</label>
          <div className="template">
            <span>{template.name}</span>
            <span className="remove" onClick={removeFromTemplate}>
              Remove
            </span>
          </div>
        </div>
      )}

      <div className="modal-fields modal-fields--full">
        <label>Project Name</label>
        <TextInput value={projectName} placeholder="e.g. New Network" className="textInput" onValueChange={onProjectValueChange} />
      </div>

      <div className="modal-fields modal-fields--full">
        <label>Customer Name</label>
        <CreatableSelect
          isClearable
          placeholder="Select or type..."
          isLoading={customersLoading}
          onChange={onCustomerChange}
          onCreateOption={onCustomerCreate}
          options={customers.map((i) => ({
            value: `${i.id}`,
            label: i.name || '',
          }))}
          styles={DropdownStyles}
          value={customer}
          theme={getTheme}
          data-testid={'customer-select'}
        />
      </div>

      <div className="modal-fields">
        <label>Customer Segment</label>
        <Select
          placeholder="Select one..."
          options={
            segments.map((i) => ({
              value: i.id.value,
              label: i.name,
            })) || []
          }
          value={segment}
          isLoading={segmentsLoading}
          onChange={onSegmentValueChange}
          theme={getTheme}
          styles={DropdownStyles}
          menuPlacement="auto"
        />
      </div>

      <div className="modal-fields modal-fields--right">
        <label>Customer Sub-segment</label>
        <Select
          placeholder="Then select..."
          options={
            subSegments.map((i) => ({
              value: i.id.value,
              label: i.name,
            })) || []
          }
          value={subSegment}
          isLoading={segmentsLoading}
          styles={DropdownStyles}
          onChange={onSubSegmentValueChange}
          theme={getTheme}
          menuPlacement="auto"
        />
      </div>
      {loading ? <RenderProgress /> : <RenderButtons onClick={onCreateClick} onClose={onClose} valid={validateForm()} />}
    </div>
  );
};

CreateProjectView.propTypes = {
  onClose: PropTypes.func.isRequired,
  fromTemplate: PropTypes.bool,
  template: PropTypes.shape({
    templateId: PropTypes.string,
    name: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CreateProjectView;
