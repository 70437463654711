import React, { useState } from 'react';
import PointsMenuIcon from 'views/components/icons/PointsMenu';
import { MenuItem } from '@szhsin/react-menu';
import { Link } from 'react-router-dom';
import ContextMenu from 'views/components/ContextMenu';

const TemplateContextMenu = ({ templateId, project, openDeleteModal }) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({});

  const openContextMenu = (e) => {
    setAnchorPoint({ x: e.pageX, y: e.pageY });
    setIsContextMenuOpen(true);
  };

  const closeContextMenu = () => {
    setIsContextMenuOpen(false);
  };
  return (
    <>
      <div onClick={openContextMenu} data-testid={'template-context-menu'}>
        <PointsMenuIcon width={13} height={13} color="#2D2D2D" />
      </div>
      <ContextMenu isContextMenuOpen={isContextMenuOpen} onCloseContextMenu={closeContextMenu} anchorPoint={anchorPoint}>
        <MenuItem>
          <Link to={{ pathname: `/templates/${templateId}`, state: { project, edit: true } }}>Edit Template</Link>
        </MenuItem>
        <MenuItem onClick={openDeleteModal}>Delete Template</MenuItem>
      </ContextMenu>
    </>
  );
};

export default TemplateContextMenu;
