import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Assert from 'utils/Assert';

import './ToggleButton.scss';

export default class ToggleButton extends React.Component {
  static defaultProps = {
    enabled: true,
    checked: false,
    onToggled: () => {},
    onChecked: () => {},
    onUnchecked: () => {},
  };

  static propTypes = {
    enabled: PropTypes.bool,
    checked: PropTypes.bool,
    onToggled: PropTypes.func,
    onChecked: PropTypes.func,
    onUnchecked: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  _onClick = async (e) => {
    e.stopPropagation();
    this.checked = !this.checked;
  };

  get checked() {
    if (typeof this.props.checked !== 'undefined') {
      return this.props.checked;
    }
    return this.state.checked;
  }

  set checked(value) {
    Assert.isBoolean(value);

    if (typeof this.props.checked === 'undefined' && this.state.checked === value) {
      return;
    }

    this.setState({ checked: value }, () => {
      this.props.onToggled(value);
      if (value) {
        this.props.onChecked();
      } else {
        this.props.onUnchecked();
      }
    });
  }

  render() {
    return (
      <button disabled={!this.props.enabled} className={cn('toggle-button', { checked: this.checked, small: this.props.small })} onClick={this._onClick} />
    );
  }

  static getDerivedStateFromProps(props, state) {
    return {
      checked: props.checked || state.checked,
    };
  }
}
