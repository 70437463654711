import React from 'react';
import './Button.scss';
import cn from 'classnames';

const Button = ({
  Icon,
  iconColor,
  iconSize = 20,
  onClick,
  text,
  color = 'red',
  isDisabled = false,
  isInverse = false,
  isStretch = false,
  smallerText = false,
  EndIcon,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={cn('button', `${color}`, { disabled: isDisabled }, { inverse: isInverse }, { stretch: isStretch }, { 'with-end-icon': !!EndIcon })}
      onClick={onClick}
      disabled={isDisabled}>
      {Icon && <Icon color={iconColor} width={iconSize} height={iconSize} />}
      <span className={cn({ smallerText: smallerText })}>{text}</span>
      {EndIcon && <EndIcon color={iconColor} />}
    </button>
  );
};

export default Button;
