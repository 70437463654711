export const sliderMarks = {
  0: '0',
  10: '10',
  20: '20',
  30: '30',
  40: '40',
};

export const sliderStyle = {
  dotStyle: {
    bottom: -7,
    width: 1,
    height: 6,
    border: 0,
    marginLeft: 0,
    borderLeft: '1px solid #EBEBEB',
    borderRadius: 0,
    transform: 'translateX(50%)',
  },
  activeDotStyle: {
    borderLeft: '1px solid #656568',
    opacity: 0.5,
  },
  handleStyle: {
    border: 'solid 2px #656568',
    backgroundColor: '#656568',
    boxShadow: 'none',
    marginTop: -7,
  },
  stepStyle: {
    height: 1,
    backgroundColor: '#656568',
  },
  railStyle: {
    height: 1,
    backgroundColor: '#656568',
    opacity: 0.3,
  },
  trackStyle: {
    height: 1,
    backgroundColor: 'transparent',
  },
};
