import React from 'react';

const ViewingIcon = ({ width = 10, height = 10, color = 'white', background = '#2D2D2D', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z" fill={background} />
      <g clipPath="url(#clip0_1048:81348)">
        <path
          d="M9.99978 5.83301C7.222 5.83301 4.84978 7.56079 3.88867 9.99967C4.84978 12.4386 7.222 14.1663 9.99978 14.1663C12.7776 14.1663 15.1498 12.4386 16.1109 9.99967C15.1498 7.56079 12.7776 5.83301 9.99978 5.83301ZM9.99978 12.7775C8.46645 12.7775 7.222 11.533 7.222 9.99967C7.222 8.46634 8.46645 7.2219 9.99978 7.2219C11.5331 7.2219 12.7776 8.46634 12.7776 9.99967C12.7776 11.533 11.5331 12.7775 9.99978 12.7775ZM9.99978 8.33301C9.07756 8.33301 8.33312 9.07745 8.33312 9.99967C8.33312 10.9219 9.07756 11.6663 9.99978 11.6663C10.922 11.6663 11.6664 10.9219 11.6664 9.99967C11.6664 9.07745 10.922 8.33301 9.99978 8.33301Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1048:81348">
          <rect width="13.3333" height="13.3333" fill={color} transform="translate(3.33301 3.33301)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ViewingIcon;
