import React from 'react';
import ProductEvents from 'analytics/ProductEvents';
import ToggleButton from '../ToggleButton';
import withAddMetro from '../hocs/withAddMetro';
import withRemoveMetro from '../hocs/withRemoveMetro';
import { dismissAllToasts } from 'utils/Toast';

function MetroToggle(props) {
  const { phaseId, projectId, active, metro, loading, addMetro, removeMetro } = props;
  const onAddMetro = async () => {
    await addMetro(projectId, phaseId, metro);
    dismissAllToasts();
    ProductEvents.metroAdded(metro.name);
  };
  const onRemoveMetro = async () => {
    await removeMetro(projectId, phaseId, metro.id);
    dismissAllToasts();
    ProductEvents.metroRemoved(metro.name);
  };
  return <ToggleButton small enabled={!loading} checked={active} onChecked={onAddMetro} onUnchecked={onRemoveMetro} />;
}
export default withAddMetro(withRemoveMetro(MetroToggle));
