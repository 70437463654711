import React, { useState } from 'react';
import { GqlQueryComponent, Queries } from 'gql';
import Segment from 'model/Segment';
import { useQuery } from 'react-apollo';

class SegmentsWrapper extends GqlQueryComponent {
  query() {
    return Queries.GET_SEGMENTS;
  }

  transform(data) {
    return Segment.fromJSONArray(data.segments);
  }
  renderQuery(loading, error, segments) {
    return this.props.children(segments, loading);
  }
}

function withSegments(Component) {
  return (props) => (
    <SegmentsWrapper {...props}>{(segments, loading) => <Component segments={segments} segmentsLoading={loading} {...props} />}</SegmentsWrapper>
  );
}

export default withSegments;

const useGetSegments = () => {
  const [segments, setSegments] = useState([]);
  const { loading } = useQuery(Queries.GET_SEGMENTS, {
    onCompleted: (data) => setSegments(Segment.fromJSONArray(data.segments)),
  });

  return { segments, segmentsLoading: loading };
};

export { useGetSegments };
