import Assert from '../utils/Assert';
import GeoCoordinates from './GeoCoordinates';
import GroupLocationId from './GroupLocationId';

// Partial data for GroupLocation. Contains the bare minimum data needed by the
// Map and the SidePanel nodes.
// Make a separate query to get the full GroupLocation data.
export default class GroupLocationPartialData {
  constructor(id, coordinates) {
    Assert.instanceOf(id, GroupLocationId);
    Assert.instanceOf(coordinates, GeoCoordinates);

    this.id = id;
    this.coordinates = coordinates;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof GroupLocationPartialData)) {
      return false;
    }

    return this.id.equals(other.id);
  }

  toString() {
    return `${this.constructor.name}{ coordinates:${this.coordinates}}`;
  }

  toJSON() {
    const { id, coordinates } = this;
    return { id, coordinates: coordinates.toJSON() };
  }

  static fromJSON(json) {
    const { id, coordinates } = json;

    return new GroupLocationPartialData(new GroupLocationId(id), new GeoCoordinates(coordinates.latitude, coordinates.longitude));
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = GroupLocationPartialData.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}
