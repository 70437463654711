import React from 'react';
import { applyStatics, MenuItem } from '@szhsin/react-menu';

const PhasesMenuItems = ({ onRename, onCopy, onDelete, isPhaseSelected, ...rest }) => {
  return (
    <>
      <MenuItem {...rest} onClick={onRename}>
        Rename
      </MenuItem>
      <MenuItem {...rest} onClick={onCopy}>
        Copy to new phase
      </MenuItem>
      {!isPhaseSelected && (
        <MenuItem {...rest} onClick={onDelete}>
          Delete this phase
        </MenuItem>
      )}
    </>
  );
};

applyStatics(MenuItem)(PhasesMenuItems);

export default PhasesMenuItems;
