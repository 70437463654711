import { Mutations } from 'gql';
import withMutation from 'gql/withMutation';

import LocationId from 'model/LocationId';
import { useMutation } from 'react-apollo';

export default withMutation({
  name: 'ADD_LOCATION_GROUP_LOCATIONS',
  mutation: Mutations.ADD_LOCATION_GROUP_LOCATIONS,
  mutateProp: 'addLocationGroupLocations',
  getMutationArgs(projectId, phaseId, locationGroupId, locationIds) {
    const locations = locationIds.filter((i) => i instanceof LocationId);
    return {
      variables: {
        projectId,
        phaseId,
        locationGroupId,
        locations,
      },
    };
  },
});

export const useAddLocationGroupLocations = () => {
  const [doAddLocationGroupLocations] = useMutation(Mutations.ADD_LOCATION_GROUP_LOCATIONS);

  const addLocationGroupLocations = async (projectId, phaseId, locationGroupId, locationIds) => {
    const locations = locationIds.filter((i) => i instanceof LocationId);

    await doAddLocationGroupLocations({
      variables: {
        projectId,
        phaseId,
        locationGroupId,
        locations,
      },
    });
  };
  return { addLocationGroupLocations };
};
