import React, { useState, useEffect } from 'react';
import 'react-notion/src/styles.css';
import './ResourcesView.scss';
import { NotionRenderer } from 'react-notion';

const renderLoading = () => {
  return (
    <div className="loading-projects resources">
      <span className="loading-message">
        <div className="spinner"></div>
        Loading Resources
      </span>
    </div>
  );
};

const ResourcesView = () => {
  const [blockMap, setBlockMap] = useState();

  useEffect(() => {
    const getNotionSite = async () => {
      const data = await fetch('https://notion-api.splitbee.io/v1/page/a2ba0b72d8fb4f63a4143719a3fc50be');

      const jsonData = await data.json();

      const toDelete = [];
      const hasContentArray = [];

      for (const id in jsonData) {
        const element = jsonData[id];

        if (element.role === 'none') {
          toDelete.push(id);
          continue;
        }

        if (element.value?.content) {
          hasContentArray.push(element.value.id);
          continue;
        }

        if (element.value?.type === 'table_of_contents') {
          toDelete.push(element.value.id);
          toDelete.push(element.value.parent_id);
          continue;
        }
      }

      toDelete.forEach((id) => {
        delete jsonData[id];
        const toDeleteContentIdIndex = hasContentArray.findIndex((contentId) => contentId === id);
        toDeleteContentIdIndex !== -1 && hasContentArray.splice(toDeleteContentIdIndex, 1);

        hasContentArray.forEach((hasContentId) => {
          const toDeleteIndex = jsonData[hasContentId].value.content.findIndex((blockId) => blockId === id);
          toDeleteIndex !== -1 && jsonData[hasContentId].value.content.splice(toDeleteIndex, 1);
        });
      });

      setBlockMap(jsonData);
    };

    getNotionSite();
  }, []);

  useEffect(() => {
    if (!blockMap) return;

    const iframes = document.querySelectorAll('iframe.notion-image-inset');

    iframes.forEach((iframe) => {
      const src = iframe.getAttribute('src');
      const video = document.createElement('video');
      video.setAttribute('src', src);
      video.setAttribute('controls', 'true');
      video.setAttribute('name', 'media');
      iframe.parentNode.appendChild(video);
      iframe.remove();
    });
  }, [blockMap]);

  return blockMap ? (
    <div className="getting-started">
      <h1>Getting Started with WorkBench®</h1>
      <NotionRenderer blockMap={blockMap} />
    </div>
  ) : (
    renderLoading()
  );
};

export default ResourcesView;
