import moment from 'moment';
import Assert from '../utils/Assert';

const DATE_FORMAT = 'dddd, MMMM Do YYYY, h:mm:ss a z';

export default class Timestamp {
  constructor(value) {
    Assert.isInteger(value);

    this.value = value;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof Timestamp)) {
      return false;
    }

    return this.value === other.value;
  }

  isBefore(other) {
    Assert.instanceOf(other, Timestamp);
    return this.value > other.value;
  }

  isAfter(other) {
    Assert.instanceOf(other, Timestamp);
    return this.value < other.value;
  }

  toString(format) {
    return moment.utc(this.value).format(format ?? DATE_FORMAT);
  }

  toJSON() {
    return this.value;
  }

  static fromJSON(json) {
    if (!json) {
      return null;
    }

    return new Timestamp(parseInt(json, 10));
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      items.push(Timestamp.fromJSON(json));
    }

    return items;
  }
}
