import React, { useEffect, useState } from 'react';
import 'react-notion/src/styles.css';
import Button from 'views/components/Button';
import PlusIcon from 'views/components/icons/Plus';
import Arrow2 from 'views/components/icons/Arrow2';
import { useQuery, useMutation } from 'react-apollo';
import { Queries, Mutations } from 'gql';
import AddUserToCompanyModal from '../modals/AddUserToCompanyModal';
import './TeamView.scss';
import DeleteUserModal from '../modals/DeleteUserModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import Modal from 'react-modal';
import defaultStyles from 'views/ProjectDetailView/modals/defaultStyles';
import TeamList from './TeamList';
import { USER_ROLES } from '../../../model/userRoles';
import CompanyModal from '../modals/CompanyModal';
import { LoggerFactory } from 'logger';
import { toast } from 'utils/Toast';
import { Actions } from 'stores/UserStore';
import { areDomainsEquals } from 'views/components/ProjectMap/helpers/compareDomains';

const TeamView = ({ onGoBackClick = null, companyId, user, usedDomains = null }) => {
  const logger = LoggerFactory.getLogger('Company');
  const { data: companyData, loading: loadingGetCompany } = useQuery(Queries.GET_COMPANY, { variables: { companyId } });
  const [updateCompany, { loading: loadingUpdate, error: errorUpdate }] = useMutation(Mutations.UPDATE_COMPANY);
  const [deleteCompany, { loading: loadingDelete, error: errorDeleteCompany }] = useMutation(Mutations.DELETE_COMPANY);
  const [deleteUser, { loading: loadingDeleteUser, error: errorDeleteUser }] = useMutation(Mutations.DELETE_USER);
  const [updateUser] = useMutation(Mutations.UPDATE_USER);
  const [companyState, setCompanyState] = useState();
  const [modalOpen, setModalOpen] = useState('');
  const [memberSelected, setMemberSelected] = useState(false);

  useEffect(() => {
    if (errorDeleteCompany) {
      logger.error(`An error occurred executing mutation: ${errorDeleteCompany.message}`);
      toast('An unexpected error occurred deleting company', 'error');
    }
    // eslint-disable-next-line
  }, [errorDeleteCompany]);

  useEffect(() => {
    if (errorDeleteUser) {
      logger.error(`An error occurred executing mutation: ${errorDeleteUser.message}`);
      toast('An unexpected error occurred deleting user', 'error');
    }
    // eslint-disable-next-line
  }, [errorDeleteUser]);

  useEffect(() => {
    if (errorUpdate) {
      logger.error(`An error occurred executing mutation: ${errorUpdate.message}`);
      toast('An unexpected error occurred updating company', 'error');
    }
    // eslint-disable-next-line
  }, [errorUpdate]);

  useEffect(() => {
    if (!companyData) return;
    setCompanyState(companyData.company);
  }, [companyData]);

  const onUpdateUser = async (userId, role) => {
    await updateUser({
      variables: { userId, role },
      refetchQueries: [{ query: Queries.GET_COMPANY, variables: { companyId } }],
    });
    Actions.reload();
  };

  const companyOwner = companyState?.members.find((user) => user.role === USER_ROLES.companyOwner.value);

  const onUpdate = (member, roleSelected) => {
    if (!!companyOwner && member?.id !== companyOwner.id && roleSelected === USER_ROLES.companyOwner.value) {
      changeModalState('update', member, roleSelected);
      return;
    }
    onUpdateUser(member?.id, roleSelected);
  };

  const onConfirm = () => {
    onUpdateUser(memberSelected?.id, USER_ROLES.companyOwner.value);
    setModalOpen(false);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const changeModalState = (newState, member) => {
    if (member) setMemberSelected(member);
    setModalOpen(newState);
  };

  const renderLoading = () => {
    return (
      <div className="loading-projects">
        <span className="loading-message">
          <div className="spinner"></div>
          Loading Team Members
        </span>
      </div>
    );
  };

  const onDeleteCompany = async (companyId, sendEmail) => {
    await deleteCompany({ variables: { companyId, sendEmail }, refetchQueries: [{ query: Queries.GET_COMPANIES }] });
    !onGoBackClick && Actions.logout();
    onCloseModal();
  };

  const onUpdateCompany = async (companyName, toggleChecked, addedDomains) => {
    if (companyName !== companyState.name || toggleChecked !== companyState.isDSPAllowed || !areDomainsEquals(addedDomains, companyState.domains)) {
      await updateCompany({
        variables: { companyId, name: companyName, isDSPAllowed: toggleChecked, domains: addedDomains },
      });
    }
  };

  const onDeleteUser = async (userId) => {
    await deleteUser({ variables: { userId }, refetchQueries: [{ query: Queries.GET_COMPANY, variables: { companyId } }] });
    user.id.value === userId && Actions.logout();
  };

  const ownerExist = !!companyState?.members.find((member) => member.role === USER_ROLES.companyOwner.value);

  return (
    <>
      {!!onGoBackClick && (
        <div className="going-back-button">
          <button className="" onClick={onGoBackClick} data-testid={'go-back-button'}>
            <Arrow2 color={'black'} width={14} height={10} />
            <span className="">Back to All Companies</span>
          </button>
        </div>
      )}
      <div className="list-heading">
        <h1 data-testid={'company-name'}>{companyState?.name}</h1>
        {user.role < USER_ROLES.teamMember.value && (
          <div className="button-section">
            {user.role <= USER_ROLES.companyOwner.value && (
              <Button
                color={'gray'}
                text="Manage"
                onClick={() => {
                  changeModalState('manage');
                }}
                data-testid={'manage-company-button'}
              />
            )}
            <Button
              color={'gray'}
              text="Add Team Member"
              Icon={PlusIcon}
              iconColor={'white'}
              iconSize={14}
              onClick={() => {
                changeModalState('create');
              }}
              data-testid={'add-team-member-button'}
            />
          </div>
        )}
      </div>
      {loadingGetCompany ? renderLoading() : <TeamList members={companyState?.members} onUpdate={onUpdate} changeModalState={changeModalState} user={user} />}
      <AddUserToCompanyModal
        isOpen={modalOpen === 'create'}
        onClose={() => {
          changeModalState('');
        }}
        companyId={companyState?.id}
        ownerExist={ownerExist}
        userRole={user.role}
      />
      <DeleteUserModal
        isOpen={modalOpen === 'delete'}
        onClose={() => {
          changeModalState('');
        }}
        member={memberSelected}
        loading={loadingDeleteUser}
        onDelete={onDeleteUser}
      />
      <Modal isOpen={modalOpen === 'update'} className={'modal-window confirmation-modal'} onRequestClose={onCloseModal} defaultStyles={defaultStyles}>
        <ConfirmationModal
          onClose={onCloseModal}
          onConfirm={onConfirm}
          title={'Credentials Transfer Warning'}
          trailingText={
            'There can only be one Company Owner. This action will transfer the Company Owner role to the user email provided. The previous Company Owner will be given the role of Team Admin.'
          }
          buttonText={'Confirm'}
        />
      </Modal>
      <CompanyModal
        usedDomains={usedDomains}
        userRole={user.role}
        isOpen={modalOpen === 'manage'}
        onClose={onCloseModal}
        onSave={onUpdateCompany}
        loading={loadingDelete || loadingUpdate}
        company={companyState}
        onDelete={onDeleteCompany}
      />
    </>
  );
};

export default TeamView;
