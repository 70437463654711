import ContextMenu from 'views/components/ContextMenu';
import { MenuItem } from '@szhsin/react-menu';
import React, { useState } from 'react';

const LocationContextMenu = ({ location, remove, ungroup }) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({});

  const openContextMenu = (e) => {
    setAnchorPoint({ x: e.pageX, y: e.pageY });
    setIsContextMenuOpen(true);
  };

  const onCloseContextMenu = () => {
    setIsContextMenuOpen(false);
  };

  return (
    <>
      <div onClick={(event) => openContextMenu(event)} className="menu" data-testid={'location-context-menu'}>
        <div className="toggle">&nbsp;</div>
      </div>
      <ContextMenu isContextMenuOpen={isContextMenuOpen} onCloseContextMenu={onCloseContextMenu} anchorPoint={anchorPoint}>
        <MenuItem
          onClick={() => {
            ungroup([location]);
          }}>
          Ungroup Site
        </MenuItem>
        <MenuItem
          onClick={() => {
            remove([location]);
          }}>
          Delete Site
        </MenuItem>
      </ContextMenu>
    </>
  );
};

export default LocationContextMenu;
