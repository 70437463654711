import React, { useLayoutEffect, useRef, useState } from 'react';
import TemplateContextMenu from './TemplateContextMenu';
import TagIcon from 'views/components/icons/Tag';
import CalendarIcon from 'views/components/icons/Calendar';
import CheckCircleIcon from 'views/components/icons/CheckCircle';

const TemplateCardInformation = ({ name, templateId, project, timesUsed, isOwner, owner, lastUpdated, categories, description, openDeleteModal }) => {
  const [categoriesToShow, setCategoriesToShow] = useState([...categories]);
  const [extraCategories, setExtraCategories] = useState([]);
  const timesUsedText = `${timesUsed} use${timesUsed === 1 ? '' : 's'}`;
  const categoriesContainer = useRef();

  useLayoutEffect(() => {
    if (categoriesContainer?.current?.children) {
      const parent = categoriesContainer.current;
      const children = Object.values(categoriesContainer.current.children);
      const overflowedIndex = children.findIndex((child) => child.offsetLeft + child.offsetWidth + 40 > parent.offsetLeft + parent.offsetWidth);
      if (overflowedIndex !== -1) {
        setCategoriesToShow(categories.slice(0, overflowedIndex - 1));
        setExtraCategories(categories.slice(overflowedIndex - 1));
      }
    }
    // eslint-disable-next-line
  }, [categoriesContainer]);

  return (
    <div className="information">
      <div className="title">
        <h3>{name}</h3>
        {isOwner && <TemplateContextMenu templateId={templateId} project={project} openDeleteModal={openDeleteModal} />}
      </div>
      <div className="small">
        <p>{isOwner ? 'My Template' : `by ${owner.email}`}</p>
      </div>
      <div className="small with-icon">
        <CheckCircleIcon width={14} height={15} color="#707073" />
        <p>{timesUsedText}</p>
        <CalendarIcon width={16} height={17} color="#707073" />
        <p>Last updated: {new Date(lastUpdated).toLocaleDateString()}</p>
      </div>
      <div ref={categoriesContainer} className="tags">
        <TagIcon width={14} height={19} color="#707073" />
        {!categories.length ? (
          'No categories'
        ) : (
          <>
            {categoriesToShow.map((category) => (
              <div key={category} className="container">
                {category}
              </div>
            ))}
            {!!extraCategories.length && (
              <div className="container tooltip-container">
                +{extraCategories.length}
                <span className="tooltip-text">{extraCategories.map((category) => `${category}\n`)}</span>
              </div>
            )}
          </>
        )}
      </div>
      <p className="description">{description}</p>
    </div>
  );
};

export default TemplateCardInformation;
