import LOGGER from './logger';
import { getGtagContext } from './GtagContext';

export default class UserEvents {
  static userLoggedIn(user, authToken) {
    LOGGER.debug(`Tracking login for user id: ${user.id}, client id: ${authToken.authorizationCode}`);

    const gtag = getGtagContext();
    gtag.clientId = authToken.authorizationCode;
    gtag.userId = user.id.toString();
    gtag.event('UserLoggedIn');
  }

  static userLoggedOut() {
    LOGGER.debug('Tracking user logout');

    //push logout event and clear the user
    const gtag = getGtagContext();
    gtag.event('UserLoggedOut');
    gtag.userId = null;
  }
}
