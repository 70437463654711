import { useEffect, useState } from 'react';

const useIsSmallScreen = () => {
  const isSmallScreenView = () => window.innerWidth <= 1149;

  const [isSmallScreen, setIsSmallScreen] = useState(isSmallScreenView());

  useEffect(() => {
    const updateIsSmallScreen = () => setIsSmallScreen(isSmallScreenView());
    window.addEventListener('resize', updateIsSmallScreen);
    return () => {
      window.removeEventListener('resize', updateIsSmallScreen);
    };
  }, []);

  return isSmallScreen;
};

export default useIsSmallScreen;
