import { Mutations, withMutation } from 'gql';

export default withMutation({
  name: 'ADD_LOCATION',
  mutation: Mutations.ADD_LOCATION,
  mutateProp: 'addLocation',
  getMutationArgs(projectId, phaseId, type, addressData, position) {
    const address = addressData.address || {};

    let country = address.country;

    // country needs to be a country code like 'FR' instead of 'France',
    // so that recommendations work correctly.
    if (address.country_code) {
      country = address.country_code.toUpperCase();
    }

    return {
      variables: {
        projectId,
        phaseId,
        type,
        address: {
          label: addressData.display_name || '',
          city: address.city || '',
          state: address.state || '',
          postalCode: address.postcode || '',
          country: country || '',
        },
        coordinates: `${position.lat},${position.lng}`,
      },
    };
  },
});
