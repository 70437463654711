import React, { useState } from 'react';
import Modal from 'react-modal';
import ConfirmationModal from 'views/ProjectListView/modals/ConfirmationModal';
import { Mutations } from 'gql';
import { useMutation } from '@apollo/react-hooks';
import TrashIcon from '../../../../../../icons/Trash';
import defaultStyles from 'views/ProjectDetailView/modals/defaultStyles';

const DeleteServiceButton = ({ projectId, phaseId, serviceConnection }) => {
  const [deprecatedService, setDeprecatedService] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [deleteService, { loading }] = useMutation(Mutations.DELETE_PROJECT_ITEMS);

  const onClick = () => {
    const { service } = serviceConnection;
    if (service.deprecated) {
      setDeprecatedService(serviceConnection);
      setOpenModal(true);
    } else {
      deleteService({
        variables: {
          projectId,
          phaseId,
          locationIds: [],
          locationGroupIds: [],
          serviceConnectionIds: [serviceConnection.id],
        },
      });
    }
  };
  const removeDeprecatedService = async () => {
    deleteService({
      variables: {
        projectId,
        phaseId,
        locationIds: [],
        locationGroupIds: [],
        serviceConnectionIds: [deprecatedService.id],
      },
    });
    setDeprecatedService(null);
    setOpenModal(false);
  };

  return (
    <>
      {!loading ? (
        <>
          <div className="clickable icon" onClick={onClick} data-testid={'delete-row'}>
            <TrashIcon width={13} height={14} color="#333333" />
          </div>
          <Modal isOpen={openModal} className={'modal-window confirmation-modal'} onRequestClose={() => setOpenModal(false)} defaultStyles={defaultStyles}>
            <ConfirmationModal
              onClose={() => setOpenModal(false)}
              onConfirm={removeDeprecatedService}
              title={'Disable service'}
              trailingText={
                'This service is no longer available. After removing it you will be unable to add it back to your project. Are you sure you want to remove it from your project?'
              }
              buttonText={'Remove Permanently'}
            />
          </Modal>
        </>
      ) : (
        <div className="loading"></div>
      )}
    </>
  );
};

export default DeleteServiceButton;
