import React, { createContext, useContext, useState } from 'react';
import { PROJECT_LIST_VIEW_SELECTION } from 'views/ProjectListView/DefaultListViewSelection';

const ListViewContext = createContext(null);

const ListViewProvider = ({ children }) => {
  const getListView = () => JSON.parse(localStorage.getItem('listViewSelection')) || PROJECT_LIST_VIEW_SELECTION;
  const [listView, _setListView] = useState(getListView());

  const setListView = (listView) => {
    localStorage.setItem('listViewSelection', JSON.stringify(listView));
    _setListView(listView);
  };

  return <ListViewContext.Provider value={{ setListView, listView }}>{children}</ListViewContext.Provider>;
};

export const useListView = () => {
  const listViewContext = useContext(ListViewContext);

  if (!listViewContext) {
    throw new Error('ListViewContext not found');
  }

  return listViewContext;
};

export const withListViewProvider = (Component) => {
  return (props) => (
    <ListViewProvider {...props}>
      <Component {...props} />
    </ListViewProvider>
  );
};

export default ListViewProvider;
