import { ApiEvents } from 'analytics';
import { GqlQueryComponent, Queries } from 'gql';
import { LoggerFactory } from 'logger';
import Project from 'model/Project';
import React, { Fragment } from 'react';
import DocumentTitle from 'react-document-title';
import { withRouter } from 'react-router-dom';
import Page from 'utils/Page';
import StopWatch from 'utils/StopWatch';
import Button from 'views/components/Button';
import CreateProjectModal from '../modals/CreateProjectModal';
import DeleteProjectModal from '../modals/DeleteProjectModal';
import LeaveProjectModal from '../modals/LeaveProjectModal';
import ShareProjectModal from '../modals/ShareProjectModal';
import UpdateProjectModal from '../modals/UpdateProjectModal';
import './ProjectListView.scss';
import ProjectsList from './ProjectsList';
import Config from 'Config';

class ProjectsView extends GqlQueryComponent {
  constructor(props) {
    super(props);
    this._isScrolling = this._isScrolling.bind(this);
    this._logger = LoggerFactory.getLogger(this);
    this._sw = null;
    this.state = {
      loading: false,
      modals: {
        create: false,
        update: false,
        share: false,
        delete: false,
        leave: false,
      },
    };
  }

  _onUpdateProject = (selectedProject) => {
    this.setState({
      selectedProjectId: selectedProject.id,
      modals: {
        ...this.state.modals,
        update: true,
      },
    });
  };

  _onShareProject = (selectedProject) => {
    this.setState({
      selectedProjectId: selectedProject.id,
      modals: {
        ...this.state.modals,
        share: true,
      },
    });
  };

  _onSelectionChanged = async (selectedItem) => {
    this._logger.debug(`Selected project changed: ${selectedItem}`);
    this.props.history.push({
      pathname: `/projects/${selectedItem.id}`,
      state: { project: selectedItem },
    });
  };

  _onCreateProjectClick = () => {
    this._logger.debug('Creating new project');
    this.setState({
      modals: {
        ...this.state.modals,
        create: true,
      },
    });
  };

  _onDeleteProjectClick = (project) => {
    this._logger.debug('Deleting project');
    this.setState({
      selectedProjectId: project.id,
      modals: {
        ...this.state.modals,
        delete: true,
      },
    });
  };

  _onLeaveProjectClick = (project) => {
    this._logger.debug('Leaving project');
    this.setState({
      selectedProjectId: project.id,
      modals: {
        ...this.state.modals,
        leave: true,
      },
    });
  };

  _onCloseModal = () => {
    this._logger.debug('Closing modals');
    this.setState({
      isScrolling: false,
      modals: {
        create: false,
        update: false,
        share: false,
        delete: false,
        leave: false,
      },
    });
  };

  _isScrolling() {
    this.setState({ isScrolling: window.pageYOffset > 10 });
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this._isScrolling);
  }

  componentDidMount() {
    //start a stopwatch for timing the API query
    this._sw = StopWatch.startNew();
    document.addEventListener('scroll', this._isScrolling);
  }

  query() {
    return Queries.GET_PROJECTS;
  }

  transform(data) {
    const projects = Project.fromJSONArray(data.projects);
    const sharedProjects = Project.fromJSONArray(data.sharedProjects);
    return { projects, sharedProjects };
  }

  onCompleted() {
    this._logger.debug(`Projects loaded successfully in ${this._sw.milliseconds}ms`);

    //track projects listed timing
    ApiEvents.projectsListed(this._sw.milliseconds);
  }

  renderLoading() {
    return (
      <DocumentTitle title={Page.title('Projects')}>
        <div className="loading-projects">
          <span className="loading-message">
            <div className="spinner"></div>
            Loading Projects
          </span>
        </div>
      </DocumentTitle>
    );
  }

  renderQuery(loading, error, data) {
    const selectedProject = data && [...data.projects, ...data.sharedProjects].find((p) => p.id.equals(this.state.selectedProjectId));
    //TODO: handle load errors

    return (
      <Fragment>
        {Config.MAINTENANCE_TEXT && <div className="maintenance-banner">{Config.MAINTENANCE_TEXT}</div>}
        <div className="list-heading">
          <h1>My Projects</h1>
          <Button color={'gray'} onClick={this._onCreateProjectClick} text="New Project" />
        </div>
        {loading ? (
          this.renderLoading()
        ) : (
          <ProjectsList
            user={this.props.user}
            history={this.props.history}
            projects={data?.projects}
            sharedProjects={data?.sharedProjects}
            onSelectionChanged={this._onSelectionChanged}
            onUpdateProject={this._onUpdateProject}
            onShareProject={this._onShareProject}
            onLeaveProjectClick={this._onLeaveProjectClick}
            onDeleteProjectClick={this._onDeleteProjectClick}
            isScrolling={this.state.isScrolling}
          />
        )}

        {this.state.modals.create && (
          <CreateProjectModal
            companyId={this.props.user.companyId}
            history={this.props.history}
            isOpen={this.state.modals.create}
            onClose={this._onCloseModal}
          />
        )}
        {this.state.modals.update && <UpdateProjectModal isOpen={this.state.modals.update} project={selectedProject} onClose={this._onCloseModal} />}
        {this.state.modals.share && (
          <ShareProjectModal
            isOpen={this.state.modals.share}
            project={selectedProject}
            onClose={this._onCloseModal}
            user={this.props.user}
            refetch={this.queryResult.refetch}
          />
        )}
        {this.state.modals.delete && <DeleteProjectModal isOpen={this.state.modals.delete} project={selectedProject} onClose={this._onCloseModal} />}
        {this.state.modals.leave && (
          <LeaveProjectModal isOpen={this.state.modals.leave} project={selectedProject} onClose={this._onCloseModal} user={this.props.user} />
        )}
      </Fragment>
    );
  }
}

export default withRouter(ProjectsView);

//TODO: maybe? add continuous polling? don't think this will be necessary, but leaving here commented just in case
/*export default graphql(Queries.GET_PROJECTS, {
    options: { pollInterval: 5000 },
})(withRouter(ProjectsView)); */
