import React from 'react';

const OpenLink = ({ width = 10, height = 10, color = 'black', ...rest }) => (
  <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M9.33342 6.05555V9.38892C9.33342 9.68361 9.21635 9.96623 9.00798 10.1746C8.7996 10.383 8.51698 10.5 8.2223 10.5H2.11112C1.81643 10.5 1.53382 10.383 1.32544 10.1746C1.11706 9.96623 1 9.68361 1 9.38892V3.27775C1 2.98306 1.11706 2.70044 1.32544 2.49207C1.53382 2.28369 1.81643 2.16663 2.11112 2.16663H5.44449"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.66678 0.5H11.0001V3.83337" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.88898 6.61117L11.0002 0.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default OpenLink;
