import React, { useRef } from 'react';
import { DragSource } from 'react-dnd';
import { source, dragCollect } from './dndSpecs';
import { MenuItem } from '@szhsin/react-menu';
import { usePhaseItems } from 'providers/PhaseItemsProvider';
import useItemParams from './useItemParams';
import useUpdateItemPreview from './useUpdateItemPreview';
import Checkbox from '../Checkbox';
import { useCurrentProject } from 'providers/ProjectProvider';
import ContextMenu from 'views/components/ContextMenu';

const ServiceItemF = ({
  item,
  pendingAdd,
  connectDragSource,
  onToggleServiceVisibility,
  onDeleteService,
  onSelect,
  onContextMenu,
  isContextMenuOpen,
  selectedItem,
  onCheck,
  connectDragPreview,
}) => {
  const baseClassName = 'service';
  const scrollTargetRef = useRef();
  const { isEditing } = useCurrentProject();
  const { onCloseContextMenu, anchorPoint } = usePhaseItems();
  const { isSelected, isChecked, classNames } = useItemParams({ item, selectedItem, baseClassName, pendingAdd, isEditing });
  useUpdateItemPreview({ item, connectDragPreview, isChecked, isSelected, scrollTargetRef, selectedItem });

  const _onSelect = () => {
    onSelect(item);
  };

  const _onCheck = (e) => {
    e.stopPropagation();
    onCheck(item, e.shiftKey);
  };

  return connectDragSource(
    <li ref={scrollTargetRef} className={classNames} onClick={_onSelect} onContextMenu={onContextMenu}>
      <ContextMenu isContextMenuOpen={isContextMenuOpen} onCloseContextMenu={onCloseContextMenu} anchorPoint={anchorPoint}>
        <MenuItem onClick={onToggleServiceVisibility}>{item.visible ? 'Hide Service' : 'Show Service'}</MenuItem>
        <MenuItem onClick={onDeleteService}>Delete Service</MenuItem>
      </ContextMenu>
      {isEditing && <Checkbox checked={isChecked} onCheck={_onCheck} />}
      <span className="item-text">{item.name}</span>
      {item.service.deprecated ? <span> (deprecated)</span> : null}
      {pendingAdd ? <span className="loading" /> : null}
    </li>
  );
};

export const ServiceItemFDrag = DragSource('projectItem', source('service'), dragCollect)(ServiceItemF);
