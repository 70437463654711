import { useMarketplaceSegments } from 'providers/MarketplaceProvider';
import React from 'react';
import TabPanel from '../../TabPanel';
import TabPanelContent from '../../TabPanelContent';
import TabPanelProductsSide from '../../TabPanelProductsSide';
import getMaxValue from './helpers/getMaxValue';
import makeCalculateWidth from './helpers/makeCalculateWidth';
import MarketplaceCategory from './MarketplaceCategory';
import TabPanelMarketplaceSide from './TabPanelMarketplaceSide';

const buyersConfig = {
  title: 'Participants',
};

const MarketplaceTabPanel = ({ metroCode, metros }) => {
  const { maxAverage } = useMarketplaceSegments();
  const title = 'Equinix Marketplace';
  const { buyers } = metros.find((metro) => metroCode.value === metro.code).marketplace;

  const maxCount = getMaxValue({ buyers });

  const maxValue = maxCount > maxAverage ? maxCount : maxAverage;

  const calculateWidth = makeCalculateWidth(maxValue);

  const productAvailable = !!buyers.length;
  const sideSection = productAvailable ? <TabPanelMarketplaceSide /> : <TabPanelProductsSide />;

  return (
    <TabPanel title={title} sideSection={sideSection} productAvailable={productAvailable} initOpen={true}>
      <TabPanelContent>
        {productAvailable && (
          <div className="marketplace-content">
            <MarketplaceCategory {...buyersConfig} items={buyers} calculateWidth={calculateWidth} />
          </div>
        )}
      </TabPanelContent>
    </TabPanel>
  );
};

export default MarketplaceTabPanel;
