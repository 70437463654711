import ProductEvents from 'analytics/ProductEvents';
import Button from 'views/components/Button';
import { LoggerFactory } from 'logger';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { dismissAllToasts, toast } from 'utils/Toast';
import TextInput from 'views/components/TextInput';
import '../AddView.scss';
import ServiceMetroConnectionsView from '../ServiceMetroConnectionsView';
import ServiceRegionsView from '../ServiceRegionsView';
import ServiceConnection from 'model/ServiceConnection';
import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';
import debounce from 'debounce';
import ConfirmationModal from 'views/ProjectListView/modals/ConfirmationModal';
import Modal from 'react-modal';
import defaultStyles from 'views/ProjectDetailView/modals/defaultStyles';
import Config from 'Config';

const EditServiceView = ({ selectedItem, onClose, onError, projectId, phaseId, onSelectionChanged }) => {
  const [deleteService, { loading }] = useMutation(Mutations.DELETE_PROJECT_ITEMS);
  const [updateService] = useMutation(Mutations.UPDATE_SERVICE);

  const [serviceNotes, setServiceNotes] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const logger = useMemo(() => LoggerFactory.getLogger('EditServiceView'), []);

  const onDeleteClick = async () => {
    const variables = {
      projectId,
      phaseId,
      locationIds: [],
      locationGroupIds: [],
      serviceConnectionIds: [selectedItem.id],
    };

    await deleteService({ variables });
    dismissAllToasts();
    ProductEvents.serviceRemoved(selectedItem.name);

    onSelectionChanged();
  };

  useEffect(() => {
    setServiceNotes(selectedItem?.notes || '');
  }, [selectedItem]);

  const updateServiceNotes = useCallback(
    debounce(async (notes) => {
      logger.debug(`Updating services note with id: '${selectedItem.serviceId}'`);
      try {
        const variables = {
          projectId,
          phaseId,
          serviceId: selectedItem.serviceId,
          metroId: selectedItem.metroId,
          notes,
        };

        await updateService({ variables });
      } catch (err) {
        logger.debug(`An error occurred executing mutation: ${err}`);
        return toast('An unexpected error occurred editing service', 'error');
      }
    }, Config.TEXT_INPUT_DELAY),
    []
  );

  const onNotesChange = (newValue) => {
    setServiceNotes(newValue);
    updateServiceNotes(newValue);
  };

  const checkWarning = () => {
    selectedItem.service.deprecated ? setOpenModal(true) : onDeleteClick();
  };

  const renderProgress = () => {
    return (
      <div className="buttons">
        <div className="loading"></div>
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="buttons">
        {selectedItem.metroId ? (
          <Button color="red" text="Remove From This Metro" onClick={checkWarning} isStretch={true} />
        ) : (
          <Button color="red" text="Remove Service" onClick={checkWarning} isStretch={true} />
        )}
      </div>
    );
  };

  if (!selectedItem || !(selectedItem instanceof ServiceConnection)) {
    return null;
  }

  return (
    <div className="AddView">
      <h2 title={selectedItem.name}>{selectedItem.name}</h2>

      <Tabs>
        <TabList>
          {selectedItem.service.regions.length > 0 && <Tab>Availability Regions</Tab>}
          <Tab>Connections</Tab>
          <Tab>Notes</Tab>
        </TabList>
        {selectedItem.service.regions.length > 0 && (
          <TabPanel>
            <div className="where">
              <ServiceRegionsView
                projectId={projectId}
                phaseId={phaseId}
                serviceConnection={selectedItem}
                availableRegions={selectedItem.service.regions}
                selectedRegions={selectedItem.regions}
              />
            </div>
          </TabPanel>
        )}

        <TabPanel>
          {selectedItem.service.deprecated ? (
            <p className="latency-disclaimer">The "{selectedItem.name}" service is deprecated and can no longer be added to any Metros.</p>
          ) : (
            [
              <p key="disclaimer" className="latency-disclaimer">
                Values between Metros and CSP datacenters are indicative only of relative distance. Actual testing at an Equinix SVC or equivalent is strongly
                suggested for any other purpose.
              </p>,
              <ServiceMetroConnectionsView
                key="connections-view"
                projectId={projectId}
                phaseId={phaseId}
                serviceConnection={selectedItem}
                onSelectionChanged={onSelectionChanged}
                onError={onError}
                onClose={onClose}
              />,
            ]
          )}
        </TabPanel>

        <TabPanel>
          <div className="note">
            <h3>Add a note</h3>
            <TextInput
              placeholder="Enter an important detail or note about this service..."
              multiline={true}
              className="multiline"
              value={serviceNotes}
              onValueChange={onNotesChange}
            />
          </div>
        </TabPanel>
      </Tabs>

      {loading ? renderProgress() : renderButtons()}

      <Modal isOpen={openModal} className={'modal-window confirmation-modal'} onRequestClose={() => setOpenModal(false)} defaultStyles={defaultStyles}>
        <ConfirmationModal
          onClose={() => setOpenModal(false)}
          onConfirm={onDeleteClick}
          loading={loading}
          title={'Disable Service'}
          trailingText={
            'This service is no longer available. After removing it you will be unable to add it back to your project. Are you sure you want to remove it from your project?'
          }
          buttonText={'Remove Permanently'}
        />
      </Modal>
    </div>
  );
};

EditServiceView.propTypes = {
  selectedItem: PropTypes.instanceOf(ServiceConnection),
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default EditServiceView;
