import { useDisableEdgeCategory } from '../../../hocs/withDisableEdge';
import { useEnableEdgeCategory } from '../../../hocs/withEnableEdge';

const useEdgeCategoryMarker = () => {
  const { enableEdgeCategory, loadingEnableEdge } = useEnableEdgeCategory();
  const { disableEdgeCategory, loadingDisableEdge } = useDisableEdgeCategory();

  const onEnableEdgeCategory = async (projectId, phaseId, metroCode, id) => {
    await enableEdgeCategory(projectId, phaseId, metroCode, id);
  };
  const onDisableEdgeCategory = async (projectId, phaseId, metroCode, id) => {
    await disableEdgeCategory(projectId, phaseId, metroCode, id);
  };

  return {
    onEnable: onEnableEdgeCategory,
    onDisable: onDisableEdgeCategory,
    loading: loadingEnableEdge || loadingDisableEdge,
  };
};

export default useEdgeCategoryMarker;
