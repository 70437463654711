import { Mutations } from 'gql';
import withMutation from 'gql/withMutation';
import { useMutation } from 'react-apollo';
import { toast } from 'utils/Toast';

export default withMutation({
  name: 'RECOMMEND_METROS',
  mutation: Mutations.RECOMMEND_METROS,
  mutateProp: 'recommendMetros',
  loadingProp: 'recommendMetrosLoading',
  onError() {},
  getMutationArgs(projectId, phaseId) {
    return {
      variables: {
        projectId,
        phaseId,
      },
    };
  },
});

export const useRecommendMetros = () => {
  const [doRecommendMetros, { loading }] = useMutation(Mutations.RECOMMEND_METROS);

  const recommendMetros = async (projectId, phaseId) => {
    await doRecommendMetros({
      variables: {
        projectId,
        phaseId,
      },
      onCompleted: (data) => {
        toast(`Metro recommendations for '${data.recommendMetros.name}' completed successfully`, 'info');
      },
      onError: () => {
        toast('An unexpected error occurred recommending metros', 'error');
      },
    });
  };
  return { recommendMetros, recommendMetrosLoading: loading };
};
