import React from 'react';
import cn from 'classnames';
import { USER_ROLES } from '../../model/userRoles';

const UserRoleSideNav = ({ selected, onSelect, user }) => {
  return (
    <>
      {user?.companyId && user?.role && (
        <>
          <div className="item title">My Team</div>
          {user.role === USER_ROLES.workbenchAdmin.value ? (
            <div
              onClick={() => onSelect({ category: 'Companies', selected: 'Companies' })}
              className={cn('item option', { selected: selected === 'Companies' })}>
              Companies
            </div>
          ) : (
            <div onClick={() => onSelect({ category: 'Team', selected: 'Team' })} className={cn('item option', { selected: selected === 'Team' })}>
              Team Members
            </div>
          )}
        </>
      )}
    </>
  );
};
export default UserRoleSideNav;
