import { useMutation } from 'react-apollo';
import { Mutations, withMutation } from 'gql';

export default withMutation({
  name: 'DISABLE_NETWORK_EDGE',
  mutation: Mutations.DISABLE_NETWORK_EDGE,
  mutateProp: 'disableEdge',
  getMutationArgs(projectId, phaseId, metroCode, categoryId = null) {
    return {
      variables: {
        projectId,
        phaseId,
        metroCode,
        categoryId,
      },
    };
  },
});

const useDisableEdgeCategory = () => {
  const [doDisableEdge, { data, loading, error }] = useMutation(Mutations.DISABLE_NETWORK_EDGE);

  const disableEdgeCategory = (projectId, phaseId, metroCode, categoryId = null) => doDisableEdge({ variables: { projectId, phaseId, metroCode, categoryId } });

  return {
    disableEdgeCategory,
    dataDisableEdge: data,
    loadingDisableEdge: loading,
    errorDisableEdge: error,
  };
};

export { useDisableEdgeCategory };
