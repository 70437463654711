import React from 'react';

const ConnectIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="2.5" cy="9.03564" r="2" stroke={color} />
      <circle cx="13.5" cy="9.03564" r="2.5" fill={color} />
      <path
        d="M12.584 5.53551C12.3409 4.9781 11.9914 4.45596 11.5354 3.99997C9.58283 2.04735 6.417 2.04735 4.46438 3.99997C4.0084 4.45596 3.6589 4.9781 3.41588 5.53551"
        stroke={color}
      />
    </svg>
  );
};

export default ConnectIcon;
