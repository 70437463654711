export default class UserId {
  constructor(value) {
    this.value = `${value}`;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof UserId)) {
      return false;
    }

    return this.value === other.value;
  }

  toString() {
    return this.value;
  }

  toJSON() {
    return this.toString();
  }

  static fromJSON(json) {
    return new UserId(json);
  }
}
