import React, { useMemo, useState } from 'react';
import { dismissAllToasts } from 'utils/Toast';
import ToggleButton from 'views/components/ToggleButton';
import 'views/components/InfoPanel/children/TableStyles.scss';

const PlatformMetroList = ({ metroList, isSelected, onChecked, onUnchecked, loading }) => {
  const [metrosLoading, setMetrosLoading] = useState([]);

  const sortedList = useMemo(() => metroList?.sort((a, b) => a.region.localeCompare(b.region) || b.name - a.name), [metroList]);

  const _onChecked = async (metro) => {
    setMetrosLoading([...metrosLoading, metro.code]);
    await onChecked(metro);
    dismissAllToasts();
    setMetrosLoading(metrosLoading.splice(metrosLoading.indexOf(metro.code), 1));
  };

  const _onUnchecked = async (metro) => {
    setMetrosLoading([...metrosLoading, metro.code]);
    await onUnchecked(metro);
    dismissAllToasts();
    setMetrosLoading(metrosLoading.splice(metrosLoading.indexOf(metro.code), 1));
  };

  if (!sortedList) return null;

  return (
    <div className={'table'}>
      {sortedList.map((metro) => (
        <div key={metro.code} className={'column'}>
          <div className={'container'}>
            <p className={'light-text'}>{metro.region}</p>
            <p>{metro.name}</p>
          </div>
          <div data-testid={'toggle-container'}>
            <ToggleButton small checked={isSelected(metro)} enabled={!loading} onChecked={() => _onChecked(metro)} onUnchecked={() => _onUnchecked(metro)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlatformMetroList;
