import AuthToken from '../model/AuthToken';
import { LoggerFactory } from '../logger';
import Assert from '../utils/Assert';
import StringUtils from '../utils/StringUtils';

const STORAGE_KEY = 'com.ioaworkbench.authToken';

/**
 * Service for managing the {@link AuthToken) when a user logs in and out.
 * Token is saved to local storage. Storage is not actively cleared upon token expiration,
 * however if a user attempts to retrieve an expired token, it will instead return null.
 */
export class AuthenticationService {
  constructor(storage) {
    Assert.notNull(storage, 'Must provide value for storage in AuthenticationService');

    this._storage = storage;
    this._logger = LoggerFactory.getLogger(this);

    this._logger.debug(`Initializing AuthenticationService with storage: ${storage}`);
    this._token = this.getToken() || null;
  }

  getToken() {
    //if we've already loaded this, just return it
    if (this._token) {
      //this._logger.debug(`Auth token already loaded: ${this._token}`);
      return this._token;
    }

    try {
      //otherwise, load it, parse it, set it, bop it
      const s = this._storage.getItem(STORAGE_KEY);
      if (StringUtils.isWhitespace(s)) {
        this._logger.debug('Auth token not found in local storage');
        return null;
      }

      const json = JSON.parse(s);
      const token = AuthToken.fromJSON(json);

      //TODO: validate JWT token value

      //if token is expired, clear it,
      //otherwise, set and return the token
      if (token.isExpired) {
        this._logger.warn('Auth token is no longer valid (expired), clearing token...');
        this.clearToken();
      } else {
        this._token = token;
        this._logger.debug(`Auth token loaded successfully from local storage: ${this._token}`);
      }
    } catch (e) {
      this._logger.warn(`Could not parse authorization token: ${e.message}`);
    }

    return this._token;
  }

  setToken(token) {
    Assert.instanceOf(token, AuthToken);

    try {
      //TODO: validate JWT token value

      const s = JSON.stringify(token);
      this._storage.setItem(STORAGE_KEY, s);

      this._token = token;
      this._logger.debug(`Auth token saved successfully to local storage: ${this._token}`);
    } catch (e) {
      //TODO: token error!
      this._logger.error(`Could not save authorization token: ${e.message}`, e);
    }
  }

  clearToken() {
    this._storage.removeItem(STORAGE_KEY);
    this._token = null;

    this._logger.debug('Auth token cleared from local storage');
  }
}

//singleton
let instance = null;
const getInstance = () => {
  if (instance) {
    return instance;
  }

  instance = new AuthenticationService(localStorage);

  return instance;
};

export default getInstance;
