import React, { useCallback, useEffect, useState } from 'react';
import TextInput from 'views/components/TextInput';
import Config from 'Config';
import debounce from 'debounce';
import { useMutation } from 'react-apollo';
import { Mutations, Queries } from 'gql';
import TextSelect from 'views/components/TextSelect';
import ConnectIcon from 'views/components/icons/Connect';
import { LoggerFactory } from 'logger';
import { useMoveProjectItems } from 'views/components/PhasePanel/hocs/withMoveProjectItems';
import AddressAutocompleteWithType from 'views/components/AddressAutocompleteWithType';
import TrashIcon from 'views/components/icons/Trash';
import GeoCoordinates from 'model/GeoCoordinates';
import { toast } from 'utils/Toast';
import cn from 'classnames';
import LocationId from 'model/LocationId';

const logger = LoggerFactory.getLogger('update location');
const connectIcon = <ConnectIcon height={12} width={16} color="#2D2D2D" />;

const LocationCard = ({ metros, location, phaseId, projectId, optionsTypeLocations, deleteLocation }) => {
  const [execUpdateLocation, { data: dataLocation, error: errorLocation }] = useMutation(Mutations.UPDATE_LOCATION);
  const { moveProjectItems } = useMoveProjectItems();
  const getCurrentMetro = () =>
    metros.find((metro) => metro.object.locations?.find((metroLocation) => JSON.stringify(metroLocation.id) === JSON.stringify(location.id)));
  const currentType = optionsTypeLocations?.find((type) => JSON.stringify(type.value) === JSON.stringify(location.type.value));
  const currentMetro = getCurrentMetro();

  const [optionsVisibility, setOptionsVisibility] = useState(false);
  const [updatedLocation, setUpdatedLocation] = useState(location);
  const [metro, setMetro] = useState(currentMetro);
  const [typeLocation, setTypeLocation] = useState(currentType);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [updateLodaing, setUpdateLoading] = useState(false);

  useEffect(() => {
    if (!errorLocation) return;
    logger.debug('An unexpected error occurred updating a location');
    toast('An unexpected error occurred updating a location', 'error');
  }, [errorLocation]);

  useEffect(() => {
    if (!dataLocation) return;
    logger.debug('Update location mutation completed successfully');
    toast(`Updated location '${updatedLocation.address.label}' successfully`, 'info');
    // eslint-disable-next-line
  }, [dataLocation]);

  useEffect(() => {
    if (!metros || !updatedLocation.metroId) return;
    setMetro(currentMetro ? currentMetro : null);
    // eslint-disable-next-line
  }, [metros]);

  const onChangeMetro = async (newValue) => {
    setMetro(!newValue.value ? null : newValue);
    await moveProjectItems(projectId, phaseId, newValue.value, [new LocationId(location.id)]);
  };

  const toggleOptionsVisibility = () => {
    setOptionsVisibility(!optionsVisibility);
  };

  const debounceOnChange = useCallback(
    debounce(async (newValue) => {
      logger.debug(`Updating location: ${newValue.id} from phase with id: ${phaseId}`);
      await execUpdateLocation({
        variables: {
          projectId,
          phaseId,
          locationId: newValue.id,
          name: newValue.name,
          type: newValue.type.value,
          address: newValue.address,
          coordinates: newValue.coordinates,
          employees: newValue.employees,
          notes: newValue.notes,
        },
        refetchQueries: [{ query: Queries.GET_PHASE, variables: { projectId, phaseId } }],
      });
      setLoadingAddress(false);
      setUpdateLoading(false);
    }, Config.TEXT_INPUT_DELAY),
    [location]
  );

  const onChangeTypeLocation = (newValue) => {
    const locationWithNewValue = { ...updatedLocation, type: newValue };
    setTypeLocation(newValue);
    setUpdatedLocation(locationWithNewValue);
    debounceOnChange(locationWithNewValue);
  };

  const onChangeLocationAddress = (newValue) => {
    if (newValue) {
      setLoadingAddress(true);
      const { label, city, state, postalCode, country, coordinates } = newValue;
      const locationWithNewValue = { ...updatedLocation, address: { label, city, state, postalCode, country }, coordinates: GeoCoordinates.parse(coordinates) };
      setUpdatedLocation(locationWithNewValue);
      debounceOnChange(locationWithNewValue);
    }
  };

  const onChangeLocation = (newValue) => {
    if (!newValue.employees && newValue.employees === '') {
      newValue.employees = null;
    }
    if (newValue.employees < 0) {
      toast(`${newValue.employees} is an invalid size`, 'error');
      return;
    }
    setUpdateLoading(true);
    const locationWithNewValue = { ...updatedLocation, ...newValue };
    setUpdatedLocation(locationWithNewValue);
    debounceOnChange(locationWithNewValue);
  };

  return (
    <div className={cn('location-card', { isLoading: updateLodaing })} data-testid={'location-card'}>
      <div className="delete" onClick={() => deleteLocation(location.id)} data-testid={'delete-location'}>
        <TrashIcon width={13} height={14} color="#333333" />
      </div>
      <AddressAutocompleteWithType
        options={optionsTypeLocations}
        address={updatedLocation.address}
        onChangeLocationAddress={onChangeLocationAddress}
        typeLocation={typeLocation}
        onChangeTypeLocation={onChangeTypeLocation}
        loading={loadingAddress}
      />
      {optionsVisibility && (
        <>
          <div className="information">
            <p>Size:</p>
            <div className="wrapper">
              <div className="size-buttons">
                <div
                  className={cn('option', { selected: !!updatedLocation.employees && updatedLocation.employees <= 100 })}
                  onClick={() => onChangeLocation({ employees: 50 })}>
                  S
                </div>
                <div
                  className={cn('option', { selected: updatedLocation.employees > 100 && updatedLocation.employees <= 500 })}
                  onClick={() => onChangeLocation({ employees: 250 })}>
                  M
                </div>
                <div className={cn('option', { selected: updatedLocation.employees > 500 })} onClick={() => onChangeLocation({ employees: 1000 })}>
                  L
                </div>
              </div>
              <TextInput
                className="textInput no-spin"
                type="number"
                onValueChange={(newValue) => onChangeLocation({ employees: newValue })}
                placeholder="..."
                value={updatedLocation.employees}
              />
            </div>
          </div>
          <div className="information">
            <p>Custom Label:</p>
            <TextInput
              className="textInput"
              onValueChange={(newValue) => onChangeLocation({ name: newValue })}
              placeholder="..."
              value={updatedLocation.name}
            />
          </div>
          <div className="information">
            <p>Notes:</p>
            <TextInput
              className="textInput"
              onValueChange={(newValue) => onChangeLocation({ notes: newValue })}
              placeholder="..."
              value={updatedLocation.notes}
            />
          </div>
        </>
      )}
      <div className="options">
        <TextSelect icon={connectIcon} placeholder={'Select a Metro...'} options={metros} onChangeTextSelect={onChangeMetro} value={metro} isClereable />
        <div className="visibility" onClick={toggleOptionsVisibility}>
          {optionsVisibility ? 'Hide more options' : 'Show more options'}
        </div>
      </div>
    </div>
  );
};

export default LocationCard;
