import React, { useState, useEffect } from 'react';
import debounce from 'debounce-promise';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import './AddressAutocompleteTextBox.scss';
import { useCallback } from 'react';

const GEOCODER = new OpenStreetMapProvider({
  params: {
    addressdetails: 1,
  },
});

const AddressAutocompleteTextBox = ({ address, placeholder, defaultValue, onAddressChange, clearSelected, autoFocus = false }) => {
  const [options, setOptions] = useState(null);
  const [inputText, setInputText] = useState(address);
  const [loading, setLoading] = useState(false);

  const inputDebounced = useCallback(
    debounce(async (value) => {
      const results = await GEOCODER.search({ query: value });
      setOptions(results);
      setLoading(false);
    }, 300),
    []
  );

  const handleOnChange = (e) => {
    setLoading(true);
    const value = e.target.value;
    setInputText(value);
    if (value?.trim().length > 0) {
      inputDebounced(e.target.value);
    } else {
      setOptions(null);
      setLoading(false);
    }
  };

  const isHongKongAddress = (label, address) => {
    return address.state === 'Hong Kong' || label === 'Hong Kong, China' || (address.country_code === 'CN' && label.includes('Hong Kong'));
  };

  const handleOnClick = (item) => {
    const address = {
      label: item.label,
      city: item.raw.address.city ?? null,
      state: item.raw.address.state,
      country: isHongKongAddress(item.label, item.raw.address) ? 'HK' : item.raw.address.country_code?.toUpperCase(),
      postalCode: item.raw.address.postcode ?? null,
      coordinates: `${item.y},${item.x}`,
    };

    if (!clearSelected) {
      setInputText(address.label);
    } else {
      setInputText('');
    }

    onAddressChange(address);
  };

  useEffect(() => {
    const handleOutsideClick = () => {
      setOptions(null);
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className="AddressAutocompleteTextBox">
      <input type="text" value={inputText || defaultValue} onChange={handleOnChange} placeholder={placeholder} autoFocus={autoFocus} />
      {loading && (
        <div className={'content-options no-result'}>
          <p>Loading...</p>
        </div>
      )}
      {options?.length === 0 && !loading && (
        <div className={'content-options no-result'}>
          <p>Address could not be found. Please try again</p>
        </div>
      )}
      {options?.length > 0 && !loading && (
        <div className="content-options">
          <ul>
            {options.map((option, index) => (
              <li key={`${option.place_id}-${index}`} onClick={() => handleOnClick(option)}>
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AddressAutocompleteTextBox;
