import React from 'react';

const CloseIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg height={height} width={width} stroke={color} {...rest}>
      <path d={`M0 0 L${width} ${height} M${width} 0 L0 ${height}`} width={width} height={height} />
    </svg>
  );
};

export default CloseIcon;
