import { useDisableMetal } from '../../../hocs/withDisableMetal';
import useEnableMetal from '../../../hooks/useEnableMetal';

const useMetalMarker = () => {
  const { enableMetal, loadingEnableMetal } = useEnableMetal();
  const { disableMetal, loadingDisableMetal } = useDisableMetal();

  const onEnableMetal = async (projectId, phaseId, metroCode) => {
    await enableMetal(projectId, phaseId, metroCode);
  };
  const onDisableMetal = async (projectId, phaseId, metroCode) => {
    await disableMetal(projectId, phaseId, metroCode);
  };

  return {
    onEnable: onEnableMetal,
    onDisable: onDisableMetal,
    loading: loadingEnableMetal || loadingDisableMetal,
  };
};

export default useMetalMarker;
