import React from 'react';

const DragIndicatorIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <line x1="7" y1="2.18557e-08" x2="7" y2="1" stroke={color} />
      <line x1="4" y1="2.18557e-08" x2="4" y2="1" stroke={color} />
      <line x1="1" y1="2.18557e-08" x2="1" y2="1" stroke={color} />
      <line x1="7" y1="2" x2="7" y2="3" stroke={color} />
      <line x1="4" y1="2" x2="4" y2="3" stroke={color} />
      <line x1="1" y1="2" x2="1" y2="3" stroke={color} />
    </svg>
  );
};

export default DragIndicatorIcon;
