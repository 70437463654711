import Assert from '../utils/Assert';

export default class Address {
  constructor(label, city, state, postalCode, country) {
    Assert.isString(label);

    this.label = label;
    this.city = city || null;
    this.state = state || null;
    this.postalCode = postalCode || null;
    this.country = country || null;
    Object.freeze(this);
  }

  equals(other) {
    if (!(other instanceof Address)) {
      return false;
    }

    return this.label === other.label;
  }

  toString() {
    return `${this.constructor.name}{ ${this.label} }`;
  }

  toJSON() {
    const { label, city, state, postalCode, country } = this;
    return { label, city, state, postalCode, country };
  }

  static fromJSON(json) {
    const { label, city, state, postalCode, country } = json;

    return new Address(label, city, state, postalCode, country);
  }

  static fromJSONArray(jsonArray) {
    if (!jsonArray) {
      return [];
    }

    const items = [];
    for (const json of jsonArray) {
      const p = Address.fromJSON(json);
      items.push(p);
    }

    return items;
  }
}
