export function source(type) {
  return {
    beginDrag(props) {
      return {
        item: props.item,
        type,
        metroId: props.item.metroId,
      };
    },
    canDrag(monitor) {
      if (!monitor.isEditing) return false;
      return true;
    },
  };
}

export function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

export const target = {
  drop(props, monitor) {
    if (monitor.didDrop()) {
      return; // we dropped on a nested item
    }
    const item = monitor.getItem();
    if (props.id && props.id.equals(item.metroId)) {
      return; // we dragged to the same metro
    }
    if (!props.id && !item.metroId) {
      return; // we dragged from unconnedted to unconnected
    }
    props.onDropped(item.item, props.id);
  },
  canDrop() {
    return true;
  },
};

export const groupTarget = {
  // Used when dropping on a group
  drop(props, monitor) {
    const item = monitor.getItem();
    props.onDropped(item.item, props.item);
  },
  canDrop() {
    return true;
  },
};

export function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
    dragInProgress: monitor.canDrop(),
    sourceItem: monitor.getItem(),
  };
}

export default {
  locationTarget: target,
  dragCollect,
  dropCollect,
};
