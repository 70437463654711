import React from 'react';

const DatacenterIcon = ({ width = 10, height = 10, color = 'black', ...rest }) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00029 10.8078C4.60253 10.8078 2.07021 10.412 1 9.56445V11.3417C1 12.2572 3.68616 13 7.00029 13C10.3144 13 13 12.2572 13 11.3417V9.56445C11.9304 10.412 9.39833 10.8078 7.00029 10.8078V10.8078ZM7.00029 7.92761C4.60253 7.92761 2.07021 7.53178 1 6.68262V8.45983C1 9.37705 3.68616 10.1184 7.00029 10.1184C10.3144 10.1184 13 9.37705 13 8.45983V6.68262C11.9304 7.53178 9.39833 7.92761 7.00029 7.92761V7.92761ZM7.00029 5.04675C4.60253 5.04675 2.07021 4.65092 1 3.80176V5.57898C1 6.4962 3.68616 7.23897 7.00029 7.23897C10.3144 7.23897 13 6.4962 13 5.57898V3.80176C11.9304 4.65092 9.39833 5.04675 7.00029 5.04675V5.04675ZM13 2.65833C13 3.57388 10.3144 4.31527 7.00029 4.31527C3.68616 4.31527 1 3.57388 1 2.65833C1 1.74278 3.68616 1 7.00029 1C10.3144 1 13 1.74278 13 2.65833V2.65833Z"
        fill={color}
      />
    </svg>
  );
};

export default DatacenterIcon;
