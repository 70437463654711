import React from 'react';
import L from 'leaflet';
import { Marker, Pane, Tooltip } from 'react-leaflet';
import cn from 'classnames';
import './ServiceRegionMarker.scss';
import PolylineWithText from './PolylineWithText';

import AWS from '../../../static/img/service-region/AWS.png';
import GoogleCloud from '../../../static/img/service-region/GCP.png';
import Azure from '../../../static/img/service-region/Azure.png';
import OracleCloud from '../../../static/img/service-region/Oracle.png';
import Alibaba from '../../../static/img/service-region/Alibaba.png';
import IBMCloud from '../../../static/img/service-region/IBM.png';
import SAPCloudPlatform from '../../../static/img/service-region/SAP.png';

const icons = {
  AWS,
  GoogleCloud,
  Azure,
  OracleCloud,
  Alibaba,
  IBMCloud,
  SAPCloudPlatform,
};

function ServiceRegionMarker(props) {
  const {
    projectId,
    phaseId,
    selectedItem,
    onEnableRegion,
    onDisableRegion,
    enableServiceRegion,
    disableServiceRegion,
    region,
    selected,
    enabled,
    service,
    layers = {},
    connections = [],
    metros,
    iconSize = 1,
    loading,
  } = props;
  if (enabled && !layers.activeRegions) return null;
  if (!enabled && !layers.inactiveRegions) return null;

  // TODO: Please refactor this handleClick logic
  let handleClick = () => {};
  if (onEnableRegion && onDisableRegion) {
    handleClick = () => onEnableRegion(region);
    if (enabled) {
      handleClick = () => onDisableRegion(region);
    }
  } else if (selectedItem && enableServiceRegion && disableServiceRegion && selectedItem?.service?.id?.equals(service?.id)) {
    handleClick = () => enableServiceRegion(projectId, phaseId, selectedItem, region);
    if (selected) {
      handleClick = () => disableServiceRegion(projectId, phaseId, selectedItem, region);
    }
  }
  const lines = connections
    .map((connection) => ({
      latency: connection.latency,
      metro: metros.find((m) => connection.metroId.equals(m.id)),
    }))
    .map((line) => ({
      ...line,
      position: line.metro.coordinates.toLeaflet(),
    }));
  const className = cn('region', {
    enabled,
    selected,
    loading,
  });
  const size = 30 * iconSize;
  const imgSize = 26 * iconSize;
  const imgStyle = `max-width: ${imgSize}px !important; max-height: ${imgSize}px !important;`;
  const anchor = size / 2;
  const html = `<img src="${icons[service.logo]}" style="${imgStyle}"/>`;
  const options = {
    html,
    iconUrl: icons[service.name],
    iconSize: [size, size],
    iconAnchor: [anchor, anchor],
    className,
  };
  const icon = L.divIcon(options);
  const tooltipOffset = [0, enabled ? -20 : -12];

  return (
    <Pane style={{ zIndex: enabled ? 599 : 300 }}>
      <Marker key={region.id} position={region.coordinates.toLeaflet()} onClick={handleClick} icon={icon}>
        <Tooltip pane={'tooltipPane'} direction="top" offset={tooltipOffset}>
          {region.code}
        </Tooltip>
        {enabled &&
          lines.map((line) => (
            <PolylineWithText
              key={line.metro.id}
              positions={[region.coordinates.toLeaflet(), line.position]}
              color="#FC9B29"
              text={layers.serviceLatencies ? `${line.latency ? line.latency : '--'} ms` : ''}
              iconSize={iconSize}
            />
          ))}
      </Marker>
    </Pane>
  );
}

export default ServiceRegionMarker;
