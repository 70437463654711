import cn from 'classnames';
import L from 'leaflet';
import React from 'react';
import { Marker, Pane, withLeaflet } from 'react-leaflet';
import withUpdateCustomLabelOffset from '../hocs/withUpdateCustomLabelOffset';
import './CustomLabel.scss';

class CustomLabel extends React.Component {
  constructor(props) {
    super(props);
    let { x, y } = props.label.offset || {};
    this.state = {
      offsetX: x,
      offsetY: y,
    };
  }

  handleDragStart = () => {
    if (this.line) {
      this.line.leafletElement.setOpacity(0);
    }
    if (this.marker) {
      this.marker.leafletElement.getElement().classList.add('dragging');
    }
  };

  handleDragEnd = (e) => {
    const { position, iconSize = 1, updateCustomLabelOffset, leaflet } = this.props;
    const zoom = leaflet.map.getZoom();
    let { offsetX, offsetY } = this.state;
    const startPoint = e.target._map.project(position);
    const endPoint = e.target._map.project(e.target.getLatLng());
    offsetX += Math.floor((startPoint.x - endPoint.x) / iconSize / zoom);
    offsetY += Math.floor((startPoint.y - endPoint.y) / iconSize / zoom);
    e.target.setLatLng(position);
    this.setState({ offsetX, offsetY });
    if (this.line) {
      this.line.leafletElement.setOpacity(1);
    }
    if (this.marker) {
      this.marker.leafletElement.getElement().classList.remove('dragging');
    }
    updateCustomLabelOffset(offsetX, offsetY);
  };

  render() {
    const { label, position, iconSize = 1, leaflet } = this.props;
    const zoom = leaflet.map.getZoom();
    // const c = getConstants(index);
    const { offsetX, offsetY } = this.state;
    const OFFSET_X = offsetX * iconSize * zoom;
    const OFFSET_Y = offsetY * iconSize * zoom;
    const wrapperClassName = cn('label', {
      small: iconSize < 0.7,
      mini: iconSize < 0.26,
    });

    const style = `background: ${label.color};`;
    const html = `
        <div class="${wrapperClassName}" style="${style}">
            ${label.value}
        </div>
        `;

    const icon = L.divIcon({
      html,
      iconAnchor: L.point(OFFSET_X, OFFSET_Y),
      className: 'labelMarker',
    });

    return (
      <React.Fragment>
        <Pane style={{ zIndex: 1300 }}>
          <Marker
            ref={(r) => (this.marker = r)}
            position={position}
            icon={icon}
            zIndexOffset={1002}
            draggable
            onDragStart={this.handleDragStart}
            onDragEnd={this.handleDragEnd}
          />
        </Pane>
        <Marker ref={(r) => (this.line = r)} key="line" position={position} icon={line(OFFSET_X, OFFSET_Y, iconSize)} zIndexOffset={-1} interactive={false} />
      </React.Fragment>
    );
  }
}

function line(ox, oy, iconSize) {
  let h, w, x1, y1, x2, y2, ax, ay;

  if (ox < 0) {
    w = -ox;
    x1 = 0;
    x2 = -ox;
    ax = -1;
  } else {
    w = ox;
    x1 = ox;
    x2 = 0;
    ax = ox - 1;
  }
  if (oy < 0) {
    h = -oy;
    y1 = 0;
    y2 = -oy;
    ay = -1;
  } else {
    h = oy;
    y1 = oy;
    y2 = 0;
    ay = oy - 1;
  }

  const svg = `
    <svg width="${w + 2}" height="${h + 2}" viewBox="0 0 ${w + 2} ${h + 2}" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <line x1="${x1 + 1}" y1="${y1 + 1}" x2="${x2 + 1}" y2="${y2 + 1}" stroke="black" stroke-width="${iconSize}"/>
        </g>
    </svg>
    `;
  const sanitizedSvg = svg.replace(/\n/g, '').replace(/#/g, '%23');
  return L.icon({
    iconUrl: `data:image/svg+xml;utf8,${sanitizedSvg}`,
    iconAnchor: L.point(ax, ay),
  });
}
CustomLabel = withLeaflet(CustomLabel);
CustomLabel = withUpdateCustomLabelOffset(CustomLabel);

export default CustomLabel;
