import { useMutation } from 'react-apollo';
import { Mutations } from 'gql';

const useRemoveMetalFromMetro = () => {
  const [doRemoveMetalFromMetro, { data, loading, error }] = useMutation(Mutations.REMOVE_METAL_FROM_METRO);

  const removeMetalFromMetro = (projectId, phaseId, metroCode, planId) => doRemoveMetalFromMetro({ variables: { projectId, phaseId, metroCode, planId } });

  return {
    removeMetalFromMetro,
    dataRemoveMetalFromMetro: data,
    loadingRemoveMetalFromMetro: loading,
    errorRemoveMetalFromMetro: error,
  };
};

export default useRemoveMetalFromMetro;
