import React from 'react';
import Group from 'model/Group';
import { usePhaseItems } from 'providers/PhaseItemsProvider';
import { GroupItemFDrag } from '../GroupItem';

const GroupItemsList = ({ groups, metroId, onDropOnGroup, onSelect, onCheck, selectedItem, isEditing }) => {
  const { pendingProps, defaultProps, pendingRemoval, onContextMenu } = usePhaseItems();

  const groupProps = groups
    .filter((item) => !pendingRemoval.find((i) => item.equals(i)))
    .map(defaultProps)
    .concat(pendingProps(Group, metroId))
    .sort((a, b) => a.item.compare(b.item));

  return groupProps.map((props, index) => (
    <GroupItemFDrag
      isEditing={isEditing}
      onDropped={onDropOnGroup}
      onSelect={onSelect}
      onCheck={onCheck}
      selectedItem={selectedItem}
      key={`group-item-${metroId}-${index}`}
      onContextMenu={onContextMenu}
      {...props}
    />
  ));
};

export default GroupItemsList;
