import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ErrorView from 'views/ErrorView';
import isProjectDetail from 'utils/isProjectDetailView';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: '', errorInfo: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error({ error, errorInfo });
    this.setState({ errorInfo });
  }

  resetState() {
    this.setState({ hasError: false });
  }

  render() {
    const { hasError } = this.state;
    const { children, history, location } = this.props;
    const isProjectDetailView = isProjectDetail(location.pathname);

    if (hasError) {
      return <ErrorView onResetState={this.resetState.bind(this)} history={history} isProjectDetailView={isProjectDetailView} />;
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
