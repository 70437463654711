import { Mutations, withMutation } from 'gql';
import { useMutation } from 'react-apollo';

export default withMutation({
  name: 'REMOVE_METRO',
  mutation: Mutations.REMOVE_METRO,
  mutateProp: 'removeMetro',
  loadingProp: 'removeMetroLoading',
  getMutationArgs(projectId, phaseId, metroId) {
    return {
      variables: {
        projectId,
        phaseId,
        metroId,
      },
    };
  },
});

const useRemoveMetro = () => {
  const [doRemoveMetro, { loading }] = useMutation(Mutations.REMOVE_METRO);

  const removeMetro = (projectId, phaseId, metroId) => doRemoveMetro({ variables: { projectId, phaseId, metroId } });

  return {
    removeMetro,
    removeMetroLoading: loading,
  };
};

export { useRemoveMetro };
