import React from 'react';

const UserIcon = ({ width = 10, height = 10, color, ...rest }) => {
  return (
    <svg width={width} height={height} fill={color ? color : 'currentColor'} xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M7 7.875C7.96615 7.875 8.78646 7.53776 9.46094 6.86328C10.1536 6.17057 10.5 5.34115 10.5 4.375C10.5 3.40885 10.1536 2.58854 9.46094 1.91406C8.78646 1.22135 7.96615 0.875 7 0.875C6.03385 0.875 5.20443 1.22135 4.51172 1.91406C3.83724 2.58854 3.5 3.40885 3.5 4.375C3.5 5.34115 3.83724 6.17057 4.51172 6.86328C5.20443 7.53776 6.03385 7.875 7 7.875ZM9.46094 8.75H8.99609C8.6862 8.89583 8.36719 9.00521 8.03906 9.07812C7.71094 9.15104 7.36458 9.1875 7 9.1875C6.63542 9.1875 6.28906 9.15104 5.96094 9.07812C5.63281 9.00521 5.3138 8.89583 5.00391 8.75H4.53906C3.53646 8.75 2.67057 9.11458 1.94141 9.84375C1.23047 10.5547 0.875 11.4115 0.875 12.4141V13.5625C0.875 13.9271 1.0026 14.237 1.25781 14.4922C1.51302 14.7474 1.82292 14.875 2.1875 14.875H11.8125C12.1771 14.875 12.487 14.7474 12.7422 14.4922C12.9974 14.237 13.125 13.9271 13.125 13.5625V12.4141C13.125 11.4115 12.7604 10.5547 12.0312 9.84375C11.3203 9.11458 10.4635 8.75 9.46094 8.75Z"
        fill={color ? color : 'currentColor'}
      />
    </svg>
  );
};

export default UserIcon;
