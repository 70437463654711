import React, { useEffect, useState, useMemo } from 'react';
import 'react-notion/src/styles.css';
import SortingControlsCompanies from './SortingControlsCompanies';
import { DIRECTION, FILTER, SORT } from './sortingCompanies';
import CompanyListItem from './CompanyListItem';
import { getCachedSort, setCachedSorted } from '../cachedSortingState';

const CompaniesList = ({ companies, onOpenCompanyClick, openModal, user }) => {
  const checkRemainingDays = (removalDate) => {
    if (!removalDate) return true;
    const deletionDate = removalDate + 1000 * 60 * 60 * 24 * 30;
    const remainingDays = Math.ceil((deletionDate - new Date()) / (24 * 60 * 60 * 1000));
    return remainingDays >= 0;
  };
  const cachedStateKey = 'companiesList';
  const userId = user.id.value;
  const { category, direction } = getCachedSort({ cachedStateKey, userId });
  const [companiesFormatted, setCompaniesFormatted] = useState(companies?.filter((company) => checkRemainingDays(company.removalDate)));
  const [searchBy, setSearchBy] = useState('');
  const [filterBy, setFilterBy] = useState(FILTER.ALL_COMPANIES);
  const [sortBy, setSortBy] = useState(category || SORT.COMPANY_NAME);
  const [sortDirection, setSortDirection] = useState(direction || DIRECTION.ASCENDING);

  useEffect(() => {
    if (!companies) return;
    const filteredCompanies = companies?.filter((company) => checkRemainingDays(company.removalDate));
    const filteredBySearch = !searchBy ? filteredCompanies : filterByText(filteredCompanies, searchBy);
    const filteredByFilter = !filterBy
      ? filteredBySearch
      : filteredBySearch.filter((company) => {
          const filterFunction = FILTER_FUNCTIONS[filterBy];
          return !filterFunction || filterFunction(company);
        });
    const sortedBySort = !sortBy ? filteredByFilter : [...filteredByFilter].sort(SORT_FUNCTIONS[sortBy][sortDirection]);
    setCompaniesFormatted(sortedBySort);
    setCachedSorted({ userId, cachedStateKey, category: sortBy, direction: sortDirection });
    // eslint-disable-next-line
  }, [companies, searchBy, sortBy, filterBy, sortDirection]);

  const setSearchText = (value) => {
    setSearchBy(value.trim().toLowerCase());
  };

  const setFilter = (value) => {
    setFilterBy(value);
  };

  const setSort = (value) => {
    setSortBy(value);
  };

  const setDirection = (value) => {
    setSortDirection(value);
  };

  const SORT_FUNCTIONS = useMemo(
    () => ({
      COMPANY_NAME: {
        [DIRECTION.ASCENDING]: (a, b) => a.name.localeCompare(b.name),
        [DIRECTION.DESCENDING]: (a, b) => b.name.localeCompare(a.name),
      },
      COMPANY_STATUS: {
        [DIRECTION.ASCENDING]: (a, b) => a.removalDate - b.removalDate,
        [DIRECTION.DESCENDING]: (a, b) => b.removalDate - a.removalDate,
      },
    }),
    []
  );

  const FILTER_FUNCTIONS = useMemo(
    () => ({
      'Active': (company) => company.removalDate === 0,
      'Pending Deletion': (company) => company.removalDate !== 0,
    }),
    []
  );

  const filterByText = (companies, searchBy) => {
    return companies.filter((company) => company.name.toLowerCase().includes(searchBy));
  };

  const renderNoCompanies = () => (
    <div className="no-projects">
      <h3>No Companies Created Yet</h3>
      <p>Get started on creating your first company</p>
    </div>
  );

  const renderCompanies = (companies) => (
    <div>
      <SortingControlsCompanies
        setSearchBy={setSearchText}
        setFilterBy={setFilter}
        filterBy={filterBy}
        setSortBy={setSort}
        sortBy={sortBy}
        setSortDirection={setDirection}
        sortDirection={sortDirection}
      />
      <ul className="projects-list">
        {companies?.map((company, index) => (
          <CompanyListItem company={company} key={index} onOpenClick={() => onOpenCompanyClick(company)} openModal={openModal} />
        ))}
      </ul>
    </div>
  );

  return !!companies.length ? renderCompanies(companiesFormatted) : renderNoCompanies();
};

export default CompaniesList;
