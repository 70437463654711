import 'react-app-polyfill/ie11';
import './index.scss';
import 'core-js';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import Config from 'Config';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { client } from 'gql';
import { LoggerFactory } from 'logger';
import App from './App';
import { ActivityMonitor } from 'activity';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import { GtagProvider } from 'analytics';
import ExportMap from 'views/components/ProjectMap/ExportMap';
import ProjectPhase from 'model/ProjectPhase';
import Metro from 'model/Metro';
import StonlyScriptProvider from 'providers/StonlyScriptProvider';
import StringUtils from 'utils/StringUtils';

const LOGGER = LoggerFactory.getLogger('root');
LOGGER.info(`Initializing IOA WorkBench v${Config.VERSION}`);

let ROOT = (
  <GtagProvider id={Config.GOOGLE_TAG_MANAGER_ID} appVersion={Config.VERSION.toString()} useTagManager={true}>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ActivityMonitor trackClicks={true}>
          <StonlyScriptProvider wid={Config.STONLY_WID} enabled={!StringUtils.isBlank(Config.STONLY_WID)}>
            <App />
          </StonlyScriptProvider>
        </ActivityMonitor>
      </BrowserRouter>
    </ApolloProvider>
  </GtagProvider>
);

if (window.mapOnly && window.phase) {
  const phase = ProjectPhase.fromJSON(window.phase);
  const metros = Metro.fromJSONArray(window.metros);
  const marketplaceSegment = JSON.parse(window.marketplaceSegment);
  ROOT = (
    <ApolloProvider client={client}>
      <div className="map">
        <ExportMap
          bounds={
            window.bounds || [
              [90, -180],
              [-90, 180],
            ]
          }
          mini={window.mini}
          center={window.center}
          zoom={window.zoom}
          metros={metros}
          phase={phase}
          connectionType={window.connectionType}
          layers={window.layers}
          ringColor={window.ringColor}
          ringLatency={window.ringLatency}
          metroType={window.metroType}
          marketplaceSegment={marketplaceSegment}
          mapType={window.mapType}
        />
      </div>
    </ApolloProvider>
  );
}

Modal.setAppElement('#root');
ReactDOM.render(ROOT, document.getElementById('root'));

if (!window.mapOnly) {
  unregisterServiceWorker();
}

LOGGER.debug('IOA WorkBench initialized successfully');
